import './SentProducts.scss'

import { Alert, message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'

import { useSiparisDetayQuery } from '../../gql/GeneratedGraphqlTypes'
import modalCantaImg1 from '../../images/white_arrow_icon.png'
import { SentProductsItem } from './types'
import { SentProductsDetailItem } from './types-products'

interface Props {
  siparis: string
}

const SentProducts = ({ siparis }: Props) => {
  const temp = parseInt(siparis)
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [messageApi] = message.useMessage()
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  if (error) {
    messageApi.error(`${error}`, 2)
  }
  console.log(data)
  if (!data) {
    return null
  }
  console.log(data)
  const SentProductsItems: SentProductsItem[] = (data?.siparisDetay?.faturalar || []).map((urun) => {
    const originalDate = urun?.gonderi_tarihi || ''
    const dateOnly = new Date(originalDate).toISOString().substr(0, 10)
    return {
      key: urun?.id?.toString() || '',
      belge_kodu: urun?.belge_kodu?.toString() || '', // Provide a default value or proper handling
      gonderi_tarihi: dateOnly, // Provide a default value or proper handling
      durum: urun?.durum || '', // Provide a default value or proper handling
      kargo_takip_no: urun?.kargo_takip_no || '', // Provide a default value or proper handling
      kargo_teslim_tarihi: urun?.kargo_teslim_tarihi || '', // Provide a default value or proper handling
      kargoya_verilme_tarihi: urun?.kargoya_verilme_tarihi || '', // Provide a default value or proper handling
    }
  })

  const columns: ColumnsType<SentProductsItem> = [
    {
      title: 'Gönderi Tarihi',
      dataIndex: 'gonderi_tarihi',
      key: 'gonderi_tarihi',
      align: 'center',
      className: 'column-ayar',
    },
    {
      title: 'Belge No',
      dataIndex: 'belge_kodu',
      key: 'belge_kodu',
      align: 'center',
    },
    {
      title: 'Durum',
      dataIndex: 'durum',
      key: 'durum',
      align: 'center',
    },
    {
      title: 'Ürünler',
      dataIndex: 'fatura_kodu_modal',
      key: 'fatura_kodu_modal',
      render: (_, record) => (
        <div>
          <button className="tab-pane-style-sent green-tab-sent" onClick={() => toggleCollapse(record.key)}>
            <span className="text-size">
              {' '}
              Gönderi <br />
              İçeriği
            </span>
            <br />
            <img src={modalCantaImg1} alt="" className="modalimages" />
          </button>
        </div>
      ),
    },
    {
      title: 'Kargo Takip No',
      dataIndex: 'kargo_no_tuple_duzeltme',
      key: 'kargo_no_tuple_duzeltme',
      align: 'center',
    },
    {
      title: 'Kargo Teslim Tarihi',
      dataIndex: 'kargo_teslim_tarihi',
      key: 'kargo_teslim_tarihi',
      align: 'center',
    },
    {
      title: 'Kargoya Verilme Tarihi',
      dataIndex: 'kargoya_verilme_tarihi',
      key: 'kargoya_verilme_tarihi',
      align: 'center',
    },
  ]
  const SentProductsDetailItems: SentProductsDetailItem[] = (data?.siparisDetay.faturalar || []).flatMap((fatura) => {
    return (fatura?.satirlar || []).map((urun) => {
      return {
        key: fatura?.id !== undefined && fatura.id !== null ? fatura.id.toString() : '',
        adet: typeof urun?.urun_adet === 'number' ? urun.urun_adet : parseFloat(urun?.urun_adet || '0'),
        urun_adi: urun?.urun_ismi || '0',
        tutar:
          typeof urun?.urun_birim_fiyat === 'number'
            ? `${urun.urun_birim_fiyat} ₺`
            : `${parseFloat(urun?.urun_birim_fiyat || '0')} ₺`,
        // Add other properties you want to extract from 'urun'
      }
    })
  })

  const details: ColumnsType<SentProductsDetailItem> = [
    {
      title: 'Ürün Adı',
      dataIndex: 'urun_adi',
      key: 'urun_adi',
      align: 'center',
    },
    {
      title: 'Adet',
      dataIndex: 'adet',
      key: 'adet',
      align: 'center',
    },
    {
      title: 'Tutar',
      dataIndex: 'tutar',
      key: 'tutar',
      align: 'center',
    },
  ]
  const toggleCollapse = (key: string) => {
    if (expandedRowKeys.includes(key)) {
      setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key))
    } else {
      setExpandedRowKeys([...expandedRowKeys, key])
    }
  }

  const isRowExpanded = (record: SentProductsItem) => expandedRowKeys.includes(record.key)
  return (
    <>
      <div className="send-products">
        <Alert
          className="custom-alert"
          description="Siparişinize ait gönderi bilgileri verilmiştir.. "
          type="success"
          closable
        />
        <br />
        <div>
          <Table
            columns={columns}
            dataSource={SentProductsItems}
            bordered={true}
            expandedRowRender={(record) =>
              isRowExpanded(record) && (
                <div>
                  <Table columns={details} dataSource={SentProductsDetailItems} bordered={true} pagination={false} />
                </div>
              )
            }
            onExpand={(expanded, record) => {
              if (expanded) {
                toggleCollapse(record.key)
              }
            }}
            expandedRowKeys={expandedRowKeys}
          />
        </div>
      </div>
    </>
  )
}

export default SentProducts

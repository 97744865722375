import './PendingProducts.scss'

import { Alert, message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { useSiparisDetayQuery } from '../../gql/GeneratedGraphqlTypes'
import { PendingProductsItem } from './types'

interface Props {
  siparis: string
}

const PendingProducts = ({ siparis }: Props) => {
  const temp = parseInt(siparis)
  const [messageApi] = message.useMessage()
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  if (error) {
    messageApi.error(`${error}`, 2)
  }
  if (!data) {
    return null
  }
  const PendingProductsItems: PendingProductsItem[] =
    data?.siparisDetay.satirlar
      ?.filter((urun) => urun?.durum === 'Hazırlanıyor')
      .map((urun) => {
        return {
          key: urun?.id?.toString() || '', //Gerekli
          urun_isim: urun?.urun.isim || '',
          adet: urun?.adet ?? 0,
          kalan_adet: urun?.kalan_adet ?? 0,
          birim_fiyat: parseFloat(`${urun?.birim_fiyat} ₺` ?? '0'),
          kdv_dahil_fiyat: parseFloat(`${urun?.birim_fiyat} ₺` ?? '0'),
          durum: urun?.durum || '',
        }
      }) || []

  const columns: ColumnsType<PendingProductsItem> = [
    {
      title: 'Ürün Adı',
      dataIndex: 'urun_isim',
      key: 'urun_isim',
      align: 'center',
    },
    {
      title: 'Adet',
      dataIndex: 'adet',
      key: 'adet',
      align: 'center',
    },
    {
      title: 'Kalan Adet',
      dataIndex: 'kalan_adet',
      key: 'kalan_adet',
      align: 'center',
    },
    {
      title: 'Fiyat',
      dataIndex: 'birim_fiyat',
      key: 'birim_fiyat',
      align: 'center',
    },
    {
      title: 'Toplam',
      dataIndex: 'kdv_dahil_fiyat',
      key: 'kdv_dahil_fiyat',
      align: 'center',
    },
    {
      title: 'Durum',
      dataIndex: 'durum',
      key: 'durum',
      align: 'center',
    },
  ]

  return (
    <>
      <div className="pending-products">
        <Alert
          className="custom-alert"
          description="Durumu hazırlanıyor olan ürünler listelenmektedir. "
          type="success"
          closable
        />
        <br />
        <div>
          <Table columns={columns} dataSource={PendingProductsItems} bordered={true} />
        </div>
      </div>
    </>
  )
}

export default PendingProducts

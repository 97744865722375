import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type Adres = {
  readonly __typename?: 'Adres'
  readonly adres?: Maybe<Scalars['String']>
  readonly baslik?: Maybe<Scalars['String']>
  readonly firma?: Maybe<Scalars['String']>
  readonly gsm?: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly il?: Maybe<Il>
  readonly ilce?: Maybe<Ilce>
  readonly isim?: Maybe<Scalars['String']>
  readonly kurumsal_adres_mi?: Maybe<Scalars['Boolean']>
  readonly mahalle?: Maybe<Mahalle>
  readonly posta_kodu?: Maybe<Scalars['String']>
  readonly vergi_dairesi?: Maybe<Scalars['String']>
  readonly vergi_no?: Maybe<Scalars['String']>
}

export type AdresInput = {
  readonly adres: Scalars['String']
  readonly adres_tipi?: InputMaybe<Scalars['String']>
  readonly baslik: Scalars['String']
  readonly faks: Scalars['String']
  readonly firma?: InputMaybe<Scalars['String']>
  readonly gsm: Scalars['String']
  readonly il_id: Scalars['Int']
  readonly ilce_id: Scalars['Int']
  readonly isim: Scalars['String']
  readonly kurumsal_adres_mi?: InputMaybe<Scalars['Boolean']>
  readonly mahalle_id?: InputMaybe<Scalars['Int']>
  readonly posta_kodu: Scalars['String']
  readonly tel: Scalars['String']
  readonly vergi_dairesi?: InputMaybe<Scalars['String']>
  readonly vergi_no?: InputMaybe<Scalars['String']>
}

export type CaprazUrun = {
  readonly __typename?: 'CaprazUrun'
  readonly indirimli_birim_fiyat?: Maybe<Scalars['String']>
  readonly indirimsiz_birim_fiyat?: Maybe<Scalars['String']>
  readonly urun?: Maybe<Urun>
}

export type CaprazUrunFiltre = {
  readonly kategori?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
  readonly marka?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
  readonly max_fiyat?: InputMaybe<Scalars['Float']>
  readonly min_fiyat?: InputMaybe<Scalars['Float']>
  readonly sayfa?: InputMaybe<Scalars['Int']>
  readonly urunId?: InputMaybe<Scalars['Int']>
}

export type CaprazUrunResponse = {
  readonly __typename?: 'CaprazUrunResponse'
  readonly capraz_urunler?: Maybe<ReadonlyArray<Maybe<CaprazUrun>>>
  readonly urun_sayisi: Scalars['Int']
}

export type Ders = {
  readonly __typename?: 'Ders'
  readonly id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
  readonly renk?: Maybe<Scalars['String']>
  readonly urun_secili_gelsin?: Maybe<Scalars['Boolean']>
}

export type Fatura = {
  readonly __typename?: 'Fatura'
  readonly belge_kodu?: Maybe<Scalars['String']>
  readonly durum?: Maybe<Scalars['String']>
  readonly gonderi_tarihi?: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly kargo_takip_no?: Maybe<Scalars['String']>
  readonly kargo_teslim_tarihi?: Maybe<Scalars['String']>
  readonly kargoya_verilme_tarihi?: Maybe<Scalars['String']>
  readonly satirlar?: Maybe<ReadonlyArray<Maybe<UrunOzet>>>
}

export type IadeResponse = {
  readonly __typename?: 'IadeResponse'
  readonly kargo_takip_no: Scalars['String']
}

export type IadeSatiriInput = {
  readonly adet: Scalars['Int']
  readonly id: Scalars['Int']
}

export type IcerikSayfasi = {
  readonly __typename?: 'IcerikSayfasi'
  readonly baslik?: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly metin?: Maybe<Scalars['String']>
  readonly slug?: Maybe<Scalars['String']>
}

export type Il = {
  readonly __typename?: 'Il'
  readonly id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
}

export type Ilce = {
  readonly __typename?: 'Ilce'
  readonly id: Scalars['Int']
  readonly il_id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
}

export type KategoriMarkalarResponse = {
  readonly __typename?: 'KategoriMarkalarResponse'
  readonly kategori?: Maybe<UrunKategorisi>
  readonly markalar?: Maybe<ReadonlyArray<Maybe<Marka>>>
}

export type LoginSession = {
  readonly __typename?: 'LoginSession'
  readonly errorMessage?: Maybe<Scalars['String']>
  readonly expire_at?: Maybe<Scalars['Int']>
  readonly token?: Maybe<Scalars['String']>
  readonly user_id?: Maybe<Scalars['Int']>
}

export type Mahalle = {
  readonly __typename?: 'Mahalle'
  readonly id: Scalars['Int']
  readonly ilce_id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
}

export type Marka = {
  readonly __typename?: 'Marka'
  readonly id?: Maybe<Scalars['Int']>
  readonly isim?: Maybe<Scalars['String']>
}

export type Mutation = {
  readonly __typename?: 'Mutation'
  readonly adresDuzenle?: Maybe<Adres>
  readonly adresEkle?: Maybe<Adres>
  readonly adresSil?: Maybe<Scalars['Boolean']>
  readonly iadeTalebiOlustur?: Maybe<IadeResponse>
  readonly login?: Maybe<LoginSession>
  readonly profilGuncelle?: Maybe<Profil>
  readonly refreshToken?: Maybe<LoginSession>
  readonly sepetPaketDuzenle?: Maybe<Sepet>
  readonly sepetPaketEkle?: Maybe<Sepet>
  readonly sepetPaketSil?: Maybe<Sepet>
  readonly sepetUrunDuzenle?: Maybe<Sepet>
  readonly sepetUrunEkle?: Maybe<Scalars['Boolean']>
  readonly sepetUrunSil?: Maybe<Sepet>
  readonly sepetiBosalt?: Maybe<Sepet>
  readonly sifreDegistir?: Maybe<Scalars['Boolean']>
  readonly sifreSifirla?: Maybe<Scalars['Boolean']>
  readonly sinifSecimSifre: SinifSecimSifreResponse
  readonly yeniUye?: Maybe<LoginSession>
}

export type MutationAdresDuzenleArgs = {
  id: Scalars['Int']
  input: AdresInput
}

export type MutationAdresEkleArgs = {
  input: AdresInput
}

export type MutationAdresSilArgs = {
  id: Scalars['Int']
}

export type MutationIadeTalebiOlusturArgs = {
  okul_slug: Scalars['String']
  satirlar?: InputMaybe<ReadonlyArray<InputMaybe<IadeSatiriInput>>>
  siparis_id: Scalars['Int']
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationProfilGuncelleArgs = {
  input: ProfilInput
}

export type MutationSepetPaketDuzenleArgs = {
  okul_slug: Scalars['String']
  sinif_id: Scalars['Int']
  urunler?: InputMaybe<ReadonlyArray<InputMaybe<SepetUrunuInput>>>
}

export type MutationSepetPaketEkleArgs = {
  okul_slug: Scalars['String']
  sinif_id: Scalars['Int']
  urunler?: InputMaybe<ReadonlyArray<InputMaybe<SepetUrunuInput>>>
}

export type MutationSepetPaketSilArgs = {
  okul_slug: Scalars['String']
  sinif_id: Scalars['Int']
}

export type MutationSepetUrunDuzenleArgs = {
  okul_slug: Scalars['String']
  urun?: InputMaybe<SepetUrunuInput>
}

export type MutationSepetUrunEkleArgs = {
  okul_slug: Scalars['String']
  urun?: InputMaybe<SepetUrunuInput>
}

export type MutationSepetUrunSilArgs = {
  okul_slug: Scalars['String']
  urun_id: Scalars['Int']
}

export type MutationSepetiBosaltArgs = {
  okul_slug: Scalars['String']
}

export type MutationSifreDegistirArgs = {
  new_password: Scalars['String']
}

export type MutationSinifSecimSifreArgs = {
  okul_id: Scalars['Int']
  password: Scalars['String']
  sinif_id: Scalars['Int']
}

export type MutationYeniUyeArgs = {
  input: YeniUyeInput
}

export type Okul = {
  readonly __typename?: 'Okul'
  readonly active?: Maybe<Scalars['Boolean']>
  readonly alan_resimleri?: Maybe<ReadonlyArray<Maybe<OkulAlanResmi>>>
  readonly alan_resimlerini_goster?: Maybe<Scalars['Boolean']>
  readonly banner?: Maybe<Scalars['String']>
  readonly bannerlar?: Maybe<ReadonlyArray<Maybe<OkulBanner>>>
  readonly bannerlari_goster?: Maybe<Scalars['Boolean']>
  readonly capraz_urun_satis_popupi_varmi?: Maybe<Scalars['Boolean']>
  readonly capraz_urun_satisi_varmi?: Maybe<Scalars['Boolean']>
  readonly email?: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly indirim?: Maybe<Scalars['Float']>
  readonly indirimli_fiyat_gosterimi?: Maybe<Scalars['Boolean']>
  readonly isim: Scalars['String']
  readonly kapida_odeme_tutari?: Maybe<Scalars['Float']>
  readonly kapida_odeme_varmi?: Maybe<Scalars['Boolean']>
  readonly kargo_tutar_kosulu?: Maybe<Scalars['Float']>
  readonly kargo_tutari?: Maybe<Scalars['Float']>
  readonly logo: Scalars['String']
  readonly odeme_varmi?: Maybe<Scalars['Boolean']>
  readonly ogrenci_girisi_varmi?: Maybe<Scalars['Boolean']>
  readonly renk1?: Maybe<Scalars['String']>
  readonly renk2?: Maybe<Scalars['String']>
  readonly sinif?: Maybe<Sinif>
  readonly sinif_secim_liste?: Maybe<ReadonlyArray<Maybe<SinifSecimSatiri>>>
  readonly slug: Scalars['String']
  readonly stok_bitince_satisa_kapat?: Maybe<Scalars['Boolean']>
  readonly tab_gosterim?: Maybe<Scalars['Boolean']>
  readonly tekstil_toplu_satis?: Maybe<Scalars['Boolean']>
  readonly urunler?: Maybe<CaprazUrunResponse>
  readonly urunler_secili_gelsin?: Maybe<Scalars['Boolean']>
  readonly uyari?: Maybe<Scalars['String']>
  readonly yabanci_dil_indirimi_varmi?: Maybe<Scalars['Boolean']>
}

export type OkulSinifArgs = {
  sinif_id: Scalars['Int']
}

export type OkulSinif_Secim_ListeArgs = {
  ust_kategori_id?: InputMaybe<Scalars['Int']>
}

export type OkulUrunlerArgs = {
  filtre?: InputMaybe<CaprazUrunFiltre>
}

export type OkulAlanResmi = {
  readonly __typename?: 'OkulAlanResmi'
  readonly alan: Scalars['Int']
  readonly id: Scalars['Int']
  readonly link?: Maybe<Scalars['String']>
  readonly resim: Scalars['String']
  readonly resim_mobil?: Maybe<Scalars['String']>
}

export type OkulBanner = {
  readonly __typename?: 'OkulBanner'
  readonly id: Scalars['Int']
  readonly link?: Maybe<Scalars['String']>
  readonly resim: Scalars['String']
  readonly resim_mobil?: Maybe<Scalars['String']>
  readonly sira?: Maybe<Scalars['Int']>
}

export type Profil = {
  readonly __typename?: 'Profil'
  readonly adresler?: Maybe<ReadonlyArray<Maybe<Adres>>>
  readonly cep_telefonu?: Maybe<Scalars['String']>
  readonly email?: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
  readonly soyisim?: Maybe<Scalars['String']>
  readonly tc?: Maybe<Scalars['String']>
}

export type ProfilInput = {
  readonly cep_telefonu?: InputMaybe<Scalars['String']>
  readonly email?: InputMaybe<Scalars['String']>
  readonly isim?: InputMaybe<Scalars['String']>
  readonly soyisim?: InputMaybe<Scalars['String']>
  readonly tc?: InputMaybe<Scalars['String']>
}

export type Query = {
  readonly __typename?: 'Query'
  readonly il?: Maybe<Il>
  readonly ilce?: Maybe<Ilce>
  readonly ilceler: ReadonlyArray<Maybe<Ilce>>
  readonly iller: ReadonlyArray<Maybe<Il>>
  readonly mahalle?: Maybe<Mahalle>
  readonly mahalleler: ReadonlyArray<Maybe<Mahalle>>
  readonly menuUrunKategorileri?: Maybe<ReadonlyArray<Maybe<UrunKategorisi>>>
  readonly okul?: Maybe<Okul>
  readonly profil?: Maybe<Profil>
  readonly sepet?: Maybe<Sepet>
  readonly siparisDetay: Siparis
  readonly siparislerim: ReadonlyArray<Maybe<SiparisOzet>>
  readonly urunKategorileri?: Maybe<ReadonlyArray<Maybe<UrunKategorisi>>>
  readonly urunKategorisi?: Maybe<KategoriMarkalarResponse>
}

export type QueryIlArgs = {
  id: Scalars['Int']
}

export type QueryIlceArgs = {
  id: Scalars['Int']
}

export type QueryIlcelerArgs = {
  il_id: Scalars['Int']
}

export type QueryMahalleArgs = {
  id: Scalars['Int']
}

export type QueryMahallelerArgs = {
  ilce_id: Scalars['Int']
}

export type QueryOkulArgs = {
  slug: Scalars['String']
}

export type QuerySepetArgs = {
  okul_slug: Scalars['String']
}

export type QuerySiparisDetayArgs = {
  siparis_id: Scalars['Int']
}

export type QuerySiparislerimArgs = {
  page?: InputMaybe<Scalars['Int']>
}

export type QueryUrunKategorileriArgs = {
  ust_kategori_id?: InputMaybe<Scalars['Int']>
}

export type QueryUrunKategorisiArgs = {
  id: Scalars['Int']
}

export type Sepet = {
  readonly __typename?: 'Sepet'
  readonly id: Scalars['Int']
  readonly ogretmen_indirimi_uygulandi?: Maybe<Scalars['Boolean']>
  readonly okul_slug: Scalars['String']
  readonly sinif?: Maybe<SepetSinifi>
  readonly urunler?: Maybe<ReadonlyArray<Maybe<SepetSatiri>>>
}

export type SepetSatiri = {
  readonly __typename?: 'SepetSatiri'
  readonly adet: Scalars['Int']
  readonly id: Scalars['Int']
  readonly indirim: Scalars['String']
  readonly indirimli_birim_fiyat: Scalars['String']
  readonly indirimli_fiyat: Scalars['String']
  readonly indirimsiz_birim_fiyat: Scalars['String']
  readonly indirimsiz_fiyat: Scalars['String']
  readonly readonly: Scalars['Boolean']
  readonly sinif_id?: Maybe<Scalars['Int']>
  readonly stok: Scalars['Int']
  readonly urun?: Maybe<Urun>
}

export type SepetSinifi = {
  readonly __typename?: 'SepetSinifi'
  readonly aktif?: Maybe<Scalars['Boolean']>
  readonly ders_kirilimi?: Maybe<Scalars['Boolean']>
  readonly id?: Maybe<Scalars['Int']>
  readonly indirim?: Maybe<Scalars['Float']>
  readonly isim?: Maybe<Scalars['String']>
  readonly kampus_adi?: Maybe<Scalars['String']>
  readonly kapali_uyarisi?: Maybe<Scalars['String']>
}

export type SepetUrunuInput = {
  readonly adet: Scalars['Int']
  readonly ogrenci_ismi?: InputMaybe<Scalars['String']>
  readonly urun_id: Scalars['Int']
}

export type Sinif = {
  readonly __typename?: 'Sinif'
  readonly aktif?: Maybe<Scalars['Boolean']>
  readonly ders_kirilimi?: Maybe<Scalars['Boolean']>
  readonly duyuru_popup?: Maybe<Scalars['String']>
  readonly id: Scalars['Int']
  readonly indirim?: Maybe<Scalars['Float']>
  readonly isim?: Maybe<Scalars['String']>
  readonly kampus_adi?: Maybe<Scalars['String']>
  readonly kapali_uyarisi?: Maybe<Scalars['String']>
  readonly kategori?: Maybe<SinifKategorisi>
  readonly kategori_id?: Maybe<Scalars['Int']>
  readonly liste_resmi?: Maybe<Scalars['String']>
  readonly paket_resmi?: Maybe<Scalars['String']>
  readonly sepet_icinde_degistirme_varmi?: Maybe<Scalars['Boolean']>
  readonly sifre_varmi?: Maybe<Scalars['Boolean']>
  readonly sinif_seviyesi?: Maybe<Scalars['Int']>
  readonly sinif_urunleri?: Maybe<ReadonlyArray<Maybe<SinifUrunu>>>
  readonly sira?: Maybe<Scalars['Int']>
  readonly urunler_secili_gelsin?: Maybe<Scalars['Boolean']>
  readonly uyari?: Maybe<Scalars['String']>
  readonly uye_indirim_var: Scalars['Boolean']
}

export type SinifKategorisi = {
  readonly __typename?: 'SinifKategorisi'
  readonly id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
  readonly liste_resmi?: Maybe<Scalars['String']>
  readonly sira?: Maybe<Scalars['Int']>
  readonly son_dal?: Maybe<Scalars['Boolean']>
  readonly ust_kategori_id?: Maybe<Scalars['Int']>
}

export type SinifSecimSatiri = {
  readonly __typename?: 'SinifSecimSatiri'
  readonly isim?: Maybe<Scalars['String']>
  readonly kategori_id?: Maybe<Scalars['Int']>
  readonly sifre_var?: Maybe<Scalars['Boolean']>
  readonly sinif_id?: Maybe<Scalars['Int']>
}

export type SinifSecimSifre = {
  readonly __typename?: 'SinifSecimSifre'
  readonly sifre?: Maybe<Scalars['String']>
  readonly sinif_id?: Maybe<Scalars['Int']>
}

export type SinifSecimSifreResponse = {
  readonly __typename?: 'SinifSecimSifreResponse'
  readonly message: Scalars['String']
  readonly statusCode: Scalars['Int']
}

export type SinifUrunu = {
  readonly __typename?: 'SinifUrunu'
  readonly adet?: Maybe<Scalars['Int']>
  readonly ders?: Maybe<Ders>
  readonly indirimli_birim_fiyat?: Maybe<Scalars['String']>
  readonly indirimsiz_birim_fiyat?: Maybe<Scalars['String']>
  readonly min_adet?: Maybe<Scalars['Int']>
  readonly satirda_uye_indirimi_var: Scalars['Boolean']
  readonly sinif_id: Scalars['Int']
  readonly sinif_liste_notu?: Maybe<Scalars['String']>
  readonly stok?: Maybe<Scalars['Int']>
  readonly urun?: Maybe<Urun>
  readonly urun_id: Scalars['Int']
  readonly zorunluluk_grubu?: Maybe<Scalars['Int']>
}

export type Siparis = {
  readonly __typename?: 'Siparis'
  readonly durum: Scalars['String']
  readonly fatura_adresi: Scalars['String']
  readonly faturalar?: Maybe<ReadonlyArray<Maybe<Fatura>>>
  readonly id: Scalars['Int']
  readonly kredi_karti_pos?: Maybe<Scalars['String']>
  readonly kredi_karti_vade_secenegi?: Maybe<Scalars['Int']>
  readonly odeme_tipi: Scalars['String']
  readonly satin_alinmayan_urunler?: Maybe<ReadonlyArray<Maybe<UrunOzet>>>
  readonly satirlar: ReadonlyArray<Maybe<SiparisSatiri>>
  readonly tarih: Scalars['String']
  readonly teslimat_adresi: Scalars['String']
  readonly toplam_tutar: Scalars['String']
}

export type SiparisOzet = {
  readonly __typename?: 'SiparisOzet'
  readonly durum: Scalars['String']
  readonly id: Scalars['Int']
  readonly tarih: Scalars['String']
  readonly toplam_tutar: Scalars['String']
}

export type SiparisSatiri = {
  readonly __typename?: 'SiparisSatiri'
  readonly adet?: Maybe<Scalars['Int']>
  readonly birim_fiyat?: Maybe<Scalars['String']>
  readonly durum?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['Int']>
  readonly kalan_adet?: Maybe<Scalars['Int']>
  readonly kargo_takip_no?: Maybe<Scalars['String']>
  readonly min_adet?: Maybe<Scalars['Int']>
  readonly urun: Urun
}

export type Urun = {
  readonly __typename?: 'Urun'
  readonly aciklamalar?: Maybe<Scalars['String']>
  readonly aktif?: Maybe<Scalars['Boolean']>
  readonly bilgiler?: Maybe<ReadonlyArray<Maybe<UrunBilgi>>>
  readonly dinamik_koli_siparis_aktarma?: Maybe<Scalars['Boolean']>
  readonly grup?: Maybe<UrunGrubu>
  readonly id: Scalars['Int']
  readonly isim: Scalars['String']
  readonly kargodan_muaf?: Maybe<Scalars['Boolean']>
  readonly kategori?: Maybe<UrunKategorisi>
  readonly kdv_yuzde: Scalars['Int']
  readonly kisa_aciklama?: Maybe<Scalars['String']>
  readonly kitap_kaplama?: Maybe<Scalars['Int']>
  readonly marka?: Maybe<Marka>
  readonly okul_indirimi?: Maybe<Scalars['Boolean']>
  readonly resim?: Maybe<Scalars['String']>
  readonly resimler?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly sinif_seviyesi?: Maybe<Scalars['Int']>
  readonly urun_kademeleri?: Maybe<Scalars['String']>
  readonly yazar?: Maybe<Scalars['String']>
}

export type UrunBilgi = {
  readonly __typename?: 'UrunBilgi'
  readonly deger?: Maybe<Scalars['String']>
  readonly isim?: Maybe<Scalars['String']>
  readonly tip?: Maybe<Scalars['Int']>
}

export type UrunGrubu = {
  readonly __typename?: 'UrunGrubu'
  readonly id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
  readonly resimler?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly sira?: Maybe<Scalars['Int']>
}

export type UrunKategorisi = {
  readonly __typename?: 'UrunKategorisi'
  readonly id: Scalars['Int']
  readonly isim?: Maybe<Scalars['String']>
  readonly resim?: Maybe<Scalars['String']>
  readonly ust_kategori_id?: Maybe<Scalars['Int']>
}

export type UrunOzet = {
  readonly __typename?: 'UrunOzet'
  readonly urun_adet?: Maybe<Scalars['String']>
  readonly urun_birim_fiyat?: Maybe<Scalars['String']>
  readonly urun_id?: Maybe<Scalars['String']>
  readonly urun_ismi?: Maybe<Scalars['String']>
}

export type UyelikIndirimi = {
  readonly __typename?: 'UyelikIndirimi'
  readonly id: Scalars['Int']
  readonly indirim?: Maybe<Scalars['Float']>
  readonly urun_kategori_id?: Maybe<Scalars['Int']>
}

export type YeniUyeInput = {
  readonly cep_telefonu?: InputMaybe<Scalars['String']>
  readonly email: Scalars['String']
  readonly isim: Scalars['String']
  readonly sifre: Scalars['String']
  readonly soyisim: Scalars['String']
}

export type ProfilGuncelleMutationVariables = Exact<{
  email: Scalars['String']
  isim: Scalars['String']
  soyisim: Scalars['String']
  cep_telefonu: Scalars['String']
}>

export type ProfilGuncelleMutation = {
  readonly __typename?: 'Mutation'
  readonly profilGuncelle?: { readonly __typename?: 'Profil'; readonly id: number } | null
}

export type SifreGuncelleMutationVariables = Exact<{
  new_password: Scalars['String']
}>

export type SifreGuncelleMutation = { readonly __typename?: 'Mutation'; readonly sifreDegistir?: boolean | null }

export type AdresEkleMutationVariables = Exact<{
  adres: Scalars['String']
  adres_tipi: Scalars['String']
  baslik: Scalars['String']
  faks: Scalars['String']
  firma: Scalars['String']
  gsm: Scalars['String']
  il_id: Scalars['Int']
  ilce_id: Scalars['Int']
  mahalle_id: Scalars['Int']
  isim: Scalars['String']
  kurumsal_adres_mi: Scalars['Boolean']
  posta_kodu: Scalars['String']
  tel: Scalars['String']
  vergi_dairesi: Scalars['String']
  vergi_no: Scalars['String']
}>

export type AdresEkleMutation = {
  readonly __typename?: 'Mutation'
  readonly adresEkle?: { readonly __typename?: 'Adres'; readonly id: number } | null
}

export type AdresDuzenleMutationVariables = Exact<{
  id: Scalars['Int']
  adres: Scalars['String']
  baslik: Scalars['String']
  gsm: Scalars['String']
  tel: Scalars['String']
  il_id: Scalars['Int']
  ilce_id: Scalars['Int']
  mahalle_id: Scalars['Int']
  isim: Scalars['String']
  posta_kodu: Scalars['String']
  faks: Scalars['String']
  firma: Scalars['String']
  vergi_dairesi: Scalars['String']
  vergi_no: Scalars['String']
  kurumsal_adres_mi: Scalars['Boolean']
  adres_tipi: Scalars['String']
}>

export type AdresDuzenleMutation = {
  readonly __typename?: 'Mutation'
  readonly adresDuzenle?: { readonly __typename?: 'Adres'; readonly id: number } | null
}

export type AdresSilMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type AdresSilMutation = { readonly __typename?: 'Mutation'; readonly adresSil?: boolean | null }

export type HesapBilgilerimQueryVariables = Exact<{ [key: string]: never }>

export type HesapBilgilerimQuery = {
  readonly __typename?: 'Query'
  readonly profil?: {
    readonly __typename?: 'Profil'
    readonly isim?: string | null
    readonly soyisim?: string | null
    readonly email?: string | null
    readonly cep_telefonu?: string | null
  } | null
}

export type AdreslerimQueryVariables = Exact<{ [key: string]: never }>

export type AdreslerimQuery = {
  readonly __typename?: 'Query'
  readonly profil?: {
    readonly __typename?: 'Profil'
    readonly adresler?: ReadonlyArray<{
      readonly __typename?: 'Adres'
      readonly id: number
      readonly adres?: string | null
      readonly gsm?: string | null
      readonly baslik?: string | null
      readonly isim?: string | null
      readonly posta_kodu?: string | null
      readonly il?: { readonly __typename?: 'Il'; readonly id: number; readonly isim?: string | null } | null
      readonly ilce?: {
        readonly __typename?: 'Ilce'
        readonly id: number
        readonly isim?: string | null
        readonly il_id: number
      } | null
      readonly mahalle?: {
        readonly __typename?: 'Mahalle'
        readonly id: number
        readonly isim?: string | null
        readonly ilce_id: number
      } | null
    } | null> | null
  } | null
}

export type IlQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type IlQuery = {
  readonly __typename?: 'Query'
  readonly il?: { readonly __typename?: 'Il'; readonly id: number; readonly isim?: string | null } | null
}

export type IlcelerQueryVariables = Exact<{
  il_id: Scalars['Int']
}>

export type IlcelerQuery = {
  readonly __typename?: 'Query'
  readonly ilceler: ReadonlyArray<{
    readonly __typename?: 'Ilce'
    readonly id: number
    readonly il_id: number
    readonly isim?: string | null
  } | null>
}

export type MahallelerQueryVariables = Exact<{
  ilce_id: Scalars['Int']
}>

export type MahallelerQuery = {
  readonly __typename?: 'Query'
  readonly mahalleler: ReadonlyArray<{
    readonly __typename?: 'Mahalle'
    readonly id: number
    readonly ilce_id: number
    readonly isim?: string | null
  } | null>
}

export type IllerQueryVariables = Exact<{ [key: string]: never }>

export type IllerQuery = {
  readonly __typename?: 'Query'
  readonly iller: ReadonlyArray<{
    readonly __typename?: 'Il'
    readonly id: number
    readonly isim?: string | null
  } | null>
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type LoginMutation = {
  readonly __typename?: 'Mutation'
  readonly login?: {
    readonly __typename?: 'LoginSession'
    readonly user_id?: number | null
    readonly token?: string | null
    readonly expire_at?: number | null
    readonly errorMessage?: string | null
  } | null
}

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>

export type RefreshTokenMutation = {
  readonly __typename?: 'Mutation'
  readonly refreshToken?: {
    readonly __typename?: 'LoginSession'
    readonly user_id?: number | null
    readonly token?: string | null
    readonly expire_at?: number | null
    readonly errorMessage?: string | null
  } | null
}

export type YeniUyeMutationVariables = Exact<{
  email: Scalars['String']
  isim: Scalars['String']
  soyisim: Scalars['String']
  cep_telefonu?: InputMaybe<Scalars['String']>
  sifre: Scalars['String']
}>

export type YeniUyeMutation = {
  readonly __typename?: 'Mutation'
  readonly yeniUye?: {
    readonly __typename?: 'LoginSession'
    readonly user_id?: number | null
    readonly token?: string | null
    readonly expire_at?: number | null
    readonly errorMessage?: string | null
  } | null
}

export type SepetPaketDuzenleMutationVariables = Exact<{
  okulSlug: Scalars['String']
  sinifId: Scalars['Int']
  urunler?: InputMaybe<ReadonlyArray<InputMaybe<SepetUrunuInput>> | InputMaybe<SepetUrunuInput>>
}>

export type SepetPaketDuzenleMutation = {
  readonly __typename?: 'Mutation'
  readonly sepetPaketDuzenle?: {
    readonly __typename?: 'Sepet'
    readonly id: number
    readonly okul_slug: string
    readonly ogretmen_indirimi_uygulandi?: boolean | null
    readonly sinif?: {
      readonly __typename?: 'SepetSinifi'
      readonly id?: number | null
      readonly isim?: string | null
      readonly kampus_adi?: string | null
      readonly kapali_uyarisi?: string | null
      readonly aktif?: boolean | null
      readonly ders_kirilimi?: boolean | null
      readonly indirim?: number | null
    } | null
    readonly urunler?: ReadonlyArray<{
      readonly __typename?: 'SepetSatiri'
      readonly id: number
      readonly sinif_id?: number | null
      readonly adet: number
      readonly indirimsiz_birim_fiyat: string
      readonly indirimli_birim_fiyat: string
      readonly indirimsiz_fiyat: string
      readonly indirimli_fiyat: string
      readonly indirim: string
      readonly readonly: boolean
      readonly urun?: {
        readonly __typename?: 'Urun'
        readonly id: number
        readonly isim: string
        readonly yazar?: string | null
        readonly resim?: string | null
        readonly sinif_seviyesi?: number | null
        readonly kdv_yuzde: number
        readonly kisa_aciklama?: string | null
        readonly kitap_kaplama?: number | null
        readonly aciklamalar?: string | null
        readonly aktif?: boolean | null
        readonly kargodan_muaf?: boolean | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly isim?: string | null
          readonly deger?: string | null
          readonly tip?: number | null
        } | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly id: number
          readonly isim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SepetUrunSilMutationVariables = Exact<{
  okulSlug: Scalars['String']
  urunId: Scalars['Int']
}>

export type SepetUrunSilMutation = {
  readonly __typename?: 'Mutation'
  readonly sepetUrunSil?: {
    readonly __typename?: 'Sepet'
    readonly id: number
    readonly okul_slug: string
    readonly ogretmen_indirimi_uygulandi?: boolean | null
    readonly sinif?: {
      readonly __typename?: 'SepetSinifi'
      readonly id?: number | null
      readonly isim?: string | null
      readonly kampus_adi?: string | null
      readonly kapali_uyarisi?: string | null
      readonly aktif?: boolean | null
      readonly ders_kirilimi?: boolean | null
      readonly indirim?: number | null
    } | null
    readonly urunler?: ReadonlyArray<{
      readonly __typename?: 'SepetSatiri'
      readonly id: number
      readonly sinif_id?: number | null
      readonly adet: number
      readonly indirimsiz_birim_fiyat: string
      readonly indirimli_birim_fiyat: string
      readonly indirimsiz_fiyat: string
      readonly indirimli_fiyat: string
      readonly indirim: string
      readonly readonly: boolean
      readonly urun?: {
        readonly __typename?: 'Urun'
        readonly id: number
        readonly isim: string
        readonly yazar?: string | null
        readonly resim?: string | null
        readonly sinif_seviyesi?: number | null
        readonly kdv_yuzde: number
        readonly kisa_aciklama?: string | null
        readonly kitap_kaplama?: number | null
        readonly aciklamalar?: string | null
        readonly aktif?: boolean | null
        readonly kargodan_muaf?: boolean | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly isim?: string | null
          readonly deger?: string | null
          readonly tip?: number | null
        } | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly id: number
          readonly isim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SepetUrunDuzenleMutationVariables = Exact<{
  okulSlug: Scalars['String']
  urun?: InputMaybe<SepetUrunuInput>
}>

export type SepetUrunDuzenleMutation = {
  readonly __typename?: 'Mutation'
  readonly sepetUrunDuzenle?: {
    readonly __typename?: 'Sepet'
    readonly id: number
    readonly okul_slug: string
    readonly ogretmen_indirimi_uygulandi?: boolean | null
    readonly sinif?: {
      readonly __typename?: 'SepetSinifi'
      readonly id?: number | null
      readonly isim?: string | null
      readonly kampus_adi?: string | null
      readonly kapali_uyarisi?: string | null
      readonly aktif?: boolean | null
      readonly ders_kirilimi?: boolean | null
      readonly indirim?: number | null
    } | null
    readonly urunler?: ReadonlyArray<{
      readonly __typename?: 'SepetSatiri'
      readonly id: number
      readonly sinif_id?: number | null
      readonly adet: number
      readonly indirimsiz_birim_fiyat: string
      readonly indirimli_birim_fiyat: string
      readonly indirimsiz_fiyat: string
      readonly indirimli_fiyat: string
      readonly indirim: string
      readonly readonly: boolean
      readonly urun?: {
        readonly __typename?: 'Urun'
        readonly id: number
        readonly isim: string
        readonly yazar?: string | null
        readonly resim?: string | null
        readonly sinif_seviyesi?: number | null
        readonly kdv_yuzde: number
        readonly kisa_aciklama?: string | null
        readonly kitap_kaplama?: number | null
        readonly aciklamalar?: string | null
        readonly aktif?: boolean | null
        readonly kargodan_muaf?: boolean | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly isim?: string | null
          readonly deger?: string | null
          readonly tip?: number | null
        } | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly id: number
          readonly isim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SepetiBosaltMutationVariables = Exact<{
  okulSlug: Scalars['String']
}>

export type SepetiBosaltMutation = {
  readonly __typename?: 'Mutation'
  readonly sepetiBosalt?: {
    readonly __typename?: 'Sepet'
    readonly id: number
    readonly okul_slug: string
    readonly ogretmen_indirimi_uygulandi?: boolean | null
    readonly sinif?: {
      readonly __typename?: 'SepetSinifi'
      readonly id?: number | null
      readonly isim?: string | null
      readonly kampus_adi?: string | null
      readonly kapali_uyarisi?: string | null
      readonly aktif?: boolean | null
      readonly ders_kirilimi?: boolean | null
      readonly indirim?: number | null
    } | null
    readonly urunler?: ReadonlyArray<{
      readonly __typename?: 'SepetSatiri'
      readonly id: number
      readonly sinif_id?: number | null
      readonly adet: number
      readonly indirimsiz_birim_fiyat: string
      readonly indirimli_birim_fiyat: string
      readonly indirimsiz_fiyat: string
      readonly indirimli_fiyat: string
      readonly indirim: string
      readonly readonly: boolean
      readonly urun?: {
        readonly __typename?: 'Urun'
        readonly id: number
        readonly isim: string
        readonly yazar?: string | null
        readonly resim?: string | null
        readonly sinif_seviyesi?: number | null
        readonly kdv_yuzde: number
        readonly kisa_aciklama?: string | null
        readonly kitap_kaplama?: number | null
        readonly aciklamalar?: string | null
        readonly aktif?: boolean | null
        readonly kargodan_muaf?: boolean | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly isim?: string | null
          readonly deger?: string | null
          readonly tip?: number | null
        } | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly id: number
          readonly isim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SepetPaketSilMutationVariables = Exact<{
  okulSlug: Scalars['String']
  sinifId: Scalars['Int']
}>

export type SepetPaketSilMutation = {
  readonly __typename?: 'Mutation'
  readonly sepetPaketSil?: {
    readonly __typename?: 'Sepet'
    readonly id: number
    readonly okul_slug: string
    readonly ogretmen_indirimi_uygulandi?: boolean | null
    readonly sinif?: {
      readonly __typename?: 'SepetSinifi'
      readonly id?: number | null
      readonly isim?: string | null
      readonly kampus_adi?: string | null
      readonly kapali_uyarisi?: string | null
      readonly aktif?: boolean | null
      readonly ders_kirilimi?: boolean | null
      readonly indirim?: number | null
    } | null
    readonly urunler?: ReadonlyArray<{
      readonly __typename?: 'SepetSatiri'
      readonly id: number
      readonly sinif_id?: number | null
      readonly adet: number
      readonly indirimsiz_birim_fiyat: string
      readonly indirimli_birim_fiyat: string
      readonly indirimsiz_fiyat: string
      readonly indirimli_fiyat: string
      readonly indirim: string
      readonly readonly: boolean
      readonly urun?: {
        readonly __typename?: 'Urun'
        readonly id: number
        readonly isim: string
        readonly yazar?: string | null
        readonly resim?: string | null
        readonly sinif_seviyesi?: number | null
        readonly kdv_yuzde: number
        readonly kisa_aciklama?: string | null
        readonly kitap_kaplama?: number | null
        readonly aciklamalar?: string | null
        readonly aktif?: boolean | null
        readonly kargodan_muaf?: boolean | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly isim?: string | null
          readonly deger?: string | null
          readonly tip?: number | null
        } | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly id: number
          readonly isim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SepetQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type SepetQuery = {
  readonly __typename?: 'Query'
  readonly sepet?: {
    readonly __typename?: 'Sepet'
    readonly id: number
    readonly ogretmen_indirimi_uygulandi?: boolean | null
    readonly okul_slug: string
    readonly sinif?: {
      readonly __typename?: 'SepetSinifi'
      readonly aktif?: boolean | null
      readonly ders_kirilimi?: boolean | null
      readonly id?: number | null
      readonly indirim?: number | null
      readonly kampus_adi?: string | null
      readonly isim?: string | null
      readonly kapali_uyarisi?: string | null
    } | null
    readonly urunler?: ReadonlyArray<{
      readonly __typename?: 'SepetSatiri'
      readonly adet: number
      readonly stok: number
      readonly indirim: string
      readonly id: number
      readonly indirimli_birim_fiyat: string
      readonly indirimli_fiyat: string
      readonly indirimsiz_birim_fiyat: string
      readonly indirimsiz_fiyat: string
      readonly readonly: boolean
      readonly sinif_id?: number | null
      readonly urun?: {
        readonly __typename?: 'Urun'
        readonly aciklamalar?: string | null
        readonly aktif?: boolean | null
        readonly id: number
        readonly isim: string
        readonly kargodan_muaf?: boolean | null
        readonly okul_indirimi?: boolean | null
        readonly resim?: string | null
        readonly kdv_yuzde: number
        readonly kisa_aciklama?: string | null
        readonly kitap_kaplama?: number | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly sinif_seviyesi?: number | null
        readonly yazar?: string | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly deger?: string | null
          readonly isim?: string | null
          readonly tip?: number | null
        } | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly id: number
          readonly isim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SepetePaketEkleMutationVariables = Exact<{
  okul_slug: Scalars['String']
  sinif_id: Scalars['Int']
  urunler?: InputMaybe<ReadonlyArray<InputMaybe<SepetUrunuInput>> | InputMaybe<SepetUrunuInput>>
}>

export type SepetePaketEkleMutation = {
  readonly __typename?: 'Mutation'
  readonly sepetPaketEkle?: { readonly __typename?: 'Sepet'; readonly id: number } | null
}

export type SinifUrunleriQueryVariables = Exact<{
  slug: Scalars['String']
  sinif_id: Scalars['Int']
}>

export type SinifUrunleriQuery = {
  readonly __typename?: 'Query'
  readonly okul?: {
    readonly __typename?: 'Okul'
    readonly active?: boolean | null
    readonly capraz_urun_satis_popupi_varmi?: boolean | null
    readonly indirim?: number | null
    readonly indirimli_fiyat_gosterimi?: boolean | null
    readonly id: number
    readonly isim: string
    readonly kapida_odeme_tutari?: number | null
    readonly kapida_odeme_varmi?: boolean | null
    readonly kargo_tutar_kosulu?: number | null
    readonly kargo_tutari?: number | null
    readonly ogrenci_girisi_varmi?: boolean | null
    readonly tab_gosterim?: boolean | null
    readonly tekstil_toplu_satis?: boolean | null
    readonly logo: string
    readonly uyari?: string | null
    readonly sinif?: {
      readonly __typename?: 'Sinif'
      readonly aktif?: boolean | null
      readonly uye_indirim_var: boolean
      readonly duyuru_popup?: string | null
      readonly id: number
      readonly indirim?: number | null
      readonly isim?: string | null
      readonly kampus_adi?: string | null
      readonly kapali_uyarisi?: string | null
      readonly ders_kirilimi?: boolean | null
      readonly kategori_id?: number | null
      readonly liste_resmi?: string | null
      readonly sifre_varmi?: boolean | null
      readonly paket_resmi?: string | null
      readonly sepet_icinde_degistirme_varmi?: boolean | null
      readonly sinif_seviyesi?: number | null
      readonly sira?: number | null
      readonly urunler_secili_gelsin?: boolean | null
      readonly uyari?: string | null
      readonly sinif_urunleri?: ReadonlyArray<{
        readonly __typename?: 'SinifUrunu'
        readonly adet?: number | null
        readonly satirda_uye_indirimi_var: boolean
        readonly indirimli_birim_fiyat?: string | null
        readonly indirimsiz_birim_fiyat?: string | null
        readonly min_adet?: number | null
        readonly stok?: number | null
        readonly sinif_id: number
        readonly sinif_liste_notu?: string | null
        readonly urun_id: number
        readonly zorunluluk_grubu?: number | null
        readonly ders?: {
          readonly __typename?: 'Ders'
          readonly id: number
          readonly isim?: string | null
          readonly renk?: string | null
        } | null
        readonly urun?: {
          readonly __typename?: 'Urun'
          readonly aciklamalar?: string | null
          readonly aktif?: boolean | null
          readonly dinamik_koli_siparis_aktarma?: boolean | null
          readonly urun_kademeleri?: string | null
          readonly okul_indirimi?: boolean | null
          readonly isim: string
          readonly id: number
          readonly kargodan_muaf?: boolean | null
          readonly kdv_yuzde: number
          readonly kisa_aciklama?: string | null
          readonly kitap_kaplama?: number | null
          readonly resim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
          readonly sinif_seviyesi?: number | null
          readonly yazar?: string | null
          readonly bilgiler?: ReadonlyArray<{
            readonly __typename?: 'UrunBilgi'
            readonly deger?: string | null
            readonly isim?: string | null
            readonly tip?: number | null
          } | null> | null
          readonly grup?: {
            readonly __typename?: 'UrunGrubu'
            readonly id: number
            readonly isim?: string | null
            readonly resimler?: ReadonlyArray<string | null> | null
            readonly sira?: number | null
          } | null
          readonly kategori?: {
            readonly __typename?: 'UrunKategorisi'
            readonly id: number
            readonly isim?: string | null
            readonly resim?: string | null
            readonly ust_kategori_id?: number | null
          } | null
          readonly marka?: {
            readonly __typename?: 'Marka'
            readonly id?: number | null
            readonly isim?: string | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type SepetUrunEkleMutationVariables = Exact<{
  okulSlug: Scalars['String']
  urun?: InputMaybe<SepetUrunuInput>
}>

export type SepetUrunEkleMutation = { readonly __typename?: 'Mutation'; readonly sepetUrunEkle?: boolean | null }

export type GetCategoriesQueryVariables = Exact<{
  ust_kategori_id: Scalars['Int']
}>

export type GetCategoriesQuery = {
  readonly __typename?: 'Query'
  readonly urunKategorileri?: ReadonlyArray<{
    readonly __typename?: 'UrunKategorisi'
    readonly id: number
    readonly isim?: string | null
    readonly resim?: string | null
    readonly ust_kategori_id?: number | null
  } | null> | null
}

export type KategoriveMarkalarQueryVariables = Exact<{
  kategoriId: Scalars['Int']
}>

export type KategoriveMarkalarQuery = {
  readonly __typename?: 'Query'
  readonly urunKategorisi?: {
    readonly __typename?: 'KategoriMarkalarResponse'
    readonly kategori?: {
      readonly __typename?: 'UrunKategorisi'
      readonly id: number
      readonly isim?: string | null
      readonly resim?: string | null
      readonly ust_kategori_id?: number | null
    } | null
    readonly markalar?: ReadonlyArray<{
      readonly __typename?: 'Marka'
      readonly id?: number | null
      readonly isim?: string | null
    } | null> | null
  } | null
}

export type CarprazUrunlerQueryVariables = Exact<{
  slug: Scalars['String']
  filtre?: InputMaybe<CaprazUrunFiltre>
}>

export type CarprazUrunlerQuery = {
  readonly __typename?: 'Query'
  readonly okul?: {
    readonly __typename?: 'Okul'
    readonly urunler?: {
      readonly __typename?: 'CaprazUrunResponse'
      readonly urun_sayisi: number
      readonly capraz_urunler?: ReadonlyArray<{
        readonly __typename?: 'CaprazUrun'
        readonly indirimli_birim_fiyat?: string | null
        readonly indirimsiz_birim_fiyat?: string | null
        readonly urun?: {
          readonly __typename?: 'Urun'
          readonly aciklamalar?: string | null
          readonly aktif?: boolean | null
          readonly id: number
          readonly isim: string
          readonly kargodan_muaf?: boolean | null
          readonly kdv_yuzde: number
          readonly kisa_aciklama?: string | null
          readonly kitap_kaplama?: number | null
          readonly resim?: string | null
          readonly resimler?: ReadonlyArray<string | null> | null
          readonly sinif_seviyesi?: number | null
          readonly yazar?: string | null
          readonly marka?: {
            readonly __typename?: 'Marka'
            readonly id?: number | null
            readonly isim?: string | null
          } | null
          readonly kategori?: {
            readonly __typename?: 'UrunKategorisi'
            readonly id: number
            readonly isim?: string | null
            readonly resim?: string | null
            readonly ust_kategori_id?: number | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type SinifSecimSifreMutationVariables = Exact<{
  okul_id: Scalars['Int']
  password: Scalars['String']
  sinif_id: Scalars['Int']
}>

export type SinifSecimSifreMutation = {
  readonly __typename?: 'Mutation'
  readonly sinifSecimSifre: {
    readonly __typename?: 'SinifSecimSifreResponse'
    readonly statusCode: number
    readonly message: string
  }
}

export type OkulQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type OkulQuery = {
  readonly __typename?: 'Query'
  readonly okul?: {
    readonly __typename?: 'Okul'
    readonly id: number
    readonly slug: string
    readonly isim: string
    readonly logo: string
    readonly email?: string | null
    readonly banner?: string | null
    readonly uyari?: string | null
    readonly indirim?: number | null
    readonly yabanci_dil_indirimi_varmi?: boolean | null
    readonly renk1?: string | null
    readonly renk2?: string | null
    readonly odeme_varmi?: boolean | null
    readonly ogrenci_girisi_varmi?: boolean | null
    readonly kargo_tutar_kosulu?: number | null
    readonly kargo_tutari?: number | null
    readonly kapida_odeme_varmi?: boolean | null
    readonly kapida_odeme_tutari?: number | null
    readonly stok_bitince_satisa_kapat?: boolean | null
    readonly capraz_urun_satisi_varmi?: boolean | null
    readonly capraz_urun_satis_popupi_varmi?: boolean | null
    readonly indirimli_fiyat_gosterimi?: boolean | null
    readonly active?: boolean | null
    readonly bannerlari_goster?: boolean | null
    readonly alan_resimlerini_goster?: boolean | null
    readonly urunler_secili_gelsin?: boolean | null
    readonly bannerlar?: ReadonlyArray<{
      readonly __typename?: 'OkulBanner'
      readonly id: number
      readonly resim: string
      readonly resim_mobil?: string | null
      readonly link?: string | null
      readonly sira?: number | null
    } | null> | null
    readonly alan_resimleri?: ReadonlyArray<{
      readonly __typename?: 'OkulAlanResmi'
      readonly id: number
      readonly resim: string
      readonly resim_mobil?: string | null
      readonly link?: string | null
      readonly alan: number
    } | null> | null
  } | null
}

export type SinifListesiVeKategorileriQueryVariables = Exact<{
  slug: Scalars['String']
  ust_kategori_id?: InputMaybe<Scalars['Int']>
}>

export type SinifListesiVeKategorileriQuery = {
  readonly __typename?: 'Query'
  readonly okul?: {
    readonly __typename?: 'Okul'
    readonly sinif_secim_liste?: ReadonlyArray<{
      readonly __typename?: 'SinifSecimSatiri'
      readonly sinif_id?: number | null
      readonly kategori_id?: number | null
      readonly isim?: string | null
      readonly sifre_var?: boolean | null
    } | null> | null
  } | null
}

export type IadeTalebiOlusturMutationVariables = Exact<{
  okul_slug: Scalars['String']
  siparis_id: Scalars['Int']
  satirlar?: InputMaybe<ReadonlyArray<InputMaybe<IadeSatiriInput>> | InputMaybe<IadeSatiriInput>>
}>

export type IadeTalebiOlusturMutation = {
  readonly __typename?: 'Mutation'
  readonly iadeTalebiOlustur?: { readonly __typename?: 'IadeResponse'; readonly kargo_takip_no: string } | null
}

export type SiparislerimQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
}>

export type SiparislerimQuery = {
  readonly __typename?: 'Query'
  readonly siparislerim: ReadonlyArray<{
    readonly __typename?: 'SiparisOzet'
    readonly id: number
    readonly tarih: string
    readonly toplam_tutar: string
    readonly durum: string
  } | null>
}

export type SiparisDetayQueryVariables = Exact<{
  siparis_id: Scalars['Int']
}>

export type SiparisDetayQuery = {
  readonly __typename?: 'Query'
  readonly siparisDetay: {
    readonly __typename?: 'Siparis'
    readonly id: number
    readonly tarih: string
    readonly toplam_tutar: string
    readonly durum: string
    readonly kredi_karti_pos?: string | null
    readonly kredi_karti_vade_secenegi?: number | null
    readonly odeme_tipi: string
    readonly teslimat_adresi: string
    readonly fatura_adresi: string
    readonly satirlar: ReadonlyArray<{
      readonly __typename?: 'SiparisSatiri'
      readonly adet?: number | null
      readonly birim_fiyat?: string | null
      readonly durum?: string | null
      readonly id?: number | null
      readonly kalan_adet?: number | null
      readonly kargo_takip_no?: string | null
      readonly min_adet?: number | null
      readonly urun: {
        readonly __typename?: 'Urun'
        readonly id: number
        readonly isim: string
        readonly kisa_aciklama?: string | null
        readonly aciklamalar?: string | null
        readonly kdv_yuzde: number
        readonly sinif_seviyesi?: number | null
        readonly aktif?: boolean | null
        readonly yazar?: string | null
        readonly resim?: string | null
        readonly kargodan_muaf?: boolean | null
        readonly kitap_kaplama?: number | null
        readonly resimler?: ReadonlyArray<string | null> | null
        readonly grup?: {
          readonly __typename?: 'UrunGrubu'
          readonly isim?: string | null
          readonly id: number
          readonly resimler?: ReadonlyArray<string | null> | null
        } | null
        readonly marka?: {
          readonly __typename?: 'Marka'
          readonly id?: number | null
          readonly isim?: string | null
        } | null
        readonly kategori?: {
          readonly __typename?: 'UrunKategorisi'
          readonly id: number
          readonly isim?: string | null
          readonly resim?: string | null
          readonly ust_kategori_id?: number | null
        } | null
        readonly bilgiler?: ReadonlyArray<{
          readonly __typename?: 'UrunBilgi'
          readonly deger?: string | null
          readonly isim?: string | null
          readonly tip?: number | null
        } | null> | null
      }
    } | null>
    readonly faturalar?: ReadonlyArray<{
      readonly __typename?: 'Fatura'
      readonly id: number
      readonly durum?: string | null
      readonly belge_kodu?: string | null
      readonly gonderi_tarihi?: string | null
      readonly kargo_takip_no?: string | null
      readonly kargo_teslim_tarihi?: string | null
      readonly kargoya_verilme_tarihi?: string | null
      readonly satirlar?: ReadonlyArray<{
        readonly __typename?: 'UrunOzet'
        readonly urun_id?: string | null
        readonly urun_adet?: string | null
        readonly urun_birim_fiyat?: string | null
        readonly urun_ismi?: string | null
      } | null> | null
    } | null> | null
    readonly satin_alinmayan_urunler?: ReadonlyArray<{
      readonly __typename?: 'UrunOzet'
      readonly urun_id?: string | null
      readonly urun_ismi?: string | null
      readonly urun_birim_fiyat?: string | null
      readonly urun_adet?: string | null
    } | null> | null
  }
}

export const ProfilGuncelleDocument = gql`
  mutation ProfilGuncelle($email: String!, $isim: String!, $soyisim: String!, $cep_telefonu: String!) {
    profilGuncelle(input: { email: $email, isim: $isim, soyisim: $soyisim, cep_telefonu: $cep_telefonu }) {
      id
    }
  }
`
export type ProfilGuncelleMutationFn = Apollo.MutationFunction<ProfilGuncelleMutation, ProfilGuncelleMutationVariables>

/**
 * __useProfilGuncelleMutation__
 *
 * To run a mutation, you first call `useProfilGuncelleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfilGuncelleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profilGuncelleMutation, { data, loading, error }] = useProfilGuncelleMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isim: // value for 'isim'
 *      soyisim: // value for 'soyisim'
 *      cep_telefonu: // value for 'cep_telefonu'
 *   },
 * });
 */
export function useProfilGuncelleMutation(
  baseOptions?: Apollo.MutationHookOptions<ProfilGuncelleMutation, ProfilGuncelleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ProfilGuncelleMutation, ProfilGuncelleMutationVariables>(ProfilGuncelleDocument, options)
}
export type ProfilGuncelleMutationHookResult = ReturnType<typeof useProfilGuncelleMutation>
export type ProfilGuncelleMutationResult = Apollo.MutationResult<ProfilGuncelleMutation>
export type ProfilGuncelleMutationOptions = Apollo.BaseMutationOptions<
  ProfilGuncelleMutation,
  ProfilGuncelleMutationVariables
>
export const SifreGuncelleDocument = gql`
  mutation SifreGuncelle($new_password: String!) {
    sifreDegistir(new_password: $new_password)
  }
`
export type SifreGuncelleMutationFn = Apollo.MutationFunction<SifreGuncelleMutation, SifreGuncelleMutationVariables>

/**
 * __useSifreGuncelleMutation__
 *
 * To run a mutation, you first call `useSifreGuncelleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSifreGuncelleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sifreGuncelleMutation, { data, loading, error }] = useSifreGuncelleMutation({
 *   variables: {
 *      new_password: // value for 'new_password'
 *   },
 * });
 */
export function useSifreGuncelleMutation(
  baseOptions?: Apollo.MutationHookOptions<SifreGuncelleMutation, SifreGuncelleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SifreGuncelleMutation, SifreGuncelleMutationVariables>(SifreGuncelleDocument, options)
}
export type SifreGuncelleMutationHookResult = ReturnType<typeof useSifreGuncelleMutation>
export type SifreGuncelleMutationResult = Apollo.MutationResult<SifreGuncelleMutation>
export type SifreGuncelleMutationOptions = Apollo.BaseMutationOptions<
  SifreGuncelleMutation,
  SifreGuncelleMutationVariables
>
export const AdresEkleDocument = gql`
  mutation AdresEkle(
    $adres: String!
    $adres_tipi: String!
    $baslik: String!
    $faks: String!
    $firma: String!
    $gsm: String!
    $il_id: Int!
    $ilce_id: Int!
    $mahalle_id: Int!
    $isim: String!
    $kurumsal_adres_mi: Boolean!
    $posta_kodu: String!
    $tel: String!
    $vergi_dairesi: String!
    $vergi_no: String!
  ) {
    adresEkle(
      input: {
        adres: $adres
        adres_tipi: $adres_tipi
        baslik: $baslik
        faks: $faks
        firma: $firma
        gsm: $gsm
        il_id: $il_id
        ilce_id: $ilce_id
        isim: $isim
        kurumsal_adres_mi: $kurumsal_adres_mi
        mahalle_id: $mahalle_id
        posta_kodu: $posta_kodu
        tel: $tel
        vergi_dairesi: $vergi_dairesi
        vergi_no: $vergi_no
      }
    ) {
      id
    }
  }
`
export type AdresEkleMutationFn = Apollo.MutationFunction<AdresEkleMutation, AdresEkleMutationVariables>

/**
 * __useAdresEkleMutation__
 *
 * To run a mutation, you first call `useAdresEkleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdresEkleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adresEkleMutation, { data, loading, error }] = useAdresEkleMutation({
 *   variables: {
 *      adres: // value for 'adres'
 *      adres_tipi: // value for 'adres_tipi'
 *      baslik: // value for 'baslik'
 *      faks: // value for 'faks'
 *      firma: // value for 'firma'
 *      gsm: // value for 'gsm'
 *      il_id: // value for 'il_id'
 *      ilce_id: // value for 'ilce_id'
 *      mahalle_id: // value for 'mahalle_id'
 *      isim: // value for 'isim'
 *      kurumsal_adres_mi: // value for 'kurumsal_adres_mi'
 *      posta_kodu: // value for 'posta_kodu'
 *      tel: // value for 'tel'
 *      vergi_dairesi: // value for 'vergi_dairesi'
 *      vergi_no: // value for 'vergi_no'
 *   },
 * });
 */
export function useAdresEkleMutation(
  baseOptions?: Apollo.MutationHookOptions<AdresEkleMutation, AdresEkleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdresEkleMutation, AdresEkleMutationVariables>(AdresEkleDocument, options)
}
export type AdresEkleMutationHookResult = ReturnType<typeof useAdresEkleMutation>
export type AdresEkleMutationResult = Apollo.MutationResult<AdresEkleMutation>
export type AdresEkleMutationOptions = Apollo.BaseMutationOptions<AdresEkleMutation, AdresEkleMutationVariables>
export const AdresDuzenleDocument = gql`
  mutation AdresDuzenle(
    $id: Int!
    $adres: String!
    $baslik: String!
    $gsm: String!
    $tel: String!
    $il_id: Int!
    $ilce_id: Int!
    $mahalle_id: Int!
    $isim: String!
    $posta_kodu: String!
    $faks: String!
    $firma: String!
    $vergi_dairesi: String!
    $vergi_no: String!
    $kurumsal_adres_mi: Boolean!
    $adres_tipi: String!
  ) {
    adresDuzenle(
      id: $id
      input: {
        adres: $adres
        baslik: $baslik
        gsm: $gsm
        tel: $tel
        il_id: $il_id
        ilce_id: $ilce_id
        mahalle_id: $mahalle_id
        isim: $isim
        posta_kodu: $posta_kodu
        faks: $faks
        firma: $firma
        vergi_dairesi: $vergi_dairesi
        vergi_no: $vergi_no
        kurumsal_adres_mi: $kurumsal_adres_mi
        adres_tipi: $adres_tipi
      }
    ) {
      id
    }
  }
`
export type AdresDuzenleMutationFn = Apollo.MutationFunction<AdresDuzenleMutation, AdresDuzenleMutationVariables>

/**
 * __useAdresDuzenleMutation__
 *
 * To run a mutation, you first call `useAdresDuzenleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdresDuzenleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adresDuzenleMutation, { data, loading, error }] = useAdresDuzenleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adres: // value for 'adres'
 *      baslik: // value for 'baslik'
 *      gsm: // value for 'gsm'
 *      tel: // value for 'tel'
 *      il_id: // value for 'il_id'
 *      ilce_id: // value for 'ilce_id'
 *      mahalle_id: // value for 'mahalle_id'
 *      isim: // value for 'isim'
 *      posta_kodu: // value for 'posta_kodu'
 *      faks: // value for 'faks'
 *      firma: // value for 'firma'
 *      vergi_dairesi: // value for 'vergi_dairesi'
 *      vergi_no: // value for 'vergi_no'
 *      kurumsal_adres_mi: // value for 'kurumsal_adres_mi'
 *      adres_tipi: // value for 'adres_tipi'
 *   },
 * });
 */
export function useAdresDuzenleMutation(
  baseOptions?: Apollo.MutationHookOptions<AdresDuzenleMutation, AdresDuzenleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdresDuzenleMutation, AdresDuzenleMutationVariables>(AdresDuzenleDocument, options)
}
export type AdresDuzenleMutationHookResult = ReturnType<typeof useAdresDuzenleMutation>
export type AdresDuzenleMutationResult = Apollo.MutationResult<AdresDuzenleMutation>
export type AdresDuzenleMutationOptions = Apollo.BaseMutationOptions<
  AdresDuzenleMutation,
  AdresDuzenleMutationVariables
>
export const AdresSilDocument = gql`
  mutation AdresSil($id: Int!) {
    adresSil(id: $id)
  }
`
export type AdresSilMutationFn = Apollo.MutationFunction<AdresSilMutation, AdresSilMutationVariables>

/**
 * __useAdresSilMutation__
 *
 * To run a mutation, you first call `useAdresSilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdresSilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adresSilMutation, { data, loading, error }] = useAdresSilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdresSilMutation(
  baseOptions?: Apollo.MutationHookOptions<AdresSilMutation, AdresSilMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdresSilMutation, AdresSilMutationVariables>(AdresSilDocument, options)
}
export type AdresSilMutationHookResult = ReturnType<typeof useAdresSilMutation>
export type AdresSilMutationResult = Apollo.MutationResult<AdresSilMutation>
export type AdresSilMutationOptions = Apollo.BaseMutationOptions<AdresSilMutation, AdresSilMutationVariables>
export const HesapBilgilerimDocument = gql`
  query HesapBilgilerim {
    profil {
      isim
      soyisim
      email
      cep_telefonu
    }
  }
`

/**
 * __useHesapBilgilerimQuery__
 *
 * To run a query within a React component, call `useHesapBilgilerimQuery` and pass it any options that fit your needs.
 * When your component renders, `useHesapBilgilerimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHesapBilgilerimQuery({
 *   variables: {
 *   },
 * });
 */
export function useHesapBilgilerimQuery(
  baseOptions?: Apollo.QueryHookOptions<HesapBilgilerimQuery, HesapBilgilerimQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HesapBilgilerimQuery, HesapBilgilerimQueryVariables>(HesapBilgilerimDocument, options)
}
export function useHesapBilgilerimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HesapBilgilerimQuery, HesapBilgilerimQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HesapBilgilerimQuery, HesapBilgilerimQueryVariables>(HesapBilgilerimDocument, options)
}
export type HesapBilgilerimQueryHookResult = ReturnType<typeof useHesapBilgilerimQuery>
export type HesapBilgilerimLazyQueryHookResult = ReturnType<typeof useHesapBilgilerimLazyQuery>
export type HesapBilgilerimQueryResult = Apollo.QueryResult<HesapBilgilerimQuery, HesapBilgilerimQueryVariables>
export const AdreslerimDocument = gql`
  query Adreslerim {
    profil {
      adresler {
        id
        adres
        gsm
        baslik
        isim
        posta_kodu
        il {
          id
          isim
        }
        ilce {
          id
          isim
          il_id
        }
        mahalle {
          id
          isim
          ilce_id
        }
      }
    }
  }
`

/**
 * __useAdreslerimQuery__
 *
 * To run a query within a React component, call `useAdreslerimQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdreslerimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdreslerimQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdreslerimQuery(baseOptions?: Apollo.QueryHookOptions<AdreslerimQuery, AdreslerimQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdreslerimQuery, AdreslerimQueryVariables>(AdreslerimDocument, options)
}
export function useAdreslerimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdreslerimQuery, AdreslerimQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdreslerimQuery, AdreslerimQueryVariables>(AdreslerimDocument, options)
}
export type AdreslerimQueryHookResult = ReturnType<typeof useAdreslerimQuery>
export type AdreslerimLazyQueryHookResult = ReturnType<typeof useAdreslerimLazyQuery>
export type AdreslerimQueryResult = Apollo.QueryResult<AdreslerimQuery, AdreslerimQueryVariables>
export const IlDocument = gql`
  query il($id: Int!) {
    il(id: $id) {
      id
      isim
    }
  }
`

/**
 * __useIlQuery__
 *
 * To run a query within a React component, call `useIlQuery` and pass it any options that fit your needs.
 * When your component renders, `useIlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIlQuery(baseOptions: Apollo.QueryHookOptions<IlQuery, IlQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IlQuery, IlQueryVariables>(IlDocument, options)
}
export function useIlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IlQuery, IlQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IlQuery, IlQueryVariables>(IlDocument, options)
}
export type IlQueryHookResult = ReturnType<typeof useIlQuery>
export type IlLazyQueryHookResult = ReturnType<typeof useIlLazyQuery>
export type IlQueryResult = Apollo.QueryResult<IlQuery, IlQueryVariables>
export const IlcelerDocument = gql`
  query ilceler($il_id: Int!) {
    ilceler(il_id: $il_id) {
      id
      il_id
      isim
    }
  }
`

/**
 * __useIlcelerQuery__
 *
 * To run a query within a React component, call `useIlcelerQuery` and pass it any options that fit your needs.
 * When your component renders, `useIlcelerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIlcelerQuery({
 *   variables: {
 *      il_id: // value for 'il_id'
 *   },
 * });
 */
export function useIlcelerQuery(baseOptions: Apollo.QueryHookOptions<IlcelerQuery, IlcelerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IlcelerQuery, IlcelerQueryVariables>(IlcelerDocument, options)
}
export function useIlcelerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IlcelerQuery, IlcelerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IlcelerQuery, IlcelerQueryVariables>(IlcelerDocument, options)
}
export type IlcelerQueryHookResult = ReturnType<typeof useIlcelerQuery>
export type IlcelerLazyQueryHookResult = ReturnType<typeof useIlcelerLazyQuery>
export type IlcelerQueryResult = Apollo.QueryResult<IlcelerQuery, IlcelerQueryVariables>
export const MahallelerDocument = gql`
  query mahalleler($ilce_id: Int!) {
    mahalleler(ilce_id: $ilce_id) {
      id
      ilce_id
      isim
    }
  }
`

/**
 * __useMahallelerQuery__
 *
 * To run a query within a React component, call `useMahallelerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMahallelerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMahallelerQuery({
 *   variables: {
 *      ilce_id: // value for 'ilce_id'
 *   },
 * });
 */
export function useMahallelerQuery(baseOptions: Apollo.QueryHookOptions<MahallelerQuery, MahallelerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MahallelerQuery, MahallelerQueryVariables>(MahallelerDocument, options)
}
export function useMahallelerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahallelerQuery, MahallelerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MahallelerQuery, MahallelerQueryVariables>(MahallelerDocument, options)
}
export type MahallelerQueryHookResult = ReturnType<typeof useMahallelerQuery>
export type MahallelerLazyQueryHookResult = ReturnType<typeof useMahallelerLazyQuery>
export type MahallelerQueryResult = Apollo.QueryResult<MahallelerQuery, MahallelerQueryVariables>
export const IllerDocument = gql`
  query iller {
    iller {
      id
      isim
    }
  }
`

/**
 * __useIllerQuery__
 *
 * To run a query within a React component, call `useIllerQuery` and pass it any options that fit your needs.
 * When your component renders, `useIllerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIllerQuery({
 *   variables: {
 *   },
 * });
 */
export function useIllerQuery(baseOptions?: Apollo.QueryHookOptions<IllerQuery, IllerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IllerQuery, IllerQueryVariables>(IllerDocument, options)
}
export function useIllerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IllerQuery, IllerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IllerQuery, IllerQueryVariables>(IllerDocument, options)
}
export type IllerQueryHookResult = ReturnType<typeof useIllerQuery>
export type IllerLazyQueryHookResult = ReturnType<typeof useIllerLazyQuery>
export type IllerQueryResult = Apollo.QueryResult<IllerQuery, IllerQueryVariables>
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user_id
      token
      expire_at
      errorMessage
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const RefreshTokenDocument = gql`
  mutation RefreshToken {
    refreshToken {
      user_id
      token
      expire_at
      errorMessage
    }
  }
`
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>
export const YeniUyeDocument = gql`
  mutation YeniUye($email: String!, $isim: String!, $soyisim: String!, $cep_telefonu: String, $sifre: String!) {
    yeniUye(input: { email: $email, isim: $isim, soyisim: $soyisim, cep_telefonu: $cep_telefonu, sifre: $sifre }) {
      user_id
      token
      expire_at
      errorMessage
    }
  }
`
export type YeniUyeMutationFn = Apollo.MutationFunction<YeniUyeMutation, YeniUyeMutationVariables>

/**
 * __useYeniUyeMutation__
 *
 * To run a mutation, you first call `useYeniUyeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useYeniUyeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [yeniUyeMutation, { data, loading, error }] = useYeniUyeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isim: // value for 'isim'
 *      soyisim: // value for 'soyisim'
 *      cep_telefonu: // value for 'cep_telefonu'
 *      sifre: // value for 'sifre'
 *   },
 * });
 */
export function useYeniUyeMutation(
  baseOptions?: Apollo.MutationHookOptions<YeniUyeMutation, YeniUyeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<YeniUyeMutation, YeniUyeMutationVariables>(YeniUyeDocument, options)
}
export type YeniUyeMutationHookResult = ReturnType<typeof useYeniUyeMutation>
export type YeniUyeMutationResult = Apollo.MutationResult<YeniUyeMutation>
export type YeniUyeMutationOptions = Apollo.BaseMutationOptions<YeniUyeMutation, YeniUyeMutationVariables>
export const SepetPaketDuzenleDocument = gql`
  mutation SepetPaketDuzenle($okulSlug: String!, $sinifId: Int!, $urunler: [SepetUrunuInput]) {
    sepetPaketDuzenle(okul_slug: $okulSlug, sinif_id: $sinifId, urunler: $urunler) {
      id
      okul_slug
      sinif {
        id
        isim
        kampus_adi
        kapali_uyarisi
        aktif
        ders_kirilimi
        indirim
      }
      urunler {
        id
        urun {
          id
          isim
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          marka {
            id
            isim
          }
          yazar
          resim
          sinif_seviyesi
          kdv_yuzde
          kisa_aciklama
          kitap_kaplama
          bilgiler {
            isim
            deger
            tip
          }
          grup {
            id
            isim
            resimler
          }
          aciklamalar
          aktif
          kargodan_muaf
          resimler
        }
        sinif_id
        adet
        indirimsiz_birim_fiyat
        indirimli_birim_fiyat
        indirimsiz_fiyat
        indirimli_fiyat
        indirim
        readonly
      }
      ogretmen_indirimi_uygulandi
    }
  }
`
export type SepetPaketDuzenleMutationFn = Apollo.MutationFunction<
  SepetPaketDuzenleMutation,
  SepetPaketDuzenleMutationVariables
>

/**
 * __useSepetPaketDuzenleMutation__
 *
 * To run a mutation, you first call `useSepetPaketDuzenleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetPaketDuzenleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetPaketDuzenleMutation, { data, loading, error }] = useSepetPaketDuzenleMutation({
 *   variables: {
 *      okulSlug: // value for 'okulSlug'
 *      sinifId: // value for 'sinifId'
 *      urunler: // value for 'urunler'
 *   },
 * });
 */
export function useSepetPaketDuzenleMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetPaketDuzenleMutation, SepetPaketDuzenleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetPaketDuzenleMutation, SepetPaketDuzenleMutationVariables>(
    SepetPaketDuzenleDocument,
    options,
  )
}
export type SepetPaketDuzenleMutationHookResult = ReturnType<typeof useSepetPaketDuzenleMutation>
export type SepetPaketDuzenleMutationResult = Apollo.MutationResult<SepetPaketDuzenleMutation>
export type SepetPaketDuzenleMutationOptions = Apollo.BaseMutationOptions<
  SepetPaketDuzenleMutation,
  SepetPaketDuzenleMutationVariables
>
export const SepetUrunSilDocument = gql`
  mutation SepetUrunSil($okulSlug: String!, $urunId: Int!) {
    sepetUrunSil(okul_slug: $okulSlug, urun_id: $urunId) {
      id
      okul_slug
      sinif {
        id
        isim
        kampus_adi
        kapali_uyarisi
        aktif
        ders_kirilimi
        indirim
      }
      urunler {
        id
        urun {
          id
          isim
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          marka {
            id
            isim
          }
          yazar
          resim
          sinif_seviyesi
          kdv_yuzde
          kisa_aciklama
          kitap_kaplama
          bilgiler {
            isim
            deger
            tip
          }
          grup {
            id
            isim
            resimler
          }
          aciklamalar
          aktif
          kargodan_muaf
          resimler
        }
        sinif_id
        adet
        indirimsiz_birim_fiyat
        indirimli_birim_fiyat
        indirimsiz_fiyat
        indirimli_fiyat
        indirim
        readonly
      }
      ogretmen_indirimi_uygulandi
    }
  }
`
export type SepetUrunSilMutationFn = Apollo.MutationFunction<SepetUrunSilMutation, SepetUrunSilMutationVariables>

/**
 * __useSepetUrunSilMutation__
 *
 * To run a mutation, you first call `useSepetUrunSilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetUrunSilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetUrunSilMutation, { data, loading, error }] = useSepetUrunSilMutation({
 *   variables: {
 *      okulSlug: // value for 'okulSlug'
 *      urunId: // value for 'urunId'
 *   },
 * });
 */
export function useSepetUrunSilMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetUrunSilMutation, SepetUrunSilMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetUrunSilMutation, SepetUrunSilMutationVariables>(SepetUrunSilDocument, options)
}
export type SepetUrunSilMutationHookResult = ReturnType<typeof useSepetUrunSilMutation>
export type SepetUrunSilMutationResult = Apollo.MutationResult<SepetUrunSilMutation>
export type SepetUrunSilMutationOptions = Apollo.BaseMutationOptions<
  SepetUrunSilMutation,
  SepetUrunSilMutationVariables
>
export const SepetUrunDuzenleDocument = gql`
  mutation SepetUrunDuzenle($okulSlug: String!, $urun: SepetUrunuInput) {
    sepetUrunDuzenle(okul_slug: $okulSlug, urun: $urun) {
      id
      okul_slug
      sinif {
        id
        isim
        kampus_adi
        kapali_uyarisi
        aktif
        ders_kirilimi
        indirim
      }
      urunler {
        id
        urun {
          id
          isim
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          marka {
            id
            isim
          }
          yazar
          resim
          sinif_seviyesi
          kdv_yuzde
          kisa_aciklama
          kitap_kaplama
          bilgiler {
            isim
            deger
            tip
          }
          grup {
            id
            isim
            resimler
          }
          aciklamalar
          aktif
          kargodan_muaf
          resimler
        }
        sinif_id
        adet
        indirimsiz_birim_fiyat
        indirimli_birim_fiyat
        indirimsiz_fiyat
        indirimli_fiyat
        indirim
        readonly
      }
      ogretmen_indirimi_uygulandi
    }
  }
`
export type SepetUrunDuzenleMutationFn = Apollo.MutationFunction<
  SepetUrunDuzenleMutation,
  SepetUrunDuzenleMutationVariables
>

/**
 * __useSepetUrunDuzenleMutation__
 *
 * To run a mutation, you first call `useSepetUrunDuzenleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetUrunDuzenleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetUrunDuzenleMutation, { data, loading, error }] = useSepetUrunDuzenleMutation({
 *   variables: {
 *      okulSlug: // value for 'okulSlug'
 *      urun: // value for 'urun'
 *   },
 * });
 */
export function useSepetUrunDuzenleMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetUrunDuzenleMutation, SepetUrunDuzenleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetUrunDuzenleMutation, SepetUrunDuzenleMutationVariables>(
    SepetUrunDuzenleDocument,
    options,
  )
}
export type SepetUrunDuzenleMutationHookResult = ReturnType<typeof useSepetUrunDuzenleMutation>
export type SepetUrunDuzenleMutationResult = Apollo.MutationResult<SepetUrunDuzenleMutation>
export type SepetUrunDuzenleMutationOptions = Apollo.BaseMutationOptions<
  SepetUrunDuzenleMutation,
  SepetUrunDuzenleMutationVariables
>
export const SepetiBosaltDocument = gql`
  mutation SepetiBosalt($okulSlug: String!) {
    sepetiBosalt(okul_slug: $okulSlug) {
      id
      okul_slug
      sinif {
        id
        isim
        kampus_adi
        kapali_uyarisi
        aktif
        ders_kirilimi
        indirim
      }
      urunler {
        id
        urun {
          id
          isim
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          marka {
            id
            isim
          }
          yazar
          resim
          sinif_seviyesi
          kdv_yuzde
          kisa_aciklama
          kitap_kaplama
          bilgiler {
            isim
            deger
            tip
          }
          grup {
            id
            isim
            resimler
          }
          aciklamalar
          aktif
          kargodan_muaf
          resimler
        }
        sinif_id
        adet
        indirimsiz_birim_fiyat
        indirimli_birim_fiyat
        indirimsiz_fiyat
        indirimli_fiyat
        indirim
        readonly
      }
      ogretmen_indirimi_uygulandi
    }
  }
`
export type SepetiBosaltMutationFn = Apollo.MutationFunction<SepetiBosaltMutation, SepetiBosaltMutationVariables>

/**
 * __useSepetiBosaltMutation__
 *
 * To run a mutation, you first call `useSepetiBosaltMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetiBosaltMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetiBosaltMutation, { data, loading, error }] = useSepetiBosaltMutation({
 *   variables: {
 *      okulSlug: // value for 'okulSlug'
 *   },
 * });
 */
export function useSepetiBosaltMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetiBosaltMutation, SepetiBosaltMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetiBosaltMutation, SepetiBosaltMutationVariables>(SepetiBosaltDocument, options)
}
export type SepetiBosaltMutationHookResult = ReturnType<typeof useSepetiBosaltMutation>
export type SepetiBosaltMutationResult = Apollo.MutationResult<SepetiBosaltMutation>
export type SepetiBosaltMutationOptions = Apollo.BaseMutationOptions<
  SepetiBosaltMutation,
  SepetiBosaltMutationVariables
>
export const SepetPaketSilDocument = gql`
  mutation SepetPaketSil($okulSlug: String!, $sinifId: Int!) {
    sepetPaketSil(okul_slug: $okulSlug, sinif_id: $sinifId) {
      id
      okul_slug
      sinif {
        id
        isim
        kampus_adi
        kapali_uyarisi
        aktif
        ders_kirilimi
        indirim
      }
      urunler {
        id
        urun {
          id
          isim
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          marka {
            id
            isim
          }
          yazar
          resim
          sinif_seviyesi
          kdv_yuzde
          kisa_aciklama
          kitap_kaplama
          bilgiler {
            isim
            deger
            tip
          }
          grup {
            id
            isim
            resimler
          }
          aciklamalar
          aktif
          kargodan_muaf
          resimler
        }
        sinif_id
        adet
        indirimsiz_birim_fiyat
        indirimli_birim_fiyat
        indirimsiz_fiyat
        indirimli_fiyat
        indirim
        readonly
      }
      ogretmen_indirimi_uygulandi
    }
  }
`
export type SepetPaketSilMutationFn = Apollo.MutationFunction<SepetPaketSilMutation, SepetPaketSilMutationVariables>

/**
 * __useSepetPaketSilMutation__
 *
 * To run a mutation, you first call `useSepetPaketSilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetPaketSilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetPaketSilMutation, { data, loading, error }] = useSepetPaketSilMutation({
 *   variables: {
 *      okulSlug: // value for 'okulSlug'
 *      sinifId: // value for 'sinifId'
 *   },
 * });
 */
export function useSepetPaketSilMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetPaketSilMutation, SepetPaketSilMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetPaketSilMutation, SepetPaketSilMutationVariables>(SepetPaketSilDocument, options)
}
export type SepetPaketSilMutationHookResult = ReturnType<typeof useSepetPaketSilMutation>
export type SepetPaketSilMutationResult = Apollo.MutationResult<SepetPaketSilMutation>
export type SepetPaketSilMutationOptions = Apollo.BaseMutationOptions<
  SepetPaketSilMutation,
  SepetPaketSilMutationVariables
>
export const SepetDocument = gql`
  query Sepet($slug: String!) {
    sepet(okul_slug: $slug) {
      id
      ogretmen_indirimi_uygulandi
      okul_slug
      sinif {
        aktif
        ders_kirilimi
        id
        indirim
        kampus_adi
        isim
        kapali_uyarisi
      }
      urunler {
        adet
        stok
        indirim
        id
        indirimli_birim_fiyat
        indirimli_fiyat
        indirimsiz_birim_fiyat
        indirimsiz_fiyat
        readonly
        sinif_id
        urun {
          aciklamalar
          aktif
          bilgiler {
            deger
            isim
            tip
          }
          grup {
            id
            isim
            resimler
          }
          id
          isim
          kargodan_muaf
          okul_indirimi
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          resim
          marka {
            id
            isim
          }
          kdv_yuzde
          kisa_aciklama
          kitap_kaplama
          resimler
          sinif_seviyesi
          yazar
        }
      }
    }
  }
`

/**
 * __useSepetQuery__
 *
 * To run a query within a React component, call `useSepetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSepetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSepetQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSepetQuery(baseOptions: Apollo.QueryHookOptions<SepetQuery, SepetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SepetQuery, SepetQueryVariables>(SepetDocument, options)
}
export function useSepetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SepetQuery, SepetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SepetQuery, SepetQueryVariables>(SepetDocument, options)
}
export type SepetQueryHookResult = ReturnType<typeof useSepetQuery>
export type SepetLazyQueryHookResult = ReturnType<typeof useSepetLazyQuery>
export type SepetQueryResult = Apollo.QueryResult<SepetQuery, SepetQueryVariables>
export const SepetePaketEkleDocument = gql`
  mutation SepetePaketEkle($okul_slug: String!, $sinif_id: Int!, $urunler: [SepetUrunuInput]) {
    sepetPaketEkle(okul_slug: $okul_slug, sinif_id: $sinif_id, urunler: $urunler) {
      id
    }
  }
`
export type SepetePaketEkleMutationFn = Apollo.MutationFunction<
  SepetePaketEkleMutation,
  SepetePaketEkleMutationVariables
>

/**
 * __useSepetePaketEkleMutation__
 *
 * To run a mutation, you first call `useSepetePaketEkleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetePaketEkleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetePaketEkleMutation, { data, loading, error }] = useSepetePaketEkleMutation({
 *   variables: {
 *      okul_slug: // value for 'okul_slug'
 *      sinif_id: // value for 'sinif_id'
 *      urunler: // value for 'urunler'
 *   },
 * });
 */
export function useSepetePaketEkleMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetePaketEkleMutation, SepetePaketEkleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetePaketEkleMutation, SepetePaketEkleMutationVariables>(SepetePaketEkleDocument, options)
}
export type SepetePaketEkleMutationHookResult = ReturnType<typeof useSepetePaketEkleMutation>
export type SepetePaketEkleMutationResult = Apollo.MutationResult<SepetePaketEkleMutation>
export type SepetePaketEkleMutationOptions = Apollo.BaseMutationOptions<
  SepetePaketEkleMutation,
  SepetePaketEkleMutationVariables
>
export const SinifUrunleriDocument = gql`
  query SinifUrunleri($slug: String!, $sinif_id: Int!) {
    okul(slug: $slug) {
      active
      capraz_urun_satis_popupi_varmi
      indirim
      indirimli_fiyat_gosterimi
      id
      isim
      kapida_odeme_tutari
      kapida_odeme_varmi
      kargo_tutar_kosulu
      kargo_tutari
      ogrenci_girisi_varmi
      tab_gosterim
      tekstil_toplu_satis
      logo
      uyari
      sinif(sinif_id: $sinif_id) {
        aktif
        uye_indirim_var
        duyuru_popup
        id
        indirim
        isim
        kampus_adi
        kapali_uyarisi
        ders_kirilimi
        kategori_id
        liste_resmi
        sifre_varmi
        paket_resmi
        sepet_icinde_degistirme_varmi
        sinif_seviyesi
        sira
        urunler_secili_gelsin
        uyari
        sinif_urunleri {
          adet
          satirda_uye_indirimi_var
          ders {
            id
            isim
            renk
          }
          indirimli_birim_fiyat
          indirimsiz_birim_fiyat
          min_adet
          stok
          sinif_id
          sinif_liste_notu
          urun {
            aciklamalar
            aktif
            dinamik_koli_siparis_aktarma
            urun_kademeleri
            okul_indirimi
            bilgiler {
              deger
              isim
              tip
            }
            grup {
              id
              isim
              resimler
              sira
            }
            isim
            id
            kargodan_muaf
            kategori {
              id
              isim
              resim
              ust_kategori_id
            }
            kdv_yuzde
            kisa_aciklama
            kitap_kaplama
            marka {
              id
              isim
            }
            resim
            resimler
            sinif_seviyesi
            yazar
          }
          urun_id
          zorunluluk_grubu
        }
      }
    }
  }
`

/**
 * __useSinifUrunleriQuery__
 *
 * To run a query within a React component, call `useSinifUrunleriQuery` and pass it any options that fit your needs.
 * When your component renders, `useSinifUrunleriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSinifUrunleriQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      sinif_id: // value for 'sinif_id'
 *   },
 * });
 */
export function useSinifUrunleriQuery(
  baseOptions: Apollo.QueryHookOptions<SinifUrunleriQuery, SinifUrunleriQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SinifUrunleriQuery, SinifUrunleriQueryVariables>(SinifUrunleriDocument, options)
}
export function useSinifUrunleriLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SinifUrunleriQuery, SinifUrunleriQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SinifUrunleriQuery, SinifUrunleriQueryVariables>(SinifUrunleriDocument, options)
}
export type SinifUrunleriQueryHookResult = ReturnType<typeof useSinifUrunleriQuery>
export type SinifUrunleriLazyQueryHookResult = ReturnType<typeof useSinifUrunleriLazyQuery>
export type SinifUrunleriQueryResult = Apollo.QueryResult<SinifUrunleriQuery, SinifUrunleriQueryVariables>
export const SepetUrunEkleDocument = gql`
  mutation SepetUrunEkle($okulSlug: String!, $urun: SepetUrunuInput) {
    sepetUrunEkle(okul_slug: $okulSlug, urun: $urun)
  }
`
export type SepetUrunEkleMutationFn = Apollo.MutationFunction<SepetUrunEkleMutation, SepetUrunEkleMutationVariables>

/**
 * __useSepetUrunEkleMutation__
 *
 * To run a mutation, you first call `useSepetUrunEkleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSepetUrunEkleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sepetUrunEkleMutation, { data, loading, error }] = useSepetUrunEkleMutation({
 *   variables: {
 *      okulSlug: // value for 'okulSlug'
 *      urun: // value for 'urun'
 *   },
 * });
 */
export function useSepetUrunEkleMutation(
  baseOptions?: Apollo.MutationHookOptions<SepetUrunEkleMutation, SepetUrunEkleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SepetUrunEkleMutation, SepetUrunEkleMutationVariables>(SepetUrunEkleDocument, options)
}
export type SepetUrunEkleMutationHookResult = ReturnType<typeof useSepetUrunEkleMutation>
export type SepetUrunEkleMutationResult = Apollo.MutationResult<SepetUrunEkleMutation>
export type SepetUrunEkleMutationOptions = Apollo.BaseMutationOptions<
  SepetUrunEkleMutation,
  SepetUrunEkleMutationVariables
>
export const GetCategoriesDocument = gql`
  query GetCategories($ust_kategori_id: Int!) {
    urunKategorileri(ust_kategori_id: $ust_kategori_id) {
      id
      isim
      resim
      ust_kategori_id
    }
  }
`

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      ust_kategori_id: // value for 'ust_kategori_id'
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options)
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options)
}
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>
export const KategoriveMarkalarDocument = gql`
  query KategoriveMarkalar($kategoriId: Int!) {
    urunKategorisi(id: $kategoriId) {
      kategori {
        id
        isim
        resim
        ust_kategori_id
      }
      markalar {
        id
        isim
      }
    }
  }
`

/**
 * __useKategoriveMarkalarQuery__
 *
 * To run a query within a React component, call `useKategoriveMarkalarQuery` and pass it any options that fit your needs.
 * When your component renders, `useKategoriveMarkalarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKategoriveMarkalarQuery({
 *   variables: {
 *      kategoriId: // value for 'kategoriId'
 *   },
 * });
 */
export function useKategoriveMarkalarQuery(
  baseOptions: Apollo.QueryHookOptions<KategoriveMarkalarQuery, KategoriveMarkalarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<KategoriveMarkalarQuery, KategoriveMarkalarQueryVariables>(KategoriveMarkalarDocument, options)
}
export function useKategoriveMarkalarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KategoriveMarkalarQuery, KategoriveMarkalarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<KategoriveMarkalarQuery, KategoriveMarkalarQueryVariables>(
    KategoriveMarkalarDocument,
    options,
  )
}
export type KategoriveMarkalarQueryHookResult = ReturnType<typeof useKategoriveMarkalarQuery>
export type KategoriveMarkalarLazyQueryHookResult = ReturnType<typeof useKategoriveMarkalarLazyQuery>
export type KategoriveMarkalarQueryResult = Apollo.QueryResult<
  KategoriveMarkalarQuery,
  KategoriveMarkalarQueryVariables
>
export const CarprazUrunlerDocument = gql`
  query CarprazUrunler($slug: String!, $filtre: CaprazUrunFiltre = {}) {
    okul(slug: $slug) {
      urunler(filtre: $filtre) {
        urun_sayisi
        capraz_urunler {
          indirimli_birim_fiyat
          indirimsiz_birim_fiyat
          urun {
            aciklamalar
            aktif
            id
            isim
            kargodan_muaf
            kdv_yuzde
            kisa_aciklama
            kitap_kaplama
            marka {
              id
              isim
            }
            resim
            resimler
            sinif_seviyesi
            yazar
            kategori {
              id
              isim
              resim
              ust_kategori_id
            }
          }
        }
      }
    }
  }
`

/**
 * __useCarprazUrunlerQuery__
 *
 * To run a query within a React component, call `useCarprazUrunlerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarprazUrunlerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarprazUrunlerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      filtre: // value for 'filtre'
 *   },
 * });
 */
export function useCarprazUrunlerQuery(
  baseOptions: Apollo.QueryHookOptions<CarprazUrunlerQuery, CarprazUrunlerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CarprazUrunlerQuery, CarprazUrunlerQueryVariables>(CarprazUrunlerDocument, options)
}
export function useCarprazUrunlerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CarprazUrunlerQuery, CarprazUrunlerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CarprazUrunlerQuery, CarprazUrunlerQueryVariables>(CarprazUrunlerDocument, options)
}
export type CarprazUrunlerQueryHookResult = ReturnType<typeof useCarprazUrunlerQuery>
export type CarprazUrunlerLazyQueryHookResult = ReturnType<typeof useCarprazUrunlerLazyQuery>
export type CarprazUrunlerQueryResult = Apollo.QueryResult<CarprazUrunlerQuery, CarprazUrunlerQueryVariables>
export const SinifSecimSifreDocument = gql`
  mutation SinifSecimSifre($okul_id: Int!, $password: String!, $sinif_id: Int!) {
    sinifSecimSifre(okul_id: $okul_id, password: $password, sinif_id: $sinif_id) {
      statusCode
      message
    }
  }
`
export type SinifSecimSifreMutationFn = Apollo.MutationFunction<
  SinifSecimSifreMutation,
  SinifSecimSifreMutationVariables
>

/**
 * __useSinifSecimSifreMutation__
 *
 * To run a mutation, you first call `useSinifSecimSifreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSinifSecimSifreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sinifSecimSifreMutation, { data, loading, error }] = useSinifSecimSifreMutation({
 *   variables: {
 *      okul_id: // value for 'okul_id'
 *      password: // value for 'password'
 *      sinif_id: // value for 'sinif_id'
 *   },
 * });
 */
export function useSinifSecimSifreMutation(
  baseOptions?: Apollo.MutationHookOptions<SinifSecimSifreMutation, SinifSecimSifreMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SinifSecimSifreMutation, SinifSecimSifreMutationVariables>(SinifSecimSifreDocument, options)
}
export type SinifSecimSifreMutationHookResult = ReturnType<typeof useSinifSecimSifreMutation>
export type SinifSecimSifreMutationResult = Apollo.MutationResult<SinifSecimSifreMutation>
export type SinifSecimSifreMutationOptions = Apollo.BaseMutationOptions<
  SinifSecimSifreMutation,
  SinifSecimSifreMutationVariables
>
export const OkulDocument = gql`
  query Okul($slug: String!) {
    okul(slug: $slug) {
      id
      slug
      isim
      logo
      email
      banner
      uyari
      indirim
      yabanci_dil_indirimi_varmi
      renk1
      renk2
      odeme_varmi
      ogrenci_girisi_varmi
      kargo_tutar_kosulu
      kargo_tutari
      kapida_odeme_varmi
      kapida_odeme_tutari
      stok_bitince_satisa_kapat
      capraz_urun_satisi_varmi
      capraz_urun_satis_popupi_varmi
      indirimli_fiyat_gosterimi
      active
      bannerlari_goster
      alan_resimlerini_goster
      urunler_secili_gelsin
      bannerlar {
        id
        resim
        resim_mobil
        link
        sira
      }
      alan_resimleri {
        id
        resim
        resim_mobil
        link
        alan
      }
    }
  }
`

/**
 * __useOkulQuery__
 *
 * To run a query within a React component, call `useOkulQuery` and pass it any options that fit your needs.
 * When your component renders, `useOkulQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOkulQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOkulQuery(baseOptions: Apollo.QueryHookOptions<OkulQuery, OkulQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OkulQuery, OkulQueryVariables>(OkulDocument, options)
}
export function useOkulLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OkulQuery, OkulQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OkulQuery, OkulQueryVariables>(OkulDocument, options)
}
export type OkulQueryHookResult = ReturnType<typeof useOkulQuery>
export type OkulLazyQueryHookResult = ReturnType<typeof useOkulLazyQuery>
export type OkulQueryResult = Apollo.QueryResult<OkulQuery, OkulQueryVariables>
export const SinifListesiVeKategorileriDocument = gql`
  query SinifListesiVeKategorileri($slug: String!, $ust_kategori_id: Int) {
    okul(slug: $slug) {
      sinif_secim_liste(ust_kategori_id: $ust_kategori_id) {
        sinif_id
        kategori_id
        isim
        sifre_var
      }
    }
  }
`

/**
 * __useSinifListesiVeKategorileriQuery__
 *
 * To run a query within a React component, call `useSinifListesiVeKategorileriQuery` and pass it any options that fit your needs.
 * When your component renders, `useSinifListesiVeKategorileriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSinifListesiVeKategorileriQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      ust_kategori_id: // value for 'ust_kategori_id'
 *   },
 * });
 */
export function useSinifListesiVeKategorileriQuery(
  baseOptions: Apollo.QueryHookOptions<SinifListesiVeKategorileriQuery, SinifListesiVeKategorileriQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SinifListesiVeKategorileriQuery, SinifListesiVeKategorileriQueryVariables>(
    SinifListesiVeKategorileriDocument,
    options,
  )
}
export function useSinifListesiVeKategorileriLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SinifListesiVeKategorileriQuery, SinifListesiVeKategorileriQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SinifListesiVeKategorileriQuery, SinifListesiVeKategorileriQueryVariables>(
    SinifListesiVeKategorileriDocument,
    options,
  )
}
export type SinifListesiVeKategorileriQueryHookResult = ReturnType<typeof useSinifListesiVeKategorileriQuery>
export type SinifListesiVeKategorileriLazyQueryHookResult = ReturnType<typeof useSinifListesiVeKategorileriLazyQuery>
export type SinifListesiVeKategorileriQueryResult = Apollo.QueryResult<
  SinifListesiVeKategorileriQuery,
  SinifListesiVeKategorileriQueryVariables
>
export const IadeTalebiOlusturDocument = gql`
  mutation IadeTalebiOlustur($okul_slug: String!, $siparis_id: Int!, $satirlar: [IadeSatiriInput]) {
    iadeTalebiOlustur(okul_slug: $okul_slug, siparis_id: $siparis_id, satirlar: $satirlar) {
      kargo_takip_no
    }
  }
`
export type IadeTalebiOlusturMutationFn = Apollo.MutationFunction<
  IadeTalebiOlusturMutation,
  IadeTalebiOlusturMutationVariables
>

/**
 * __useIadeTalebiOlusturMutation__
 *
 * To run a mutation, you first call `useIadeTalebiOlusturMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIadeTalebiOlusturMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iadeTalebiOlusturMutation, { data, loading, error }] = useIadeTalebiOlusturMutation({
 *   variables: {
 *      okul_slug: // value for 'okul_slug'
 *      siparis_id: // value for 'siparis_id'
 *      satirlar: // value for 'satirlar'
 *   },
 * });
 */
export function useIadeTalebiOlusturMutation(
  baseOptions?: Apollo.MutationHookOptions<IadeTalebiOlusturMutation, IadeTalebiOlusturMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IadeTalebiOlusturMutation, IadeTalebiOlusturMutationVariables>(
    IadeTalebiOlusturDocument,
    options,
  )
}
export type IadeTalebiOlusturMutationHookResult = ReturnType<typeof useIadeTalebiOlusturMutation>
export type IadeTalebiOlusturMutationResult = Apollo.MutationResult<IadeTalebiOlusturMutation>
export type IadeTalebiOlusturMutationOptions = Apollo.BaseMutationOptions<
  IadeTalebiOlusturMutation,
  IadeTalebiOlusturMutationVariables
>
export const SiparislerimDocument = gql`
  query Siparislerim($page: Int) {
    siparislerim(page: $page) {
      id
      tarih
      toplam_tutar
      durum
    }
  }
`

/**
 * __useSiparislerimQuery__
 *
 * To run a query within a React component, call `useSiparislerimQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiparislerimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiparislerimQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSiparislerimQuery(
  baseOptions?: Apollo.QueryHookOptions<SiparislerimQuery, SiparislerimQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SiparislerimQuery, SiparislerimQueryVariables>(SiparislerimDocument, options)
}
export function useSiparislerimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SiparislerimQuery, SiparislerimQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SiparislerimQuery, SiparislerimQueryVariables>(SiparislerimDocument, options)
}
export type SiparislerimQueryHookResult = ReturnType<typeof useSiparislerimQuery>
export type SiparislerimLazyQueryHookResult = ReturnType<typeof useSiparislerimLazyQuery>
export type SiparislerimQueryResult = Apollo.QueryResult<SiparislerimQuery, SiparislerimQueryVariables>
export const SiparisDetayDocument = gql`
  query siparisDetay($siparis_id: Int!) {
    siparisDetay(siparis_id: $siparis_id) {
      id
      tarih
      toplam_tutar
      durum
      satirlar {
        adet
        birim_fiyat
        durum
        id
        kalan_adet
        kargo_takip_no
        min_adet
        urun {
          id
          isim
          kisa_aciklama
          aciklamalar
          kdv_yuzde
          sinif_seviyesi
          aktif
          yazar
          resim
          kargodan_muaf
          kitap_kaplama
          grup {
            isim
            id
            resimler
          }
          marka {
            id
            isim
          }
          kategori {
            id
            isim
            resim
            ust_kategori_id
          }
          resimler
          bilgiler {
            deger
            isim
            tip
          }
        }
      }
      faturalar {
        id
        durum
        belge_kodu
        gonderi_tarihi
        kargo_takip_no
        kargo_teslim_tarihi
        kargoya_verilme_tarihi
        satirlar {
          urun_id
          urun_adet
          urun_birim_fiyat
          urun_ismi
        }
      }
      satin_alinmayan_urunler {
        urun_id
        urun_ismi
        urun_birim_fiyat
        urun_adet
      }
      kredi_karti_pos
      kredi_karti_vade_secenegi
      odeme_tipi
      teslimat_adresi
      fatura_adresi
    }
  }
`

/**
 * __useSiparisDetayQuery__
 *
 * To run a query within a React component, call `useSiparisDetayQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiparisDetayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiparisDetayQuery({
 *   variables: {
 *      siparis_id: // value for 'siparis_id'
 *   },
 * });
 */
export function useSiparisDetayQuery(
  baseOptions: Apollo.QueryHookOptions<SiparisDetayQuery, SiparisDetayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SiparisDetayQuery, SiparisDetayQueryVariables>(SiparisDetayDocument, options)
}
export function useSiparisDetayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SiparisDetayQuery, SiparisDetayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SiparisDetayQuery, SiparisDetayQueryVariables>(SiparisDetayDocument, options)
}
export type SiparisDetayQueryHookResult = ReturnType<typeof useSiparisDetayQuery>
export type SiparisDetayLazyQueryHookResult = ReturnType<typeof useSiparisDetayLazyQuery>
export type SiparisDetayQueryResult = Apollo.QueryResult<SiparisDetayQuery, SiparisDetayQueryVariables>

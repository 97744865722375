import './CrossProductFilter.scss'

import { SearchOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, InputNumber, Row, Space, Tooltip } from 'antd'
import CenteredLayout from 'components/centered-layout/CenteredLayout'
import React from 'react'

interface Props {
  categories: any
  setSelectedCategory: any
  selectedCategoryId: number | null
  markalar: any
  selectedMarkaId: number
  setSelectedMarkaId: any

  setMinPrice: React.Dispatch<React.SetStateAction<number>>
  setMaxPrice: React.Dispatch<React.SetStateAction<number>>
  minTempPrice: number
  setMinTempPrice: React.Dispatch<React.SetStateAction<number>>
  maxTempPrice: number
  setMaxTempPrice: React.Dispatch<React.SetStateAction<number>>
}

//const { Panel } = Collapse

const CrossProductFilterPage = ({
  categories,
  setSelectedCategory,
  selectedCategoryId,
  markalar,
  selectedMarkaId,
  setSelectedMarkaId,
  setMinPrice,
  setMaxPrice,
  minTempPrice,
  setMinTempPrice,
  maxTempPrice,
  setMaxTempPrice,
}: Props) => {
  const handleCategoryChange = (category: any) => {
    setSelectedCategory({
      id: category.id,
      isim: category.isim,
    })
  }

  const handleMarkaChange = (markaId: number) => {
    setSelectedMarkaId(markaId)
  }

  const handleMinPriceChange = (value: any) => {
    setMinTempPrice(value)
  }

  const handleMaxPriceChange = (value: any) => {
    setMaxTempPrice(value)
  }

  const handleSearch = (minTempPrice: number, maxTempPrice: number) => {
    setMinPrice(minTempPrice)
    setMaxPrice(maxTempPrice)
  }

  return (
    <div className="cross-products-filter">
      <CenteredLayout>
        <Card title="Filtrele" className="card-header-background">
          <div>
            <h2>
              <span className="kategori-title">Kategoriler</span>
            </h2>
            <div>
              {categories.map((category: any) => (
                <div key={category.id}>
                  <Checkbox checked={selectedCategoryId === category.id} onClick={() => handleCategoryChange(category)}>
                    {category.isim}
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className="fiyat-filter">
              <h2>
                <span className="kategori-title">Fiyat Aralığı</span>
              </h2>
              <Row style={{ display: 'flex', flexDirection: 'row' }}>
                <Space>
                  <Col>
                    <InputNumber min={1} placeholder="En az" onChange={handleMinPriceChange} />
                  </Col>
                  <Col>
                    <InputNumber min={1} placeholder="En çok" onChange={handleMaxPriceChange} />
                  </Col>
                  <Col>
                    <Tooltip title="Search">
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<SearchOutlined />}
                        onClick={() => handleSearch(minTempPrice, maxTempPrice)}
                      />
                    </Tooltip>
                  </Col>
                </Space>
              </Row>
            </div>

            <div className="fiyat-filter">
              <h2>
                <span className="kategori-title">Markalar</span>
              </h2>

              <div>
                {markalar.map((veriler: any, index: number) => (
                  <div key={index}>
                    <Checkbox checked={selectedMarkaId === veriler.id} onChange={() => handleMarkaChange(veriler.id)}>
                      {veriler.isim}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
        {/*<Collapse defaultActiveKey={['filtrele']}>*/}
        {/*  <Panel*/}
        {/*    header={*/}
        {/*      <span>*/}
        {/*        <i className="fa fa-filter"></i> Filtrele*/}
        {/*      </span>*/}
        {/*    }*/}
        {/*    key="filtrele"*/}
        {/*  >*/}
        {/*    <div>*/}
        {/*      <h2>*/}
        {/*        <span>Kategoriler</span>*/}
        {/*      </h2>*/}
        {/*      <div>*/}
        {/*        {categories.map((category: any) => (*/}
        {/*          <div key={category.id}>*/}
        {/*            <Checkbox*/}
        {/*              checked={selectedCategoryId === category.id}*/}
        {/*              onClick={() => handleCategoryChange(category)}*/}
        {/*            >*/}
        {/*              {category.isim}*/}
        {/*            </Checkbox>*/}
        {/*          </div>*/}
        {/*        ))}*/}
        {/*      </div>*/}
        {/*      <br />*/}
        {/*      <hr />*/}
        {/*      <div className="categoryFilter fiyatFilter">*/}
        {/*        <h2>Fiyat Aralığı</h2>*/}
        {/*        <Row>*/}
        {/*          <Space>*/}
        {/*            <Col>*/}
        {/*              <InputNumber min={1} value={minTempPrice} onChange={handleMinPriceChange} />*/}
        {/*            </Col>*/}
        {/*            <Col>*/}
        {/*              <InputNumber min={1} value={maxTempPrice} onChange={handleMaxPriceChange} />*/}
        {/*            </Col>*/}
        {/*            <Col>*/}
        {/*              <Tooltip title="Search">*/}
        {/*                <Button*/}
        {/*                  type="primary"*/}
        {/*                  shape="circle"*/}
        {/*                  icon={<SearchOutlined />}*/}
        {/*                  onClick={() => handleSearch(minTempPrice, maxTempPrice)}*/}
        {/*                />*/}
        {/*              </Tooltip>*/}
        {/*            </Col>*/}
        {/*          </Space>*/}
        {/*        </Row>*/}
        {/*      </div>*/}
        {/*      <br />*/}
        {/*      <hr />*/}
        {/*      <h2>*/}
        {/*        <span>Markalar</span>*/}
        {/*      </h2>*/}
        {/*      <div>*/}
        {/*        {markalar.map((veriler: any, index: number) => (*/}
        {/*          <div key={index}>*/}
        {/*            <Checkbox checked={selectedMarkaId === veriler.id} onChange={() => handleMarkaChange(veriler.id)}>*/}
        {/*              {veriler.isim}*/}
        {/*            </Checkbox>*/}
        {/*          </div>*/}
        {/*        ))}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Panel>*/}
        {/*</Collapse>*/}
      </CenteredLayout>
    </div>
  )
}

export default CrossProductFilterPage

import { Col, Row } from 'antd'

const EmptyOrder = () => {
  return (
    <div className={'empty-order'}>
      <Row justify={'center'}>
        <Col>
          <p className={'empty-basket-text'}>Siparişiniz Bulunmuyor</p>
        </Col>
      </Row>
    </div>
  )
}

export default EmptyOrder

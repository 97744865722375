import './OrderDetailCheckPage.scss'

import { LeftOutlined } from '@ant-design/icons'
import { Button, Card, Col, message, Row } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useSiparisDetayQuery } from '../../../gql/GeneratedGraphqlTypes'

const OrderDetailCheckPage = () => {
  const { siparisId } = useParams()
  const navigate = useNavigate()
  const [messageApi] = message.useMessage() // Is this part of your code correct?
  const temp = parseInt(siparisId || '1') // Parse as integer or fallback to empty string
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  if (error) {
    messageApi.error(`${error}`, 2) // Is messageApi correctly initialized?
  }
  if (!data) {
    return null
  }
  const handleGeriDonClick = () => {
    navigate(-1) // Önceki sayfaya geri dönme
  }
  return (
    <>
      <div className="orders-detail-check-page">
        <div>
          <Card bordered={false}>
            <Row>
              <Col xs={{ span: 11, offset: 1 }} lg={{ span: 9, offset: 3 }}>
                <h3>Adres Bilgileri </h3>
                <p></p>
                <br />
                <h5>Teslimat Adresi</h5>
                <br />
                <p>{data.siparisDetay.teslimat_adresi}</p>
                <br />
                <h5>Fatura Adresi</h5>
                <br />
                <p>{data.siparisDetay.fatura_adresi}</p>
              </Col>
              <Col xs={{ span: 11, offset: 1 }} lg={{ span: 9, offset: 3 }}>
                <h3>Ödeme Bilgileri </h3>
                <Card bordered={false} style={{ backgroundColor: '#f7fdf7' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {data.siparisDetay.odeme_tipi === 'Havale' ? (
                      <span> Havale</span>
                    ) : (
                      <span>{data.siparisDetay.kredi_karti_pos}</span>
                    )}
                    {data.siparisDetay.kredi_karti_vade_secenegi ? (
                      <span style={{ fontSize: 11 }}>Taksit Sayısı: {data.siparisDetay.kredi_karti_vade_secenegi}</span>
                    ) : null}
                  </div>
                </Card>
                <br />
                <p style={{ textAlign: 'center' }}>
                  Toplam:{' '}
                  <span style={{ color: '#5bd363', fontSize: 20, fontWeight: 'bold' }}>
                    {data.siparisDetay.toplam_tutar}₺
                  </span>
                </p>
              </Col>
            </Row>
          </Card>
        </div>
        <div>
          <Button type={'primary'} className="geri-don" icon={<LeftOutlined />} block onClick={handleGeriDonClick}>
            Geri Dön
          </Button>
        </div>
      </div>
    </>
  )
}

export default OrderDetailCheckPage

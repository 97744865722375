import './ExtraditionProducts.scss'

import { Alert, Input, message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { useSiparisDetayQuery } from '../../gql/GeneratedGraphqlTypes'
import { ExtraditionProductsItem } from './types'

interface Props {
  siparis: string
}

const ExtraditionProducts = ({ siparis }: Props) => {
  const temp = parseInt(siparis)
  const [messageApi, contextHolder] = message.useMessage()
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  if (error) {
    messageApi.error(`${error}`, 2)
  }
  if (!data) {
    return null
  }
  console.log(data)
  const ReturnedProductsItems: ExtraditionProductsItem[] =
    data?.siparisDetay.satirlar
      ?.filter((urun) => urun?.durum === 'İade Talebi')
      .map((urun) => {
        return {
          key: urun?.id?.toString() || '',
          isim: urun?.urun.isim || '', // isim alanı düzeltildi
          iade_talep_adet: urun?.adet || 0, // iade_talep_adet alanı dü zeltildi
          birim_fiyat: parseFloat(`${urun?.birim_fiyat} ₺` ?? '0'),
          durum: urun?.durum || '', // degisim alanı düzeltildi
          kargo_takip_no: urun?.kargo_takip_no || '',
          min_adet: urun?.min_adet || 0,
          kargo_fiyat: urun?.kargo_takip_no || '',
        }
      }) || []

  const columns: ColumnsType<ExtraditionProductsItem> = [
    {
      title: 'Ürün Adı',
      dataIndex: 'isim',
      key: 'isim',
      align: 'center',
    },
    {
      title: 'İade Talep Adeti',
      dataIndex: '',
      key: 'iade_talep_adet',
      align: 'center',
      render: (record) => {
        return (
          <>
            <Input
              min={record.min_adet === 0 ? 1 : record.min_adet ?? 1}
              disabled={true}
              className="input-number quantity-number"
              value={record.iade_talep_adet!}
            />
          </>
        )
      },
    },
    {
      title: 'Fiyat',
      dataIndex: 'birim_fiyat',
      key: 'birim_fiyat',
      align: 'center',
    },
    {
      title: 'İade Durumu',
      dataIndex: 'durum',
      key: 'durum',
      align: 'center',
      render: (durum, record) => {
        if (durum === 'İade Talebi') {
          return <span>İadesi Yapıldı</span>
        }
      },
    },
    {
      title: 'Kargo Numarası',
      dataIndex: 'kargo_fiyat',
      key: 'kargo_fiyat',
      align: 'center',
    },
  ]
  return (
    <>
      {contextHolder}
      <div className="extradition-products">
        <Alert
          className="custom-alert"
          description="İadesi yapılan ürünleriniz listelenmektedir. "
          type="success"
          closable
        />
        <br />
        <div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={ReturnedProductsItems}
            bordered={true}
            className="custom-table"
          />
        </div>
      </div>
    </>
  )
}

export default ExtraditionProducts

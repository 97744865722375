import './GroupProductsTable.scss'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Image, Input, Table } from 'antd'
import { SinifUrunu } from 'gql/GeneratedGraphqlTypes'
import useMediaQuery from 'hooks/useMediaQuery'
import { SecilenUrun } from 'pages/class-products/ClassProductsPage'
import { useState } from 'react'
import { setPriceFormat } from 'utils/helpers'

import GroupProductsModal from '../class-group-product-modal/GroupProductsModal'

interface GroupProductsTableProps {
  products: any
  selectedProducts: SecilenUrun[]
  setSelectedProducts: any
  indirimliFiyatGosterilsinmi: Boolean
}

const GroupProductsTable = ({
  products,
  selectedProducts,
  setSelectedProducts,
  indirimliFiyatGosterilsinmi,
}: GroupProductsTableProps) => {
  const isTabletDimen = useMediaQuery(901)
  const isMobileDimen = useMediaQuery(600)
  // Grupları bir listeye alıyoruz. Grubun içerisindeki ürünler bir modalda gösterilecek.
  let gruplarArr: SinifUrunu[] = []

  products.forEach((element: SinifUrunu) => {
    const someResult = gruplarArr.some((item: SinifUrunu) => item.urun?.grup?.id === element?.urun?.grup?.id)
    if (!someResult) {
      gruplarArr.push(element)
    }
  })
  gruplarArr = gruplarArr.sort((x: any, y: any) => x.urun?.grup?.sira - y.urun?.grup?.sira)

  // Modalin açılmasını kontrol eder
  const [modalVisible, setModalVisible] = useState(false)
  // Model açıldığında seçilen grubun id'si.
  const [selectedGroupIdFromRow, setSelectedGroupIdFromRow] = useState(0)

  const showModal = (groupId: number) => {
    setModalVisible(true)
    setSelectedGroupIdFromRow(groupId)
  }

  const rowSelection = {
    selectedRowKeys: selectedProducts.map((product: SecilenUrun) => `${product.dersId}-${product.grupId}`),
    onChange: (selectedRowKeys: React.Key[], selectedRows: SinifUrunu[]) => {
      const urunler = selectedRows.map((product) => {
        return {
          isim: product.urun?.isim ?? '',
          urunId: product.urun_id,
          dersId: product.ders?.id,
          adet: product.adet,
          fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
          minAdet: product.min_adet ?? 0,
          grupId: product.urun?.grup?.id,
          zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
          etiket_bilgisi: null,
          secilenBeden: null,
          kitapKaplama: product.urun?.kitap_kaplama ?? 0,
        } as SecilenUrun
      })

      const eskiSecilenGrupluUrunler = [...selectedProducts.filter((item) => item.grupId)]
      const eskiVeYeniUrunlerinBirlesimi = eskiSecilenGrupluUrunler.concat(urunler)
      const diffrentRows = eskiSecilenGrupluUrunler.filter((item) => {
        return urunler.filter((i) => i.dersId === item.dersId && i.grupId === item.grupId).length === 0
      })

      const uniqueRows = eskiVeYeniUrunlerinBirlesimi.filter((item, index) => {
        // item'daki ders_id ve id değerlerinin birleşimi önceki elemanlarda yoksa true, aksi halde false döndürüyoruz.
        return (
          index === eskiVeYeniUrunlerinBirlesimi.findIndex((t) => t.dersId === item.dersId && t.grupId === item.grupId)
        )
      })

      const rowsDiff = uniqueRows.filter((item) => {
        return diffrentRows.filter((i) => i.dersId === item.dersId && i.grupId === item.grupId).length === 0
      })

      setSelectedProducts([...selectedProducts.filter((item) => !item.grupId), ...rowsDiff])
    },
    getCheckboxProps: (record: any) => ({
      // Beden seçilmeyen bir üründe checkbox işaretlenemez.
      disabled: !selectedProducts.some(
        (obj: SecilenUrun) => obj.dersId === record.ders?.id && obj.grupId === record.urun?.grup?.id,
      ),
    }),
  }

  const handleQuantityChange = (product: SinifUrunu, value: number) => {
    if (value < 1) {
      return
    }

    const prevRows = [...selectedProducts]
    const updatedProductIndex = prevRows.findIndex(
      (item) => item.grupId === product.urun?.grup?.id && item.dersId === product.ders?.id && item.grupId !== null,
    )

    if (updatedProductIndex !== -1) {
      // Seçili satır var ise adet değerini güncelle
      prevRows[updatedProductIndex].adet = value
      setSelectedProducts(prevRows)
    } else {
      // Seçili satır yok ise ekle
      setSelectedProducts([
        ...selectedProducts,
        {
          isim: product.urun?.isim ?? '',
          urunId: product.urun_id,
          dersId: product.ders?.id,
          adet: value,
          fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
          minAdet: product.min_adet ?? 0,
          grupId: product.urun?.grup?.id,
          zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
          etiket_bilgisi: null,
          secilenBeden: null,
          kitapKaplama: product.urun?.kitap_kaplama ?? 0,
        } as SecilenUrun,
      ])
    }
  }

  const columns: any = [
    {
      title: 'Ürün Resmi',
      dataIndex: '',
      key: '',
      render: (urun: SinifUrunu) => {
        return (
          <div style={{ textAlign: 'center', maxWidth: '75%' }}>
            <Image src={urun.urun?.grup?.resimler?.[0] ?? ''} style={{ maxWidth: '130px', maxHeight: '90px' }} />
          </div>
        )
      },
    },
    {
      title: 'Ürün Adı',
      dataIndex: '',
      key: '',
      render: (urun: SinifUrunu) => {
        return (
          <span>
            <span className="gp-title">{urun.urun?.grup?.isim}</span>
            <br />
            <span style={{ color: 'red' }}>{urun.urun?.kisa_aciklama}</span>
            <br />
            <span style={{ color: 'red' }}>{urun.sinif_liste_notu}</span>
          </span>
        )
      },
    },
    {
      title: 'Beden',
      dataIndex: '',
      key: '',
      render: (record: SinifUrunu) => {
        const selectProduct = selectedProducts.find(
          (item: SecilenUrun) => item?.grupId === record?.urun?.grup?.id && item.dersId === record?.ders?.id,
        )
        const seciliBeden = selectProduct?.secilenBeden

        return (
          <div>
            <Button
              className={seciliBeden ? 'beden-secildi' : ''}
              onClick={() => showModal(record.urun?.grup?.id ?? 0)}
            >
              {seciliBeden ? seciliBeden : 'Beden Seçiniz'}
            </Button>
          </div>
        )
      },
    },
    {
      title: 'Adet',
      align: 'center',
      dataIndex: '',
      key: 'adet',
      render: (urun: SinifUrunu) => {
        const selectProduct = selectedProducts.find(
          (item: SecilenUrun) => item?.grupId === urun?.urun?.grup?.id && item.dersId === urun?.ders?.id,
        )
        const seciliBeden = selectProduct?.secilenBeden

        // Grup ürünlerinde kaplama hizmeti hesaplamaya gerek yok
        // Grup ürünlerinde disabled özelliği gruptan bir ürünün olup olmamasına bakmalı
        const value =
          selectedProducts.find((p: SecilenUrun) => p.grupId === urun.urun?.grup?.id && p?.dersId === urun?.ders?.id)
            ?.adet || urun.adet

        // Todo: Max ?
        return (
          <>
            {seciliBeden && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <div className="quantity-selector">
                  <div className="quantity-wrapper input-group">
                    <button
                      type="button"
                      className="quantity-left-minus btn btn-number"
                      onClick={() => handleQuantityChange(urun, value! - 1)} // Pass 'value' as an argument
                    >
                      <MinusOutlined />
                    </button>
                    <Input
                      min={urun.min_adet === 0 ? 1 : urun.min_adet ?? 1}
                      className="input-number quantity-number"
                      value={value!}
                    />
                    <button
                      type="button"
                      className="quantity-right-plus btn btn-number"
                      onClick={() => handleQuantityChange(urun, value! + 1)} // Pass 'value' as an argument
                    >
                      <PlusOutlined />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      },
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Birim Fiyat</span> <span>Tümü</span>
        </div>
      ),
      dataIndex: '',
      key: 'indirimli_birim_fiyat',
      render: (urun: any) => {
        const value = 1
        if (parseInt(urun.indirimsiz_birim_fiyat) <= parseInt(urun.indirimli_birim_fiyat) || !indirimliFiyatGosterilsinmi) {
          // İndirimsiz fiyat daha küçük veya eşit ise indirimli fiyat gösterilmeyecek
          // Backend'den data yanlış gelebiliyor
          return (
            <div>
              <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), value)} TL</span> <br />
            </div>
          )
        }

        return (
          <div>
            <span className="text-line-through">{setPriceFormat(String(urun.indirimsiz_birim_fiyat), value)} TL</span>{' '}
            <br />
            <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), value)} TL</span>
          </div>
        )
      },
    },
  ]

  const tabletColumns: any = [
    {
      title: 'Ürün',
      dataIndex: '',
      key: 'tablet-urun',
      width: '90px',
      render: (urun: SinifUrunu) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Image src={urun.urun?.grup?.resimler?.[0] ?? ''} style={{ maxWidth: '130px', maxHeight: '90px' }} />
          </div>
        )
      },
    },
    {
      title: 'Beden-Adet',
      dataIndex: '',
      key: 'tablet-urun-adet',
      render: (record: SinifUrunu) => {
        const selectProduct = selectedProducts.find(
          (item: SecilenUrun) => item?.grupId === record?.urun?.grup?.id && item.dersId === record?.ders?.id,
        )
        const seciliBeden = selectProduct?.secilenBeden
        const value =
          selectedProducts.find(
            (item: SecilenUrun) => item?.grupId === record?.urun?.grup?.id && item.dersId === record?.ders?.id,
          )?.adet || record.adet

        return (
          <div className="tablet-gp-beden-container">
            <div>
              {record.urun?.grup?.isim}
              <br />
              <span style={{ color: 'red' }}>{record.urun?.kisa_aciklama}</span>
              <br />
              <span style={{ color: 'red' }}>{record.sinif_liste_notu}</span>
            </div>

            <div className="tablet-beden-bilgi">
              <span className="secili-beden-text">{seciliBeden}</span>
              <Button
                className={seciliBeden ? 'beden-secildi' : ''}
                onClick={() => showModal(record.urun?.grup?.id ?? 0)}
              >
                Beden Seçiniz
              </Button>
              <div className="quantity-selector">
                {seciliBeden && (
                  <div className="quantity-wrapper input-group">
                    <button
                      type="button"
                      className="quantity-left-minus btn btn-number"
                      onClick={() => handleQuantityChange(record, value! - 1)} // Pass 'value' as an argument
                    >
                      <MinusOutlined />
                    </button>
                    <Input
                      min={record.min_adet === 0 ? 1 : record.min_adet ?? 0}
                      // disabled={isDisabled}
                      className="input-number quantity-number"
                      value={value!}
                    />
                    <button
                      type="button"
                      className="quantity-right-plus btn btn-number"
                      onClick={() => handleQuantityChange(record, value! + 1)} // Pass 'value' as an argument
                    >
                      <PlusOutlined />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Birim Fiyat</span> <span>Tümü</span>
        </div>
      ),
      dataIndex: '',
      key: 'tablet-tutar',
      render: (urun: any) => {
        const value = 1
        if (urun.indirimsiz_birim_fiyat <= urun.indirimli_birim_fiyat || !indirimliFiyatGosterilsinmi) {
          // İndirimsiz fiyat daha küçük veya eşit ise indirimli fiyat gösterilmeyecek
          // Backend'den data yanlış gelebiliyor
          return (
            <div>
              <span>{setPriceFormat(String(urun.indirimsiz_birim_fiyat), value)} TL</span> <br />
            </div>
          )
        }

        return (
          <div>
            <span className="text-line-through">{setPriceFormat(String(urun.indirimsiz_birim_fiyat), value)} TL</span>{' '}
            <br />
            <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), value)} TL</span>
          </div>
        )
      },
    },
  ]

  const mobileColumns: any = [
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Ürün</span> <span>Tümü</span>
        </div>
      ),
      dataIndex: '',
      key: 'mobile-urun',
      render: (urun: SinifUrunu) => {
        const selectProduct = selectedProducts.find(
          (item: SecilenUrun) => item?.grupId === urun?.urun?.grup?.id && item.dersId === urun?.ders?.id,
        )
        const seciliBeden = selectProduct?.secilenBeden
        const value = selectProduct?.adet ?? urun.adet

        return (
          <div className="gp-mobile-urun-base">
            <div className="mobil-urun-img-container">
              <Image className="mobil-urun-img" src={urun.urun?.grup?.resimler?.[0] ?? ''} />
            </div>
            <div className="mobil-urun-bilgi-container">
              <div>
                {urun.urun?.grup?.isim}
                <br />
                <span style={{ color: 'red' }}>{urun.urun?.kisa_aciklama}</span>
                <br />
                <span style={{ color: 'red' }}>{urun.sinif_liste_notu}</span>
              </div>

              <div className="mobile-urun-secilibeden-adet-tutar-container">
                {seciliBeden && (
                  <div className="mobil-urun-secilibeden-adet-bilgi">
                    <span className="mobil-urun-secili-beden-text">{seciliBeden}</span>
                    <div className="quantity-selector">
                      <div className="quantity-wrapper input-group">
                        <button
                          type="button"
                          className="quantity-left-minus btn btn-number"
                          onClick={() => handleQuantityChange(urun, value! - 1)} // Pass 'value' as an argument
                        >
                          <MinusOutlined />
                        </button>
                        <Input
                          min={urun.min_adet === 0 ? 1 : urun.min_adet ?? 0}
                          // disabled={isDisabled}
                          className="input-number quantity-number"
                          value={value!}
                        />
                        <button
                          type="button"
                          className="quantity-right-plus btn btn-number"
                          onClick={() => handleQuantityChange(urun, value! + 1)} // Pass 'value' as an argument
                        >
                          <PlusOutlined />
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mobil-urun-beden-bilgi">
                  <a className="mobil-urun-beden-sec-btn" onClick={() => showModal(urun.urun?.grup?.id ?? 0)}>
                    Beden Seçiniz
                  </a>
                  {urun.indirimsiz_birim_fiyat! <= urun.indirimli_birim_fiyat! || !indirimliFiyatGosterilsinmi ? (
                    <div>
                      <span>{setPriceFormat(String(urun.indirimsiz_birim_fiyat), 1)} TL</span> <br />
                    </div>
                  ) : (
                    <div>
                      <span className="text-line-through">
                        {setPriceFormat(String(urun.indirimsiz_birim_fiyat), 1)} TL
                      </span>{' '}
                      <br />
                      <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), 1)} TL</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  var filteredColumns = columns

  if (isTabletDimen && !isMobileDimen) {
    filteredColumns = tabletColumns
  }

  if (isMobileDimen) {
    filteredColumns = mobileColumns
  }

  filteredColumns.push(Table.SELECTION_COLUMN)

  return (
    <div className="group-product-table-container" key={`${products[0].urun.grup.id}_grup_table`}>
      <div style={{ width: '100%', textAlign: 'center', backgroundColor: `${products[0].ders.renk}` }}>
        <h2 style={{ marginTop: '5px', color: 'white', padding: '10px' }}>{products[0].ders.isim}</h2>
      </div>
      <Table
        dataSource={gruplarArr}
        columns={filteredColumns}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey={(obj) => `${obj.ders?.id}-${obj.urun?.grup?.id}`}
        pagination={false}
      />

      {selectedGroupIdFromRow !== 0 && (
        <GroupProductsModal
          modelVisible={modalVisible}
          setModalVisible={setModalVisible}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          products={products.filter((item: any) => item.urun.grup.id === selectedGroupIdFromRow)}
        />
      )}
    </div>
  )
}
export default GroupProductsTable

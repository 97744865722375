import './BasketPage.scss'

import { LeftOutlined } from '@ant-design/icons'
import { RightOutlined, ShoppingCartOutlined } from '@ant-design/icons/lib/icons'
import { Button, Card, Col, Layout, message, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import CenteredLayout from 'components/centered-layout/CenteredLayout'
import React, { useContext, useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { DEV_BACKEND_DOMAIN } from 'utils/const'

import BasketInfoTable from '../../components/basket-info-table/BasketInfoTable'
import BasketProductInfoTable from '../../components/basket-product-info-table/BasketProductInfoTable'
import EmptyBasket from '../../components/empty-basket/EmptyBasket'
import PaymentInfoCard from '../../components/payment-info-card/PaymentInfoCard'
import Spinner from '../../components/spinner/Spinner'
import AppContext from '../../context/AppContext'
import {
  SepetSatiri,
  useSepetiBosaltMutation,
  useSepetPaketDuzenleMutation,
  useSepetPaketSilMutation,
  useSepetQuery,
  useSepetUrunDuzenleMutation,
  useSepetUrunSilMutation,
} from '../../gql/GeneratedGraphqlTypes'
import useMediaQuery from '../../hooks/useMediaQuery'

const BasketPage = () => {
  const navigate = useNavigate()
  const { slug, token } = useContext(AppContext)
  const [messageApi, contextHolder] = message.useMessage()
  const [isSinif] = useState(true)

  const { loading, data, error, refetch } = useSepetQuery({
    variables: { slug: slug! },
    fetchPolicy: 'network-only',
  })

  //const [updatedItems, setUpdatedItems] = useState<SepetSatiri[]>([])
  const [isBasketTableVisible, setIsBasketTableVisible] = useState<boolean>(true)
  const [isBasketDeleteVisible, setIsBasketDeleteVisible] = useState<boolean>(false)
  const [isDigital, setIsDigital] = useState<boolean>(false)
  const [isKirtasiye, setIsKirtasiye] = useState<boolean>(false)
  //const [duzenleSepetMutation] = useSepetPaketDuzenleMutation() //todo:{loading: mutationLoading},error: mutationError} bu tarz eklenebilir ihtiyaç durumunda
  const [silSepetMutation] = useSepetUrunSilMutation()
  const [sepetUrunDuzenleMutation] = useSepetUrunDuzenleMutation()
  const [sepetiBosalt] = useSepetiBosaltMutation()
  const [sepetPaketSil] = useSepetPaketSilMutation()
  const [sepetPaketDuzenleMutation] = useSepetPaketDuzenleMutation()
  const isMobile = useMediaQuery(768)
  const [studentInfo, setStudentInfo] = useState({
    studentName: '',
    studentSurname: '',
    digital: '',
    gender: 0,
  })
  const [siderMaxHeight, setSiderMaxHeight] = useState('100vh')

  // Sepetteki tüm ürünleri silme
  const handleClear = () => {
    let items = []
    if (Array.isArray(data?.sepet?.urunler)) {
      items.push(...(data?.sepet?.urunler as SepetSatiri[]))
    }
    try {
      sepetiBosalt({
        variables: {
          okulSlug: slug!,
        },
      })
      messageApi.success('Sepet başarılı bir şekilde silindi', 6)
    } catch (error) {
      messageApi.error(`${error}`, 6)
    }
  }

  // django odeme adimlarina gecis
  const handleUpdate = async () => {
    let savedItems = []
    if (data?.sepet?.sinif !== null) {
      if (isDigital && (studentInfo.digital === '' || studentInfo.digital.length !== 11)) {
        if (studentInfo.digital.length !== 11) {
          messageApi.error('T.C kimlik kısmı 11 karakter olmalıdır.', 6)
        } else {
          messageApi.error('Dijital ürün bulunmakta, lütfen T.C kimlik kısmını doldurunuz.', 6)
        }
        return
      }

      if (isKirtasiye && studentInfo.gender === 0) {
        messageApi.error('Kırtasiye ürünü bulunmakta, lütfen cinsiyet kısmını doldurunuz.', 6)
        return
      }

      if (studentInfo.studentName === '' || studentInfo.studentSurname === '') {
        messageApi.error('Öğrenci ad soyad boş geçilemez.', 6)
        return
      }

      if (Array.isArray(data?.sepet?.urunler)) {
        savedItems.push(...(data?.sepet?.urunler as SepetSatiri[]))

        // Sepet verisini Django'ya gönderimi yapılır
        // burası sepet kontrol adımı sepetten ürünler silinirse false göndeririz
        fetch(
          `https://${slug}.${DEV_BACKEND_DOMAIN}/sepet-kontrol/${token}/?isim=${
            studentInfo.studentName + '%20' + studentInfo.studentSurname
          }&tc=${studentInfo.digital}&cinsiyet=${studentInfo.gender === 0 ? '' : studentInfo.gender === 1 ? 'E' : 'K'}`,
        )
          .then((response) => {
            if (response.status === 200) {
              return response.json()
            } else {
              return response.json().then((errorData) => {
                throw new Error(errorData.Error)
              })
            }
          })
          .then((data) => {
            const url = `https://${slug}.${DEV_BACKEND_DOMAIN}/token-to-session-auth/${token}/` // authentication django siteye geçiş
            window.location.href = url
          })
          .catch((error) => {
            messageApi.info('T.C. Kimlik Numarası hatalı girilmiştir. Lütfen kontrol ediniz.', 6)
          })
      }
    } else {
      if (Array.isArray(data?.sepet?.urunler)) {
        savedItems.push(...(data?.sepet?.urunler as SepetSatiri[]))

        // Sepet verisini Django'ya gönderimi yapılır
        // burası sepet kontrol adımı sepetten ürünler silinirse false göndeririz
        fetch(`https://${slug}.${DEV_BACKEND_DOMAIN}/sepet-kontrol/${token}/`)
          .then((response) => {
            if (response.status === 200) {
              return response.json()
            } else {
              return response.json().then((errorData) => {
                throw new Error(errorData.Error)
              })
            }
          })
          .then((data) => {
            const url = `https://${slug}.${DEV_BACKEND_DOMAIN}/token-to-session-auth/${token}/` // authentication django siteye geçiş
            window.location.href = url
          })
          .catch((error) => {
            messageApi.info('Sınıf listesindeki eski ürünler sepetten çıkarıldı.', 6)
          })
      }
    }
    refetch()
  }

  //sepetteki sınıf ürünleri buradan siliniyor.
  if (isBasketDeleteVisible && Array.isArray(data?.sepet?.urunler)) {
    try {
      // const response =
      sepetPaketSil({
        variables: {
          okulSlug: slug!,
          sinifId: data?.sepet?.sinif?.id!,
        },
      })
      setIsBasketDeleteVisible((prevValue: boolean) => !prevValue)
      messageApi.success('Sınıf ürünleri başarılı bir şekilde sepetten silindi')
    } catch (error) {
      messageApi.error(`${error}`, 6)
    }
  }

  const handleGeriDonClick = () => {
    navigate(-1) // Önceki sayfaya geri dönme
  }

  //ürün adet değişimi satir_id geliyor.
  const handleChange = async (id: number, value: number) => {
    const matchedItem = data?.sepet?.urunler?.find((item) => item?.id === id)
    // Convert updatedItems to the appropriate type
    try {
      if (matchedItem!.sinif_id == null) {
        await sepetUrunDuzenleMutation({
          variables: {
            okulSlug: slug!,
            urun: { urun_id: matchedItem?.urun?.id!, adet: value, ogrenci_ismi: '' },
          },
        })
        messageApi.success('Ürün başarılı bir şekilde güncellendi.', 6)
      } else {
        const alfa = []

        for (let i = 0; i < data?.sepet?.urunler?.length!; i++) {
          const urunVeri = urun![i]

          // Her bir ürün verisini urunler dizisine ekle
          if (urunVeri?.id === matchedItem!.id) {
            alfa.push({
              urun_id: urunVeri?.urun?.id as number,
              adet: value,
              ogrenci_ismi: '',
            })
          } else {
            alfa.push({
              urun_id: urunVeri?.urun?.id as number,
              adet: urunVeri?.adet!,
              ogrenci_ismi: '',
            })
          }
        }

        await sepetPaketDuzenleMutation({
          variables: {
            okulSlug: slug!,
            sinifId: matchedItem!.sinif_id,
            urunler: alfa,
          },
        })

        messageApi.success('Ürün başarılı bir şekilde güncellendi', 6)
      }
    } catch (hata) {
      messageApi.error(`${hata}`, 6)
    }
  }

  //spesifik ürün silme işlemi
  const handleDeleteChange = async (id: number) => {
    const selectedUrun = data?.sepet?.urunler?.find((urun) => urun?.id === id)
    try {
      if (selectedUrun!.sinif_id == null) {
        await silSepetMutation({
          variables: {
            okulSlug: slug!,
            urunId: selectedUrun?.urun?.id!,
          },
        })
        messageApi.success('Ürün başarılı bir şekilde silindi', 6)
      } else {
        const urunler = []

        for (let i = 0; i < data?.sepet?.urunler?.length!; i++) {
          const urunVeri = urun![i]

          // Her bir ürün verisini urunler dizisine ekle
          if (urunVeri?.id === selectedUrun!.id) {
            urunler.push({
              urun_id: urunVeri?.urun?.id as number,
              adet: 0,
              ogrenci_ismi: '',
            })
          } else {
            urunler.push({
              urun_id: urunVeri?.urun?.id as number,
              adet: urunVeri?.adet!,
              ogrenci_ismi: '',
            })
          }
        }
        await sepetPaketDuzenleMutation({
          variables: {
            okulSlug: slug!,
            sinifId: selectedUrun!.sinif_id,
            urunler: urunler,
          },
        })

        messageApi.success('Ürün başarılı bir şekilde silindi', 6)
      }
    } catch (hata) {
      messageApi.error(`${hata}`, 6)
    }
  }

  // Sepet Stok Kontrol && Sepet satırlarından sınıfı belirle
  useEffect(() => {
    if (data && data.sepet && data.sepet.urunler) {
      // Sepet satırlarından sınıfı belirle.
      const hasSinifId = data.sepet.urunler.some((urun: SepetSatiri | null) => urun?.sinif_id)
      console.log(hasSinifId)
      // setIsSinif(hasSinifId)
      
      // Sepet'de stok kontrolü yap.
      data.sepet.urunler.map((satir) => {
        if (satir?.urun?.grup !== null && satir?.stok! < satir?.adet!) {
          // Eğer stok 0'dan küçük ise satırı silme işlemine tabi tut
          if (satir?.stok! <= 0) {
            handleDeleteChange(satir?.id!)
            messageApi.error(`${satir?.urun?.isim} ürününden stokta kalmadığı için sepetten silindi.`, 6)
          } else {
            handleChange(satir?.id!, satir?.stok!)
            messageApi.error(
              `${satir?.urun?.isim} ürününden stokta ${satir?.stok} kadar kaldı. Ürün Otomatik olarak sepette ${satir?.stok} adet ile güncellendi.`,
              6,
            )
          }
        }
      })
    }
  }, [data])

  // Sider Yüksekliği Ayarlama
  useEffect(() => {
    const desiredPercentage = 60 // Ekranda nereye kadar inmesi gerekli yüzdesi
    const windowHeight = window.innerHeight
    const calculatedHeight = `${(windowHeight * desiredPercentage) / 100}px`
    setSiderMaxHeight(calculatedHeight)
  }, [])

  if (loading) {
    // Show a loading indicator while the data is being fetched
    return (
      <CenteredLayout>
        <Spinner showSpinner={true} color={'red'} />
      </CenteredLayout>
    )
  }
  if (error) {
    messageApi.error(`${error}`, 2)
  }
  if (!data) {
    return null
  }
  const sepet = data.sepet
  if (!sepet || sepet.__typename !== 'Sepet') {
    return null
  }
  const urun = sepet.urunler // todo: bunu state'e çevirip cevap sonrası güncelleme yapmamız lazım
  if (!urun) {
    return null
  }
  if (!data?.sepet || data.sepet.urunler?.length === 0) {
    return (
      <CenteredLayout>
        <EmptyBasket />
      </CenteredLayout>
    )
  } else {
    return (
      <CenteredLayout>
        {contextHolder}
        <div className="basket-page">
          <Card
            title={<p>ALIŞVERİŞ SEPETİM</p>}
            type={'inner'}
            // ToDo: Satın alma işlemine geçildiğinde actions'daki button'lar değiştirilmeli
            actions={[
              <Row justify="space-evenly" gutter={24}>
                <Col xs={8} sm={8}>
                  <Button
                    type={'primary'}
                    className="geri-don"
                    icon={<LeftOutlined />}
                    block
                    onClick={handleGeriDonClick}
                  >
                    Geri Dön
                  </Button>
                </Col>
                <Col xs={8} sm={8}>
                  <Button className="hizalama" icon={<ShoppingCartOutlined />} block onClick={handleClear}>
                    Sepeti Boşalt
                  </Button>
                </Col>
                <Col xs={8} sm={8}>
                  <Button type={'primary'} className="geri-don" block icon={<RightOutlined />} onClick={handleUpdate}>
                    Satın Al
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            {
              <div>
                {isMobile ? (
                  <>
                    <Row gutter={20}>
                      <Col xs={24} md={18}>
                        {isSinif && (
                          <BasketInfoTable
                            data={sepet}
                            setIsBasketTableVisible={setIsBasketTableVisible}
                            setIsBasketDeleteVisible={setIsBasketDeleteVisible}
                            setIsDigital={setIsDigital}
                            setIsKirtasiye={setIsKirtasiye}
                            studentInfo={studentInfo}
                            setStudentInfo={setStudentInfo}
                          />
                        )}
                        {urun.map((urunler, index) => {
                          if (urunler) {
                            return (
                              <BasketProductInfoTable
                                key={index}
                                data={urunler}
                                onChanges={handleChange}
                                onDeleteChange={handleDeleteChange}
                                isBasketTableVisible={isBasketTableVisible}
                              />
                            )
                          }
                          return null
                        })}
                        {urun?.length > 15 && <PaymentInfoCard data={sepet} onPurchase={handleUpdate} />}
                      </Col>
                      <Col xs={24} md={6} className="payment-info-mobile">
                        <PaymentInfoCard data={sepet} onPurchase={handleUpdate} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Layout className="layout">
                      <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <Row gutter={20}>
                          <Col xs={24} md={24}>
                            <BasketInfoTable
                              data={sepet}
                              setIsBasketTableVisible={setIsBasketTableVisible}
                              setIsBasketDeleteVisible={setIsBasketDeleteVisible}
                              setIsDigital={setIsDigital}
                              setIsKirtasiye={setIsKirtasiye}
                              studentInfo={studentInfo}
                              setStudentInfo={setStudentInfo}
                            />
                            {urun.map((urunler, index) => {
                              if (urunler) {
                                return (
                                  <BasketProductInfoTable
                                    key={index}
                                    data={urunler}
                                    onChanges={handleChange}
                                    onDeleteChange={handleDeleteChange}
                                    isBasketTableVisible={isBasketTableVisible}
                                  />
                                )
                              }
                              return null
                            })}
                            {/*<BasketTotal data={sepet} />*/}
                          </Col>
                        </Row>
                      </Content>
                      <Sider
                        width={300}
                        theme="light"
                        style={{
                          overflow: 'auto',
                          maxHeight: siderMaxHeight, // Sider Max Yüksekliği
                          position: 'sticky',
                          top: 0,
                          paddingLeft: 10,
                        }}
                      >
                        <Col xs={24} md={24} className="payment-info-mobile">
                          <PaymentInfoCard data={sepet} onPurchase={handleUpdate} />
                        </Col>
                      </Sider>
                    </Layout>
                  </>
                )}
              </div>
            }
          </Card>
        </div>
      </CenteredLayout>
    )
  }
}

export default BasketPage

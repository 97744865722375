import './ReturnedProducts.scss'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Input, message, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useContext, useState } from 'react'

import AppContext from '../../context/AppContext'
import { useIadeTalebiOlusturMutation, useSiparisDetayQuery } from '../../gql/GeneratedGraphqlTypes'
import { ReturnedProductsItem } from './types'

interface Props {
  siparis: string
}

const ReturnedProducts = ({ siparis }: Props) => {
  const temp = parseInt(siparis)
  const { slug } = useContext(AppContext)
  const [iadeTalebiOlusturMutation] = useIadeTalebiOlusturMutation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<{ key: string; adet: number }[]>([])
  const [messageApi, contextHolder] = message.useMessage()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<any>(null)
  const [productQuantities, setProductQuantities] = useState<{ key: string; adet: number }[]>([])

  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  if (error) {
    messageApi.error(`${error}`, 2)
  }
  if (!data) {
    return null
  }
  const ReturnedProductsItems: ReturnedProductsItem[] =
    data?.siparisDetay.satirlar
      ?.filter((urun) => urun?.durum === 'Tamamlandı' || urun?.durum === 'Kargolandı' || urun?.durum === 'Faturalandı')
      .map((urun) => {
        return {
          key: urun?.id?.toString() || '',
          isim: urun?.urun.isim || '', // isim alanı düzeltildi
          iade_talep_adet: urun?.adet || 0, // iade_talep_adet alanı düzeltildi
          birim_fiyat: parseFloat(`${urun?.birim_fiyat} ₺` || '0'),
          durum: urun?.durum || '', // degisim alanı düzeltildi
          kargo_takip_no: urun?.kargo_takip_no || '',
          min_adet: urun?.min_adet || 0,
        }
      }) || []
  const initialQuantities = ReturnedProductsItems.map((item) => ({
    key: item.key,
    adet: 0, // Varsayılan olarak 0 veya başka bir değer
  }))
  console.log(initialQuantities)
  console.log(productQuantities)
  if (productQuantities.length === 0 && initialQuantities.length !== 0) {
    setProductQuantities(initialQuantities)
  }

  const handleRowSelectChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    const updatedSelectedRows: { key: string; adet: number }[] = selectedRows.map((row) => {
      return {
        key: row.key,
        adet: row.iade_talep_adet,
      }
    })
    setSelectedRowKeys(updatedSelectedRows)
  }
  const showModal = (data: any) => {
    setModalData(data)
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const columns: ColumnsType<ReturnedProductsItem> = [
    {
      title: 'Ürün Adı',
      dataIndex: 'isim',
      key: 'isim',
      align: 'center',
    },
    {
      title: 'İade Talep Adeti',
      dataIndex: '',
      key: 'iade_talep_adet',
      align: 'center',
      render: (record) => {
        const handleIncrement = (record: ReturnedProductsItem) => {
          setProductQuantities(initialQuantities)
          const updatedQuantities = productQuantities.map((item) => {
            if (item.key === record.key && item.adet < record.iade_talep_adet) {
              return { key: item.key, adet: item.adet + 1 }
            }
            return item
          })
          setProductQuantities(updatedQuantities)
        }

        const handleDecrement = (record: ReturnedProductsItem) => {
          const updatedQuantities = productQuantities.map((item) => {
            if (item.key === record.key && item.adet > 0) {
              return { key: item.key, adet: item.adet - 1 }
            }
            return item
          })
          setProductQuantities(updatedQuantities)
        }

        // Miktarı artırmak için bir işlev

        let isDisabled = false
        if (record.min_adet > 0) {
          isDisabled = true
        }
        return (
          <>
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}
            >
              <div className="quantity-selector">
                <div className="quantity-wrapper input-group">
                  {record.min_adet <= 0 && (
                    <button
                      type="button"
                      className="quantity-left-minus btn btn-number"
                      onClick={() => handleDecrement(record)} // Parametre olarak 'record' ürününü iletebilirsiniz
                    >
                      <MinusOutlined />
                    </button>
                  )}
                  <Input
                    min={record.min_adet === 0 ? 1 : record.min_adet ?? 1}
                    disabled={isDisabled}
                    className="input-number quantity-number"
                    value={productQuantities.find((item) => item.key === record.key)?.adet ?? 0}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value)
                      if (!isNaN(newValue)) {
                        const updatedQuantities = productQuantities.map((item) => {
                          if (item.key === record.key) {
                            return { key: item.key, adet: newValue }
                          }
                          return item
                        })
                        setProductQuantities(updatedQuantities)
                      }
                    }}
                  />

                  {record.min_adet <= 0 && (
                    <button
                      type="button"
                      className="quantity-right-plus btn btn-number"
                      onClick={() => handleIncrement(record)} // Parametre olarak 'record' ürününü iletebilirsiniz
                    >
                      <PlusOutlined />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )
      },
    },
    {
      title: 'Fiyat',
      dataIndex: 'birim_fiyat',
      key: 'birim_fiyat',
      align: 'center',
    },
    {
      title: 'İade Durumu',
      dataIndex: 'durum',
      key: 'durum',
      align: 'center',
      render: (durum, record) => {
        if (durum === 'Tamamlandı' || durum === 'Kargolandı' || durum === 'Faturalandı') {
          if (record.min_adet > 0) {
            return <span>Zorunlu Ürün</span>
          } else {
            return <span>İade Yapılabilir</span>
          }
        } else {
          return <span>İade Yapılamaz</span>
        }
      },
    },
    Table.SELECTION_COLUMN,
  ]
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ReturnedProductsItem[]) => {},
    getCheckboxProps: (record: ReturnedProductsItem) => ({
      disabled:
        !(record.durum === 'Tamamlandı' || record.durum === 'Kargolandı' || record.durum === 'Faturalandı') ||
        record.kargo_takip_no !== '' ||
        record.min_adet > 0,
    }),
  }
  const handleSave = async () => {
    try {
      const satirlar = selectedRowKeys.map((row) => {
        return {
          id: parseInt(row.key),
          adet: row.adet,
        }
      })
      const { data } = await iadeTalebiOlusturMutation({
        variables: {
          okul_slug: slug!,
          siparis_id: parseInt(siparis),
          satirlar: satirlar,
        },
      })
      if (data?.iadeTalebiOlustur) {
        messageApi.success('Ürün İade Talebi Oluşturuldu..', 6)
        showModal(data)
      } else {
        messageApi.error('Ürün İade Talebi Oluşturulamadı.', 6)
      }
    } catch (error) {
      messageApi.error(`${error}`, 6)
    }
  }
  return (
    <>
      {contextHolder}
      <div className="returned-products">
        <Alert
          className="custom-alert"
          description="iade talebi oluşturabileceğiniz ürünler listelenmektedir. "
          type="success"
          closable
        />
        <br />
        <Alert
          className="custom-alert"
          description="Ürün elimize ulaştıktan sonra iade koşullarına uygunluğu incelenecek ve 2 iş günü içerisinde size sonuç hakkında bilgi verilecektir.. "
          type="success"
          closable
        />
        <br />
        <div>
          <Table
            columns={columns}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
              selectedRowKeys: selectedRowKeys.map((row) => row.key), // Seçili ürün id'lerini belirt
              onChange: handleRowSelectChange, // Seçim değiştiğinde çağrılacak fonksiyon
            }}
            pagination={false}
            dataSource={ReturnedProductsItems}
            bordered={true}
            className="custom-table"
          />
          <Button disabled={selectedRowKeys.length === 0} onClick={handleSave} className="buton-ayarla">
            Seçili Ürünleri İade Et
          </Button>
          <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {modalData && (
              <div>
                <p> Seçili ürün veya ürünlerinizi 7 iş günü içerisinde Yurtiçi Kargoya teslim etmeniz gerekmektedir.</p>
                <p>
                  Ücretsiz İade Kargo NO:{' '}
                  <span className="kargo-yazi-ayar">{modalData.iadeTalebiOlustur.kargo_takip_no}</span>
                </p>
                {/* Add other properties as needed */}
              </div>
            )}
          </Modal>
        </div>
      </div>
    </>
  )
}

export default ReturnedProducts

import { useEffect, useState } from 'react'

const useMediaQuery = (minWidth: number) => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: false,
  })

  const resizeHandler = () => {
    const currentWindowWidth = window.innerWidth
    const isDesiredWidth = currentWindowWidth < minWidth
    setState({ windowWidth: currentWindowWidth, isDesiredWidth })
  }

  useEffect(() => {
    setState({
      windowWidth: window.innerWidth,
      isDesiredWidth: window.innerWidth < minWidth,
    })
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [state.windowWidth])

  return state.isDesiredWidth
}

export default useMediaQuery

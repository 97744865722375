import AccountForm from 'components/account-form/AccountForm'

const MyAccountPage = () => {
  return (
    <div className="my-account-page-inner">
      <AccountForm />
    </div>
  )
}

export default MyAccountPage

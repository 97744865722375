import './SelectBranchModal.scss'

import { useApolloClient } from '@apollo/client'
import { Modal, Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import AppContext from 'context/AppContext'
import { SinifListesiVeKategorileriDocument, SinifListesiVeKategorileriQueryResult } from 'gql/GeneratedGraphqlTypes'
import { useContext, useEffect, useState } from 'react'
const { Option } = Select

interface Props {
  modalOpen: boolean
  setModalOpen: (val: boolean) => void
  onClassSelected: (classId: number) => void
}

type CategoryGroup = {
  categories: readonly ({
    readonly __typename?: 'SinifSecimSatiri' | undefined
    readonly sinif_id?: number | null | undefined
    readonly kategori_id?: number | null | undefined
    readonly isim?: string | null | undefined
  } | null)[]
  resultOfId: number | null
}

const SelectBranchModal = ({ modalOpen, setModalOpen, onClassSelected }: Props) => {
  const { slug } = useContext(AppContext)
  const client = useApolloClient()
  const [categoryGroups, setCategoryGroups] = useState<CategoryGroup[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null)

  const getCategoriesAndClasses = async (ust_kategori_id: number | null) => {
    setFetching(true)
    const { data } = (await client.query({
      query: SinifListesiVeKategorileriDocument,
      variables: { slug, ust_kategori_id },
    })) as SinifListesiVeKategorileriQueryResult
    const sinifKategorileri = data?.okul?.sinif_secim_liste
    if (sinifKategorileri && sinifKategorileri.length > 0) {
      setFetching(false)
      return sinifKategorileri
    }
    setFetching(false)
    return []
  }

  const initInitialCategoriesAndClasses = async () => {
    const categories = await getCategoriesAndClasses(null)
    if (categories) {
      const categoryGroup = { categories, resultOfId: null }
      const list: CategoryGroup[] = []
      list.push(categoryGroup)
      setCategoryGroups(list)
    }
  }

  const handleCategorySelectChange = async (value: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const opt = option as DefaultOptionType
    const selectedCategoryId = opt.value as number
    const isClass = opt.key.split(':')[0] === 'actual_class'
    setSelectedClassId(null)

    if (!isClass) {
      const indexOfCategoryGroup = categoryGroups.findIndex((categoryGroup) => {
        let result = false
        categoryGroup.categories.forEach((category) => {
          if (category?.kategori_id === selectedCategoryId) {
            result = true
          }
        })
        return result
      })

      const splittedList = categoryGroups.slice(0, indexOfCategoryGroup + 1)
      const classes = await getCategoriesAndClasses(selectedCategoryId)
      if (classes) {
        const categoryGroup = { categories: classes, resultOfId: selectedCategoryId }
        const list: CategoryGroup[] = [...splittedList]
        list.push(categoryGroup)
        setCategoryGroups(list)
      }
    } else if (isClass) {
      const indexOfCategoryGroup = categoryGroups.findIndex((categoryGroup) => {
        let result = false
        categoryGroup.categories.forEach((category) => {
          if (category?.sinif_id === selectedCategoryId) {
            result = true
          }
        })
        return result
      })
      // seçilen değer bütününde class ve category aynı array'de olabilir.
      // eğer seçilen değer bir class ise önceden seçilmiş bir kategorinin alt seçimlerini kaldırıyoruz.
      if (indexOfCategoryGroup !== -1) {
        const removeChildClass = categoryGroups.slice(0, indexOfCategoryGroup + 1)
        setCategoryGroups(removeChildClass)
      }
      setSelectedClassId(selectedCategoryId)
    }
  }

  useEffect(() => {
    initInitialCategoriesAndClasses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      centered
      title="Lütfen öğrencinizin kayıtlı olduğu sınıfı seçiniz."
      open={modalOpen}
      onOk={() => {
        setModalOpen(false)
        setCategoryGroups([...categoryGroups].slice(0, 1))
        if (selectedClassId) {
          onClassSelected(selectedClassId)
        }
        setSelectedClassId(null)
      }}
      okButtonProps={{ disabled: selectedClassId === null }}
      onCancel={() => {
        setModalOpen(false)
        setCategoryGroups([...categoryGroups].slice(0, 1))
        setSelectedClassId(null)
      }}
      className="select-branch-modal"
      wrapClassName="custom-modal-wrapper"
      destroyOnClose
    >
      {categoryGroups && categoryGroups.length > 0 && (
        <>
          {categoryGroups.map((categories) => {
            return (
              <Select
                loading={fetching}
                onChange={handleCategorySelectChange}
                key={`${categories.resultOfId}`}
                placeholder="Lütfen Seçiniz"
              >
                {categories.categories.map((categoryOrClass) => {
                  return (
                    <Option
                      key={`${categoryOrClass?.sinif_id ? 'actual_class' : 'category'}:${categoryOrClass?.kategori_id}${
                        categoryOrClass?.sinif_id
                      }`}
                      value={categoryOrClass?.sinif_id || categoryOrClass?.kategori_id || undefined}
                    >
                      <span className="category-option">{categoryOrClass?.isim}</span>
                    </Option>
                  )
                })}
              </Select>
            )
          })}
        </>
      )}
    </Modal>
  )
}

export default SelectBranchModal

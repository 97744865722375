import React from 'react'

export type FooterModalConfig = {
  title: string
  content: React.ReactNode
}

type FooterBaseItem = {
  title: React.ReactNode
}

export type FooterHeaderItem = FooterBaseItem & {}

export type FooterClickableItem = FooterBaseItem & {
  modalConfig?: FooterModalConfig
  onClick?: () => void
}

export type FooterItem = FooterHeaderItem | FooterClickableItem

export type FooterGroup = FooterItem[]

export function isFooterClickableItem(footerItem: FooterItem): footerItem is FooterClickableItem {
  return footerItem.hasOwnProperty('onClick') || footerItem.hasOwnProperty('modalConfig')
}

import './ClassProductsTable.scss'

import {MinusOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Image, Input, Table} from 'antd'
import {SinifUrunu} from 'gql/GeneratedGraphqlTypes'
import useMediaQuery from 'hooks/useMediaQuery'
import {SecilenUrun} from 'pages/class-products/ClassProductsPage'
import React, {useState} from 'react'
import {ETIKET_URUN_ID, KITAP_KAPLAMA_DERS_ID, KITAP_KAPLAMA_URUN_ID} from 'utils/const'
import {setPriceFormat} from 'utils/helpers'

import ClassProductsModal from '../class-product-modal/ClassProductsModal'

interface ClassProductsTableProps {
    products: SinifUrunu[]
    selectedProducts: SecilenUrun[]
    setSelectedProducts: React.Dispatch<React.SetStateAction<SecilenUrun[]>>
    lessonGruping: Boolean
    indirimliFiyatGosterilsinmi: Boolean
}

const ClassProductsTable = ({
                                products,
                                selectedProducts,
                                setSelectedProducts,
                                lessonGruping,
                                indirimliFiyatGosterilsinmi,
                            }: ClassProductsTableProps) => {
    const isTabletDimen = useMediaQuery(901)
    const isMobileDimen = useMediaQuery(600)
    const kitapKaplamaDersId = KITAP_KAPLAMA_DERS_ID
    const etiktetUrunId = ETIKET_URUN_ID
    var rowSpanlananGruplar: number[] = []

    // Etiket Modalin açılmasını kontrol eder
    const [modalVisible, setModalVisible] = useState(false)

    // Grupsuz olan sınıf ürünlerini derse göre kategorileme
    const grupsuzUrunlerKategorili = products.reduce((acc: any, obj: any) => {
        const key = obj?.ders?.isim
        acc[key] = acc[key] || []
        acc[key].push(obj)

        return acc
    }, {})

    // Gelen ürünleri sıralama
    const compareFunc = (first: SinifUrunu, second: SinifUrunu) => {
        if (second?.ders?.id === first?.ders?.id) {
            return 0
        }
        if (first?.ders?.id === kitapKaplamaDersId) {
            return -1
        } else if (second?.ders?.id === kitapKaplamaDersId) {
            return 1
        }
        // Diğer verileri ders isimlerine göre sırala
        return first.ders!.isim!.localeCompare(second.ders!.isim!)
    }

    products.sort(compareFunc)

    // Gruplama yapısını düzenleme
    const groupedProducts: { key: string; products: any[] }[] = Object.entries<any[]>(grupsuzUrunlerKategorili).map(
        ([key, value]) => {
            const groupKey = value[0]?.ders?.id || key

            return {
                key: groupKey,
                products: value.sort(compareFunc),
            }
        },
    )

    const showModal = (groupId: number) => {
        setModalVisible(true)
    }

    const rowSelection = {
        selectedRowKeys: selectedProducts.map((product: SecilenUrun) => `${product.dersId}-${product.urunId}`),
        onChange: (selectedRowKeys: React.Key[], selectedRows: SinifUrunu[]) => {
            const urunler = selectedRows.map((product) => {
                return {
                    isim: product.urun?.isim ?? '',
                    urunId: product.urun_id,
                    dersId: product.ders?.id,
                    adet: product.adet,
                    fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
                    minAdet: product.min_adet ?? 0,
                    grupId: null,
                    zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
                    etiket_bilgisi: null,
                    secilenBeden: null,
                    kitapKaplama: product.urun?.kitap_kaplama ?? 0,
                } as SecilenUrun
            })

            const eskiSecilenUrunler = [...selectedProducts.filter((i) => !i.grupId)]
            const eskiVeYeniUrunlerinBirlesimi = eskiSecilenUrunler.concat(urunler)

            const diffrentRows = eskiSecilenUrunler.filter((item) => {
                return urunler.filter((i) => i.dersId === item.dersId && i.urunId === item.urunId).length === 0
            })

            const uniqueRows = eskiVeYeniUrunlerinBirlesimi.filter((item, index) => {
                // item'daki ders_id ve id değerlerinin birleşimi önceki elemanlarda yoksa true, aksi halde false döndürüyoruz.
                return (
                    index === eskiVeYeniUrunlerinBirlesimi.findIndex((t) => t.dersId === item.dersId && t.urunId === item.urunId)
                )
            })

            const rowsDiff = uniqueRows.filter((item) => {
                return diffrentRows.filter((i) => i.dersId === item.dersId && i.urunId === item.urunId).length === 0
            })

            setSelectedProducts([...selectedProducts.filter((i) => i.grupId), ...rowsDiff])
        },
        getCheckboxProps: (record: any) => ({
            // min_adet 0'dan büyük ise user checkbox'ı kaldıramaz
            disabled: record.min_adet > 0,
        }),
        onCell: (record: any) => {
            return getColumnStyle(record)
        },
    }

    const handleQuantityChange = (product: SinifUrunu, value: any) => {
        if (value > 0) {
            const prevRows = [...selectedProducts]
            const updatedProductIndex = prevRows.findIndex(
                (item) => item.urunId === product.urun?.id && item.dersId === product.ders?.id,
            )
            if (updatedProductIndex !== -1) {
                // Seçili satır var ise adet değerini güncelle
                prevRows[updatedProductIndex].adet = value
                setSelectedProducts(prevRows)
            } else {
                // Seçili satır yok ise ekle
                setSelectedProducts([
                    ...selectedProducts,
                    {
                        isim: product.urun?.isim ?? '',
                        urunId: product.urun_id,
                        dersId: product.ders?.id,
                        adet: value,
                        fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
                        minAdet: product.min_adet ?? 0,
                        grupId: null,
                        zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
                        etiket_bilgisi: null,
                        secilenBeden: null,
                        kitapKaplama: product.urun?.kitap_kaplama ?? 0,
                    } as SecilenUrun,
                ])
            }
        }
    }

    const getColumnStyle = (record: any) => {
        // Grupladığımız verinin length değerine göre rowSpan belirleniyor.
        const group = groupedProducts.find((group: any) => group.key === record?.ders?.id)
        const groupIndex = group?.products.findIndex((item: any) => item === record)
        const groupLength = group?.products.length ?? 0

        if (groupLength - 1 === groupIndex || modalVisible) {
            return {
                style: {
                    borderBottom: `3px solid ${record?.ders?.renk ?? 'red'}`,
                },
            }
        }
        return {}
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: 'laptop-ders',
            onCell: (record: SinifUrunu) => {
                // Grupladığımız verinin length değerine göre rowSpan belirleniyor.
                const group = groupedProducts.find((group: any) => group.key === record?.ders?.id)
                const rowSpan = group ? group.products.length : 1

                if (rowSpanlananGruplar.some((id: any) => record.ders?.id === id)) {
                    return {
                        style: {display: 'none'},
                    }
                }

                rowSpanlananGruplar.push(record.ders?.id!)
                return {
                    style: {
                        background: 'rgb(250,250,250)',
                        width: '1%',
                        borderLeft: `4px solid ${record?.ders?.renk ?? 'red'}`,
                        borderBottom: `3px solid ${record?.ders?.renk ?? 'red'}`,
                    },
                    rowSpan: group ? rowSpan : undefined,
                }
            },
            render: (record: any) => {
                return <span className="vertical-text">{record?.ders?.isim}</span>
            },
        },
        {
            title: 'Ürün Resmi',
            dataIndex: 'urun',
            key: 'laptop-urun-resim',
            onCell: (record: any) => {
                return getColumnStyle(record)
            },
            render: (urun: any) => {
                return (
                    <div className="flex-center-div">
                        <Image
                            src={urun?.resimler?.[0] ?? urun?.resim ?? ''}
                            style={{maxWidth: '90px', maxHeight: '100px'}}
                            alt=""
                        />
                    </div>
                )
            },
            width: '10%',
        },
        {
            title: 'Ürün Adı',
            dataIndex: '',
            key: 'laptop-urun-adi',
            onCell: (record: SinifUrunu) => {
                return getColumnStyle(record)
            },
            render: (urun: SinifUrunu) => {
                return (
                    <div>
                        {urun.urun?.isim}
                        {urun.urun?.kisa_aciklama && (
                            <>
                                <br/>
                                <span style={{color: 'red'}}>{urun.urun?.kisa_aciklama}</span>
                            </>
                        )}
                        {urun.sinif_liste_notu && (
                            <>
                                <br/>
                                <span style={{color: 'red'}}
                                      dangerouslySetInnerHTML={{__html: urun.sinif_liste_notu}}></span>
                            </>
                        )}
                    </div>
                )
            },
        },
        {
            title: '',
            dataIndex: 'urun',
            key: 'laptop-etiket',
            onCell: (record: any) => {
                return getColumnStyle(record)
            },
            render: (urun: any) => {
                if (urun.id === etiktetUrunId && selectedProducts.some((row: SecilenUrun) => row.urunId === etiktetUrunId)) {
                    return (
                        <div>
                            <Button onClick={() => showModal(urun.id)}>Etiket Oluştur</Button>
                        </div>
                    )
                }
            },
        },
        {
            title: 'Adet',
            dataIndex: '',
            key: 'laptop-adet',
            onCell: (record: SinifUrunu) => {
                return getColumnStyle(record)
            },
            render: (urun: SinifUrunu) => {
                let isDisabled = false
                let value =
                    selectedProducts.find((p: SecilenUrun) => p.urunId === urun.urun?.id && p.dersId === urun.ders?.id)?.adet ||
                    urun.adet

                if (urun.urun_id === KITAP_KAPLAMA_URUN_ID) {
                    isDisabled = true
                    value = 0
                    selectedProducts.forEach((sinifUrunu: SecilenUrun) => {
                        value = value! + (sinifUrunu.kitapKaplama ?? 0) * (sinifUrunu.adet ?? 0)
                    })
                }
                // Todo: Max ?
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        <div className="quantity-selector">
                            <div className="quantity-wrapper input-group">
                                {urun.urun_id !== KITAP_KAPLAMA_URUN_ID && (
                                    <button
                                        type="button"
                                        className="quantity-left-minus btn btn-number"
                                        onClick={() => handleQuantityChange(urun, value! - 1)} // Pass 'value' as an argument
                                    >
                                        <MinusOutlined/>
                                    </button>
                                )}
                                <Input
                                    min={urun.min_adet === 0 ? 1 : urun.min_adet ?? 1}
                                    disabled={isDisabled}
                                    className="input-number quantity-number"
                                    value={value!}
                                />
                                {urun.urun_id !== KITAP_KAPLAMA_URUN_ID && (
                                    <button
                                        type="button"
                                        className="quantity-right-plus btn btn-number"
                                        onClick={() => handleQuantityChange(urun, value! + 1)} // Pass 'value' as an argument
                                    >
                                        <PlusOutlined/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )
            },
        },
        {
            title: (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>Birim Fiyat</span> <span>Tümü</span>
                </div>
            ),
            dataIndex: '',
            key: 'laptop-toplam-tutar',
            onCell: (record: any) => {
                return getColumnStyle(record)
            },
            render: (urun: SinifUrunu) => {
                var value = 1
                return (
                    <div>
                        {
                            indirimliFiyatGosterilsinmi &&
                            parseInt(urun.indirimli_birim_fiyat!) < parseInt(urun.indirimsiz_birim_fiyat!) ?
                            <span className="text-line-through">
                              {setPriceFormat(String(urun.indirimsiz_birim_fiyat || '0').toString(), value!)} TL
                            </span>
                            :
                            null
                        }
                        <br/>
                        <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), value!)} TL</span>
                    </div>
                )
            }
        },
    ]

    const tabletColumns: any = [
        {
            title: '',
            dataIndex: '',
            key: 'tablet-ders',
            onCell: (record: SinifUrunu) => {
                // Grupladığımız verinin length değerine göre rowSpan belirleniyor.
                const group = groupedProducts.find((group: any) => group.key === record?.ders?.id)
                const rowSpan = group ? group.products.length : 1
                if (rowSpanlananGruplar.some((id: any) => record.ders?.id === id)) {
                    return {
                        style: {display: 'none'},
                    }
                }

                rowSpanlananGruplar.push(record.ders?.id!)
                return {
                    style: {
                        background: 'rgb(250,250,250)',
                        width: '1%',
                        borderLeft: `4px solid ${record?.ders?.renk ?? 'red'}`,
                        borderBottom: `3px solid ${record?.ders?.renk ?? 'red'}`,
                    },
                    rowSpan: group ? rowSpan : undefined,
                }
            },
            render: (record: any) => {
                return <span className="vertical-text">{record?.ders?.isim}</span>
            },
        },
        {
            title: 'Ürün Resmi',
            dataIndex: 'urun',
            key: 'tablet-urun-adi',
            onCell: (record: any) => {
                return getColumnStyle(record)
            },
            render: (urun: any) => {
                return (
                    <div>
                        <div className="flex-center-div" style={{width: '130px', height: '90px'}}>
                            <Image
                                src={urun?.resimler?.[0] ?? urun?.resim ?? ''}
                                style={{maxWidth: '130px', maxHeight: '90px'}}
                                alt=""
                            />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Ürün',
            dataIndex: '',
            key: 'tablet-adet',
            onCell: (record: SinifUrunu) => {
                return getColumnStyle(record)
            },
            render: (urun: SinifUrunu) => {
                let isDisabled = false
                let value =
                    selectedProducts.find((p: SecilenUrun) => p.urunId === urun.urun?.id && p.dersId === urun.ders?.id)?.adet ||
                    urun.adet

                if (urun.urun_id === KITAP_KAPLAMA_URUN_ID) {
                    isDisabled = true
                    value = 0
                    selectedProducts.forEach((sinifUrunu: SecilenUrun) => {
                        value = value! + (sinifUrunu.kitapKaplama ?? 0) * (sinifUrunu.adet ?? 0)
                    })
                }
                // Todo: Max ?
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            {urun.urun?.isim}
                            {urun.urun?.kisa_aciklama && (
                                <>
                                    <br/>
                                    <span style={{color: 'red'}}>{urun.urun?.kisa_aciklama}</span>
                                </>
                            )}
                            {urun.sinif_liste_notu && (
                                <>
                                    <br/>
                                    <span style={{color: 'red'}}
                                          dangerouslySetInnerHTML={{__html: urun.sinif_liste_notu}}></span>
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: '13px',
                            }}
                        >
                            <div className="quantity-selector">
                                <div className="quantity-wrapper input-group">
                                    {urun.urun_id !== KITAP_KAPLAMA_URUN_ID && (
                                        <button
                                            type="button"
                                            className="quantity-left-minus btn btn-number"
                                            onClick={() => handleQuantityChange(urun, value! - 1)} // Pass 'value' as an argument
                                        >
                                            <MinusOutlined/>
                                        </button>
                                    )}
                                    <Input
                                        min={urun.min_adet === 0 ? 1 : urun.min_adet ?? 1}
                                        disabled={isDisabled}
                                        className="input-number quantity-number"
                                        value={value!}
                                    />
                                    {urun.urun_id !== KITAP_KAPLAMA_URUN_ID && (
                                        <button
                                            type="button"
                                            className="quantity-right-plus btn btn-number"
                                            onClick={() => handleQuantityChange(urun, value! + 1)} // Pass 'value' as an argument
                                        >
                                            <PlusOutlined/>
                                        </button>
                                    )}
                                </div>
                            </div>

                            {urun.urun_id === etiktetUrunId &&
                                selectedProducts.some((row: SecilenUrun) => row.urunId === etiktetUrunId) && (
                                    <Button onClick={() => showModal(urun.urun_id)}>Etiket Oluştur</Button>
                                )}
                        </div>
                    </div>
                )
            },
        },
        {
            title: (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>Birim Fiyat</span> <span>Tümü</span>
                </div>
            ),
            dataIndex: '',
            key: 'tablet-toplam-tutar',
            onCell: (record: any) => {
                return getColumnStyle(record)
            },
            render: (urun: SinifUrunu) => {
                let value = 1

                if (
                    urun.indirimsiz_birim_fiyat! <= urun.indirimli_birim_fiyat! ||
                    (!indirimliFiyatGosterilsinmi && !urun.satirda_uye_indirimi_var)
                ) {
                    // İndirimsiz fiyat daha küçük veya eşit ise indirimli fiyat gösterilmeyecek
                    // Backend'den data yanlış gelebiliyor
                    return (
                        <div>
                            <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), value!)} TL</span> <br/>
                        </div>
                    )
                }

                return (
                    <div>
                        <span
                            className="text-line-through">{setPriceFormat(String(urun.indirimsiz_birim_fiyat), value!)} TL</span>{' '}
                        <br/>
                        <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), value!)} TL</span>
                    </div>
                )
            },
        },
    ]

    const mobileColumns: any = [
        {
            title: '',
            dataIndex: '',
            key: 'mobile-ders',
            onCell: (record: SinifUrunu) => {
                // Grupladığımız verinin length değerine göre rowSpan belirleniyor.
                const group = groupedProducts.find((group: any) => group.key === record?.ders?.id)
                const rowSpan = group ? group.products.length : 1
                if (rowSpanlananGruplar.some((id: any) => record.ders?.id === id)) {
                    return {
                        style: {display: 'none'},
                    }
                }

                rowSpanlananGruplar.push(record.ders?.id!)
                return {
                    style: {
                        background: 'rgb(250,250,250)',
                        borderLeft: `4px solid ${record?.ders?.renk ?? 'red'}`,
                        borderBottom: `3px solid ${record?.ders?.renk ?? 'red'}`,
                        padding: '16px 4px',
                    },
                    rowSpan: group ? rowSpan : undefined,
                }
            },
            width: '30px',
            render: (record: any) => {
                return <span className="vertical-text">{record?.ders?.isim}</span>
            },
        },
        {
            title: (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>Ürün</span> <span>Tümü</span>
                </div>
            ),
            dataIndex: '',
            key: 'mobile-urun',
            onCell: (record: any) => {
                return getColumnStyle(record)
            },
            render: (urun: SinifUrunu) => {
                let value =
                    selectedProducts.find((p: SecilenUrun) => p.urunId === urun.urun?.id && p.dersId === urun.ders?.id)?.adet ||
                    urun.adet
                let isDisabled = false
                if (urun.urun_id === KITAP_KAPLAMA_URUN_ID) {
                    isDisabled = true
                    value = 0
                    selectedProducts.forEach((sinifUrunu: SecilenUrun) => {
                        value = value! + (sinifUrunu.kitapKaplama ?? 0) * (sinifUrunu.adet ?? 0)
                    })
                }

                return (
                    <div className="mobile-urun-base">
                        <div className="mobile-img">
                            <Image
                                className="mobile-urun-img"
                                src={urun.urun?.resimler?.[0] ?? urun.urun?.resim ?? ''}
                                alt="Resim bulunamadı"
                            />
                        </div>
                        <div className="mobile-urun-bilgi-container">
                            <div>
                                {urun.urun?.isim}
                                {urun.urun?.kisa_aciklama && (
                                    <>
                                        <br/>
                                        <span style={{color: 'red'}}>{urun.urun.kisa_aciklama}</span>
                                    </>
                                )}
                                {urun.sinif_liste_notu && (
                                    <>
                                        <br/>
                                        <span style={{color: 'red'}}
                                              dangerouslySetInnerHTML={{__html: urun.sinif_liste_notu}}></span>
                                    </>
                                )}
                            </div>

                            {urun.urun_id === etiktetUrunId &&
                                selectedProducts.some((row: SecilenUrun) => row.urunId === etiktetUrunId) && (
                                    <div className="mobile-etiket-container">
                                        <a className="mobile-etiket" onClick={() => showModal(urun.urun_id)}>
                                            Etiket Oluştur
                                        </a>
                                    </div>
                                )}

                            <div className="mobile-bilgi">
                                <div className="quantity-selector">
                                    <div className="quantity-wrapper input-group">
                                        {urun.urun_id !== KITAP_KAPLAMA_URUN_ID && (
                                            <button
                                                type="button"
                                                className="quantity-left-minus btn btn-number"
                                                onClick={() => handleQuantityChange(urun, value! - 1)} // Pass 'value' as an argument
                                            >
                                                <MinusOutlined/>
                                            </button>
                                        )}
                                        <Input
                                            min={urun.min_adet === 0 ? 1 : urun.min_adet ?? 1}
                                            disabled={isDisabled}
                                            className="input-number quantity-number"
                                            value={value!}
                                        />
                                        {urun.urun_id !== KITAP_KAPLAMA_URUN_ID && (
                                            <button
                                                type="button"
                                                className="quantity-right-plus btn btn-number"
                                                onClick={() => handleQuantityChange(urun, value! + 1)} // Pass 'value' as an argument
                                            >
                                                <PlusOutlined/>
                                            </button>
                                        )}
                                    </div>
                                </div>

                                {urun.indirimsiz_birim_fiyat! <= urun.indirimli_birim_fiyat! ||
                                (!indirimliFiyatGosterilsinmi && !urun.satirda_uye_indirimi_var) ? (
                                    <div>
                                        <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), 1)} TL</span> <br/>
                                    </div>
                                ) : (
                                    <div>
                                        <span className="text-line-through">
                                          {setPriceFormat(String(urun.indirimsiz_birim_fiyat), 1)} TL
                                        </span>{' '}
                                        <br/>
                                        <span>{setPriceFormat(String(urun.indirimli_birim_fiyat), 1)} TL</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            },
        },
    ]

    var filteredColumns: any = columns

    if (isTabletDimen && !isMobileDimen) {
        filteredColumns = tabletColumns
    }

    if (isMobileDimen) {
        filteredColumns = mobileColumns
    }

    if (!lessonGruping) {
        filteredColumns = filteredColumns.filter(
            (x: any) => x.key !== 'laptop-ders' && x.key !== 'tablet-ders' && x.key !== 'mobile-ders',
        )
    }

    filteredColumns.push(Table.SELECTION_COLUMN)

    return (
        <div className="non-group-product-table-container">
            <Table
                dataSource={products}
                columns={filteredColumns}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={false}
                rowKey={(obj: SinifUrunu) => `${obj.ders?.id}-${obj.urun?.id}`} // Aynı üründen farklı derse eklenebilir.
            />
            <ClassProductsModal
                modelVisible={modalVisible}
                setModalVisible={setModalVisible}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            />
        </div>
    )
}

export default ClassProductsTable

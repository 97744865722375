import './MasterAccountPage.scss'

import { MenuOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import AccountMenu, { AccountMenuItems } from 'components/account-menu/AccountMenu'
import CenteredLayout from 'components/centered-layout/CenteredLayout'
import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { AccountMenuItem } from '../../components/account-menu/types'
import CommonDrawer from '../../components/drawer/CommonDrawer'
import AppContext from '../../context/AppContext'

const MasterAccountPage = () => {
  const appContext = useContext(AppContext)
  const navigate = useNavigate()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [activeItem, setActiveItem] = useState('Hesap Bilgileri')
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 570px)') // Adjust breakpoint as needed Todo: Hook
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setIsSmallScreen(event.matches)
    }

    setIsSmallScreen(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onItemClick = (item: AccountMenuItem) => {
    item.onClick(navigate, appContext)
    closeDrawer()
  }
  const menuItems = AccountMenuItems.map((item, i) => (
    <Button key={i} icon={item.icon} onClick={() => onItemClick(item)} className={'menu-button'} type={'ghost'}>
      {item.title}
    </Button>
  ))

  return (
    <CenteredLayout>
      <div className="my-account-page">
        <Card
          title={
            <p className={'card-title'}>
              {activeItem}
              {
                isSmallScreen ? (
                  <span style={{ float: 'right' }}>
                    <MenuOutlined onClick={() => setDrawerVisible(true)} />
                  </span>
                ) : null /* No icon for larger screens */
              }
            </p>
          }
          type={'inner'}
        >
          <Row gutter={[0, 24]}>
            <Col lg={4} md={6} sm={8} xs={24}>
              <AccountMenu activeItem={activeItem} setActiveItem={setActiveItem} />
            </Col>
            <Col lg={20} md={18} sm={16} xs={24}>
              <Outlet />
            </Col>
          </Row>
        </Card>
        {isSmallScreen && (
          <CommonDrawer
            title="" // Add a placeholder title or an empty string here
            isVisible={drawerVisible}
            onClose={closeDrawer}
            items={menuItems}
          />
        )}
      </div>
    </CenteredLayout>
  )
}

export default MasterAccountPage

import './OrdersTable2.scss'

import { message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { AppRoutes } from 'App'
import modalCantaImg1 from 'images/black-ikon.png'
import modalCantaImg from 'images/white-ikon.png'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useSiparislerimQuery } from '../../gql/GeneratedGraphqlTypes'
import useMediaQuery from '../../hooks/useMediaQuery'
import EmptyOrder from '../empty-order/EmptyOrder'
import { OrderItem } from './types'

const rowClassName = (record: any, index: number) => {
  return record.durum === 'İptal' ? 'odd-row' : 'even-row'
}
const OrderTable2 = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const isMobileDimen = useMediaQuery(600)
  const navigate = useNavigate()
  const { data, error } = useSiparislerimQuery({
    variables: { page: 0 },
  })

  if (error) {
    messageApi.error(`${error}`, 2)
  }
  if (!data) {
    return null
  }

  console.log(data)
  if (!data || !data.siparislerim || data.siparislerim.length === 0) {
    // Eğer "data" yoksa veya "siparislerim" dizisi boşsa, tabloyu göstermeyin.
    return (
      <>
        <EmptyOrder />
      </>
    )
  }
  const handleButtonClick1 = (record: OrderItem) => {
    navigate(`/${AppRoutes.account.root}/${AppRoutes.account.orderDetail}/${record.siparis_no}/`)
  }

  const handleButtonClick2 = (record: OrderItem) => {
    console.log(record)
    navigate(`/${AppRoutes.account.root}/${AppRoutes.account.orderDetailCheck}/${record.key}/`)
  }

  const OrderItems: OrderItem[] = data.siparislerim.map((urun) => {
    const siparisTarihi = urun?.tarih ? new Date(urun.tarih).toISOString().slice(0, 10) : ''
    return {
      key: urun?.id?.toString() || '',
      siparis_tarihi: siparisTarihi,
      siparis_no: Number(urun?.id),
      tutar: Number(urun?.toplam_tutar) || 0,
      durum: urun?.durum || '',
    }
  })

  const columns: ColumnsType<OrderItem> = [
    {
      title: 'Siparis Tarihi',
      dataIndex: 'siparis_tarihi',
      align: 'center',
    },
    {
      title: 'Siparis No',
      dataIndex: 'siparis_no',
      align: 'center',
    },
    {
      title: 'Durum',
      dataIndex: 'durum',
      align: 'center',
    },
    {
      title: 'Toplam Tutar',
      dataIndex: 'tutar',
      align: 'center',
    },
    {
      key: 'action',
      align: 'center',
      render: (_: any, record: OrderItem, index) => (
        <>
          <div>
            <button className="tab-pane-style green-tab" onClick={() => handleButtonClick1(record)}>
              <span className="text-size">
                {' '}
                Siparişinize <br /> Ait Ürünler <br />
              </span>
              <img src={modalCantaImg1} alt="" className="modalimages" />
            </button>
            <button className="tab-pane-style2 white-tab" onClick={() => handleButtonClick2(record)}>
              <span className="text-size">
                Adres ve <br /> Ödeme <br />
              </span>
              <img src={modalCantaImg} alt="" className="modalimages" />
            </button>
          </div>
        </>
      ),
    },
  ]
  return (
    <>
      {contextHolder}
      <div className="orders-table">
        {isMobileDimen ? (
          <Table
            columns={columns}
            dataSource={OrderItems}
            rowClassName={rowClassName}
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  <div>
                    <p className="durum-p-ayar">
                      <strong>Durum</strong> <span className="durum-ayar">:{record.durum}</span>
                    </p>
                  </div>
                  <hr />
                  <div className="button-ayar">
                    <button className="tab-pane-style green-tab" onClick={() => handleButtonClick1(record)}>
                      <span className="text-size">
                        {' '}
                        Siparişinize <br /> Ait Ürünler <br />
                      </span>
                      <img src={modalCantaImg1} alt="" className="modalimages" />
                    </button>
                    <button className="tab-pane-style2 white-tab" onClick={() => handleButtonClick2(record)}>
                      <span className="text-size">
                        Adres ve <br /> Ödeme <br />
                      </span>
                      <img src={modalCantaImg} alt="" className="modalimages" />
                    </button>
                  </div>
                </div>
              ),
            }}
          />
        ) : (
          <Table columns={columns} rowClassName={rowClassName} dataSource={OrderItems} />
        )}
      </div>
    </>
  )
}
export default OrderTable2

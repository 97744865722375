import './ClassProductsPage.scss'

import {ShoppingCartOutlined} from '@ant-design/icons'
import {Alert, Button, Collapse, Input, message, Modal, notification, Select} from 'antd'
import {NotificationPlacement} from 'antd/es/notification/interface'
import {AppRoutes} from 'App'
import CenteredLayout from 'components/centered-layout/CenteredLayout'
import GroupProductsTab from 'components/class-group-products-tab/GroupProductsTab'
import GroupProductsTable from 'components/class-group-products-table/GroupProductsTable'
import ClassProductsTable from 'components/class-products-table/ClassProductsTable'
import Spinner from 'components/spinner/Spinner'
import AppContext from 'context/AppContext'
import {
    SinifUrunu, UrunGrubu,
    useSepetePaketEkleMutation,
    useSepetiBosaltMutation, useSinifSecimSifreMutation,
    useSinifUrunleriQuery
} from 'gql/GeneratedGraphqlTypes'
import infoImg from 'images/info.png'
import modalCantaImg from 'images/modal_cantaa.jpg'
import modalKalemImg from 'images/modal_kalem.png'
import modalKalemKutuImg from 'images/modal_kalemkutu.png'
import modalMataraImg from 'images/modal_matara.png'
import tabTekstilImg from 'images/tab_tekstil.png'
import tabKirtasiyeImg from 'images/tab-kirtasiye.png'
import React, {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ETIKET_URUN_ID} from 'utils/const'

import GroupProductsTabTekstil from "../../components/class-group-products-tab/GroupProductsTabTekstil";

const {Panel} = Collapse;

interface TabData {
    label: JSX.Element
    className: string
    content: JSX.Element
}

export type SecilenUrun = {
    isim: string
    urunId: number
    dersId: number
    adet: number
    minAdet: number
    fiyat: string
    kisa_aciklama: string
    resimler: any[]
    indirimsiz_birim_fiyat?: string
    indirimli_birim_fiyat?: string
    grupId: number | null
    zorunlulukGrubu: number | null
    secilenBeden: string | null
    etiket_bilgisi: string | null
    kitapKaplama: number | null
    grup: UrunGrubu;
}

function ClassProductsPage() {
    const params = useParams()
    const {slug} = useContext(AppContext)
    const sinifId = params.classId ? parseInt(params.classId) : 0
    const [activeTab, setActiveTab] = useState<number>(0)
    const [lessonGrouping, setLessonGrouping] = useState<boolean>(true) // Sınıf Ürünlerinin neye göre sıralanacağı.
    const [sepetePaketEkleMutation] = useSepetePaketEkleMutation()

    const [selectedProducts, setSelectedProducts] = useState<SecilenUrun[]>([]) // Tablolarda seçilen ürünler bu state'e eklenir.
    const [selected1Products, setSelected1Products] = useState<SecilenUrun[]>([]) // Tablolarda seçilen ürünler bu state'e eklenir.
    const [selected2Products, setSelected2Products] = useState<SecilenUrun[]>([]) // Tablolarda seçilen ürünler bu state'e eklenir.
    const [selected3Products, setSelected3Products] = useState<SecilenUrun[]>([]) // Tablolarda seçilen ürünler bu state'e eklenir.
    const [selected4Products, setSelected4Products] = useState<SecilenUrun[]>([]) // Tablolarda seçilen ürünler bu state'e eklenir.

    const [messageApi, contextHolderMessage] = message.useMessage()
    const [modalVisible, setModalVisible] = useState(false)
    const [grupluUrunler, setGrupluUrunler] = useState<any[]>([])
    const [grupsuzUrunler, setGrupsuzUrunler] = useState<any[]>([])
    const [grupluAnaOkulUrunler, setGrupluAnaOkulUrunler] = useState<any[]>([])
    const [grupluIlkOkulUrunler, setGrupluIlkOkulUrunler] = useState<any[]>([])
    const [grupluOrtaOkulUrunler, setGrupluOrtaOkulUrunler] = useState<any[]>([])
    const [grupluLiseUrunler, setGrupluLiseUrunler] = useState<any[]>([])
    const [grupluUrunlerDersKategorili, setGrupluUrunlerDersKategorili] = useState<any[]>()
    const navigate = useNavigate()
    const etiktetUrunId = ETIKET_URUN_ID
    const [sepetiBosalt] = useSepetiBosaltMutation()
    const [isAModalVisible, setIsAModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [optionsValue, setOptionsValue] = useState(-1);
    const [sinifSecimSifre] = useSinifSecimSifreMutation()
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false)
    const [classPassword, setClassPassword] = useState<string>('')
    const [isPasswordChecked, setIsPasswordChecked] = useState(false);
    const {data, loading, error} = useSinifUrunleriQuery({
        variables: {
            slug: slug!,
            sinif_id: sinifId,
        },
    })
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [temp, setTemp] = useState(false)
    const [selectedKey, setSelectedKey] = useState(0)
    const handlePasswordSubmit = async () => {
        try {
            const response = await sinifSecimSifre({
                variables: {
                    okul_id: data!.okul?.id!,
                    sinif_id: sinifId,
                    password: classPassword,
                },
            });

            if (response.data?.sinifSecimSifre) {
                const {statusCode, message} = response.data.sinifSecimSifre;

                if (statusCode === 200) {
                    notification.open({
                        message: 'Başarılı',
                        description: 'Şifre Doğru.',
                        type: 'success',
                    });
                    setIsPasswordChecked(false)
                    setShowPasswordModal(false);
                } else {
                    messageApi.error(message, 6);
                }
            }
        } catch (error) {
            messageApi.error(`${error}`, 6);
        }
    };
    useEffect(() => {
        if (data?.okul?.sinif?.sifre_varmi === true) {
            setShowPasswordModal(true);
            setIsPasswordChecked(true)
        } else {
            setIsPasswordChecked(false);
        }
    }, [data]);
    const closeDiv = () => {
        setTemp(false)
    }
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showErrorModal = (message: string) => {
        setIsAModalVisible(true);
        const errorMessage = message ? message.slice(6) : message;
        setErrorMessage(errorMessage);
    };

    const handleModalOk = () => {
        setIsAModalVisible(false);
        handleClear()
    };
    const handleClear = async () => {
        try {
            await sepetiBosalt({
                variables: {
                    okulSlug: slug!,
                },
            });
            messageApi.success('Sepet başarılı bir şekilde güncellendi', 6);
            handleSepeteEkleButton()
        } catch (error) {
            messageApi.error(`${error}`, 6);
        }
    };

    const handleModalCancel = () => {
        setIsAModalVisible(false);
    };

    useEffect(() => {
        showModal();
    }, []);
    
    useEffect(() => {
        const openNotification = (placement: NotificationPlacement, message: string) => {
            notification.open({
                message: 'Bilgilendirme',
                description: <p dangerouslySetInnerHTML={{__html: message}}/>,
                type: 'info',
                placement: placement,
            })
        }

        if (!loading && data) {
            if (data!.okul?.sinif?.aktif && data!.okul?.sinif?.kapali_uyarisi) {
                navigate(`/${AppRoutes.classClose}/${data.okul?.sinif?.id!}/`)
            }
            if (!data!.okul?.active) {
                // !data!.okul?.sinif?.aktif
                navigate(`/${AppRoutes.schoolClose}`)
            }

            if (data?.okul?.sinif?.uyari && data?.okul?.sinif?.uyari !== '') {
                // openNotification('topRight', data?.okul?.sinif?.uyari)
                setTemp(true)
            }
            if (data?.okul?.sinif?.duyuru_popup && data?.okul?.sinif?.duyuru_popup !== '') {
                openNotification('topRight', data?.okul?.sinif?.duyuru_popup)
            }

            if (data?.okul?.sinif?.uye_indirim_var) {
                openNotification(
                    'topRight',
                    'Üyeliğinize özel indirimler fiyatlara yansıtılmıştır. Sınıf Listelerinde indirimli fiyat gösterilmektedir.',
                )
            }
        }

        // Data ayrımı
        // gruplu ve grupsuz olmasına göre
        const {sinif_urunleri} = data?.okul?.sinif || {};
        let innerGrupluUrunler: any[] = [];
        let innerGrupsuzUrunler: any[] = [];
        let innerGruplu1Urunler: any[] = [];
        let innerGruplu2Urunler: any[] = [];
        let innerGruplu3Urunler: any[] = [];
        let innerGruplu4Urunler: any[] = [];
        let grupluUrunlerDersKategorili: any[] = [];

        if (sinif_urunleri) {
            sinif_urunleri.forEach((item) => {
                if (item?.urun?.grup !== null) {
                    if (item?.urun?.urun_kademeleri?.includes('1')) {
                        innerGruplu1Urunler.push(item);
                    }
                    if (item?.urun?.urun_kademeleri?.includes('2')) {
                        innerGruplu2Urunler.push(item);
                    }
                    if (item?.urun?.urun_kademeleri?.includes('3')) {
                        innerGruplu3Urunler.push(item);
                    }
                    if (item?.urun?.urun_kademeleri?.includes('4')) {
                        innerGruplu4Urunler.push(item);
                    }
                    if (data?.okul?.tekstil_toplu_satis === false) {
                        innerGrupluUrunler.push(item);
                    }
                } else {
                    innerGrupsuzUrunler.push(item);
                }
            });
        }
        // Gruoplu olan sınıf ürünlerini derse göre ayırma
        const grupluUrunlerKategorili = innerGrupluUrunler.reduce((acc, obj) => {
            const key = obj.ders.id
            acc[key] = acc[key] || []
            acc[key].push(obj)
            return acc
        }, {})
        grupluUrunlerDersKategorili = Object.values(grupluUrunlerKategorili)
        setGrupluUrunler(innerGrupluUrunler)
        setGrupsuzUrunler(innerGrupsuzUrunler)
        setGrupluAnaOkulUrunler(innerGruplu1Urunler)
        setGrupluIlkOkulUrunler(innerGruplu2Urunler)
        setGrupluOrtaOkulUrunler(innerGruplu3Urunler)
        setGrupluLiseUrunler(innerGruplu4Urunler)
        setGrupluUrunlerDersKategorili(grupluUrunlerDersKategorili)
        // Zorunlu ürünlerin seçilmesi
        const zorunluUrunler = innerGrupsuzUrunler
            .filter((product: SinifUrunu) => product.min_adet! > 0)
            .map((product: SinifUrunu) => {
                return {
                    isim: product.urun?.isim ?? '',
                    urunId: product.urun_id,
                    dersId: product.ders?.id,
                    resimler: product.urun?.resimler,
                    adet: product.adet ?? 1,
                    fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
                    minAdet: product.min_adet ?? 0,
                    indirimsiz_birim_fiyat: product.indirimsiz_birim_fiyat,
                    indirimli_birim_fiyat: product.indirimli_birim_fiyat,
                    kisa_aciklama: product.urun?.kisa_aciklama,
                    grupId: null,
                    zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
                    etiket_bilgisi: '',
                    secilenBeden: null,
                    kitapKaplama: product.urun?.kitap_kaplama,
                    grup: {
                        id: product.urun?.grup?.id ?? 0, // Adjust the property names as per your actual types
                        isim: product.urun?.grup?.isim ?? '',
                        resimler: product.urun?.grup?.resimler ?? [],
                        sira: product.urun?.grup?.sira ?? 0, // Assuming 'sira' is part of your UrunGrubu type
                    },
                } as SecilenUrun
            })

        if (!data?.okul?.sinif?.urunler_secili_gelsin ?? false) {
            setSelectedProducts(zorunluUrunler)
        } else {
            const tumUrunler = innerGrupsuzUrunler.map((product) => {
                return {
                    isim: product.urun?.isim ?? '',
                    urunId: product.urun_id,
                    dersId: product.ders?.id,
                    grupId: null,
                    adet: product.adet ?? 1,
                    resimler: product.urun?.resimler,
                    kisa_aciklama: product.urun?.kisa_aciklama,
                    indirimsiz_birim_fiyat: product.indirimsiz_birim_fiyat,
                    indirimli_birim_fiyat: product.indirimli_birim_fiyat,
                    fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
                    minAdet: product.min_adet ?? 0,
                    zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
                    etiket_bilgisi: '',
                    secilenBeden: '',
                    grup: {
                        id: product.urun?.grup?.id ?? 0, // Adjust the property names as per your actual types
                        isim: product.urun?.grup?.isim ?? '',
                        resimler: product.urun?.grup?.resimler ?? [],
                        sira: product.urun?.grup?.sira ?? 0, // Assuming 'sira' is part of your UrunGrubu type
                    },
                    kitapKaplama: product.urun?.kitap_kaplama,
                } as SecilenUrun
            })

            setSelectedProducts(tumUrunler)
        }
    }, [data])
    // Loading
    if (loading) {
        return <Spinner showSpinner={loading} color={'red'}></Spinner>
    }

    if (error) {
        navigate(`/`)
    }
    if (slug == null || sinifId === 0 || data === null || !sinifId || !slug) {
        // Todo: buraya sınıfta ürün yok olduğunu belirtecek bir component gelicek
        navigate(`/`)
    }

    const handleOrderSelectChange = (value: boolean) => {
        // Ürünlerin kırtasiye-ürün / ders-ürün sıralamasını değiştirir.
        setLessonGrouping(value)
    }

    const handleAccordionChange = (key: string | string[]) => {
        // Key parameter contains the key value of the opened panel
        const panelKey = typeof key === 'string' ? parseInt(key, 10) : key.map(k => parseInt(k, 10));
        let a = 0;

        // If panelKey is an array, set the first element, otherwise set the single value
        if (Array.isArray(panelKey)) {
            setOptionsValue(panelKey[0]);
            a = panelKey[0];
        } else {
            setOptionsValue(panelKey);
            a = panelKey;
        }
        // Do nothing if a is equal to selectedKey
        if (a === selectedKey) {
            console.log("")
        }
        const {sinif_urunleri} = data?.okul?.sinif || {};
        let innerGruplu1Urunler: any[] = [];
        let innerGrupsuz1Urunler: any[] = [];
        let innerGruplu2Urunler: any[] = [];
        let innerGruplu3Urunler: any[] = [];
        let innerGruplu4Urunler: any[] = [];
        let innerGruplu5Urunler: any[] = [];
        let grupluUrunler1DersKategorili: any[] = [];
        if (a !== undefined && a >= 0) {
            setSelectedKey(a);
            if (a === 1) {
                innerGruplu2Urunler = grupluAnaOkulUrunler;
                // Grouping class products by subject
                const grupluUrunler1Kategorili = innerGruplu2Urunler.reduce((acc, obj) => {
                    const key = obj.ders.id;
                    acc[key] = acc[key] || [];
                    acc[key].push(obj);
                    return acc;
                }, {});
                grupluUrunler1DersKategorili = Object.values(grupluUrunler1Kategorili);
                setGrupluAnaOkulUrunler(innerGruplu2Urunler);
                setGrupluUrunlerDersKategorili(grupluUrunler1DersKategorili);
            } else if (a === 2) {
                innerGruplu3Urunler = grupluIlkOkulUrunler;
                // Grouping class products by subject
                const grupluUrunler1Kategorili = innerGruplu3Urunler.reduce((acc, obj) => {
                    const key = obj.ders.id;
                    acc[key] = acc[key] || [];
                    acc[key].push(obj);
                    return acc;
                }, {});
                grupluUrunler1DersKategorili = Object.values(grupluUrunler1Kategorili);
                setGrupluIlkOkulUrunler(innerGruplu3Urunler);
                setGrupluUrunlerDersKategorili(grupluUrunler1DersKategorili);
            } else if (a === 3) {
                innerGruplu4Urunler = grupluOrtaOkulUrunler;
                // Grouping class products by subject
                const grupluUrunler1Kategorili = innerGruplu4Urunler.reduce((acc, obj) => {
                    const key = obj.ders.id;
                    acc[key] = acc[key] || [];
                    acc[key].push(obj);
                    return acc;
                }, {});
                grupluUrunler1DersKategorili = Object.values(grupluUrunler1Kategorili);
                setGrupluOrtaOkulUrunler(innerGruplu4Urunler);
                setGrupluUrunlerDersKategorili(grupluUrunler1DersKategorili);
            } else if (a === 4) {
                innerGruplu5Urunler = grupluLiseUrunler;
                // Grouping class products by subject
                const grupluUrunler1Kategorili = innerGruplu5Urunler.reduce((acc, obj) => {
                    const key = obj.ders.id;
                    acc[key] = acc[key] || [];
                    acc[key].push(obj);
                    return acc;
                }, {});
                grupluUrunler1DersKategorili = Object.values(grupluUrunler1Kategorili);
                setGrupluLiseUrunler(innerGruplu5Urunler);
                setGrupluUrunlerDersKategorili(grupluUrunler1DersKategorili);
            }

            const filteredUrunler = data?.okul?.sinif?.sinif_urunleri?.filter(urun =>
                urun?.urun?.urun_kademeleri?.includes(a.toString())
            );
            if (filteredUrunler) {
                filteredUrunler.forEach((item) => {
                    if (item?.urun?.grup !== null) {
                        // handle grouped items
                    } else {
                        innerGrupsuz1Urunler.push(item);
                    }
                });
            }
            if (sinif_urunleri) {
                sinif_urunleri.forEach((item) => {
                    if (item?.urun?.grup === null) {
                        innerGrupsuz1Urunler.push(item);
                    }
                });
            }
            setGrupsuzUrunler(innerGrupsuz1Urunler);
        } else if (a === -1 || a === undefined) {
            if (sinif_urunleri) {
                sinif_urunleri.forEach((item) => {
                    if (item?.urun?.grup !== null) {
                        innerGruplu1Urunler = [];
                    } else {
                        innerGrupsuz1Urunler.push();
                    }
                });
            }
            const grupluUrunler2Kategorili = innerGruplu1Urunler.reduce((acc, obj) => {
                const key = obj.ders.id;
                acc[key] = acc[key] || [];
                acc[key].push(obj);
                return acc;
            }, {});
            grupluUrunler1DersKategorili = Object.values(grupluUrunler2Kategorili);
            setGrupluUrunler(innerGruplu1Urunler);
            setGrupsuzUrunler(innerGrupsuz1Urunler);
            setGrupluUrunlerDersKategorili(grupluUrunler1DersKategorili);
        } else {
            console.log("here")
            // Handle other cases if needed
        }
    };
    
    const handleSepeteEkleButton = async () => {
        const zorunluUrunler: SinifUrunu[] = grupsuzUrunler.filter((product: SinifUrunu) => product.min_adet! > 0)
        const zorunluGruplar: number[] = grupluUrunler
            .filter((product: SinifUrunu) => product.zorunluluk_grubu !== null && product.zorunluluk_grubu !== undefined)
            .map((product: SinifUrunu) => product.zorunluluk_grubu as number)
            .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index)
        let selectedProductsArray: { urun_id: number; adet: number; ogrenci_ismi: string | null; }[] = [];
        if (data?.okul?.tekstil_toplu_satis) {
            // Collect selected products from each state
            const collectSelectedProducts = (products: SecilenUrun[], index: number) => {
                return products.map((product) => ({
                    urun_id: product.urunId,
                    adet: product.adet,
                    ogrenci_ismi: product.urunId === etiktetUrunId ? product.etiket_bilgisi : null,
                }));
            };
            selectedProductsArray.push(...collectSelectedProducts(selectedProducts, 0));
            selectedProductsArray.push(...collectSelectedProducts(selected1Products, 1));
            selectedProductsArray.push(...collectSelectedProducts(selected2Products, 2));
            selectedProductsArray.push(...collectSelectedProducts(selected3Products, 3));
            selectedProductsArray.push(...collectSelectedProducts(selected4Products, 4));
        } else {
            selectedProductsArray = Object.values(selectedProducts).map((product) => ({
                urun_id: product.urunId,
                adet: product.adet,
                ogrenci_ismi: product.urunId === etiktetUrunId ? product.etiket_bilgisi : null,
            }))

        }
        // Seçili ürünleri al

        // Tüm grupsuz zorunlu ürünler seçili mi?
        const isAllZorunluUrunlerSelected =
            zorunluUrunler.filter((zorunluUrun: SinifUrunu) =>
                selectedProductsArray.some((selectedProduct) => selectedProduct.urun_id === zorunluUrun.urun_id),
            ).length === zorunluUrunler.length

        if (!isAllZorunluUrunlerSelected) {
            messageApi.warning('Listedeki zorunlu ürünlerin tamamı seçilmelidir.', 10)
            return
        }

        // Tüm gruplu zorunlu ürünler seçildi mi ?
        const areAllZorunluGruplarSelected = zorunluGruplar.every((zorunluGrup: number) =>
            selectedProducts.some((selectedProduct) => selectedProduct.zorunlulukGrubu === zorunluGrup),
        )

        if (!areAllZorunluGruplarSelected) {
            // Seçili olmayan zorunluluk grubunun ürünlerini bul
            const notSelectedGroups = zorunluGruplar.filter((grupId) => {
                const groupProducts: SinifUrunu[] = grupluUrunler.filter(
                    (product: SinifUrunu) => product.zorunluluk_grubu === grupId,
                )
                return !groupProducts.some((product: SinifUrunu) =>
                    selectedProductsArray.some((selectedProduct) => selectedProduct.urun_id === product.urun_id),
                )
            })

            // bu ürünlerden grupların adlarını unique bir şekilde al
            const uniqueGroupNames: Set<string> = new Set()
            notSelectedGroups.forEach((grupId) => {
                let groupProducts = grupluUrunler.filter((product: SinifUrunu) => product.zorunluluk_grubu === grupId)
                groupProducts.forEach((product: SinifUrunu) => {
                    let groupName = product.urun?.grup?.isim
                    if (groupName) {
                        uniqueGroupNames.add(groupName)
                    }
                })
            })

            // kullanıcıya hata mesajı ver
            const groupNamesArray = Array.from(uniqueGroupNames)
            const productsStr = groupNamesArray.join('-')
            messageApi.warning(`${productsStr} ürünlerinden en az birinin alınması zorunludur.`, 10)
            return
        }

        // Tüm tekstil ürünlerinin bedenleri seçildi mi
        const secilenTekstilUrunleri = selectedProducts.filter((product) => product.grupId !== null)
        const bedenSecilmeyenUrunler = secilenTekstilUrunleri.filter((product) => product.secilenBeden == null)
        if (bedenSecilmeyenUrunler.length > 0) {
            bedenSecilmeyenUrunler.forEach((element: SecilenUrun) => {
                messageApi.warning(`${element.isim} ürününde beden seçimi yapınız.`, 10)
            })
            return
        }

        // Etiket ürünü var ise etiket bilgileri girilmiş mi ?
        const etiketUrunuSecimi = selectedProducts.filter((product) => product.urunId === etiktetUrunId)
        if (etiketUrunuSecimi.length > 0) {
            if (!etiketUrunuSecimi[0].etiket_bilgisi) {
                messageApi.warning(
                    `${etiketUrunuSecimi[0].isim} ürününde etiket bilgileri eksik. Lütfen etiket bilgilerini giriniz.`,
                    6,
                )
                return
            }
        }

        // Sepete ekle mutation'ını çalıştır
        try {
            await sepetePaketEkleMutation({
                variables: {
                    okul_slug: slug!,
                    sinif_id: sinifId,
                    urunler: selectedProductsArray,
                },
            })
            // ürünler başarı ile eklendikten sonra capraz_urun_satis_popupi_varmi'a göre modali aç veya yönlendir.
            if (data?.okul?.capraz_urun_satis_popupi_varmi) {
                setModalVisible(true)
            } else {
                navigate(`/${AppRoutes.basket}?sinifId=${sinifId}`);
            }
        } catch (error) {
            showErrorModal(`${error}`);
        }
    }
    const modalContent = (
        <div className="redirect-container">
            <div className="redirect-modal">
                <div className="redirect-modal-content">
                    <div className="image-container" onClick={() => navigate(`/${AppRoutes.crossproduct}/2167`)}>
                        <div>
                            <img className="modalimages" src={modalCantaImg} alt=""/>
                            <div className="modal-text">Çantalar</div>
                        </div>
                    </div>
                    <div className="image-container" onClick={() => navigate(`/${AppRoutes.crossproduct}/2159`)}>
                        <div>
                            <img className="modalimages" src={modalKalemImg} alt=""/>
                            <div className="modal-text">Kalemler</div>
                        </div>
                    </div>
                    <div className="image-container" onClick={() => navigate(`/${AppRoutes.crossproduct}/2173`)}>
                        <div>
                            <img className="modalimages" src={modalMataraImg} alt=""/>
                            <div className="modal-text">Mataralar</div>
                        </div>
                    </div>
                    <div className="image-container" onClick={() => navigate(`/${AppRoutes.crossproduct}/2168`)}>
                        <div>
                            <img className="modalimages" src={modalKalemKutuImg} alt=""/>
                            <div className="modal-text">Kalem Çantaları</div>
                        </div>
                    </div>
                </div>
            </div>
            <Button className="redirect-btn" onClick={() => navigate(`/${AppRoutes.basket}`)}>
                Sepete Devam Et
            </Button>
        </div>
    )
    const getSelectedProducts = () => {
        switch (optionsValue) {
            case 1:
                return selected1Products;
            case 2:
                return selected2Products;
            case 3:
                return selected3Products;
            case 4:
                return selected4Products;
            default:
                return selectedProducts;
        }
    };
    const getSelectedSetProducts = () => {
        switch (optionsValue) {
            case 1:
                return setSelected1Products;
            case 2:
                return setSelected2Products;
            case 3:
                return setSelected3Products;
            case 4:
                return setSelected4Products;
            default:
                return setSelectedProducts;
        }
    };
    const renderProductTables = () => (
        <div className="product-tables">
            {grupluUrunlerDersKategorili &&
                grupluUrunlerDersKategorili.map((element: any, index: number) => (
                    <React.Fragment key={`${element[0].urun.grup.id}_grup_component`}>
                        {data?.okul?.tekstil_toplu_satis ? (
                            <GroupProductsTabTekstil
                                products={element}
                                selectedProducts={getSelectedProducts()}
                                setSelectedProducts={getSelectedSetProducts()}
                                indirimliFiyatGosterilsinmi={data?.okul?.indirimli_fiyat_gosterimi ?? false}
                                cokluSatis={true}
                            />
                        ) : (
                            <GroupProductsTab
                                products={element}
                                selectedProducts={getSelectedProducts()}
                                setSelectedProducts={getSelectedSetProducts()}
                                indirimliFiyatGosterilsinmi={data?.okul?.indirimli_fiyat_gosterimi ?? false}
                                cokluSatis={false}
                            />
                        )}
                    </React.Fragment>
                ))}
        </div>
    );

    const tabDatas1: TabData[] = [
        {
            label: (
                <div>
                    <img
                        alt=""
                        src={tabTekstilImg}
                        width={25}
                        height={25}
                        style={{paddingBottom: '1px', marginRight: '2px'}}
                    />
                    <span>OKUL KIYAFETİ</span>
                </div>
            ),
            className: 'okul-kiyafeti-tab',
            content:
                <div className="accordion-container">
                    <Collapse accordion onChange={handleAccordionChange}>
                        <Panel header="Anaokulu" key="1" style={{background: '#eccd9e'}}>
                            {renderProductTables()}
                        </Panel>
                        <Panel header="İlkokul" key="2" style={{background: '#d3ac66'}}>
                            {renderProductTables()}
                        </Panel>
                        <Panel header="Ortaokul" key="3" style={{background: '#e6d161'}}>
                            {renderProductTables()}
                        </Panel>
                        <Panel header="Lise" key="4" style={{background: '#FDD835'}}>
                            {renderProductTables()}
                        </Panel>
                    </Collapse>
                </div>
        },
        {
            label: (
                <div>
                    <img
                        alt=""
                        src={tabKirtasiyeImg}
                        width={25}
                        height={25}
                        style={{paddingBottom: '1px', marginRight: '2px'}}
                    />
                    <span>KİTAP-KIRTASİYE</span>
                </div>
            ),
            className: 'kitap-kirtasiye-tab',
            content: (
                <div className="product-tables">
                    <ClassProductsTable
                        products={grupsuzUrunler}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        lessonGruping={lessonGrouping}
                        indirimliFiyatGosterilsinmi={data?.okul?.indirimli_fiyat_gosterimi ?? false}
                    />
                </div>
            ),
        },
    ];
    
    const tabDatas2: TabData[] = [
        {
            label: (
                <div>
                    <img
                        alt=""
                        src={tabTekstilImg}
                        width={25}
                        height={25}
                        style={{paddingBottom: '1px', marginRight: '2px'}}
                    />
                    <span>OKUL KIYAFETİ</span>
                </div>
            ),
            className: 'okul-kiyafeti-tab',
            content:
                <div className="product-tables">{renderProductTables()}</div>
        },
        {
            label: (
                <div>
                    <img
                        alt=""
                        src={tabKirtasiyeImg}
                        width={25}
                        height={25}
                        style={{paddingBottom: '1px', marginRight: '2px'}}
                    />
                    <span>KİTAP-KIRTASİYE</span>
                </div>
            ),
            className: 'kitap-kirtasiye-tab',
            content: (
                <div className="product-tables">
                    <ClassProductsTable
                        products={grupsuzUrunler}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        lessonGruping={lessonGrouping}
                        indirimliFiyatGosterilsinmi={data?.okul?.indirimli_fiyat_gosterimi ?? false}
                    />
                </div>
            ),
        },
    ];


    const handleTabChange = (index: number) => {
        setActiveTab(index)
    }
    if (data?.okul?.tab_gosterim === false) {
        return (
            <CenteredLayout maxWidth={1300}>
                {contextHolderMessage}
                {!isPasswordChecked ? <>
                    {temp && (
                        <Modal title="Bilgilendirme" open={isModalVisible} className="uyari-modal"
                               onCancel={handleCancel}>
                            <p className="okul-yazi"
                               dangerouslySetInnerHTML={{__html: data?.okul?.sinif?.uyari || ''}}/>
                        </Modal>
                    )}
                    <Modal
                        title="Uyarı!"
                        visible={isAModalVisible}
                        onOk={handleModalOk}
                        cancelText={'Sepetime Dokunma'}
                        okText={'Sepeti Güncelle'}
                        onCancel={handleModalCancel}
                    >
                        <p>{errorMessage}</p>
                    </Modal>
                    <div className="sinif-baslik-container">
                        <div>
                            <h1>{data?.okul?.sinif?.isim} İhtiyaç Listesi</h1>
                        </div>
                        <div className="sinif-siralama-secim">
                            <Select
                                className="order-select"
                                defaultValue={true}
                                onChange={handleOrderSelectChange}
                                options={[
                                    {value: true, label: 'Derse Göre Sırala'},
                                    {value: false, label: 'Kitap Kırtasiyeye Göre Sırala'},
                                ]}
                            />
                        </div>
                    </div>
                    {temp && (
                        <div style={{
                            border: '1px solid #ddd',
                            backgroundColor: '#dff2ff',
                            padding: '10px',
                            position: 'relative'
                        }}>
                            <Button type="text" onClick={closeDiv}
                                    style={{position: 'absolute', top: '5px', right: '5px'}}>
                                X
                            </Button>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="" src={infoImg} width={25} height={25}
                                     style={{paddingBottom: '1px', marginRight: '30px'}}/>
                                <p
                                    style={{color: '#3c5d90', margin: '0'}}
                                    dangerouslySetInnerHTML={{__html: data?.okul?.sinif?.uyari || ''}}
                                />
                            </div>
                        </div>
                    )}
                    <div className="product-tables">
                        {grupluUrunlerDersKategorili &&
                            grupluUrunlerDersKategorili.map((element: any, index: number) => (
                                <GroupProductsTable // sınıftaki tekstil ürünleri
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                    products={element}
                                    indirimliFiyatGosterilsinmi={data?.okul?.indirimli_fiyat_gosterimi ?? false}
                                    key={`${element[0].urun.grup.id}_grup_component`}
                                />
                            ))}

                        <ClassProductsTable // sınıf ürünleri
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            products={grupsuzUrunler}
                            lessonGruping={lessonGrouping}
                            indirimliFiyatGosterilsinmi={data?.okul?.indirimli_fiyat_gosterimi ?? false}
                        />
                    </div>

                    <div style={{display: 'flex', marginTop: '15px', marginBottom: '20px', justifyContent: 'end'}}>
                        <div style={{maxWidth: '250px', width: '200px'}}>
                            <Button
                                type="primary"
                                className="button-new-color"
                                style={{width: '100%'}}
                                onClick={handleSepeteEkleButton}
                            >
                                <ShoppingCartOutlined/>
                                Sepete Ekle
                            </Button>

                        </div>
                    </div>
                    {data?.okul?.capraz_urun_satis_popupi_varmi && (
                        <Modal
                            open={modalVisible}
                            title="Size özel fiyatlı ürünlere göz atmak ister misiniz ?"
                            onOk={() => setModalVisible(false)}
                            footer={null} // Cancel butonunu kaldırmak için
                            closable={false} // X butonunu gizlemek için
                            width={'760px'}
                        >
                            {modalContent}
                        </Modal>
                    )}
                </> : null}
                <Modal
                    centered
                    title="Sınıf Şifresi"
                    open={showPasswordModal}
                    onOk={handlePasswordSubmit}
                    onCancel={() => setShowPasswordModal(false)}
                    okText="Gönder"
                    cancelText="İptal"
                    destroyOnClose
                >
                    <Input.Password
                        placeholder="Sınıf şifresi giriniz"
                        value={classPassword}
                        onChange={(e) => setClassPassword(e.target.value)}
                    />
                </Modal>
            </CenteredLayout>
        )
    } else {
        return (
            <CenteredLayout maxWidth={1300}>
                {contextHolderMessage}
                {!isPasswordChecked ? <>
                    {temp && (
                        <Modal title="Bilgilendirme" open={isModalVisible} className="uyari-modal"
                               onCancel={handleCancel}>
                            <p className="okul-yazi"
                               dangerouslySetInnerHTML={{__html: data?.okul?.sinif?.uyari || ''}}/>
                        </Modal>
                    )}
                    <Modal
                        title="Uyarı!"
                        visible={isAModalVisible}
                        onOk={handleModalOk}
                        cancelText={'Sepetime Dokunma'}
                        okText={'Sepeti Güncelle'}
                        onCancel={handleModalCancel}
                    >
                        <p>{errorMessage}</p>
                    </Modal>
                    <div className="tab-container">
                        <div
                            id="tab-bar"
                            className="tab-bar"
                            style={{
                                borderBottom:
                                    activeTab === 1
                                        ? `5px solid var(--kitap-kirtasiye-tab-color)`
                                        : '5px solid var(--okul-kiyafeti-tab-color)',
                            }}
                        >
                            {data?.okul?.tekstil_toplu_satis ?
                                tabDatas1.map((tab, index) => (
                                    <div key={`tab_${index}`} className={`tab ${tab.className}`}
                                         onClick={() => handleTabChange(index)}>
                                        {tab.label}
                                    </div>
                                )) :
                                tabDatas2.map((tab, index) => (
                                    <div key={`tab_${index}`} className={`tab ${tab.className}`}
                                         onClick={() => handleTabChange(index)}>
                                        {tab.label}
                                    </div>
                                ))}
                        </div>
                        {temp && (
                            <div
                                style={{
                                    border: '1px solid #ddd',
                                    backgroundColor: '#dff2ff',
                                    padding: '10px',
                                    position: 'relative'
                                }}
                            >
                                <Button type="text" onClick={closeDiv}
                                        style={{position: 'absolute', top: '5px', right: '5px'}}>
                                    X
                                </Button>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img
                                        alt=""
                                        src={infoImg}
                                        width={25}
                                        height={25}
                                        style={{paddingBottom: '4px', marginRight: '30px'}}
                                    />
                                    <p
                                        style={{color: '#3c5d90', margin: '0'}}
                                        dangerouslySetInnerHTML={{__html: data?.okul?.sinif?.uyari || ''}}
                                    />
                                </div>
                            </div>
                        )}
                        {data?.okul?.tekstil_toplu_satis ?
                            optionsValue <= 0 && activeTab === 0 &&
                            <div style={{paddingTop: 5}}>
                                <Alert type={"error"} closable={false}
                                       message={'Sınıf Listelerine ulaşmak için öğrencinin kayıtlı olduğu sınıf kademesi seçilmelidir.'}></Alert>
                            </div>

                            : null
                        }
                        <div className="sinif-baslik-container">
                            <div>
                                <h2>
                                    <img src={data?.okul?.logo} alt="Logo bulunamadı." style={{maxWidth: '50px'}}/>{' '}
                                    {data?.okul?.sinif?.isim} İhtiyaç Listesi
                                </h2>
                            </div>
                            {activeTab === 1 && (
                                <div className="sinif-siralama-secim">
                                    <Select
                                        className="order-select"
                                        defaultValue={true}
                                        onChange={handleOrderSelectChange}
                                        options={[
                                            {value: true, label: 'Derse Göre Sırala'},
                                            {value: false, label: 'Kitap Kırtasiyeye Göre Sırala'},
                                        ]}
                                    />
                                </div>
                            )}
                            {/*{activeTab === 0 && data?.okul?.tekstil_toplu_satis && (*/}
                            {/*    <div className="sinif-siralama-secim">*/}

                            {/*        <Select*/}
                            {/*            className="order-select"*/}
                            {/*            onChange={handleSeviyeSelectChange}*/}
                            {/*            defaultValue={optionsValue}*/}
                            {/*            options={[*/}
                            {/*                {value: -1, label: 'Sınıf Kademesi Seçiniz'},*/}
                            {/*                {value: 1, label: 'Ana Okulu'},*/}
                            {/*                {value: 2, label: 'İlk Okulu'},*/}
                            {/*                {value: 3, label: 'Orta Okulu'},*/}
                            {/*                {value: 4, label: 'Lise'},*/}
                            {/*            ]}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                        {data?.okul?.tekstil_toplu_satis === true ?
                            <div className="tab-content">{tabDatas1[activeTab].content}</div>
                            :
                            <div className="tab-content">{tabDatas2[activeTab].content}</div>
                        }
                    </div>

                    <div style={{display: 'flex', marginTop: '15px', marginBottom: '20px', justifyContent: 'end'}}>
                        <div style={{maxWidth: '250px', width: '200px'}}>
                            {activeTab === 1 ? (
                                <Button
                                    type="primary"
                                    style={{width: '100%'}}
                                    className="button-new-color"
                                    onClick={handleSepeteEkleButton}
                                >
                                    <ShoppingCartOutlined/>
                                    Sepete Ekle
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    style={{width: '100%'}}
                                    className="button-new-color"
                                    onClick={handleSepeteEkleButton}
                                >
                                    <ShoppingCartOutlined/>
                                    Sepete Ekle
                                </Button>
                                // <Button
                                //     type="primary"
                                //     style={{width: '100%', backgroundColor: '#44B365'}}
                                //     onClick={() => {
                                //         handleTabChange(1)
                                //         var element = document.getElementById('tab-bar')
                                //         element!.scrollIntoView({behavior: 'smooth'})
                                //     }}
                                // >
                                //     Kitap Kırtasiyeye İlerle {'>'}
                                // </Button>
                            )}
                        </div>
                    </div>

                    {data?.okul?.capraz_urun_satis_popupi_varmi && (
                        <Modal
                            open={modalVisible}
                            title="Size özel fiyatlı ürünlere göz atmak ister misiniz ?"
                            onOk={() => setModalVisible(false)}
                            footer={null} // Cancel butonunu kaldırmak için
                            closable={false} // X butonunu gizlemek için
                            width={'760px'}
                        >
                            {modalContent}
                        </Modal>
                    )}
                </> : null}
                <Modal
                    centered
                    title="Sınıf Şifresi"
                    open={showPasswordModal}
                    onOk={handlePasswordSubmit}
                    onCancel={() => setShowPasswordModal(false)}
                    okText="Gönder"
                    cancelText="İptal"
                    destroyOnClose
                >
                    <Input.Password
                        placeholder="Sınıf şifresi giriniz"
                        value={classPassword}
                        onChange={(e) => setClassPassword(e.target.value)}
                    />
                </Modal>
            </CenteredLayout>
        )
    }
}

export default ClassProductsPage

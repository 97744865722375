import './GroupProductsTab.scss'

import { ShoppingCartOutlined } from '@ant-design/icons'
import { Button, InputNumber, notification } from 'antd'
import { SinifUrunu } from 'gql/GeneratedGraphqlTypes'
import { SecilenUrun } from 'pages/class-products/ClassProductsPage'
import React, { useState } from 'react'

import GroupProductsModalTekstil from "../class-group-product-modal/GroupProductsModalTekstil";

interface GroupProductsTabProps {
  products: any
  selectedProducts: any
  setSelectedProducts: any
  indirimliFiyatGosterilsinmi: Boolean
  cokluSatis: Boolean
}

const GroupProductsTabTekstil = ({ products, cokluSatis, selectedProducts, setSelectedProducts }: GroupProductsTabProps) => {
  let gruplarArr: SinifUrunu[] = []
  products.forEach((element: SinifUrunu) => {
    const someResult = gruplarArr.some((item: SinifUrunu) => item.urun?.grup?.id === element?.urun?.grup?.id)
    if (!someResult) {
      gruplarArr.push(element)
    }
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedGroupIdFromRow, setSelectedGroupIdFromRow] = useState(0)

  const showModal = (groupId: number) => {
    setModalVisible(true)
    setSelectedGroupIdFromRow(groupId)
  }

  const handleQuantityChange = (product: SecilenUrun, value: number) => {
    if (value < 1) {
      return
    }
    const prevRows = [...selectedProducts]
    const updatedProductIndex = prevRows.findIndex(
        (item) => item.grupId === product.grupId && item.dersId === product.dersId && item.urunId === product.urunId && item.grupId !== null,
    )
    if (updatedProductIndex !== -1) {
      prevRows[updatedProductIndex].adet = value
      setSelectedProducts(prevRows)
    } else {
      setSelectedProducts([
        ...selectedProducts,
        {
          isim: product.isim ?? '',
          urunId: product.urunId,
          dersId: product.dersId,
          adet: value,
          fiyat: product.fiyat,
          indirimsiz_birim_fiyat: product.indirimsiz_birim_fiyat,
          indirimli_birim_fiyat: product.indirimli_birim_fiyat,
          minAdet: product.minAdet ?? 0,
          resimler: 'resimler' in product ? product.resimler ?? [''] : [''], // Güvenlik kontrolü
          grupId: product.grupId,
          grup: {
            id: product.grup.id ?? 0, // Adjust the property names as per your actual types
            isim: product.grup?.isim ?? '',
            resimler: product.grup?.resimler ?? [],
            sira: product.grup?.sira ?? 0, // Assuming 'sira' is part of your UrunGrubu type
          },
          zorunlulukGrubu: product.zorunlulukGrubu ?? 0,
          etiket_bilgisi: null,
          secilenBeden: null,
          kitapKaplama: product.kitapKaplama ?? 0,
        } as SecilenUrun,
      ])
    }
    notification.open({
      message: 'Başarılı',
      description: 'Başarı ile güncellendi',
      type: 'success',
      duration: 1.5,
    })
  }

  const sepettenCikar = (product: SecilenUrun) => {
    const newArr = selectedProducts.filter(
        (seciliUrun: SecilenUrun) =>
            !(seciliUrun.dersId === product.dersId && seciliUrun.grupId === product.grupId && seciliUrun.urunId === product.urunId),
    )
    setSelectedProducts(newArr)
  }
  const filteredSelectedProducts = selectedProducts.filter(
      (selectedItem:any) =>
          !gruplarArr.some(
              (gruplarItem) => selectedItem.dersId !== gruplarItem.ders?.id
          )
  );
  const combinedProducts = [
    ...filteredSelectedProducts,
    ...gruplarArr.filter((gruplarItem: SinifUrunu) =>
        !selectedProducts.some(
            (selectedItem: SecilenUrun) =>
                selectedItem.grupId === gruplarItem.urun?.grup?.id &&
                selectedItem.dersId === gruplarItem.ders?.id
        )
    ),
  ];
// Sort combined products by 'sira' property of 'grup' in ascending order
  combinedProducts.sort((a, b) => {
    const siraA = a.grup?.sira ? a.grup.sira : a.urun.grup.sira; 
    const siraB = b.grup?.sira ? b.grup.sira : b.urun.grup.sira;
    return siraA - siraB;
  });


  return (
      <>
        {products[0].ders.id === 127 ?
            <p className="outlet-style">{products[0].ders.isim}</p>
            : <p className="outlet-style1">{products[0].ders.isim}</p>
        }
        <div className="group-product-tab-container" key={`${products[0].urun.grup.id}_grup_table`}>
          <div className="card-base">
            <div className="card-container">
              {combinedProducts.map((item: SinifUrunu | SecilenUrun, index: number) => {
                if ('urun' in item) {
                  // It's a group product
                  const itemSecili = selectedProducts.some(
                      (prod: SecilenUrun) =>
                          prod.grupId === item.urun?.grup?.id && prod.dersId === item.ders?.id && prod.grupId !== null,
                  );
                  const itemSeciliCss = itemSecili ? {backgroundColor: '#44B365', color: 'white'} : {};
                  const seciliBeden =
                      selectedProducts.find(
                          (prod: SecilenUrun) =>
                              prod?.grupId === item.urun?.grup?.id && prod.dersId === item?.ders?.id,
                      )?.secilenBeden ?? 'Beden Seçiniz';

                  return (
                      <div className="card-item" key={`tekstil_urun_${item.urun?.grup?.id}_${index}`}>
                        <img
                            className="card-image"
                            alt="example"
                            src={
                              Array.isArray(item.urun?.grup?.resimler) && item.urun?.grup?.resimler.length ? item.urun?.grup?.resimler.length > 0
                                      ? item.urun.grup.resimler[0]
                                      : ''
                                  : ''
                            }
                        />
                        {/*<img className="card-image" alt="example" src={item.urun?.grup?.resimler![0] ?? ''}/>*/}
                        <h3 className="card-prod-name">{(item as SinifUrunu).urun?.grup?.isim}</h3>
                        <p style={{color: 'red'}}>{item.urun?.kisa_aciklama}</p>
                        <div className="card-description">
                          <div className="card-amount">
                            <span className="card-indirimli-fiyat">{item.indirimli_birim_fiyat} TL</span>
                            {item.indirimsiz_birim_fiyat! <= item.indirimli_birim_fiyat! ? null : (
                                <span className="card-indirimsiz-fiyat">{item.indirimsiz_birim_fiyat} TL</span>
                            )}
                          </div>
                          <div className="card-adet-beden-btn">
                            <Button
                                style={{fontSize: '0.89em'}}
                                onClick={() => showModal(item.urun?.grup?.id ?? 0)}
                            >
                              {seciliBeden}
                            </Button>
                            {itemSecili && (
                                <InputNumber
                                    style={{fontSize: '0.89em', marginTop: '2%'}}
                                    min={1}
                                    defaultValue={1}
                                    onChange={(value) => handleQuantityChange(item as unknown as SecilenUrun, value!)}
                                />
                            )}
                          </div>
                        </div>
                        <div className="card-btn-container">
                          <Button
                              style={{borderRadius: '0px', ...itemSeciliCss}}
                              className="card-sepete-ekle-btn"
                              onClick={() => (itemSecili ? sepettenCikar(item as unknown as SecilenUrun) : showModal(item.urun?.grup?.id ?? 0))}
                          >
                            <ShoppingCartOutlined/>
                            <span className="ml-2">{itemSecili ? 'Sepete Eklendi' : 'Sepete Ekle'}</span>
                          </Button>
                        </div>
                      </div>
                  );
                } else {
                  // It's a selected product
                  const selectedProduct = item as SecilenUrun;
                  return (
                      <div key={`selected_item_${index}`} className="card-item">
                        <img className="card-image" alt="example"
                             src={'resimler' in selectedProduct ? selectedProduct.resimler[0] ?? '' : ''}/>
                        <h3 className="card-prod-name">{selectedProduct.isim}</h3>
                        <p style={{color: 'red'}}>{selectedProduct.kisa_aciklama}</p>
                        <div className="card-description">
                          <div className="card-amount">
                            <span className="card-indirimli-fiyat">{selectedProduct.indirimli_birim_fiyat} TL</span>
                            {parseInt(selectedProduct.indirimsiz_birim_fiyat!) <= parseInt(selectedProduct.indirimli_birim_fiyat!) ? null : (
                                <span
                                    className="card-indirimsiz-fiyat">{selectedProduct.indirimsiz_birim_fiyat} TL</span>
                            )}
                          </div>
                          <div className="card-adet-beden-btn">
                            <Button
                                style={{fontSize: '0.89em'}}
                                onClick={() => showModal(selectedProduct.grupId ?? 0)}
                            >
                              {selectedProduct.secilenBeden}
                            </Button>
                            <InputNumber
                                style={{fontSize: '0.89em', marginTop: '2%'}}
                                min={1}
                                defaultValue={selectedProduct.adet!}
                                onChange={(value) => handleQuantityChange(selectedProduct, value!)}
                            />
                          </div>
                        </div>
                        <div className="card-btn-container">
                          <Button
                              style={{borderRadius: '0px', backgroundColor: '#44B365', color: 'white'}}
                              className="card-sepete-ekle-btn"
                              onClick={() => sepettenCikar(selectedProduct)}
                          >
                            <ShoppingCartOutlined/>
                            <span className="ml-2">Sepetten Çıkar</span>
                          </Button>
                        </div>
                      </div>
                  );
                }
              })}
            </div>
          </div>

          {selectedGroupIdFromRow !== 0 && (
              <GroupProductsModalTekstil
                  modelVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  cokluSatis={cokluSatis}
                  products={products.filter((item: any) => item.urun.grup.id === selectedGroupIdFromRow)}
              />
          )}
        </div>
      </>
  )
}

export default GroupProductsTabTekstil

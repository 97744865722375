import { Drawer, Space } from 'antd'
import React from 'react'

interface CommonDrawerProps {
  title: string
  items: React.ReactNode[]
  isVisible: boolean
  onClose: () => void
}

const CommonDrawer: React.FC<CommonDrawerProps> = ({ title, items, isVisible, onClose }) => {
  return (
    <Drawer title={title} placement="left" closable={true} onClose={onClose} open={isVisible}>
      <Space direction="vertical">{items}</Space>
    </Drawer>
  )
}

export default CommonDrawer

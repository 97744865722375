import './RegisterForm.scss'

import { Button, Checkbox, Form, Input, Modal, notification } from 'antd'
import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AppContext from '../../context/AppContext'
import { useYeniUyeMutation } from '../../gql/GeneratedGraphqlTypes'
import { executeMutation, saveLoginSession } from '../../utils/helpers'
import { AuthState } from '../../utils/types'
import { DEFAULT_MAX_WIDTH } from '../centered-layout/CenteredLayout'
import Spinner from '../spinner/Spinner'

const RegisterForm = () => {
  const [yeniUyeMutation, { loading }] = useYeniUyeMutation()
  const { setAuthState, setToken } = useContext(AppContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalConfig, setModalConfig] = useState(false)
  const [elektronikConfig, setElektronikConfig] = useState(false)
  const [bilgiConfig, setBilgiConfig] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const onFinish = async (values: any) => {
    if (!values.uyelik_sozlesmesi || !values.elektronik_sozlesmesi || !values.aydinlatma_metni) {
      notification.open({
        message: 'Hata',
        description: 'Üyelik sözleşmelerini onaylamadan formu gönderemezsiniz.',
        type: 'error',
        placement: 'topRight',
      })
      return
    }
    if (values.password !== values.password_confirm) {
      notification.open({
        message: 'Hata',
        description: "Lütfen 'Şifre' ve 'Şifre Onayı' alanlarının aynı olduğundan emin olun.",
        type: 'error',
        placement: 'topRight',
      })
      return
    }
    const result = await executeMutation(yeniUyeMutation, {
      variables: {
        isim: values.isim,
        soyisim: values.soyisim,
        email: values.email,
        sifre: values.password,
        cep_telefonu: '',
      },
    })
    if (result && result && result.user_id) {
      if (values.remember) {
        saveLoginSession(result)
      }
      setToken(result.token)
      setAuthState(AuthState.LOGGED_IN)
      navigate(location?.state?.return_url || '/')
    } else {
      notification.open({
        message: 'Hata!',
        description: 'Hatalı Kayıt Oldu',
        type: 'error',
        placement: 'topRight',
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className="register-form">
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Spinner showSpinner={loading} color="red" />

        <Form.Item label="İsim" name="isim" rules={[{ required: true, message: 'Bu alan zorunlu!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Soyisim" name="soyisim" rules={[{ required: true, message: 'Bu alan zorunlu!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Bu alan zorunlu!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Şifre" name="password" rules={[{ required: true, message: 'Bu alan zorunlu!' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Şifre onayı"
          name="password_confirm"
          rules={[{ required: true, message: 'Bu alan zorunlu!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="elektronik_sozlesmesi" valuePropName="checked">
          <Checkbox>
            <p>
              <span>Tarafıma şirketiniz tarafından </span>
              <a
                onClick={() => {
                  setElektronikConfig(true)
                  setModalOpen(true)
                }}
              >
                Ticari Elektronik Aydınlatma Metni
              </a>
              <span> kapsamında ticari elektronik ileti gönderilmesi için izin veriyorum.</span>
            </p>
          </Checkbox>
        </Form.Item>

        <Form.Item name="uyelik_sozlesmesi" valuePropName="checked">
          <Checkbox>
            <p>
              <a
                onClick={() => {
                  setModalConfig(true)
                  setModalOpen(true)
                }}
              >
                Üyelik Sözleşmesini
              </a>
              <span> Okudum, Onaylıyorum.</span>
            </p>
          </Checkbox>
        </Form.Item>

        <Form.Item name="aydinlatma_metni" valuePropName="checked">
          <Checkbox>
            <p>
              <a
                onClick={() => {
                  setBilgiConfig(true)
                  setModalOpen(true)
                }}
              >
                Aydınlatma Metnini
              </a>
              <span> Okudum, Onaylıyorum.</span>
            </p>
          </Checkbox>
        </Form.Item>

        {/*<Form.Item name="aydinlatma_metni_bilgi" valuePropName="checked">*/}
        {/*  <Checkbox>Aydınlatma Metni kapsamında kişisel verilerimin yurtdışına aktarılmasına izin veriyorum</Checkbox>*/}
        {/*</Form.Item>*/}

        <Form.Item>
          <Button type="primary" htmlType="submit" className="uye-ol" block>
            Üye Ol
          </Button>
        </Form.Item>
      </Form>
      {modalConfig && (
        <Modal
          width={DEFAULT_MAX_WIDTH}
          onCancel={() => {
            setModalOpen(false)
          }}
          footer={null}
          open={modalOpen}
          title={<div className="baslik-ayar">Üyelik Sözleşmesi</div>}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ margin: '0 auto', padding: '20px' }}>
                <div className="main m-top20 m-bottom20 sign-box login-content">
                  <div className="row no-margin">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <p style={{ alignItems: 'center' }}>
                        <br />
                        <strong>&Uuml;YELİK S&Ouml;ZLEŞMESİ</strong>
                      </p>

                      <p>
                        <strong>1. S&Ouml;ZLEŞMENİN TARAFLARI</strong>
                      </p>

                      <p>
                        İşbu &Uuml;yelik S&ouml;zleşmesi (&ldquo;<strong>S&ouml;zleşme</strong>&rdquo;);{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesinin (&ldquo;
                        <strong>Site</strong>&rdquo;) sahibi olan ve Bostancı Mahallesi Tarikihas Sokak
                        Tavuk&ccedil;uoğlu İş Merkezi No:2/6 Kadık&ouml;y, İstanbul adresinde bulunan&nbsp;Okul Sepeti
                        Pazarlama Anonim Şirketi (bundan b&ouml;yle &ldquo;<strong>Şirket</strong>&rdquo; olarak
                        anılacaktır)&nbsp;ile; aşağıdaki şartları kabul ederek &uuml;ye olan internet sitesi kullanıcısı
                        (&quot;<strong>&Uuml;ye</strong>&quot;) arasında, &Uuml;ye&rsquo;nin Şirket&rsquo;in sunduğu
                        &uuml;r&uuml;n ve hizmetlerden yararlanmasına ilişkin koşulların belirlenmesi i&ccedil;in
                        akdedilmiştir. İşbu s&ouml;zleşme uyarınca Şirket ve &Uuml;ye birlikte &ldquo;
                        <strong>Taraflar</strong>&rdquo; ve ayrı ayrı &ldquo;<strong>Taraf</strong>&rdquo; olarak
                        anılacaktır.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>2. S&Ouml;ZLEŞMENİN AMACI VE KAPSAMI</strong>
                      </p>

                      <p>
                        İşbu &Uuml;yelik S&ouml;zleşmesi&rsquo;nin konusu,{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> adlı Site&rsquo;den
                        &Uuml;ye&rsquo;nin faydalanma şartlarının belirlenmesi ile birlikte, sunulan &uuml;r&uuml;n ve
                        hizmetlerin ve bu &uuml;r&uuml;nlerin satın alınması şartları ile aşağıda belirtilen Tarafların
                        hak ve y&uuml;k&uuml;ml&uuml;l&uuml;klerinin belirlenmesidir. S&ouml;zleşme&#39;nin &Uuml;ye
                        tarafından kabul&uuml; ile &Uuml;ye, Site&rsquo;de yer alan ve yer alacak olan Şirket tarafından
                        sunulan &uuml;r&uuml;nlere, i&ccedil;eriklere ve uygulamalara ilişkin hak ve
                        y&uuml;k&uuml;ml&uuml;l&uuml;kleri eksiksiz, doğru, zamanında ve işbu S&ouml;zleşme&rsquo;de
                        d&uuml;zenlenen şartlar dahilinde yerine getireceğini kabul, beyan ve taahh&uuml;t eder.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>3.TARAFLAR&#39;IN HAK VE Y&Uuml;K&Uuml;ML&Uuml;L&Uuml;KLERİ</strong>
                      </p>

                      <p>
                        <strong>3.1- </strong>&Uuml;ye,{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesini kullanım
                        konusunda bilgilendirme amacı taşıyan aşağıda yazılı koşulları okuduğunu ve bu koşullara peşinen
                        uyacağını kabul, beyan ve taahh&uuml;t etmektedir.&nbsp;
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        &Uuml;yelik stat&uuml;s&uuml;n&uuml;n kazanılması i&ccedil;in, &Uuml;ye olmak isteyen
                        kullanıcının, internet sitesinde bulunan &Uuml;yelik S&ouml;zleşmesi&#39;ni onaylayarak, burada
                        talep edilen bilgileri doğru ve g&uuml;ncel bilgilerle doldurması gerekmektedir. &Uuml;ye olmak
                        isteyen kullanıcının 18 (on sekiz) yaşını doldurmuş olması şarttır. &Uuml;ye, işbu
                        S&ouml;zleşme&rsquo;yi onaylayarak, 18 yaşını doldurduğunu beyan etmektedir. &Uuml;ye,
                        &uuml;yelik stat&uuml;s&uuml;n&uuml; Hesabım Sayfasından ulaşabileceği &quot;&Uuml;yelik
                        İptali&quot; butonuna basarak her zaman sonlandırabilme hakkına sahiptir.
                      </p>

                      <p>
                        <strong>3.2-</strong> &Uuml;ye,{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesine &uuml;ye
                        olurken verdiği kişisel ve diğer sair bilgilerin kanunlar &ouml;n&uuml;nde doğru olduğunu,
                        Şirket&rsquo;in bu bilgilerin ger&ccedil;eğe aykırılığı nedeniyle uğrayacağı t&uuml;m zararları
                        aynen ve derhal tazmin edeceğini kabul, beyan ve taahh&uuml;t eder.
                      </p>

                      <p>
                        <strong>3.3-</strong> &Uuml;ye&rsquo;nin Hesabım Sayfasına erişmek ve internet sitesi
                        &uuml;zerinden işlem ger&ccedil;ekleştirebilmek i&ccedil;in ihtiya&ccedil; duyduğu kullanıcı adı
                        ve şifre bilgisi, &Uuml;ye tarafından oluşturulmakta olup, s&ouml;z konusu bilgilerin
                        g&uuml;venliği ve gizliliği tamamen &Uuml;ye&rsquo;nin sorumluluğundadır. &Uuml;ye, kendisine
                        ait kullanıcı adı ve şifre ile ger&ccedil;ekleştirilen işlemlerin kendisi tarafından
                        ger&ccedil;ekleştirilmiş olduğunu, bu işlemlerden kaynaklanan sorumluluğunun peşinen kendisine
                        ait olduğunu, bu şekilde ger&ccedil;ekleştirilen iş ve işlemleri kendisinin
                        ger&ccedil;ekleştirmediği yolunda herhangi bir def&#39;i ve/veya itiraz ileri
                        s&uuml;remeyeceğini ve/veya bu def&#39;i veya itiraza dayanarak
                        y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getirmekten ka&ccedil;ınmayacağını kabul, beyan ve
                        taahh&uuml;t eder.
                      </p>

                      <p>
                        <strong>3.4-</strong>&nbsp;&Uuml;ye, internet sitesi &uuml;zerinden g&ouml;r&uuml;nt&uuml;lediği
                        ilanlarla ilgili herhangi bir hukuki işlem veya satın alma s&uuml;reci başlatmak istemesi
                        halinde, internet sitesinde yer alanlar da dahil olmak &uuml;zere t&uuml;m gerekli yasal
                        y&uuml;k&uuml;ml&uuml;l&uuml;kleri ve yasal prosed&uuml;rleri yerine getirmek zorunda olduğunu,
                        s&ouml;z konusu y&uuml;k&uuml;ml&uuml;l&uuml;kler ve prosed&uuml;rlerle ilgili olarak
                        Şirket&rsquo;in herhangi bir sorumluluğunun bulunmadığını kabul, beyan ve taahh&uuml;t eder.
                      </p>

                      <p>
                        <strong>3.5-</strong> &Uuml;ye,{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesi
                        i&ccedil;indeki faaliyetlerinde, sitenin herhangi bir b&ouml;l&uuml;m&uuml;nde veya
                        iletişimlerinde genel ahlaka ve adaba aykırı, kamu d&uuml;zenini bozucu, kanunlara aykırı,
                        &uuml;&ccedil;&uuml;nc&uuml; kişilerin haklarını zedeleyen, yanıltıcı, başkalarını rahatsız ve
                        taciz edici şekilde, saldırgan, m&uuml;stehcen, pornografik, kişilik haklarını zedeleyen, telif
                        haklarına aykırı, yasa dışı faaliyetleri teşvik edecek şekilde kullanım ger&ccedil;ekleştiremez.
                        Aksi halde oluşabilecek t&uuml;m zararlardan &Uuml;ye sorumlu olduğunu kabul, beyan ve
                        taahh&uuml;t eder.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        B&ouml;yle bir durumda, Şirket yetkilileri, bu t&uuml;r hesapları askıya alma, sona erdirme,
                        yasal s&uuml;re&ccedil; başlatma hakkını saklı tutmaktadır. Bu sebeple yargı mercilerinden
                        &Uuml;ye hesapları ile ilgili bilgi talepleri gelmesi halinde Şirket paylaşım hakkını saklı
                        tutmaktadır. <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesi
                        &uuml;yelerinin birbirleri veya &uuml;&ccedil;&uuml;nc&uuml; şahıslarla olan ilişkileri kendi
                        sorumluluğundadır. Ayrıca, &Uuml;ye başkalarının hizmetleri kullanmasını &ouml;nleyici veya
                        zorlaştırıcı faaliyet (spam, vir&uuml;s, truva atı, vb.) ve işlemlerde bulunamaz.
                      </p>

                      <p>
                        <strong>3.6-</strong> <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet
                        sitesinde &uuml;yeler tarafından beyan edilen, yazılan, kullanılan fikir ve
                        d&uuml;ş&uuml;nceler, tamamen &uuml;yelerin kendi kişisel g&ouml;r&uuml;şleridir ve bunlarla
                        ilgili t&uuml;m sorumluluk ilgili &Uuml;ye&rsquo;ye aittir. Bu g&ouml;r&uuml;ş ve
                        d&uuml;ş&uuml;ncelerin Şirket&rsquo;le hi&ccedil;bir ilgi ve bağlantısı yoktur. Şirket&rsquo;in
                        &Uuml;ye&rsquo;nin beyan edeceği fikir ve g&ouml;r&uuml;şler nedeniyle
                        &uuml;&ccedil;&uuml;nc&uuml; kişilerin uğrayabileceği zararlardan ve
                        &uuml;&ccedil;&uuml;nc&uuml; kişilerin beyan edeceği fikir ve g&ouml;r&uuml;şler nedeniyle
                        &Uuml;ye&rsquo;nin uğrayabileceği zararlardan dolayı herhangi bir sorumluluğu bulunmamaktadır.
                      </p>

                      <p>
                        <strong>3.7-</strong> &Uuml;ye, diğer internet kullanıcılarının yazılımlarına ve verilerine
                        izinsiz olarak ulaşmamayı veya bunları kullanmamayı kabul etmiştir. Aksi takdirde, bundan
                        doğacak hukuki ve cezai sorumluluklar tamamen &Uuml;ye&rsquo;ye aittir.
                      </p>

                      <p>
                        <strong>3.8-</strong> İşbu &Uuml;yelik S&ouml;zleşmesi i&ccedil;erisinde sayılan maddelerden bir
                        ya da birka&ccedil;ını ihlal eden &uuml;ye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen
                        sorumlu olup, Şirket&rsquo;i bu ihlallerin hukuki ve cezai sonu&ccedil;larından ari tutacaktır.
                        Ayrıca; işbu ihlal nedeniyle, olayın yargıya intikal ettirilmesi halinde, Şirket&rsquo;in
                        &uuml;yeye karşı &Uuml;yelik S&ouml;zleşmesi&rsquo;ne uyulmamasından dolayı tazminat talebinde
                        bulunma hakkı saklıdır.
                      </p>

                      <p>
                        <strong>3.9-</strong> <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet
                        sitesi yazılım ve tasarımı Şirket&rsquo;in m&uuml;lkiyetinde olup, bunlara ilişkin telif hakkı
                        ve/veya diğer fikri m&uuml;lkiyet hakları ilgili kanunlarca korunmakta olup, bunlar &Uuml;ye
                        tarafından izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Site&rsquo;de yer alan
                        &uuml;r&uuml;nler farklı şirketlerin &uuml;r&uuml;nleri olmakla beraber bu &uuml;r&uuml;nlerin
                        ticari markaları koruma altında olup, &uuml;r&uuml;nlerin markaları izinsiz kullanılamaz,
                        iktisap edilemez ve değiştirilemez.
                      </p>

                      <p>
                        <strong>3.10-</strong> <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a>{' '}
                        internet sitesinin vir&uuml;s ve benzeri ama&ccedil;lı yazılımlardan arındırılmış olması
                        i&ccedil;in mevcut imkanlar dahilinde tedbirler alınmıştır. Bunun yanında nihai g&uuml;venliğin
                        sağlanması i&ccedil;in kullanıcının, kendi vir&uuml;s koruma sistemini tedarik etmesi ve gerekli
                        korunmayı sağlaması gerekmektedir. Bu bağlamda &Uuml;ye{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesine girmesiyle,
                        kendi yazılım ve işletim sistemlerinde oluşabilecek t&uuml;m hata ve bunların doğrudan ya da
                        dolaylı sonu&ccedil;larından kendisinin sorumlu olduğunu kabul, beyan ve taahh&uuml;t
                        etmektedir.
                      </p>

                      <p>
                        <strong>3.11-</strong> Şirket, Site&rsquo;nin i&ccedil;eriğini dilediği zaman değiştirme,
                        &Uuml;ye&rsquo;lere sağlanan herhangi bir hizmeti değiştirme ya da sona erdirme veya{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet&nbsp; sitesinde
                        kayıtlı &uuml;yeliği, kullanıcı bilgi ve verilerini silme hakkını saklı tutar.
                      </p>

                      <p>
                        <strong>3.12-</strong> Şirket, &Uuml;yelik S&ouml;zleşmesi&rsquo;nin koşullarını hi&ccedil;bir
                        şekil ve surette &ouml;n ihbara ve/veya ihtara gerek kalmaksızın her zaman değiştirebilir,
                        g&uuml;ncelleyebilir veya iptal edebilir. Değiştirilen, g&uuml;ncellenen ya da
                        y&uuml;r&uuml;rl&uuml;kten kaldırılan her h&uuml;k&uuml;m, yayın tarihinde t&uuml;m &uuml;yeler
                        bakımından h&uuml;k&uuml;m ifade edecektir.
                      </p>

                      <p>
                        <strong>3.13-</strong> Şirket işbu &Uuml;yelik S&ouml;zleşmesi uyarınca, &Uuml;ye&rsquo;lerinin
                        kendisinde kayıtlı e-posta adreslerine bilgilendirme mailleri ve cep telefonlarına reklam ve
                        kampanyalardan faydalandırma, haberdar etme dışında bilgilendirme sms&#39;leri g&ouml;nderme
                        yetkisine sahip olmakla beraber, &Uuml;ye işbu &Uuml;yelik S&ouml;zleşmesi&rsquo;ni
                        onaylamasıyla beraber bilgilendirme maillerinin e-posta adresine ve bilgilendirme
                        sms&#39;lerinin cep telefonuna g&ouml;nderilmesini kabul etmektedir.
                      </p>

                      <p>
                        <strong>3.14-</strong> &Uuml;ye, internet sitesi &uuml;zerinde ger&ccedil;ekleştirdiği
                        işlemlerde ve yazışmalarda, S&ouml;zleşme&#39;nin h&uuml;k&uuml;mlerine, internet sitesinde
                        belirtilen t&uuml;m koşullara, y&uuml;r&uuml;rl&uuml;kteki mevzuata ve ahlak kurallarına uygun
                        olarak hareket edeceğini kabul, beyan ve taahh&uuml;t eder. &Uuml;ye&rsquo;nin internet sitesi
                        dahilinde yaptığı işlem ve eylemlere ilişkin hukuki ve cezai sorumluluk kendisine aittir.
                      </p>

                      <p>
                        <strong>3.15-</strong>&nbsp;Şirket, &Uuml;ye ile &Uuml;ye&rsquo;nin &uuml;yelik hesabı
                        oluştururken bildirmiş olduğu e-posta adresi vasıtasıyla veya telefon numarasına arama yapmak ve
                        SMS g&ouml;ndermek suretiyle iletişim kuracaktır. &Uuml;ye, e-posta adresini ve telefon
                        numarasını g&uuml;ncel tutmakla y&uuml;k&uuml;ml&uuml;d&uuml;r.
                      </p>

                      <p>
                        <strong>3.16-</strong> İnternet sitesi &uuml;zerinden, Şirket kendi kontrol&uuml;nde olmayan
                        ve/veya başkaca &uuml;&ccedil;&uuml;nc&uuml; kişilerin sahip olduğu ve/veya işlettiği başka
                        internet sitelerine ve/veya başka i&ccedil;eriklere link verilebilir. Bu linkler &uuml;yelere
                        y&ouml;nlendirme kolaylığı sağlamak amacıyla konmuş olup herhangi bir internet sitesini veya o
                        siteyi işleten kişiyi desteklememektedir. Link verilen internet sitesinin i&ccedil;erdiği
                        bilgilere y&ouml;nelik herhangi t&uuml;rde bir beyan veya garanti niteliği taşımamaktadır.{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> &uuml;zerindeki linkler
                        vasıtasıyla erişilen internet siteleri ve i&ccedil;erikleri hakkında Şirket&rsquo;in herhangi
                        bir sorumluluğu yoktur ve bu sitelerin kullanımıyla doğabilecek zararlar, &uuml;yenin kendi
                        sorumluluğundadır.&nbsp;
                      </p>

                      <p>
                        <strong>3.17-</strong> &Uuml;ye, Şirket&rsquo;in &ouml;nceden yazılı onayını almaksızın işbu
                        &Uuml;yelik S&ouml;zleşmesi&rsquo;ndeki haklarını veya y&uuml;k&uuml;ml&uuml;l&uuml;klerini
                        t&uuml;m&uuml;yle veya kısmen temlik edemeyecektir.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>4. GİZLİLİK H&Uuml;K&Uuml;MLERİ</strong>
                      </p>

                      <p>
                        Şirket, &Uuml;ye tarafından, &uuml;yelik hesabı a&ccedil;mak, &uuml;r&uuml;n satın almak,
                        &ouml;deme yapmak ve &uuml;r&uuml;n teslimi amacı ile paylaşılan ve elde edilen t&uuml;m
                        bilgileri, her t&uuml;r veriyi, gizli bilgi şeklinde koruyup,{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesinde yayınlanan
                        &lsquo;Aday M&uuml;şteri Aydınlatma Metni&rsquo; ve &lsquo;M&uuml;şteri Aydınlatma Metni&rsquo;
                        kapsamı dışında, s&ouml;zl&uuml;, yazılı yahut başka şekilde &uuml;&ccedil;&uuml;nc&uuml;
                        kişilere ifşa etmeyeceğini ve a&ccedil;ıklamayacağını şimdiden kabul, beyan ve taahh&uuml;t
                        etmektedir.
                      </p>

                      <p>
                        Şirket bu bilgileri sadece faaliyet amacını ger&ccedil;ekleştirmek ve idari ve yasal
                        zorunluluğun mevcudiyeti &ccedil;er&ccedil;evesinde a&ccedil;ıklayabilecektir. Araştırma
                        ehliyeti belgelenmiş her t&uuml;rl&uuml; adli soruşturma dahilinde Şirket kendisinden istenen
                        bilgiyi elinde bulunduruyorsa ilgili makam ile paylaşabilecektir. Buna ilişkin
                        &Uuml;ye&rsquo;nin kişisel verileriyle ilgili izlenecek yol{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesinde yer alan
                        &lsquo;<em>Kişisel Verilerin Korunması ve İşlenmesi Politikasında</em>&rsquo; ve
                        &ldquo;KVKK&rdquo; başlığı altında yer almaktadır.
                      </p>

                      <p>
                        Bu politikalara g&ouml;re de &Uuml;ye&rsquo;ye ait ad, soyadı, e-posta adresi, T.C. Kimlik
                        numarası, ev/işyeri/cep telefon numaraları, ev/işyeri adresi, m&uuml;şteri işlem bilgisi vb.
                        bilgiler; kişiler verileri yalnızca Şirket tarafından &uuml;r&uuml;n satış ve teslimi ve buna
                        bağlı bilgilendirme prosed&uuml;rleri i&ccedil;in işlenmektedir. &Uuml;ye&rsquo;nin ancak
                        a&ccedil;ık rızası olduğu takdirde kampanya bilgileri, yeni &uuml;r&uuml;nler, hizmetler
                        hakkında bilgiler, promosyon bilgileri &Uuml;ye&rsquo;ye aktarılacaktır.
                      </p>

                      <p>
                        Gizlilik y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; S&ouml;zleşme&rsquo;nin devamı s&uuml;resince ve
                        S&ouml;zleşme&rsquo;nin herhangi bir nedenle sona ermesi halinde, s&uuml;resiz olarak
                        y&uuml;r&uuml;rl&uuml;kte olacaktır. Bu gizlilik y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;ne
                        uyulmaması halinde işbu S&ouml;zleşme&rsquo;nin diğer h&uuml;k&uuml;mleri ile verilen haklar
                        saklı kalacaktır.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>5. KİŞİSEL VERİLERİN KORUNMASI</strong>
                      </p>

                      <p>
                        6698 sayılı Kişisel Verilerin Korunması Kanununu (&ldquo;KVKK&rdquo;) kapsamında kişisel veri
                        olarak tanımlanabilecek &Uuml;ye&rsquo;ye ait ad, soyadı, e-posta adresi, T.C. Kimlik numarası,
                        ev/işyeri/cep telefon numaraları, ev/işyeri adresi, m&uuml;şteri işlem bilgisi vb. bilgiler;
                        &uuml;r&uuml;n satın alım işlemlerinin ger&ccedil;ekleştirilmesi, &uuml;r&uuml;nleri sunmak,
                        &uuml;r&uuml;nleri geliştirmek, sistemsel sorunların &ccedil;&ouml;z&uuml;m&uuml;, &ouml;deme
                        işlemlerini ger&ccedil;ekleştirmek, &uuml;r&uuml;n g&ouml;nderimi, fatura g&ouml;nderimi,
                        &uuml;r&uuml;nler hakkında pazarlama faaliyetlerinde kullanılmak, &Uuml;ye&rsquo;ye ait
                        bilgilerin g&uuml;ncellenmesinde ve &uuml;yeliklerin y&ouml;netimi ve
                        s&uuml;rd&uuml;r&uuml;lmesi ile &Uuml;ye ile Şirket arasında kurulan mesafeli satış
                        s&ouml;zleşmesi ve sair s&ouml;zleşmelerin ifası amacıyla ve 3. kişilerin teknik ve benzeri
                        diğer işlevlerinin Şirket adına yerine getirilmesini sağlamak i&ccedil;in Şirket iştirakleri ve
                        &uuml;&ccedil;&uuml;nc&uuml; kişi ve/veya kuruluşlar tarafından &lsquo;
                        <em>Kişisel Verilen Saklanması Ve İmhası Politikasında</em>&rsquo; belirtilen s&uuml;relerce
                        kaydedilir, yazılı/manyetik arşivlerde muhafaza edilebilir, kullanılabilir,
                        g&uuml;ncellenebilir, paylaşılabilir, transfer olunabilir ve sair suretler ile işlenebilir.
                        &Uuml;ye, bu bağlamda kişisel verilerinin işlenebileceğini hakkında internet sitesinde bulunan
                        &ldquo;KVKK&rdquo; başlığı altında bulunan aydınlatma metinleri ile bilgilendirilmektedir.
                        &nbsp;
                      </p>

                      <p>
                        Şirket, y&uuml;r&uuml;rl&uuml;kteki mevzuat uyarınca yetkili makamların talebi halinde,
                        &Uuml;ye&rsquo;nin kendisinde bulunan bilgilerini s&ouml;z konusu makamlarla paylaşabilecektir.
                      </p>

                      <p>
                        Şirket s&ouml;z konusu kişisel verilerin KVKK madde 12 uyarınca g&uuml;venli şekilde saklanması,
                        yetkisiz erişimlerin ve hukuka aykırı veri işlemelerin &ouml;nlenmesi i&ccedil;in gereken her
                        t&uuml;rl&uuml; tedbiri almaktadır. &Uuml;ye, kişisel verileri &uuml;zerinde KVKK madde 11
                        uyarınca sahip olduğu hakları kullanma ve kişisel verilerinde dilediği zaman değişiklik veya
                        g&uuml;ncelleme yapma hakkına sahiptir. Kişisel verinin kullanılması ile ilgili koşullar ve bu
                        konudaki haklar ile ilgili olarak detaylı bilgi &lsquo;
                        <em>Kişisel Verilerin Korunması ve İşlenmesi Politikası</em>&rsquo; ve &lsquo;
                        <em>M&uuml;şteri Aydınlatma Metinlerinde</em>&rsquo; verilmektedir. &nbsp;
                      </p>

                      <p>
                        <strong>6. FİKRİ M&Uuml;LKİYET HAKLARI</strong>
                      </p>

                      <p>
                        Şirket&nbsp;markası ve logosu, internet sitesinin tasarımı, yazılımı, alan adı ve bunlara
                        ilişkin olarak Şirket tarafından oluşturulan her t&uuml;rl&uuml; marka, tasarım, logo, ticari
                        takdim şekli, slogan ve diğer t&uuml;m işbu internet sitesinde yer alan unvan, işletme adı,
                        marka, patent, logo, tasarım, bilgi, y&ouml;ntem ve i&ccedil;erik gibi tescilli veya tescilsiz
                        t&uuml;m fikri m&uuml;lkiyet hakları site işleteni ve sahibi Şirkete veya belirtilen ilgilisine
                        ait olup, ulusal ve uluslararası hukukun koruması altındadır. &nbsp;İşbu Site&rsquo;ye &uuml;ye
                        olunması, ziyaret edilmesi, &uuml;r&uuml;n satın alınması veya bu Site&rsquo;deki hizmetlerden
                        yararlanılması s&ouml;z konusu fikri m&uuml;lkiyet hakları konusunda hi&ccedil;bir hak
                        sağlamamaktadır.
                      </p>

                      <p>
                        &Uuml;ye, Şirket&rsquo;in m&uuml;lkiyetine tabi fikri m&uuml;lkiyet haklarını ve/veya bilgileri,
                        &ouml;zellikleri Şirket&rsquo;in yazılı izni olmaksızın kullanamaz, paylaşamaz, dağıtamaz,
                        sergileyemez, &ccedil;oğaltamaz ve bunlardan t&uuml;remiş &ccedil;alışmalar yapamaz. &Uuml;ye,
                        internet sitesinin b&uuml;t&uuml;n&uuml; ya da bir kısmını başka bir ortamda Şirket&rsquo;in
                        yazılı izni olmaksızın kullanamaz. &Uuml;ye&rsquo;nin, &uuml;&ccedil;&uuml;nc&uuml; kişilerin
                        veya Şirket&rsquo;in fikri m&uuml;lkiyet haklarını ihlal edecek şekilde davranması halinde,
                        &Uuml;ye, Şirket&rsquo;in ve/veya s&ouml;z konusu &uuml;&ccedil;&uuml;nc&uuml; kişinin t&uuml;m
                        doğrudan ve dolaylı zararları ile masraflarını tazmin etmekle y&uuml;k&uuml;ml&uuml;d&uuml;r.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>7. M&Uuml;CBİR SEBEP</strong>
                      </p>

                      <p>
                        Şirket, kontrol&uuml;nde olmayan tabii afetler, yangınlar, patlamalar, i&ccedil; savaşlar,
                        savaşlar, ayaklanmalar, halk hareketleri, seferberlik ilanı, grev, lokavt ve salgın hastalıklar,
                        altyapı ve internet arızaları, elektrik kesintisi gibi m&uuml;cbir sebep sayılan t&uuml;m
                        durumlarda, <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> internet sitesine
                        ait işbu &Uuml;yelik S&ouml;zleşmesi&rsquo;nde ge&ccedil;en y&uuml;k&uuml;ml&uuml;l&uuml;kleri
                        ge&ccedil; ifadan veya hi&ccedil; ifa etmemekten dolayı herhangi bir sorumluluk kabul
                        etmemektedir.
                      </p>

                      <p>
                        Şirket&rsquo;in kontrol&uuml; dışında ger&ccedil;ekleşen, kusurundan kaynaklanmayan ve makul
                        olarak &ouml;ng&ouml;r&uuml;lemeyecek her t&uuml;rl&uuml; olay&nbsp;(m&uuml;cbir
                        sebepler)&nbsp;Şirket&rsquo;in işbu S&ouml;zleşme&rsquo;den doğan
                        y&uuml;k&uuml;ml&uuml;l&uuml;klerini ifa etmesini engeller veya geciktirirse, Şirket ifası
                        m&uuml;cbir sebep sonucunda engellenen veya geciken y&uuml;k&uuml;ml&uuml;l&uuml;klerinden
                        dolayı sorumlu tutulamaz ve bu durum S&ouml;zleşme&rsquo;nin bir ihlali olarak kabul edilemez.
                        &Uuml;ye bu durumu kabul ettiğini şimdiden kabul, beyan ve taahh&uuml;t eder.&nbsp;&nbsp;
                      </p>

                      <p>
                        <strong>8. S&Ouml;ZLEŞMENİN FESHİ</strong>
                      </p>

                      <p>
                        İşbu S&ouml;zleşme &Uuml;ye&rsquo;nin &uuml;yeliğini iptal etmesi veya{' '}
                        <strong>Şirket tarafından</strong> &uuml;yeliğinin iptal edilmesine kadar
                        y&uuml;r&uuml;rl&uuml;kte kalacaktır. &Uuml;ye&rsquo;nin &Uuml;yelik S&ouml;zleşmesi&rsquo;nin
                        herhangi bir h&uuml;km&uuml;n&uuml; ihlal etmesi durumunda, Şirket &Uuml;ye&rsquo;nin
                        &uuml;yeliğini iptal ederek s&ouml;zleşmeyi tek taraflı olarak feshedebilecektir. &Uuml;ye
                        fesihe sebep olabilecek fiilleri nedeniyle Şirket&rsquo;in uğrayabileceği t&uuml;m zararı tazmin
                        edeceğini kabul, beyan ve taahh&uuml;t eder.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>9. YETKİLİ VE G&Ouml;REVLİ MAHKEME</strong>
                      </p>

                      <p>
                        İşbu S&ouml;zleşme&rsquo;nin uygulanmasında, &Uuml;ye (t&uuml;ketici); şikayet ve itirazları
                        konusunda başvurularını, T.C. G&uuml;mr&uuml;k ve Ticaret Bakanlığı tarafından her yıl Aralık
                        ayında belirlenen parasal sınırlar dahilinde t&uuml;keticinin &uuml;r&uuml;n&uuml; satın aldığı
                        veya ikametgahının bulunduğu yerdeki t&uuml;ketici sorunları hakem heyetine veya t&uuml;ketici
                        mahkemesine yapabilir.
                      </p>

                      <p>
                        &Uuml;yelik S&ouml;zleşmesi&rsquo;nden kaynaklanan veya bağlantılı olan her t&uuml;rl&uuml;
                        ihtilaf, İstanbul T&uuml;ketici Hakem Heyetleri ve İstanbul (Anadolu) Mahkemeleri ve İcra
                        M&uuml;d&uuml;rl&uuml;kleri&#39;nin m&uuml;nhasır yargı yetkisinde olacaktır.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>10. Y&Uuml;R&Uuml;RL&Uuml;K</strong>
                      </p>

                      <p>
                        &Uuml;ye&rsquo;nin, &uuml;yelik hesabı oluşturması aşamasında, &ldquo;okudum, onaylıyorum&rdquo;
                        kutusunu işaretlemesi, &Uuml;ye&rsquo;nin &Uuml;yelik S&ouml;zleşmesi&rsquo;nde yer alan
                        t&uuml;m maddeleri okuduğu ve &uuml;yelik s&ouml;zleşmesinde yer alan t&uuml;m maddeleri kabul
                        ettiği anlamına gelmektedir. &nbsp;İşbu S&ouml;zleşme, &Uuml;ye&rsquo;nin &ldquo;okudum,
                        onaylıyorum&rdquo; kutusunu işaretlemesi ve &uuml;yeliğini ger&ccedil;ekleştirmesi anında
                        &Uuml;ye ve Şirket arasında karşılıklı olarak y&uuml;r&uuml;rl&uuml;ğe girecektir. Şirket,
                        dilediği zaman işbu S&ouml;zleşme h&uuml;k&uuml;mlerinde değişikliğe gidebilir, değişiklikler,
                        madde numarası ve değişiklik tarihi belirtilerek{' '}
                        <a href="http://www.okulsepeti.com.tr/">www.okulsepeti.com.tr</a> &uuml;zerinden yayınlanır ve
                        aynı tarihte y&uuml;r&uuml;rl&uuml;ğe girer. Değişiklikleri takip etmek &Uuml;ye&rsquo;nin
                        sorumluluğundadır. &Uuml;ye, sunulan &uuml;r&uuml;n ve hizmetlerden yararlanmaya devam etmekle
                        ve &uuml;yelik hesabını iptal etmeyerek bu değişiklikleri kabul etmiş sayılacaktır.
                      </p>

                      <p>
                        &Uuml;yelik S&ouml;zleşmesi Taraflar arasındaki anlaşmanın tamamını oluşturmaktadır.
                        S&ouml;zleşme&rsquo;nin herhangi bir h&uuml;km&uuml;n&uuml;n yetkili herhangi bir mahkeme,
                        tahkim heyeti veya idari makam tarafından t&uuml;m&uuml;yle veya kısmen ge&ccedil;ersiz,
                        uygulanamaz veya makul olmadığına karar verilmesi halinde, s&ouml;z konusu ge&ccedil;ersizlik,
                        uygulanmazlık veya makul olmama hallerinde, S&ouml;zleşme b&ouml;l&uuml;nebilir olarak kabul
                        edilecek ve diğer h&uuml;k&uuml;mler t&uuml;m&uuml;yle y&uuml;r&uuml;rl&uuml;kte kalmaya devam
                        edecektir.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>11. TEBLİGAT</strong>
                      </p>

                      <p>
                        İşbu S&ouml;zleşme ile ilgili Taraflar&rsquo;a g&ouml;nderilecek olan t&uuml;m bildirimler,
                        Şirket&rsquo;in bilinen e-posta adresi ve &Uuml;ye&rsquo;nin &uuml;yelik formunda belirttiği
                        e-posta adresi vasıtasıyla yapılacaktır. &Uuml;ye, &uuml;ye olurken belirttiği adresin
                        ge&ccedil;erli tebligat adresi olduğunu, değişmesi durumunda 5 (beş) g&uuml;n i&ccedil;inde
                        yazılı olarak bu değişikliği diğer tarafa bildireceğini, aksi halde bu adrese yapılacak
                        tebligatların ge&ccedil;erli sayılacağını kabul eder.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>12. DELİL S&Ouml;ZLEŞMESİ</strong>
                      </p>

                      <p>
                        Taraflar arasında işbu S&ouml;zleşme kapsamında &ccedil;ıkabilecek her t&uuml;rl&uuml;
                        uyuşmazlıklarda Taraflar&rsquo;ın defter, kayıt ve belgeleri ile bilgisayar kayıtları 6100
                        sayılı Hukuk Muhakemeleri Kanunu uyarınca delil olarak kabul edilecek olup, &Uuml;ye bu
                        kayıtlara itiraz etmeyeceğini kabul, beyan ve taahh&uuml;t eder.
                      </p>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {elektronikConfig && (
        <Modal
          width={DEFAULT_MAX_WIDTH}
          onCancel={() => {
            setModalOpen(false)
          }}
          footer={null}
          open={modalOpen}
          title={<div className="baslik-ayar">TİCARİ ELEKTRONİK İLETİ AYDINLATMA METNİ</div>}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="row no-margin">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      6698 sayılı Kişisel Verilerin Korunması Kanunu, 6563 sayılı Elektronik Ticaretin D&uuml;zenlenmesi
                      Hakkında Kanun ve 15 Temmuz 2015 tarihli 29417 sayılı Resmi Gazete &rsquo;de yayınlanan Ticari
                      İletişim ve Ticari Elektronik İletiler Hakkında Y&ouml;netmelik ve sair mevzuatlar kapsamında Okul
                      Sepeti Pazarlama Anonim Şirketi (&ldquo;Şirket&rdquo;) olarak siz değerli m&uuml;şterilerimizi
                      ticari elektronik iletiler hakkında bilgilendirmek ve ticari elektronik ileti onayınızı alarak
                      size daha iyi bir hizmet sunmak istemekteyiz.
                    </p>

                    <p>
                      <b>Bilgilendirme Metni</b>
                      <br />
                      Ticari elektronik ileti; telefon, &ccedil;ağrı merkezleri, faks, otomatik arama makineleri, akıllı
                      ses kaydedici sistemler, elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak
                      elektronik ortamda ger&ccedil;ekleştirilen ve ticari ama&ccedil;larla g&ouml;nderilen veri, ses ve
                      g&ouml;r&uuml;nt&uuml; i&ccedil;erikli iletileri, ifade etmektedir. Ticari elektronik iletiler,
                      alıcılara ancak &ouml;nceden onayları alınmak kaydıyla g&ouml;nderilebilir. Bu onay, yazılı olarak
                      veya her t&uuml;rl&uuml; elektronik iletişim ara&ccedil;larıyla alınabilir. Alıcılar diledikleri
                      zaman, hi&ccedil;bir gerek&ccedil;e belirtmeksizin ticari elektronik iletileri almayı
                      reddedebilir. Bu kapsamda ticari elektronik ileti g&ouml;nderimine dair onay verseniz dahi
                      dilediğiniz zaman, hi&ccedil;bir gerek&ccedil;e belirtmeksizin ticari elektronik iletileri almayı
                      g&ouml;nderide yer alan linke girerek her zaman reddedebilirsiniz.
                    </p>

                    <p>
                      <b>Ticari İletişim İzni</b>
                      <br />
                      Ticari Elektronik İleti g&ouml;nderilmesinin onaylanması ile 6698 sayılı Kişisel Verilerin
                      Korunması Kanunu ve 6563 sayılı Elektronik Ticaretin D&uuml;zenlenmesi Hakkında Kanun ve 15 Temmuz
                      2015 tarihli 29417 sayılı Resmi Gazete &rsquo;de yayınlanan Ticari İletişim ve Ticari Elektronik
                      İletiler Hakkında Y&ouml;netmelik kapsamında gerekli bilgilendirmenin tarafınıza yapıldığını, işbu
                      bilgilendirmeyi okuyup anladığınızı kabul etmiş olmaktasınız.
                      <br />
                      Bu &ccedil;er&ccedil;evede, pazarlama, iletişim ve kimlik verilerinizin Okul Sepeti Pazarlama
                      Anonim Şirketi tarafından; genel veya size &ouml;zel kişiselleştirilmiş kampanyalar, avantajlar,
                      promosyonlar, &uuml;r&uuml;n/hizmet tanıtımları, reklamlar, bilgilendirmeler, pazarlama
                      faaliyetleri kapsamında g&uuml;ncel iletişim bilgileriniz &uuml;zerinden size y&ouml;nelik ticari
                      iletişim faaliyetlerinde (SMS, e-posta, arama vb.) bulunulabilmesi, &uuml;r&uuml;n ve
                      hizmetlerimizle ilgili m&uuml;şteri memnuniyetine y&ouml;nelik anketlerin, pazar araştırması
                      anketlerinin kampanyaların, yarışma, &ccedil;ekiliş, davet, a&ccedil;ılış ve diğer etkinliklere
                      ilişkin davetlerin iletilmesi, g&uuml;ncel gelişmelerden haberdar edilmesi, kutlama ama&ccedil;lı
                      iletiler g&ouml;nderilmesi, sunum ve b&uuml;lten gibi i&ccedil;eriklerin paylaşılması ve benzer
                      ama&ccedil;larla kanunlara uygun şekilde SMS/kısa mesaj, otomatik arama, telefonla arama, mobil
                      uygulama, anlık bildirim, bluetooth, beacon kablosuz ağlar, sosyal medya ile
                      &ccedil;evrimi&ccedil;i reklam ağları, e-posta/mail ve diğer her t&uuml;rl&uuml; elektronik
                      iletişim ara&ccedil;ları-kanalları yoluyla tarafınıza ticari elektronik iletiler ve diğer iletiler
                      g&ouml;nderilmesine, bu şekilde iletişime ge&ccedil;ilmesine, konu hakkında teredd&uuml;de yer
                      vermeyecek şekilde bilgi sahibi olarak, aydınlatılmış a&ccedil;ık rızanız ile onay/izin
                      vermektesiniz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {bilgiConfig && (
        <Modal
          width={DEFAULT_MAX_WIDTH}
          onCancel={() => {
            setModalOpen(false)
          }}
          footer={null}
          open={modalOpen}
          title={<div className="baslik-ayar">Müşteri Aydınlatma Metni</div>}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="main m-top20 m-bottom20 sign-box login-content">
                  <div className="row no-margin">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
                      <title></title>
                      <p style={{ alignItems: 'center' }}>
                        <strong>
                          <u>M&Uuml;ŞTERİ AYDINLATMA METNİ</u>
                        </strong>
                      </p>

                      <p style={{ alignItems: 'left' }}>
                        <br />
                        <strong>1. Veri Sorumlusu Kimliği:</strong>
                        <br />
                        Bostancı Mahallesi Tarikihas Sokak Tavuk&ccedil;uoğlu İş Merkezi No:2/6 Kadık&ouml;y / İstanbul
                        adresinde bulunan ve İstanbul Ticaret Sicili M&uuml;d&uuml;rl&uuml;ğ&uuml; nezdinde 949196 sicil
                        numarası ile kayıtlı olan Okul Sepeti Pazarlama A.Ş.(&ldquo;
                        <strong>Şirket</strong>&rdquo;)&rsquo;dir.
                      </p>

                      <p style={{ alignItems: 'left' }}>
                        <br />
                        <strong>2. Veri Kategorizasyonu:</strong>
                      </p>

                      <table>
                        <tbody>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }}>
                              <p>
                                <strong>Kimlik Bilgisi</strong>
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }}>
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&nbsp;</li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="382">
                              <p>
                                Ad, soyadı, T.C. kimlik numarası gibi(Bireysel M&uuml;şteriler) Firma unvanı
                                gibi(Kurumsal M&uuml;şteriler)
                                <br />
                                &Ouml;ğrencinin adı soyadı, cinsiyeti gibi
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="168">
                              <p>
                                <strong>İletişim Bilgisi</strong>
                              </p>
                            </td>
                            <td width="53">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&nbsp;</li>
                              </ul>
                            </td>
                            <td width="382">
                              <p>Telefon numarası, e-mail adresi gibi (Bireysel ve Kurumsal M&uuml;şteriler)</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="168">
                              <p>
                                <strong>Lokasyon Bilgisi</strong>
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="53">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&nbsp;</li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="382">
                              <p>Ev adresi, iş adresi gibi</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="168">
                              <p>
                                <strong>Finans Bilgisi</strong>
                              </p>
                            </td>
                            <td width="53">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&nbsp;</li>
                              </ul>

                              <p>&nbsp;</p>
                            </td>
                            <td width="382">
                              <p>Vergi kimlik numarası, vergi dairesi (Kurumsal M&uuml;şteriler)</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="168">
                              <p>
                                <strong>Fiziksel Mek&acirc;n G&uuml;venliği Bilgisi</strong>
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="53">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&nbsp;</li>
                              </ul>

                              <p>&nbsp;</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="382">
                              <p>Kamera kayıtları ile kaydedilen g&ouml;r&uuml;nt&uuml;ler gibi</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="168">
                              <p>
                                <strong>İşitsel Kayıtlar</strong>
                              </p>
                            </td>
                            <td width="53">&nbsp;</td>
                            <td width="382">
                              <p>&Ccedil;ağrı merkezi tarafından alınan ses kayıtları gibi</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="168">
                              <p>
                                <strong>Hukuki İşlem Verisi</strong>
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="53">
                              &nbsp;
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="382">
                              <p>Yetkili kamu kuruluşları ile yapılan adli yazışmalarda bulunan bilgiler gibi</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>3. Kişisel Verilerin Hangi Ama&ccedil;larla İşlendiği</strong>
                      </p>

                      <table>
                        <tbody>
                          <tr>
                            <td align="left" valign="middle" width="189">
                              <strong>Veri Kategorisi</strong>
                            </td>
                            <td align="left" valign="middle" width="272">
                              <p>
                                <strong>Veri İşleme Amacı</strong>
                              </p>
                            </td>
                            <td align="left" valign="middle" width="221">
                              <p>
                                <strong>Veri İşlemenin Hukuki Sebebi</strong>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="189">
                              <p>
                                Kimlik Bilgileri, İletişim Bilgileri, Lokasyon Bilgileri, Hukuki İşlem Verisi, Finans
                                Bilgileri
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="272">
                              <p>&nbsp;</p>

                              <ul>
                                <li style={{ listStyle: 'disc' }}>okulsepeti.com site &uuml;yeliği tanımlama</li>
                                <li style={{ listStyle: 'disc' }}>
                                  İletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi
                                </li>
                                <li style={{ listStyle: 'disc' }}>
                                  İş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi / denetimi
                                </li>
                                <li style={{ listStyle: 'disc' }}>
                                  Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesi
                                </li>
                                <li style={{ listStyle: 'disc' }}>
                                  Finans ve muhasebe işlerinin y&uuml;r&uuml;t&uuml;lmesi
                                </li>
                                <li style={{ listStyle: 'disc' }}>
                                  Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
                                </li>
                                <li style={{ listStyle: 'disc' }}>
                                  &Uuml;r&uuml;n satış faaliyetlerinin kaydının tutulması
                                </li>
                                <li style={{ listStyle: 'disc' }}>
                                  M&uuml;şteri ilişkileri y&ouml;netimi s&uuml;re&ccedil;lerinin
                                  y&uuml;r&uuml;t&uuml;lmesi
                                </li>
                                <li style={{ listStyle: 'disc' }}>&Uuml;r&uuml;nlerin alıcıya tesliminin sağlanması</li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="221">
                              <p>KVKK m.5 f.2 c bendi; S&ouml;zleşmenin Kurulması ve İfası ile İlgili Olması</p>

                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="189">
                              <p>Kimlik Bilgileri, Hukuki İşlem Verisi, İletişim Bilgileri, Lokasyon Bilgileri</p>
                            </td>
                            <td width="272">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
                                </li>
                              </ul>
                            </td>
                            <td width="221">
                              <p>
                                KVKK m.5 f.2 &ccedil; bendi; Hukuki Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n Yerine
                                Getirilmesi İ&ccedil;in Zorunlu Olması
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="189">
                              <p>Fiziksel mek&acirc;n g&uuml;venliği</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="272">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>Fiziksel mek&acirc;n g&uuml;venliğinin temini</li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="221">
                              <p>
                                KVKK m.5 f.2 &ccedil; bendi; Hukuki Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n Yerine
                                Getirilmesi İ&ccedil;in Zorunlu Olması
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="189">
                              <p>Finansal Bilgiler</p>
                            </td>
                            <td width="272">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Finans ve muhasebe işlerinin y&uuml;r&uuml;t&uuml;lmesi
                                </li>
                              </ul>
                            </td>
                            <td width="221">
                              <p>KVKK m.5 f.2 c bendi; S&ouml;zleşmenin Kurulması ve İfası ile İlgili Olması</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="189">
                              <p>İşitsel Kayıtlar</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="272">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&Ccedil;ağrı merkezi ile</li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="221">
                              <p>KVKK m.5 f.2 f bendi;Veri Sorumlusunun Meşru Menfaati</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>4. Kişisel Verilerin Kimlere ve Hangi Ama&ccedil;la Aktarılabileceği</strong>
                      </p>

                      <table>
                        <tbody>
                          <tr>
                            <td width="146">
                              <strong>Alıcı Grubu</strong>
                            </td>
                            <td width="250">
                              <p>
                                <strong>Aktarım Amacı</strong>
                              </p>
                            </td>
                            <td width="231">
                              <p>
                                <strong>Aktarımın Hukuki Sebebi</strong>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="146">
                              <p>Yetkili kamu kurum ve kuruluşları</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Kamera kayıtlarının istendiğinde Emniyet M&uuml;d&uuml;rl&uuml;ğ&uuml; ve Adli
                                  makamlarla paylaşılması
                                </li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="231">
                              <p>
                                KVKK m.5 f.2 &ccedil; bendi; Hukuki Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n Yerine
                                Getirilmesi İ&ccedil;in Zorunlu Olması
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="146">
                              <p>Kargo firması ve kurye</p>
                            </td>
                            <td width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>&Uuml;r&uuml;nlerin m&uuml;şteriye teslimi</li>
                              </ul>
                            </td>
                            <td width="231">
                              <p>KVKK m.5 f.2 c bendi; S&ouml;zleşmenin Kurulması ve İfası ile İlgili Olması</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="146">
                              <p>
                                Tedarik&ccedil;i (Okul-&Ouml;ğrencinin okumakta olduğu&nbsp; okulun atadığı yetkili kiş)
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Okulun, &ouml;ğrencinin satın aldığı &uuml;r&uuml;n verilerinin takibini yapabilmesi
                                </li>
                              </ul>

                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  İş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi / denetimi
                                </li>
                              </ul>

                              <p>&nbsp;</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="231">
                              <p>KVKK m.5 f.2 f bendi;Veri Sorumlusunun Meşru Menfaati</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="146">
                              <p>Tedarik&ccedil;i (Yazılım firması)</p>
                            </td>
                            <td width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Sistemin &ccedil;alışabilmesi amacıyla veri girişinin sağlanması
                                </li>
                              </ul>

                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Teknik zorunluluklar ve sistemsel problemlerin &ccedil;&ouml;z&uuml;m&uuml;n&uuml;n
                                  sağlanabilmesi
                                </li>
                              </ul>
                            </td>
                            <td width="231">
                              <p>KVKK m.5 f.2 c bendi; S&ouml;zleşmenin Kurulması ve İfası ile İlgili Olması</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="146">
                              <p>Tedarik&ccedil;i (Bankalar, mali m&uuml;şavirler)</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Fatura d&uuml;zenlemede Finansbank e-finans sistemi ile paylaşım
                                </li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="231">
                              <p>KVKK m.5 f.2 c bendi; S&ouml;zleşmenin Kurulması ve İfası ile İlgili Olması</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="146">
                              <p>Tedarik&ccedil;i (mali m&uuml;şavir)</p>
                            </td>
                            <td width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Finansal ve mali tabloların YMM şirketi tarafından onaylanması
                                </li>
                              </ul>
                            </td>
                            <td width="231">
                              <p>
                                KVKK m.5 f.2 &ccedil; bendi; Hukuki Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n Yerine
                                Getirilmesi İ&ccedil;in Zorunlu Olması
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="146">
                              <p>
                                Tedarik&ccedil;i
                                <br />
                                (Avukatlar)
                              </p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Uyuşmazlıkların &ccedil;&ouml;z&uuml;m&uuml; i&ccedil;in
                                </li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="231">
                              <p>
                                KVKK m.5 f.2 f bendi;
                                <br />
                                Veri Sorumlusunun Meşru Menfaati
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="146">
                              <p>Tedarik&ccedil;i (E-posta hizmeti tedarik&ccedil;isi)</p>
                            </td>
                            <td width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Şirket i&ccedil;i ve Şirket dışı e-posta kanalıyla yapılan iletişimin sağlanabilmesi
                                  amacıyla mali belgelerin ve m&uuml;şteri bilgilerinin iletilmesi
                                </li>
                              </ul>
                            </td>
                            <td width="231">
                              <p>
                                KVKK m.5 f.2 f bendi;
                                <br />
                                Veri Sorumlusunun Meşru Menfaati
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="146">
                              <p>Tedarik&ccedil;i (İletişim şirketi)</p>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="250">
                              <ul>
                                <li style={{ listStyle: 'disc' }}>
                                  Sipariş ve teslimatlarla ilgili bilgilendirme mesajlarının m&uuml;şteriye sms ile
                                  g&ouml;nderilmesi
                                </li>
                              </ul>
                            </td>
                            <td style={{ backgroundColor: '#F3F3F3' }} width="231">
                              <p>KVKK m.5 f.2 c bendi; S&ouml;zleşmenin Kurulması ve İfası ile İlgili Olması</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <p>&nbsp;</p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>5. Kişisel Verilerinizin Toplanma Y&ouml;ntemleri&nbsp;</strong>
                        <br />
                        Yukarıda belirtilen kişisel verileriniz Şirket&rsquo;e ilettiğiniz y&ouml;nteme bağlı olarak
                        fiziki veya elektronik ortamda toplanmakta ve yukarıda belirtilen ama&ccedil;lar d&acirc;hilinde
                        işlenmekte ve aktarılmaktadır.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <br />
                        <strong>
                          6. İlgili Kişiler&rsquo;in Kişisel Verileri &Uuml;zerindeki Haklarının Neler Olduğunu ve Bu
                          Hakları Nasıl Kullanabilecekleri
                        </strong>
                      </p>

                      <p>KVKK m.11 uyarınca ilgili kişi sıfatıyla tarafımıza başvurarak;</p>

                      <ul>
                        <li style={{ listStyle: 'disc' }}>Kişisel verilerinizin işlenip işlenmediğini &ouml;ğrenme,</li>
                        <li style={{ listStyle: 'disc' }}>
                          Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
                        </li>
                        <li style={{ listStyle: 'disc' }}>
                          Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
                          &ouml;ğrenme,
                        </li>
                        <li style={{ listStyle: 'disc' }}>
                          Yurt i&ccedil;inde veya yurt dışında kişisel verilerinizin aktarıldığı
                          &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,
                        </li>
                        <li style={{ listStyle: 'disc' }}>
                          Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların d&uuml;zeltilmesini
                          isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı
                          &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,
                        </li>
                        <li style={{ listStyle: 'disc' }}>
                          KVKK ve ilgili diğer mevzuata uygun olarak işlenmiş olmasına rağmen; ama&ccedil;, s&uuml;re ve
                          meşrutiyet prensipleri dahilinde değerlendirilmek &uuml;zere kişisel verilerinizin işlenmesini
                          gerektiren sebeplerin ortadan kalkması halinde, kişisel verilerinizin silinmesini veya yok
                          edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
                          &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,
                        </li>
                        <li style={{ listStyle: 'disc' }}>
                          İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
                          kişinin kendisi aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme,
                        </li>
                        <li style={{ listStyle: 'disc' }}>
                          Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın
                          giderilmesini talep etme
                        </li>
                      </ul>

                      <p>haklarına sahipsiniz.</p>

                      <p>
                        <br />
                        Yukarıda sıralanan haklarınıza y&ouml;nelik başvurularınızı{' '}
                        <strong>
                          <u>
                            <a
                              href="https://kvkk.okulsepeti.com.tr/icerik/kvkk-basvuru-formu/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              başvuru formu
                            </a>
                          </u>
                        </strong>{' '}
                        linkinde yer alan &ldquo; Veri Sorumlusuna Başvuru Form &rdquo;nu yazan talimatlara uygun olarak
                        doldurarak, kullanabilirsiniz.
                        <br />
                        Başvurular, ilgili veri sahibinin kimliğini tespit edecek belgelerle birlikte, aşağıdaki
                        y&ouml;ntemlerden biri ile ger&ccedil;ekleştirilecektir:
                        <br />
                        Formun doldurularak ıslak imzalı kopyasının elden veya posta yoluyla Bostancı Mahallesi
                        Tarikihas Sokak Tavuk&ccedil;uoğlu İş Merkezi No:2/6 Kadık&ouml;y/İstanbul adresine iletilmesi,
                        <br />
                        Formun 5070 sayılı Elektronik İmza Kanunu kapsamında d&uuml;zenlenen g&uuml;venli elektronik
                        imza ile imzalanarak okulsepetipazarlama@hs03.kep.tr adresine kayıtlı elektronik posta ile
                        g&ouml;nderilmesi,
                        <br />
                        Şirket, Kanun&rsquo;da &ouml;ng&ouml;r&uuml;lm&uuml;ş sınırlar &ccedil;er&ccedil;evesinde
                        s&ouml;z konusu hakları kullanmak isteyen veri sahiplerine, yine Kanun&rsquo;da
                        &ouml;ng&ouml;r&uuml;len şekilde azami otuz (30) g&uuml;n i&ccedil;erisinde cevap vermektedir.
                        <br />
                        Başvurunuzun ayrıca bir maliyeti gerektirmesi h&acirc;linde, Kişisel Verileri Koruma Kurulu
                        tarafından belirlenen tarifedeki &uuml;cret alınabilecektir.
                      </p>

                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
export default RegisterForm

import './AuthForm.scss'

import { Button, Checkbox, Form, Input, notification } from 'antd'
import { AppRoutes } from 'App'
import Spinner from 'components/spinner/Spinner'
import AppContext from 'context/AppContext'
import { useLoginMutation } from 'gql/GeneratedGraphqlTypes'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DEV_BACKEND_DOMAIN } from 'utils/const'
import { executeMutation, saveLoginSession } from 'utils/helpers'
import { AuthState } from 'utils/types'

const AuthForm = () => {
  const { setAuthState, setToken, slug } = useContext(AppContext)
  const [login, { loading }] = useLoginMutation()
  const navigate = useNavigate()
  const location = useLocation()

  const onFinish = async (values: any) => {
    const searchParams = new URLSearchParams(location.search)
    const nextValue = searchParams.get('next')

    const result = await executeMutation(login, {
      variables: { email: values.email, password: values.password },
    })
    if (result && result.token && result.user_id) {
      //TODO: This will need work. We need to keep track of token's expiration date and refresh it when needed.
      //So we need to set the local storage item with token and expiration date together.
      //Also remember to clean this on logout.
      if (values.remember) {
        saveLoginSession(result)
      }
      setToken(result.token)
      setAuthState(AuthState.LOGGED_IN)
      if (nextValue !== null) {
        const url = `https://${slug}.${DEV_BACKEND_DOMAIN}/token-to-session-auth-okulyonetim/${result.token}/` // authentication django siteye geçiş
        window.location.href = url
      } else {
        navigate(location?.state?.return_url || '/')
      }
    } else {
      notification.open({
        message: 'Hata!',
        description: 'Kullanıcı adı veya şifre hatalı. Lütfen tekrar deneyiniz.',
        type: 'error',
        placement: 'topRight',
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    notification.open({
      message: 'Hata!',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
      type: 'error',
      placement: 'topRight',
    })
  }

  return (
    <div className="auth-form">
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Spinner showSpinner={loading} color="red" />

        <Form.Item label="Kullanıcı Mail" name="email" rules={[{ required: true, message: 'Bu alan zorunlu!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Şifre" name="password" rules={[{ required: true, message: 'Bu alan zorunlu!' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="beni-hatirla">Beni Hatırla</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button className="giris-yap" type="primary" htmlType="submit" block>
            Giriş Yap
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            className="forgot-password-btn"
            type="link"
            onClick={() => {
              navigate(`/${AppRoutes.resetPassword}`)
            }}
          >
            Şifremi Unuttum.
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AuthForm

import './CrossProductsCarousel.scss'

import { Image } from 'antd'
import Carousel from 'antd/es/carousel'
import React, { useRef } from 'react'

interface Props {
  veri: any
}

const CrossProductsCarousel = ({ veri }: Props) => {
  const ref: any = useRef()

  if (!veri) {
    return null
  }

  if (veri.urun.resimler.length > 0) {
    return (
      <div className="cross-products-carousel">
        <Carousel dots={false} autoplay draggable ref={ref}>
          {veri.urun.resimler.map((resim: string, index: number) => (
            <Image key={index} src={resim} className="image-still" />
          ))}
        </Carousel>

        <div className="custom-cross-product-indicators">
          {veri.urun.resimler.map((image: any, index: any) => (
            <div
              key={`productImagesDots-${index}`}
              className="custom-cross-product-indicator"
              onClick={() => {
                ref.current.goTo(index) // Tıklanıldığında ilgili resime gidecek indicator ref.
              }}
            >
              <img src={image} alt="" />
            </div>
          ))}
        </div>
      </div>
    )
  } else if (veri.urun.resimler.length === 0) {
    return <p>Resim bulunamadı</p>
  }

  return (
    <div>
      <Image src={veri.urun.resimler[0]} className="image-still" />
    </div>
  )
}

export default CrossProductsCarousel

import './ResetPasswordPage.scss'

import { Button, Col, Row } from 'antd' //Divider
import Card from 'antd/es/card/Card'
import ResetPasswordForm from 'components/reset-password-form/ResetPasswordForm'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '../../App'

const ResetPasswordPage = () => {
  const navigate = useNavigate()

  return (
    <div className="password-reset-page">
      <Row justify="center">
        <Col xs={{ span: 18 }} sm={{ span: 16 }} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <ResetPasswordForm />
            <Button
              className="come-back-btn"
              type="link"
              onClick={() => {
                navigate(`/${AppRoutes.auth}`)
              }}
            >
              Üye Giriş Sayfasına Geri Dön.
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ResetPasswordPage

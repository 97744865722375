import './Toolbar.scss'

import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons/lib/icons'
import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import { AppRoutes } from 'App'
import AppContext from 'context/AppContext'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthState } from 'utils/types'

import { AccountMenuItems } from '../account-menu/AccountMenu'
import { AccountMenuItem } from '../account-menu/types'
import CommonDrawer from '../drawer/CommonDrawer'

interface Props {
  logo: string
}

const Toolbar: React.FC<Props> = (props) => {
  const { logo } = props
  const appContext = useContext(AppContext)
  const { authState } = appContext
  const navigate = useNavigate()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 600px)') // Adjust breakpoint as needed
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setIsSmallScreen(event.matches)
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    setIsSmallScreen(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)
    window.addEventListener('resize', handleResize)
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const openDrawer = () => {
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onItemClick = (item: AccountMenuItem) => {
    item.onClick(navigate, appContext)
    closeDrawer()
  }

  const menuItems = AccountMenuItems.map((item, i) => (
    <Button key={i} icon={item.icon} onClick={() => onItemClick(item)} className={'menu-button'} type={'ghost'}>
      {item.title}
    </Button>
  ))
  const accountMenuItems: MenuProps['items'] = AccountMenuItems.map((item, i) => {
    return {
      key: i,
      label: (
        <Button
          icon={item.icon}
          onClick={() => {
            item.onClick(navigate, appContext)
          }}
          className={'menu-button'}
          type={'ghost'}
        >
          {item.title}
        </Button>
      ),
    }
  })

  return (
    <div className="toolbar" style={{ maxWidth: `${windowWidth}px` }}>
      {isSmallScreen && (
        <div className="absoulute-logo">
          <img
            className="logo-img"
            src={logo}
            width={80}
            alt="logo"
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div className="toolbar-content">
          <div className="toolbar-content-left">
            {!isSmallScreen && (
              <div className="toolbar-logo">
                <img
                  src={logo}
                  alt="logo"
                  onClick={() => {
                    navigate('/')
                  }}
                />
              </div>
            )}
          </div>
          <div className="toolbar-content-right">
            {authState === AuthState.LOGGED_IN ? (
              <>
                <Button
                  className="toolbar-auth-button"
                  icon={<ShoppingCartOutlined className="toolbar-icon" />}
                  type="link"
                  onClick={() => {
                    navigate(AppRoutes.basket)
                  }}
                >
                  Sepet
                </Button>
                {isSmallScreen ? (
                  <>
                    <Button
                      className="toolbar-auth-button"
                      icon={<UserOutlined className="toolbar-icon" />}
                      type="link"
                      onClick={openDrawer}
                    >
                      Hesabım
                    </Button>
                    <CommonDrawer
                      title="HESAP BİLGİLERİM"
                      isVisible={drawerVisible}
                      onClose={closeDrawer}
                      items={menuItems}
                    />
                  </>
                ) : (
                  <Dropdown menu={{ items: accountMenuItems }} placement="bottom" arrow={{ pointAtCenter: true }}>
                    <Button
                      className="toolbar-auth-button"
                      icon={<UserOutlined className="toolbar-icon" />}
                      type="link"
                      onClick={() => {
                        navigate(`/${AppRoutes.account.root}/${AppRoutes.account.myAccountInformation}`)
                      }}
                    >
                      {isSmallScreen ? '' : 'Hesabım'}
                    </Button>
                  </Dropdown>
                )}
              </>
            ) : (
              <Button
                className="toolbar-auth-button"
                icon={<UserOutlined className="toolbar-icon" />}
                type="link"
                onClick={() => {
                  navigate(AppRoutes.auth)
                }}
              >
                {isSmallScreen ? 'Üyelik' : 'Üyelik'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Toolbar

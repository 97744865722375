import './Slider.scss'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Carousel from 'antd/es/carousel'
import SelectBranchModal from 'components/select-branch-modal/SelectBranchModal'
import useMediaQuery from 'hooks/useMediaQuery'
import React, { useRef, useState } from 'react'

interface Props {
  onClassSelected: (selectedClassId: number) => void
  banner: any
  okulId:number
}

const Slider: React.FC<Props> = ({ onClassSelected, banner,okulId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const isMobile = useMediaQuery(768)
  var images = [...banner]
  images.sort((a: any, b: any) => a.sira && b.sira && a.sira - b.sira)
  const ref: any = useRef()

  return (
    <>
      <div className="slider">
        <Carousel dots={false} ref={ref} swipeToSlide draggable autoplay autoplaySpeed={4000}>
          {images.map((img: any) => (
            <div key={img.id} className="relative-container">
              <div>
                <Button
                  className="absolute-left"
                  type="text"
                  onClick={() => {
                    ref.current.prev()
                  }}
                >
                  <LeftOutlined className="btn-icon" />
                </Button>

                <img
                  onClick={() => {
                    setModalOpen(true)
                  }}
                  src={isMobile ? img.resim_mobil : img.resim}
                  className="banner-image"
                  alt=""
                />

                <Button
                  className="absolute-right"
                  type="text"
                  onClick={() => {
                    ref.current.next()
                  }}
                >
                  <RightOutlined className="btn-icon" />
                </Button>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <SelectBranchModal onClassSelected={onClassSelected} modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  )
}

export default Slider

import './SchoolClosePage.scss'

import { Modal } from 'antd'
import CenteredLayout from 'components/centered-layout/CenteredLayout'
import { Okul } from 'gql/StaticTypes'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useSinifUrunleriQuery } from '../../gql/GeneratedGraphqlTypes'

interface SchoolClosePageProps {
  okul: Okul
}

function SchoolClosePage({ okul }: SchoolClosePageProps) {
  const { classId } = useParams()
  let sinif = parseInt(classId!)
  const { data } = useSinifUrunleriQuery({
    variables: {
      slug: okul!.slug!,
      sinif_id: sinif! || 1,
    },
  })

  console.log(data)
  const [isModalVisible, setIsModalVisible] = useState(true)

  const showModal = () => {
    setIsModalVisible(true)
  }

  useEffect(() => {
    showModal()
  }, [])

  return (
    <CenteredLayout className="modal-ayar">
      {okul.uyari && (
        <Modal title="İhtiyaç listelerinizin satışı henüz başlatılmamıştır." open={isModalVisible} className="custom-modal">
          <p className="okul-yazi">{okul.uyari}</p>
        </Modal>
      )}
      {data?.okul?.sinif?.kapali_uyarisi && (
        <Modal title="Sınıfımız Henüz Satışa Açılmamıştır!" open={isModalVisible} className="custom-modal">
          <p className="okul-yazi">{data?.okul.sinif.kapali_uyarisi}</p>
        </Modal>
      )}
    </CenteredLayout>
  )
}

export default SchoolClosePage

import './AddAddressForm.scss'

import { Button, Col, Form, Input, notification, Row, Select } from 'antd'
import { AppRoutes } from 'App'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useAdresEkleMutation,
  useIlcelerQuery,
  useIllerQuery,
  useMahallelerQuery,
} from '../../gql/GeneratedGraphqlTypes'

const AddAddressForm = () => {
  const navigate = useNavigate()
  const [deger, setDeger] = useState(0)
  const [ilcedeger, setIlceDeger] = useState(0)
  const [adresEkleMutation] = useAdresEkleMutation()

  const onFinish = async (values: any) => {
    try {
      await adresEkleMutation({
        variables: {
          adres: values.adres,
          adres_tipi: 'T',
          baslik: values.baslik,
          faks: '',
          firma: '',
          gsm: values.gsm,
          il_id: values.il_id,
          ilce_id: values.ilce_id,
          mahalle_id: values.mahalle_id,
          isim: values.isim,
          kurumsal_adres_mi: false,
          posta_kodu: values.posta_kodu,
          tel: values.cep_telefonu,
          vergi_dairesi: '',
          vergi_no: '',
        },
      })
      notification.open({
        message: 'Başarılı',
        description: 'Adres başarılı bir şekilde eklendi.',
        type: 'success',
        placement: 'topRight',
      })
      navigate(`/${AppRoutes.account.root}/${AppRoutes.account.addresses}`)
      return
    } catch (error) {
      notification.open({
        message: 'Hata',
        description: 'Sistemsel bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
        type: 'error',
        placement: 'topRight',
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const { data: admData } = useIllerQuery({
    onError: (error) => {
      console.log('erro', error)
    },
    onCompleted: (data) => {
      console.log(data)
    },
  })

  const { data: admilceData } = useIlcelerQuery({
    onError: (error) => {
      console.log('erro', error)
    },
    onCompleted: (data) => {
      console.log(data)
    },
    variables: { il_id: deger },
  })

  const { data: admmahalleData } = useMahallelerQuery({
    onError: (error) => {
      console.log('erro', error)
    },
    onCompleted: (data) => {
      console.log(data)
    },
    variables: { ilce_id: ilcedeger },
  })

  return (
    <div className="addresses-page-add">
      <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Başlık"
              name="baslik"
              rules={[{ required: true, warningOnly: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="İsim"
              name="isim"
              rules={[{ required: true, warningOnly: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="İl"
              name="il_id"
              rules={[{ required: true, warningOnly: true, type: 'number', message: 'Bu alan zorunlu!' }]}
            >
              <Select
                showSearch
                onChange={(e) => setDeger(e)}
                className=""
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={admData?.iller.map((user) => ({ label: user?.isim?.toLowerCase(), value: user?.id }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="İlçe"
              name="ilce_id"
              rules={[{ required: true, warningOnly: true, type: 'number', message: 'Bu alan zorunlu!' }]}
            >
              <Select
                showSearch
                className=""
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(e) => setIlceDeger(e)}
                options={admilceData?.ilceler.map((user) => ({ label: user?.isim?.toLowerCase(), value: user?.id }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} wrap={true}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Mahalle"
              name="mahalle_id"
              rules={[{ required: true, warningOnly: true, type: 'number', message: 'Bu alan zorunlu!' }]}
            >
              <Select
                showSearch
                className=""
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={admmahalleData?.mahalleler.map((user) => ({
                  label: user?.isim?.toLowerCase(),
                  value: user?.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Telefon"
              name="cep_telefonu"
              rules={[
                {
                  required: true,
                  message: 'Bu alan zorunlu!',
                },
                {
                  max: 11, // Maksimum karakter sınırı, verilen örnekte 11 karakterdir
                  message: 'Telefon numarası 11 karakterden fazla olmamalıdır',
                },
              ]}
            >
              <Input
                placeholder="5xx xxx xx xx" // İlgili formatı kullanıcıya göstermek için
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Gsm"
              name="gsm"
              rules={[
                {
                  required: true,
                  message: 'Bu alan zorunlu!',
                },
                {
                  max: 11, // Maksimum karakter sınırı, verilen örnekte 11 karakterdir
                  message: 'GSM 11 karakterden fazla olmamalıdır',
                },
              ]}
            >
              <Input placeholder="5xx xxx xx xx" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Posta Kodu"
              name="posta_kodu"
              rules={[
                { required: true, warningOnly: true, type: 'string', message: 'Bu alan zorunlu!' },
                {
                  max: 5, // Maksimum karakter sınırı, verilen örnekte 5 karakterdir
                  message: 'Posta Kodu 5 karakterden fazla olmamalıdır',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24}>
            <Form.Item
              label="Adres"
              name="adres"
              rules={[{ required: true, warningOnly: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button className="button-new-color-1" type="primary" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AddAddressForm

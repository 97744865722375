import './AccountMenu.scss'

import { LogoutOutlined, RightOutlined } from '@ant-design/icons/lib/icons'
import { Button, Space } from 'antd'
import { AppRoutes } from 'App'
import AppContext, { AppContextType } from 'context/AppContext'
import { useContext, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { DEFAULT_API_TOKEN } from 'utils/const'
import { clearLoginSession } from 'utils/helpers'
import { AuthState } from 'utils/types'

import CommonDrawer from '../drawer/CommonDrawer'
import { AccountMenuItem } from './types'

interface Props {
  activeItem: any
  setActiveItem: any
}
export const AccountMenuItems: AccountMenuItem[] = [
  {
    title: 'Hesap Bilgileri',
    icon: <RightOutlined />,
    onClick: (navigate: NavigateFunction) => {
      navigate(`/${AppRoutes.account.root}/${AppRoutes.account.myAccountInformation}`)
    },
  },
  {
    title: 'Adreslerim',
    icon: <RightOutlined />,
    onClick: (navigate: NavigateFunction) => {
      navigate(`/${AppRoutes.account.root}/${AppRoutes.account.addresses}`)
    },
  },
  {
    title: 'Siparişlerim',
    icon: <RightOutlined />,
    onClick: (navigate: NavigateFunction) => {
      navigate(`/${AppRoutes.account.root}/${AppRoutes.account.orders}`)
    },
  },
  {
    title: 'Şifre Değiştirme',
    icon: <RightOutlined />,
    onClick: (navigate: NavigateFunction) => {
      navigate(`/${AppRoutes.account.root}/${AppRoutes.account.changePassword}`)
    },
  },
  {
    title: 'Çıkış yap',
    icon: <LogoutOutlined />,
    onClick: (navigate: NavigateFunction, appContext: AppContextType) => {
      clearLoginSession()
      appContext.setToken(DEFAULT_API_TOKEN)
      appContext.setAuthState(AuthState.LOGGED_OUT)
      navigate('/')
    },
  },
]

const AccountMenu = ({ activeItem, setActiveItem }: Props) => {
  const appContext = useContext(AppContext)
  const navigate = useNavigate()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 570px)') // Adjust breakpoint as needed Todo: Hook
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setIsSmallScreen(event.matches)
    }

    setIsSmallScreen(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onItemClick = (item: AccountMenuItem) => {
    setActiveItem(item.title)
    item.onClick(navigate, appContext)
    closeDrawer()
  }
  const menuItems = AccountMenuItems.map((item, i) => (
    <Button
      key={i}
      icon={item.icon}
      onClick={() => onItemClick(item)}
      className={`menu-button ${activeItem === item.title ? 'active' : ''}`}
      type={'ghost'}
    >
      {item.title}
    </Button>
  ))

  return (
    <div className="account-menu">
      {isSmallScreen ? (
        <>
          <CommonDrawer title="HESAP BİLGİLERİM" isVisible={drawerVisible} onClose={closeDrawer} items={menuItems} />
        </>
      ) : (
        <Space direction={'vertical'}>{menuItems}</Space>
      )}
    </div>
  )
}

export default AccountMenu

import './CrossProductsDetailPage.scss'

import {Button, InputNumber, message, notification} from 'antd'
import Spinner from 'components/spinner/Spinner'
import {useContext, useState} from 'react'
import {useParams} from 'react-router-dom'

import CrossProductsCarousel from '../../components/cross-products/crossProductsCarousel'
import AppContext from '../../context/AppContext'
import {CaprazUrunFiltre, useCarprazUrunlerQuery, useSepetUrunEkleMutation} from '../../gql/GeneratedGraphqlTypes'

const CrossProductDetailPage = () => {
    const {slug} = useContext(AppContext)
    const [messageApi, contextHolder] = message.useMessage()
    const {urunId} = useParams()
    const [sepetUrunEkleMutation, {loading}] = useSepetUrunEkleMutation()
    const [minTempPrice, setMinTempPrice] = useState(1)

    let queryVariables = {
        slug: slug!,
        filtre: {urunId: urunId} as CaprazUrunFiltre, // filtre özelliğini boş bir nesne olarak başlat
    }

    const {data, error} = useCarprazUrunlerQuery({
        variables: queryVariables,
    })

    if (error) {
        messageApi.error(`${error}`, 2)
    }

    if (!data) {
        return null
    }

    var filteredData = data.okul?.urunler?.capraz_urunler?.find((urun) => urun?.urun?.id === parseInt(urunId!)) ?? null

    //TODO:Carousel yapısı düzenlenencek
    const handleMinPriceChangeDetail = (value: number | null) => {
        setMinTempPrice(value ?? 0)
    }

    const handleAddToCartDetail = async (productId: number, minTempPrice: number) => {
        try {
            await sepetUrunEkleMutation({
                variables: {
                    okulSlug: slug!,
                    urun: {urun_id: productId!, adet: minTempPrice},
                },
            })
            notification.open({
                message: 'Başarılı',
                description: 'Ürün başarılı bir şekilde sepete eklendi.',
                type: 'success',
            })
        } catch (error) {
            messageApi.error(`${error}`, 6)
        }
    }

    if (loading || !filteredData) {
        return <Spinner showSpinner={loading} color="red"/>
    }
    const aciklama = filteredData.urun!.aciklamalar
    const metin = aciklama!.replace(/^<p>|<\/p>$/g, '')

    function escapeHTML(html: any) {
        return html.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    }

    return (
        <div className="crossproductsdetailpage">
            {contextHolder}
            <div className="card-container">
                <div className="card-image">
                    <CrossProductsCarousel veri={filteredData}/>
                </div>
                <div>
                    <h1 className="product-title">{filteredData.urun?.isim}</h1>
                    <div>
                        <div className="card-price">
                            {parseInt(filteredData.indirimli_birim_fiyat!) <= parseInt(filteredData.indirimsiz_birim_fiyat!) ?
                                <div className="price">
                                  <span className="price-header">Satış Fiyatı:</span>{' '}
                                  <span
                                      className="price-indirimsiz">{filteredData.indirimsiz_birim_fiyat} &#8378;</span>
                                </div>
                                : null}
                          <div className="price">
                            <span className="price-header">Size Özel :</span>{' '}
                            <span className="price-indirimli">{filteredData.indirimli_birim_fiyat} &#8378;</span>
                          </div>
                        </div>

                        <div className="card-btn-container">
                            <InputNumber min={1} value={minTempPrice} onChange={handleMinPriceChangeDetail}/>
                            <Button type="primary"
                                    onClick={() => handleAddToCartDetail(parseInt(urunId ?? '0'), minTempPrice)}>
                                Sepete Ekle
                            </Button>
                        </div>
                        {metin && (
                            <div className="card-btn-container-aciklama">
                                <p className="color-aciklama" dangerouslySetInnerHTML={{__html: escapeHTML(metin)}}/>
                            </div>
                        )}
                    </div>
                    <hr style={{marginTop: '40px'}}/>
                </div>
            </div>
        </div>
    )
}

export default CrossProductDetailPage

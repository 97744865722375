import './Banner.scss'

import { Link, useNavigate } from 'react-router-dom'

import { AppRoutes } from '../../App'

interface Props {
  height?: number
  image: string
  kategoriId: number
  value: number
}

const Banner: React.FC<Props> = (props) => {
  const { height, image, kategoriId, value } = props
  const navigate = useNavigate()

  return (
    <>
      {value === 1 ? (
        <Link
          to={`${AppRoutes.crossproduct}/${kategoriId}`}
          style={{ height: height ? height : 'auto' }}
          className="banner"
        >
          <img
            src={image}
            alt="banner"
            style={{ objectPosition: 'left' }}
            onClick={() => navigate(`${AppRoutes.crossproduct}/${kategoriId}`)}
          />
        </Link>
      ) : (
        <div className="banner" style={{ height: height ? height : 'auto' }}>
          <img src={image} alt="banner" style={{ objectPosition: 'left' }} />
        </div>
      )}
    </>
  )
}

export default Banner

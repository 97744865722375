import { useEffect, useState } from 'react'
import { appVersion } from 'utils/helpers'

interface CacheBusterProps {
  children: JSX.Element
  loadingView?: JSX.Element
  onLatestAppVersion?: (latestAppVersion: string) => void
}

/*
 * CacheBuster is a component that will force a reload of the page when the app version changes.
 * This is useful when you want to force a reload of the page when you deploy a new version of the app.
 *
 * Usage:
 * Version in package.json should be incremented when you deploy a new version of the app.
 * Then just wrap your app in the CacheBuster component.
 */
export const CacheBuster: React.FC<CacheBusterProps> = (props) => {
  const { children, loadingView, onLatestAppVersion } = props
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const refreshCacheAndReload = () => {
      if (window.caches) {
        window.caches.keys().then(function (names) {
          for (let name of names) caches.delete(name)
        })
      }
      window.location.reload()
    }

    const isMetaVersionNewer = (metaVersion: string, packageVersion: string) => {
      const versionsA = metaVersion.split(/\./g)
      const versionsB = packageVersion.split(/\./g)

      while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())
        const b = Number(versionsB.shift())
        if (a === b) {
          continue
        }
        return a > b || isNaN(b)
      }
      return false
    }

    const checkVersion = async () => {
      const response = await fetch('/v1/meta.json')
      const meta = await response.json()
      const metaVersion = meta.version
      const packageVersion = appVersion
      if (onLatestAppVersion) {
        onLatestAppVersion(metaVersion)
      }
      //We check for last attempt to refresh cache and reload version, so we don't refresh cache and reload every time
      const lastRefreshVersion = localStorage.getItem('last_refresh_version') || ''
      if (isMetaVersionNewer(metaVersion, packageVersion) && lastRefreshVersion !== metaVersion) {
        localStorage.setItem('last_refresh_version', metaVersion)
        refreshCacheAndReload()
      } else {
        setLoading(false)
      }
    }

    checkVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    if (loadingView) {
      return loadingView
    }
    return null
  }

  return children
}

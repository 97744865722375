import './ChangePasswordForm.scss'

import { Button, Col, Form, Input, notification, Row } from 'antd'
import Spinner from 'components/spinner/Spinner'
import AppContext from 'context/AppContext'
import { useSifreGuncelleMutation } from 'gql/GeneratedGraphqlTypes'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_API_TOKEN } from 'utils/const'
import { clearLoginSession } from 'utils/helpers'
import { AuthState } from 'utils/types'

const ChangePasswordForm = () => {
  const [sifreGuncelleMutation, { loading }] = useSifreGuncelleMutation()
  const navigate = useNavigate()
  const appContext = useContext(AppContext)

  const onFinish = async (values: any) => {
    if (values.password !== values.password_confirm) {
      notification.open({
        message: 'Hata',
        description: "Lütfen 'Şifre' ve 'Şifre Onayı' alanlarının aynı olduğundan emin olun.",
        type: 'error',
        placement: 'topRight',
      })
      return
    }
    try {
      await sifreGuncelleMutation({
        variables: { new_password: values.password_confirm },
      })
      notification.open({
        message: 'Başarılı',
        description: 'Şifre değiştirme işlemi başarılı...',
        type: 'success',
        placement: 'topRight',
      })

      clearLoginSession()
      appContext.setToken(DEFAULT_API_TOKEN)
      appContext.setAuthState(AuthState.LOGGED_OUT)
      navigate('/')
    } catch (error: any) {
      let err_message = error.message
      if (err_message === null) {
        err_message = 'Sistemsel bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
      }
      notification.open({
        message: 'Hata',
        description: err_message,
        type: 'error',
        placement: 'topRight',
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className="change-password">
      <Form layout="vertical" autoComplete="off" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Spinner showSpinner={loading} color="red" />
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Şifre"
              name="password"
              rules={[{ required: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Şifre Onayı"
              name="password_confirm"
              rules={[{ required: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" className="change-password-buton" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ChangePasswordForm

import './GroupProductsModal.scss'

import { Button, Carousel, Col, Image, Modal, Row, Table } from 'antd'
import AppContext from 'context/AppContext'
import {Scalars, SinifUrunu} from 'gql/GeneratedGraphqlTypes'
import {Maybe} from "graphql/jsutils/Maybe";
import useMediaQuery from 'hooks/useMediaQuery'
import { SecilenUrun } from 'pages/class-products/ClassProductsPage'
import { useContext, useEffect, useRef, useState } from 'react'

interface GroupProductsTableProps {
  products: any
  modelVisible: any
  setModalVisible: any
  selectedProducts: any
  setSelectedProducts: any
  cokluSatis?: Boolean
}

const GroupProductsModalTekstil = ({
  products,
  modelVisible,
  setModalVisible,
  selectedProducts,
  setSelectedProducts,
  cokluSatis
}: GroupProductsTableProps) => {
  // Model açıldığında seçilen grubun id'si.
  const isMobileDimen = useMediaQuery(700)
  const isSmallLaptopAndTabletDimen = useMediaQuery(1200)
  const { okul } = useContext(AppContext)
  const [selectedProductImages, setselectedProductImages] = useState(products[0] ? products[0].urun.grup.resimler : '')
  const [selectedRow, setSelectedRow] = useState<SinifUrunu[]>([])
  const ref: any = useRef()

  useEffect(() => {
    setSelectedRow([])
  }, [modelVisible])

  // products her değiştiğinde ürünlerin grub resminide değiştir.
  useEffect(() => {
    if (products[0]?.urun?.grup?.resimler && selectedProductImages !== products[0].urun.grup.resimler) {
      setselectedProductImages(products[0].urun.grup.resimler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);


  // Ürünün içerisinde bilgiler array'inde gelen datada
  // ürünlere özel bilgiler olduğu için, kolonları dinamik şekilde oluşturuyoruz.
  const dynamicColumns = products[0]?.urun?.bilgiler ? Object.keys(products[0].urun.bilgiler).map((key) => ({
    title: products[0].urun.bilgiler[key]?.isim || '',
    dataIndex: '',
    key,
    render: (urun: SinifUrunu) => {
      let temp = '';
      const className = okul?.stok_bitince_satisa_kapat === false && urun.stok! <= 0 ? 'text-style' : '';
      urun?.urun?.bilgiler?.forEach((element: any) => {
        if (element?.isim === products[0].urun.bilgiler[key]?.isim) {
          temp = element?.deger || '';
        }
      });
      return <span className={className}>{temp}</span>;
    },
  })) : [];



  const rowSelection = {
    selectedRowKeys: selectedRow.map(
      (product: SinifUrunu) => `${product.ders?.id}-${product.urun?.grup?.id}-${product.urun_id}`,
    ),
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      // Seçilen bir radio elementinin 0. resmini selectedProductImages state'ine atıyoruz.
      setselectedProductImages(selectedRows[0].urun.resimler)
      setSelectedRow(selectedRows)
    },
    getCheckboxProps: (record: SinifUrunu) => ({
      // min_adet 0'dan büyük ise user checkbox'ı kaldıramaz
      disabled: okul?.stok_bitince_satisa_kapat === false && record.stok! <= 0,
    }),
  }

  // Seçilen ürünün birden fazla resmi var ise slider yok ise tek bir ürün resmi dönüyoruz.
  function getProductImageOrSlider(imagesArr: any) {
    if (imagesArr.length > 1) {
      return (
        <div>
          <Carousel dots={false} autoplay draggable ref={ref}>
            {imagesArr.map((image: any, index: any) => (
              <div key={`productImages-${index}`}>
                <Image src={image} style={{ width: '100%' }} />
              </div>
            ))}
          </Carousel>

          <div className="custom-indicators">
            {imagesArr.map((image: any, index: any) => (
              <div
                key={`productImagesDots-${index}`}
                className={'custom-indicator'}
                onClick={() => {
                  ref.current.goTo(index) // Tıklanıldığında ilgili resime gidecek indicator ref.
                }}
              >
                <img src={image} alt="" />
              </div>
            ))}
          </div>
        </div>
      )
    } else if (imagesArr.length === 0) {
      return <p>Resim bulunamadı</p>
    }
    return (
      <div>
        <Image src={selectedProductImages[0]} style={{ width: '100%' }} />
      </div>
    )
  }
  const convertResimler = (resimler?: ReadonlyArray<Maybe<Scalars['String']>>): string[] => {
    if (!resimler) return [];
    return resimler.map(resim => resim || '');
  };

  const handleOk = () => {
    setModalVisible(false);
    if(cokluSatis) {
      const newSelectedProducts: SecilenUrun[] = [...selectedProducts];

      selectedRow.forEach((selectedGroupProduct) => {
        if (!selectedGroupProduct) {
          return;
        }

        const existingProductIndex = newSelectedProducts.findIndex(
            (product: SecilenUrun) => product.urunId === selectedGroupProduct?.urun?.id
        );

        if (existingProductIndex > -1) {
          // Mevcut ürün varsa adetini artır
          newSelectedProducts[existingProductIndex] = {
            ...newSelectedProducts[existingProductIndex],
            adet: newSelectedProducts[existingProductIndex].adet + selectedGroupProduct.adet!
          };
        } else {
          // Yeni ürün ekle
          const selectedBedenProperty = selectedGroupProduct?.urun?.bilgiler?.find((item: any) => item.isim === 'Beden');
          var seciliBeden = selectedBedenProperty
              ? selectedBedenProperty.deger
              : selectedGroupProduct?.urun?.bilgiler?.find((item: any) => item?.isim === 'Göğüs')?.deger;

          seciliBeden = seciliBeden ?? 'Seçim Yapıldı';

          const newProduct: SecilenUrun = {
            isim: selectedGroupProduct.urun?.isim!,
            kisa_aciklama: selectedGroupProduct.urun?.kisa_aciklama!,
            urunId: selectedGroupProduct.urun_id,
            dersId: selectedGroupProduct.ders?.id ?? 0,
            adet: selectedGroupProduct.adet ?? 1,
            minAdet: selectedGroupProduct.min_adet ?? 0,
            resimler: convertResimler(selectedGroupProduct.urun?.grup?.resimler!),
            indirimli_birim_fiyat: selectedGroupProduct.indirimli_birim_fiyat!.toString() ?? 0,
            indirimsiz_birim_fiyat: selectedGroupProduct.indirimsiz_birim_fiyat!.toString() ?? 0,
            fiyat: selectedGroupProduct.indirimsiz_birim_fiyat!,
            grupId: selectedGroupProduct.urun?.grup?.id ?? null,
            zorunlulukGrubu: selectedGroupProduct.zorunluluk_grubu ?? null,
            secilenBeden: seciliBeden!,
            etiket_bilgisi: null,
            kitapKaplama: null,
            grup: {
              id: selectedGroupProduct.urun?.grup?.id ?? 0, // Adjust the property names as per your actual types
              isim: selectedGroupProduct.urun?.grup?.isim ?? '',
              resimler: selectedGroupProduct.urun?.grup?.resimler ?? [],
              sira: selectedGroupProduct.urun?.grup?.sira ?? 0, // Assuming 'sira' is part of your UrunGrubu type
            },
          };

          newSelectedProducts.push(newProduct);
        }
      });

      setSelectedProducts(newSelectedProducts);
    }else{
      const selectedGroupProduct = selectedRow[0] // Seçilen ürün grubunu al
      if (!selectedGroupProduct) {
        // eğer seçilmedi ise geri dön.
        return
      }
      // Seçilen ürün grubunun grup ID'si ile aynı olan öğeyi bul
      const existingProduct = selectedProducts.find(
          (product: SecilenUrun) => product.grupId === selectedGroupProduct?.urun?.grup?.id,
      )
      if (existingProduct) {
        // Öğe seçili ise ürünü ve seçili bedeni güncelle
        const updatedProducts: SecilenUrun = selectedProducts.map((product: SecilenUrun) => {
          if (product?.grupId === selectedGroupProduct?.urun?.grup?.id) {
            const selectedBedenProperty = selectedGroupProduct?.urun?.bilgiler?.find((item: any) => item.isim === 'Beden')
            var seciliBeden = selectedBedenProperty
                ? selectedBedenProperty.deger
                : selectedGroupProduct?.urun?.bilgiler?.find((item: any) => item?.isim === 'Göğüs')?.deger

            seciliBeden = seciliBeden ?? 'Seçim Yapıldı'
            return {
              ...product,
              urunId: selectedGroupProduct.urun_id,
              adet: product.adet,
              secilenBeden: seciliBeden,
            }
          }
          return product
        })
        setSelectedProducts(updatedProducts)
      } else {
        // Öğe mevcut değil ise, yeni bir öğe ekle
        const selectedBedenProperty = selectedGroupProduct?.urun?.bilgiler?.find((item: any) => item.isim === 'Beden')
        var seciliBeden = selectedBedenProperty
            ? selectedBedenProperty.deger
            : selectedGroupProduct?.urun?.bilgiler?.find((item: any) => item?.isim === 'Göğüs')?.deger

        seciliBeden = seciliBeden ?? 'Seçim Yapıldı'

        const newProduct: SecilenUrun = {
          isim: selectedGroupProduct.urun?.isim!,
          kisa_aciklama: selectedGroupProduct.urun?.kisa_aciklama!,
          urunId: selectedGroupProduct.urun_id,
          dersId: selectedGroupProduct.ders?.id ?? 0,
          adet: selectedGroupProduct.adet ?? 1,
          minAdet: selectedGroupProduct.min_adet ?? 0,
          fiyat: selectedGroupProduct.indirimsiz_birim_fiyat!,
          resimler: convertResimler(selectedGroupProduct.urun?.grup?.resimler!),
          indirimli_birim_fiyat: selectedGroupProduct.indirimli_birim_fiyat!.toString() ?? 0,
          indirimsiz_birim_fiyat: selectedGroupProduct.indirimsiz_birim_fiyat!.toString() ?? 0,
          grupId: selectedGroupProduct.urun?.grup?.id ?? null,
          zorunlulukGrubu: selectedGroupProduct.zorunluluk_grubu ?? null,
          secilenBeden: seciliBeden!,
          grup: {
            id: selectedGroupProduct.urun?.grup?.id ?? 0, // Adjust the property names as per your actual types
            isim: selectedGroupProduct.urun?.grup?.isim ?? '',
            resimler: selectedGroupProduct.urun?.grup?.resimler ?? [],
            sira: selectedGroupProduct.urun?.grup?.sira ?? 0, // Assuming 'sira' is part of your UrunGrubu type
          },
          etiket_bilgisi: null,
          kitapKaplama: null,
        }

        setSelectedProducts([...selectedProducts, newProduct])
      }
    }
  };
  const handleCancel = () => {
    setModalVisible(false) // Modali kapat
  }
  try {
    var deger = products[0].urun.bilgiler.filter((x: any) => x.isim && x.isim === 'Ürün Kısa Açıklama')[0].deger
  } catch (e) {
    deger = ''
  }

  try {
    var stkId = products[0].urun.bilgiler.filter((x: any) => x.isim && x.isim === 'StkID')[0].deger
  } catch (e) {
    stkId = ''
  }

  return (
    <Modal
      className="group-product-modal"
      width={1100}
      title="Beden Seçimi"
      open={modelVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="custom-modal-wrapper"
      maskClosable={false}
      footer={
        isMobileDimen || isSmallLaptopAndTabletDimen
          ? null
          : [
              <Button onClick={handleCancel}>İptal</Button>,
              <Button type="primary" onClick={handleOk}>
                Tamam
              </Button>,
            ]
      }
    >
      <Row>
        {isMobileDimen && (
          <p>
            {deger} {stkId && ` StkID: ${stkId}`}
          </p>
        )}
        <Col xl={16} md={24} span={24}>
          <Table
            dataSource={products}
            columns={dynamicColumns}
            rowSelection={{
              type: cokluSatis ? 'checkbox' : 'radio',
              ...rowSelection,
            }}
            pagination={false}
            style={{ width: '100%' }}
            rowKey={(obj: SinifUrunu) => `${obj.ders?.id}-${obj.urun?.grup?.id}-${obj.urun_id}`}
          />
        </Col>
        {(isMobileDimen || isSmallLaptopAndTabletDimen) && (
          <div style={{ marginTop: '10px' }}>
            <Button onClick={handleCancel}>İptal</Button>
            <Button style={{ marginLeft: '8px' }} type="primary" onClick={handleOk}>
              Tamam
            </Button>
          </div>
        )}
        <Col xl={8} md={24} span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Col xl={24} md={10} sm={8} span={24}>
            {getProductImageOrSlider(selectedProductImages)}
          </Col>
        </Col>
      </Row>
    </Modal>
  )
}

export default GroupProductsModalTekstil

import './PaymentInfoCard.scss'

import { Button, Card } from 'antd'
import React from 'react'

import { Sepet } from '../../gql/GeneratedGraphqlTypes'

interface Props {
  data: Sepet
  onPurchase: () => void
}

const PaymentInfoCard = ({ data, onPurchase }: Props) => {
  if (!data) {
    return null
  }
  const array = data.urunler?.reduce((acc: any, obj: any) => {
    const key = obj.urun.id
    acc[key] = acc[key] || []
    acc[key].push(obj.indirimli_birim_fiyat * obj.adet)
    return acc
  }, {})

  var sum = 0
  for (let key in array) {
    var value = array[key]
    for (var i in value) {
      var deger = Number(value[i])
      sum += deger
    }
  }

  const array1 = data.urunler?.reduce((acc: any, obj: any) => {
    const key = obj.urun.id
    acc[key] = acc[key] || []
    acc[key].push((obj.indirimli_birim_fiyat - (obj.urun.kdv_yuzde / 100) * obj.indirimli_birim_fiyat) * obj.adet)
    return acc
  }, {})

  var sum1 = 0
  for (let key in array1) {
    var value1 = array1[key]
    for (var j in value1) {
      var deger1 = Number(value1[j])
      sum1 += deger1
    }
  }
  var sum_trunc = Math.trunc(sum * 100) / 100
  var sum_formatted = sum_trunc.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  var kdv_orani = sum - sum1
  var total = sum - kdv_orani
  total = parseFloat(total.toFixed(2))
  var sum_formatted_total = total.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  kdv_orani = parseFloat(kdv_orani.toFixed(2))

  const array2 = data.urunler?.reduce((aca: any, obj: any) => {
    const key = obj.urun.id
    aca[key] = aca[key] || []
    aca[key].push(obj.adet)
    return aca
  }, {})

  var toplam = 0
  for (let key in array2) {
    var value2 = array2[key]
    for (var y in value2) {
      var deger2 = Number(value2[y])
      toplam += deger2
    }
  }

  return (
    <div className="payment-info-card">
      <Card title={'Ödenecek Tutar'} className="card-header-konum">
        <div>
          <span className="boyut-header">{sum_formatted} &#8378;</span>
        </div>
        <div>
          <Button type={'primary'} className="sepeti-onayla-button" block onClick={onPurchase}>
            Sepeti Onayla
          </Button>
        </div>
        <div className="card-background">
          <p className="info-text-container">
            <span className="info-text-head">Sepet Tutarı :</span>
            <span className="info-text-value">{sum_formatted_total} &#8378;</span>
          </p>
          <p className="info-text-container">
            <span className="info-text-head">Toplam Adet :</span>
            <span className="info-text-value">{toplam}</span>
          </p>
          <p className="info-text-container">
            <span className="info-text-head">Kdv Tutarı : </span>
            <span className="info-text-value">{kdv_orani} &#8378;</span>
          </p>
          {/*<p className="info-text-container">*/}
          {/*  <span className="info-text-head">Kargo Tutarı :</span>*/}
          {/*  <span className="info-text-value">*/}
          {/*    <span className="text-cizgi">{kdv_orani} &#8378;</span>*/}
          {/*    <span className="bedavas-color">Bedava</span>*/}
          {/*  </span>*/}
          {/*</p>*/}
        </div>
      </Card>
    </div>
  )
}

export default PaymentInfoCard

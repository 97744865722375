import OrderTable2 from '../../../components/orders-table/OrderTable2'

const OrdersPage = () => {
  return (
    <div className="orders-page">
      <OrderTable2 />
    </div>
  )
}

export default OrdersPage

import { useEffect, useState } from 'react'

const useRemainingChars = (initialValue: any) => {
  const MAX_LENGTH = 14
  const [value, setValue] = useState(initialValue)
  const [remainingChars, setRemainingChars] = useState(MAX_LENGTH)

  useEffect(() => {
    setRemainingChars(MAX_LENGTH - value.toString().length)
  }, [value])

  return [value, setValue, remainingChars]
}

export default useRemainingChars

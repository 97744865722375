import './StudentInfo.scss'

import { Card, Col, Form, Input, Radio, RadioChangeEvent, Row } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'

import { Sepet } from '../../gql/GeneratedGraphqlTypes'
import { KIRTASIYE_KATEGRI_ID } from '../../utils/const'

interface Props {
  data: Sepet
  setIsBasketTableVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsBasketDeleteVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsDigital: React.Dispatch<React.SetStateAction<boolean>>
  setIsKirtasiye: React.Dispatch<React.SetStateAction<boolean>>
  studentInfo: {
    studentName: string
    studentSurname: string
    digital: string
    gender: number
  }
  setStudentInfo: Dispatch<
    SetStateAction<{
      studentName: string
      studentSurname: string
      digital: string
      gender: number
    }>
  >
}

const StudentInfo = ({
  data,
  setIsBasketTableVisible,
  setIsBasketDeleteVisible,
  setIsDigital,
  setIsKirtasiye,
  studentInfo,
  setStudentInfo,
}: Props) => {
  const [value, setValue] = useState<number>(0)
  const [form] = Form.useForm()
  const [isDigital] = useState(() => {
    if (data && data.urunler) {
      return data.urunler.some(
        (item: any) =>
          item.urun.bilgiler && item.urun.bilgiler.some((bilgi: any) => bilgi.isim === 'TC Gerektiren Ürün'),
      )
    }
    return false
  })

  const [isKirtasiye] = useState(() => {
    if (data && data.urunler) {
      return data.urunler.some((item: any) => {
        if (item?.urun?.kategori) {
          if (
            item.urun.kategori.id === KIRTASIYE_KATEGRI_ID ||
            item.urun.kategori.ust_kategori_id === KIRTASIYE_KATEGRI_ID
          ) {
            return true
          }
        }
        return false
      })
    }
    return false
  })

  if (isDigital) {
    setIsDigital(true)
  }

  if (isKirtasiye) {
    setIsKirtasiye(true)
  }
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
    setStudentInfo({
      ...studentInfo,
      gender: e.target.value,
    })
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudentInfo({
      ...studentInfo,
      studentName: e.target.value,
    })
  }
  const handleDigitalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudentInfo({
      ...studentInfo,
      digital: e.target.value,
    })
  }

  const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudentInfo({
      ...studentInfo,
      studentSurname: e.target.value,
    })
  }

  if (!data) {
    return null
  }

  return (
    <Card className="info-card-container">
      <p>{data.sinif?.isim}</p>
      <Row gutter={24}>
        <Col xs={24} md={24}>
          <p className="info-text">(Öğrenci ad ve soyad'ı eksiksiz giriniz.)</p>
        </Col>
        <Col xs={24} md={12}>
          <Input
            placeholder="Öğrenci Ad"
            value={studentInfo.studentName}
            defaultValue={studentInfo.studentName}
            onChange={handleNameChange}
          />
        </Col>
        <Col xs={24} md={12} className="ogrenci-soyad-input">
          <Input
            placeholder="Öğrenci Soyad"
            value={studentInfo.studentSurname}
            defaultValue={studentInfo.studentSurname}
            onChange={handleSurnameChange}
          />
        </Col>
      </Row>
      {isDigital && (
        <Row gutter={24} className="digital-card">
          <Col xs={24} md={24}>
            <p>Öğrenci T.C. Kimlik Numarası</p>
          </Col>
          <Col xs={24} md={24}>
            <p>
              T.C. Kimlik Numarası Dijital ürün ve bazı özel ürünlerin öğrenci adına tanımlanabilmesi için gereklidir.
            </p>
          </Col>
          <Col xs={24} md={24}>
            <Form form={form}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Bu alan zorunlu!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value.length === 11) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('Tc Kimlik numarası 11 karakter olmalıdır'))
                      }
                    },
                  }),
                ]}
              >
                <Input
                  value={studentInfo.digital}
                  defaultValue={studentInfo.studentSurname}
                  onChange={handleDigitalChange}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      {isKirtasiye && (
        <div className="gender-card">
          <Row gutter={24}>
            <Col md={24}>
              {/*ToDo: antdesign'da aradığım icon'ları bulamadım ama kız ve erkek icon'ları kullanılmalı*/}
              <Radio.Group onChange={onChange} value={value} defaultValue={null}>
                <Radio value={1}>Erkek</Radio>
                <Radio value={2}>Kız</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  )
}

export default StudentInfo

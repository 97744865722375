import { MutationFunction, MutationFunctionOptions } from '@apollo/client'
import { message } from 'antd'
import { LoginSession } from 'gql/GeneratedGraphqlTypes'

import packageJson from '../../package.json'

export const appVersion = packageJson.version

export const executeMutation = async <T extends MutationFunction<any, any>>(
  mutationFn: T,
  options: MutationFunctionOptions,
  successMessage?: string,
  errorMessage?: string,
): Promise<any | null> => {
  return new Promise<any | null>((resolve) => {
    mutationFn(options)
      .then((result) => {
        if (result.data) {
          //Success responses have 1 more level. Go one level deeper to retrieve actual data.
          const realData = Object.values(result.data)[0] as any

          if (!realData.success && realData.message) {
            throw new Error(realData.message)
          }

          if (successMessage) {
            //Show success message if overwritten.
            message.success(successMessage)
          } else if (realData.message) {
            //Show the server response success message if available.
            message.success(realData.message)
          }
          resolve(realData)
        }
        resolve(null)
      })
      .catch((error) => {
        console.error(error)

        if (errorMessage) {
          //Show error message if overwritten.
          message.error(errorMessage)
        } else if (error.message) {
          //Show the server response error message if available.
          message.error(error.message)
        }
        resolve(null)
      })
  })
}

export const saveLoginSession = (loginSession: LoginSession) => {
  localStorage.setItem('session', JSON.stringify(loginSession))
}

export const doesLoginSessionExist = () => {
  return !!localStorage.getItem('session')
}

export const getLoginSession = (): LoginSession | null => {
  const session = localStorage.getItem('session')
  return session ? (JSON.parse(session) as LoginSession) : null
}

export const clearLoginSession = () => {
  localStorage.removeItem('session')
}

export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const setPriceFormat = (value: string, quantity: number): string => {
  try {
    value = (parseFloat(value) * quantity).toString()
  } catch {}

  const splitArray = value.split('.')
  const integerPart = splitArray[0] || '0'
  const decimalPart = splitArray[1] || '00'
  const decimalPartPadded = decimalPart.padEnd(2, '0').substring(0, 2)
  const formattedValue = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return `${formattedValue},${decimalPartPadded}`
}

import './GroupProductsTab.scss'

import { ShoppingCartOutlined } from '@ant-design/icons'
import {Button, Image, InputNumber, notification} from 'antd'
// import { setPriceFormat } from 'utils/helpers'
import GroupProductsModal from 'components/class-group-product-modal/GroupProductsModal'
import { SinifUrunu } from 'gql/GeneratedGraphqlTypes'
import { SecilenUrun } from 'pages/class-products/ClassProductsPage'
import React, { useState } from 'react'


interface GroupProductsTabProps {
  products: any
  selectedProducts: any
  setSelectedProducts: any
  indirimliFiyatGosterilsinmi: Boolean
  cokluSatis: Boolean
}

const GroupProductsTab = ({ products, selectedProducts,cokluSatis, setSelectedProducts }: GroupProductsTabProps) => {
  // Grupları bir listeye alıyoruz. Grubun içerisindeki ürünler bir modalda gösterilecek.
  let gruplarArr: SinifUrunu[] = []
  products.forEach((element: SinifUrunu) => {
    const someResult = gruplarArr.some((item: SinifUrunu) => item.urun?.grup?.id === element?.urun?.grup?.id)
    if (!someResult) {
      gruplarArr.push(element)
    }
  })

  // Modalin açılmasını kontrol eder
  const [modalVisible, setModalVisible] = useState(false)
  // Model açıldığında seçilen grubun id'si.
  const [selectedGroupIdFromRow, setSelectedGroupIdFromRow] = useState(0)

  const showModal = (groupId: number) => {
    setModalVisible(true)
    setSelectedGroupIdFromRow(groupId)
  }

  const handleQuantityChange = (product: SinifUrunu, value: number) => {
    if (value < 1) {
      return
    }
    const prevRows = [...selectedProducts]
    const updatedProductIndex = prevRows.findIndex(
      (item) => item.grupId === product.urun?.grup?.id && item.dersId === product.ders?.id && item.grupId !== null,
    )

    if (updatedProductIndex !== -1) {
      // Seçili satır var ise adet değerini güncelle
      prevRows[updatedProductIndex].adet = value
      setSelectedProducts(prevRows)
    } else {
      // Seçili satır yok ise ekle
      setSelectedProducts([
        ...selectedProducts,
        {
          isim: product.urun?.isim ?? '',
          urunId: product.urun_id,
          dersId: product.ders?.id,
          adet: value,
          fiyat: product.indirimli_birim_fiyat, // Todo: Fiyat
          minAdet: product.min_adet ?? 0,
          grupId: product.urun?.grup?.id,
          zorunlulukGrubu: product.zorunluluk_grubu ?? 0,
          etiket_bilgisi: null,
          secilenBeden: null,
          kitapKaplama: product.urun?.kitap_kaplama ?? 0,
        } as SecilenUrun,
      ])
    }
    notification.open({
      message: 'Başarılı',
      description: 'Başarı ile güncellendi',
      type: 'success',
      duration: 1.5,
    })
  }

  const sepettenCikar = (product: SinifUrunu) => {
    const newArr = selectedProducts.filter(
      (seciliUrun: SecilenUrun) =>
        !(seciliUrun.dersId === product.ders?.id && seciliUrun.grupId === product.urun?.grup?.id),
    )

    setSelectedProducts(newArr)
  }
  const sortedProducts = gruplarArr.sort((a, b) => {
    return (a?.urun?.grup?.sira ?? Infinity) - (b?.urun?.grup?.sira ?? Infinity);
  });
  return (
      <>
        {/*//todo: burada gelen ürünün ders id sine göre farklı işlem yaptırılıyor. ders id 127 teyiti geldiğinde onun olma durumunda ekstra bir div koyarız*/}
        {products[0].ders.id === 127 ?
            <p className="outlet-style">{products[0].ders.isim}</p>
            : <p className="outlet-style1">{products[0].ders.isim}</p>
        }
        <div className="group-product-tab-container" key={`${products[0].urun.grup.id}_grup_table`}>
          <div className="card-base">
            <div className="card-container">
              {sortedProducts.map<JSX.Element>((item: SinifUrunu) => {
                var itemSecili = selectedProducts.some(
                    (prod: SecilenUrun) =>
                        prod.grupId === item.urun?.grup?.id && prod.dersId === item.ders?.id && prod.grupId !== null,
                )
                var itemSeciliCss = itemSecili ? {backgroundColor: '#44B365', color: 'white'} : {}
                const selectProduct = selectedProducts.find(
                    (prod: SecilenUrun) => prod?.grupId === item.urun?.grup?.id && prod.dersId === item?.ders?.id,
                )
                const seciliBeden = selectProduct?.secilenBeden
                var seciliBedenText = itemSecili ? seciliBeden : 'Beden Seçiniz'

                return (
                    <div className="card-item" key={`tekstil_urun_${item.urun?.grup?.id}`}>
                      <Image
                          className="card-image"
                          alt=""
                          src={item.urun?.grup?.resimler?.[0] ?? ''}
                      />
                      <h3 className="card-prod-name">{item.urun?.grup?.isim}</h3>
                      <p style={{color: 'red'}}>{item.urun?.kisa_aciklama}</p>
                      <div className="card-description">
                        <div className="card-amount">
                          {parseInt(item.indirimsiz_birim_fiyat!) <= parseInt(item.indirimli_birim_fiyat!) ? null : (
                              <span className="card-indirimsiz-fiyat">{item.indirimsiz_birim_fiyat} TL</span>
                          )}
                          <span className="card-indirimli-fiyat">{item.indirimli_birim_fiyat} TL</span>
                        </div>
                        <div className="card-adet-beden-btn">
                          <Button style={{fontSize: '0.89em'}} onClick={() => showModal(item.urun?.grup?.id ?? 0)}>
                            {seciliBedenText}
                          </Button>
                          {itemSecili && (
                              <>
                                {/* Todo: Max stok ayarlaması */}
                                <InputNumber
                                    style={{fontSize: '0.89em', marginTop: '2%'}}
                                    min={1}
                                    defaultValue={1}
                                    onChange={(value) => handleQuantityChange(item, value!)}
                                />
                              </>
                          )}
                        </div>
                      </div>
                      <div className="card-btn-container">
                        <Button
                            style={{borderRadius: '0px', ...itemSeciliCss}}
                            className="card-sepete-ekle-btn"
                            onClick={() => (itemSecili ? sepettenCikar(item) : showModal(item.urun?.grup?.id ?? 0))}
                        >
                          <ShoppingCartOutlined/>
                          <span className="ml-2">{itemSecili ? 'Sepete Eklendi' : 'Sepete Ekle'}</span>
                        </Button>
                      </div>
                    </div>
                )
              })}
            </div>
          </div>

          {selectedGroupIdFromRow !== 0 && (
              <GroupProductsModal
                  modelVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  cokluSatis={cokluSatis}
                  products={products.filter((item: any) => item.urun.grup.id === selectedGroupIdFromRow)}
              />
          )}
        </div>
      </>

  )
}
export default GroupProductsTab

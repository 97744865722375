import './EmptyBasket.scss'

import { RightOutlined, ShoppingCartOutlined } from '@ant-design/icons/lib/icons'
import { Button, Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

const EmptyBasket = () => {
  const navigate = useNavigate()
  return (
    <div className={'empty-basket'}>
      <Row justify={'center'}>
        <Col>
          <ShoppingCartOutlined className={'basket-size'} />
        </Col>
      </Row>

      <Row justify={'center'}>
        <Col>
          <p className={'empty-basket-text'}>Alışveriş Sepetiniz Boş</p>
        </Col>
      </Row>

      <Row justify={'center'}>
        <Col>
          <Button
            type={'primary'}
            className="alisveris-butonu"
            icon={<RightOutlined />}
            onClick={() => {
              navigate(`/`)
            }}
          >
            Alışverişe Başla
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default EmptyBasket

import './NotBoughtProducts.scss'

import { Alert, message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { useSiparisDetayQuery } from '../../gql/GeneratedGraphqlTypes'
import { NotBoughtProductsItem } from './types'

interface Props {
  siparis: string
}

const NotBoughtProducts = ({ siparis }: Props) => {
  const temp = parseInt(siparis)
  const [messageApi] = message.useMessage()
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  if (error) {
    messageApi.error(`${error}`, 2)
  }
  if (!data) {
    return null
  }
  console.log(data)
  const NotBoughtProductsItems: NotBoughtProductsItem[] = (data?.siparisDetay.satin_alinmayan_urunler || []).map(
    (urun, index) => {
      return {
        key: index.toString() || '',
        urun_ismi: urun?.urun_ismi || '',
        adet: typeof urun?.urun_adet === 'number' ? urun.urun_adet : parseFloat(urun?.urun_adet || '0'),
        urun_birim_fiyat:
          typeof urun?.urun_birim_fiyat === 'number'
            ? `${urun.urun_birim_fiyat} ₺`
            : `${parseFloat(urun?.urun_birim_fiyat || '0')} ₺`,
      }
    },
  )
  NotBoughtProductsItems.reverse()

  const columns: ColumnsType<NotBoughtProductsItem> = [
    {
      title: 'Ürün Adı',
      dataIndex: 'urun_ismi',
      key: 'urun_ismi',
      align: 'center',
    },
    {
      title: 'Adet',
      dataIndex: 'adet',
      key: 'adet',
      align: 'center',
    },
    {
      title: 'Birim Fiyat',
      dataIndex: 'urun_birim_fiyat',
      key: 'urun_birim_fiyat',
      align: 'center',
    },
  ]

  return (
    <>
      <div className="not-bought-products">
        <Alert
          className="custom-alert"
          description="Satın alınmayan ürünler listelenmektedir."
          type="success"
          closable
        />
        <br />
        <div>
          <Table columns={columns} dataSource={NotBoughtProductsItems} bordered={true} />
        </div>
      </div>
    </>
  )
}

export default NotBoughtProducts

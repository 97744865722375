import './AccountForm.scss'

import { Button, Col, Form, Input, notification, Row } from 'antd'
import Spinner from 'components/spinner/Spinner'
import { useHesapBilgilerimQuery, useProfilGuncelleMutation } from 'gql/GeneratedGraphqlTypes'

const AccountForm = () => {
  const { data, error, loading: queryLoading } = useHesapBilgilerimQuery()
  const [profilGuncelleMutation, { loading }] = useProfilGuncelleMutation()

  if (queryLoading) {
    return <Spinner showSpinner={queryLoading} color={'red'}></Spinner>
  }

  if (error) {
    console.log(error)
  }

  if (!data) {
    return null
  }
  const onFinish = async (values: any) => {
    try {
      await profilGuncelleMutation({
        variables: { isim: values.first_name, soyisim: values.last_name, email: '', cep_telefonu: values.numara },
      })

      notification.open({
        message: 'Başarılı',
        description: 'İşlem başarılı bir şekilde tamamlandı.',
        type: 'success',
        placement: 'topRight',
      })
      return
    } catch (error) {
      notification.open({
        message: 'Hata',
        description: 'Sistemsel bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
        type: 'error',
        placement: 'topRight',
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    notification.open({
      message: 'Hata',
      description: 'Sistemsel bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
      type: 'error',
      placement: 'topRight',
    })
  }

  return (
    <div className={'account-form'}>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          first_name: data.profil?.isim,
          last_name: data.profil?.soyisim,
          email: data.profil?.email,
          numara: data.profil?.cep_telefonu,
        }}
      >
        <Spinner showSpinner={loading} color="red" />
        {/* Todo: Okul rengi verilebilir. */}
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="İsim"
              name="first_name"
              rules={[{ required: true, warningOnly: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Soyisim"
              name="last_name"
              rules={[{ required: true, warningOnly: true, type: 'string', message: 'Bu alan zorunlu!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Cep Telefonu"
              name="numara"
              rules={[
                {
                  max: 14, // Maksimum karakter sınırı, verilen örnekte 14 karakterdir
                  message: 'Telefon numarası 11 karakterden fazla olmamalıdır',
                },
              ]}
            >
              <Input type="text" placeholder="5xxxxxxxxx" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Email" name="email">
              <Input disabled={true} type="email" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" className="button-new-color-1" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AccountForm

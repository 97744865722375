import './QuantitySelector.scss'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'

import { KITAP_KAPLAMA_URUN_ID } from '../../utils/const'

interface QuantitySelectorProps {
  data: {
    adet: number // Örneğin, adet özelliğinin bir sayı olduğunu varsayalım
    // Diğer data özellikleri buraya eklenmeli
  }
  kaplama: number
  handleDecrement: () => void
  handleIncrement: () => void
}

function QuantitySelector({ data, kaplama, handleDecrement, handleIncrement }: QuantitySelectorProps) {
  if (kaplama !== KITAP_KAPLAMA_URUN_ID) {
    return (
      <>
        <div className="quantity-selector">
          <div className="quantity-wrapper input-group">
            <button type="button" className="quantity-left-minus btn btn-number" onClick={handleDecrement}>
              <MinusOutlined />
            </button>
            <Input
              min={1}
              disabled
              className="input-number quantity-number"
              defaultValue={data.adet}
              value={data.adet}
            />
            <button type="button" className="quantity-right-plus btn btn-number" onClick={handleIncrement}>
              <PlusOutlined />
            </button>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="quantity-selector">
          <div className="quantity-wrapper input-group">
            <Input
              min={1}
              disabled
              className="input-number quantity-number left-allign"
              defaultValue={data.adet}
              value={data.adet}
            />
          </div>
        </div>
      </>
    )
  }
}

export default QuantitySelector

import { Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Dispatch, SetStateAction } from 'react'

import { Sepet } from '../../gql/GeneratedGraphqlTypes'
import StudentInfo from '../student-info/StudentInfo'
import { BasketItem } from './types'

interface Props {
  data: Sepet
  setIsBasketTableVisible: Dispatch<SetStateAction<boolean>>
  setIsBasketDeleteVisible: Dispatch<SetStateAction<boolean>>
  setIsDigital: Dispatch<SetStateAction<boolean>>
  setIsKirtasiye: Dispatch<SetStateAction<boolean>>
  studentInfo: {
    studentName: string
    studentSurname: string
    digital: string
    gender: number
  }
  setStudentInfo: Dispatch<
    SetStateAction<{
      studentName: string
      studentSurname: string
      digital: string
      gender: number
    }>
  >
}

const BasketInfoTable = ({
  data,
  setIsBasketTableVisible,
  setIsBasketDeleteVisible,
  setIsDigital,
  setIsKirtasiye,
  studentInfo,
  setStudentInfo,
}: Props) => {
  if (!data) {
    return null
  }
  const sepet = data
  if (!sepet || sepet.__typename !== 'Sepet') {
    return null
  }
  const array = data.urunler?.reduce((acc: any, obj: any) => {
    const key = obj.urun.id
    acc[key] = acc[key] || []
    acc[key].push(obj.indirimli_birim_fiyat * obj.adet)
    return acc
  }, {})

  var sum = 0
  for (let key in array) {
    var value = array[key]
    for (var i in value) {
      var deger = Number(value[i])
      sum += deger
    }
  }
  let truncatedSum = Math.trunc(sum * 100) / 100
  let result = truncatedSum.toString()
  const BasketItems: BasketItem[] = [
    {
      key: data.urunler?.find((urun) => !!urun?.id)?.id?.toString() || '',
      ogrenci_bilgisi: '',
      adet: data.urunler?.length!,
      tutar: result,
    },
  ]

  const columns: ColumnsType<BasketItem> = [
    {
      title: 'Öğrenci Bilgisi',
      dataIndex: 'ogrenci_bilgisi',
      key: 'ogrenci_bilgisi',
      align: 'center',
      render: (_, record) => (
        <Row>
          <Col style={{ flexBasis: '100%' }}>
            <StudentInfo
              data={sepet}
              setIsBasketTableVisible={setIsBasketTableVisible}
              setIsBasketDeleteVisible={setIsBasketDeleteVisible}
              setIsDigital={setIsDigital}
              setIsKirtasiye={setIsKirtasiye}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
            />
          </Col>
        </Row>
      ),
    },
  ]

  return <Table columns={columns} dataSource={BasketItems} pagination={false} />
}

export default BasketInfoTable

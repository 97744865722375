import { Okul } from 'gql/StaticTypes'
import React from 'react'
import { AuthState } from 'utils/types'

export interface AppContextType {
  slug: string | null
  authState: AuthState
  setAuthState: (authState: AuthState) => void
  setToken: (token: string) => void
  okul: Okul | null
  token: string
}

const AppContext = React.createContext<AppContextType>({
  authState: AuthState.LOGGED_OUT,
  setAuthState: () => {},
  slug: '',
  setToken: () => {},
  okul: null,
  token: '',
})

export default AppContext

import './App.scss'

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ConfigProvider, Empty, Layout } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import trTR from 'antd/es/locale/tr_TR'
import { AliasToken } from 'antd/es/theme/interface'
import FooterComponent from 'components/footer/Footer'
import LoginRequired from 'components/login-required/LoginRequired'
import Toolbar from 'components/toolbar/Toolbar'
import AppContext from 'context/AppContext'
import {
  OkulDocument,
  OkulQueryResult,
  RefreshTokenDocument,
  RefreshTokenMutationResult,
} from 'gql/GeneratedGraphqlTypes'
import introSpectionResult from 'gql/IntrospectionResults'
import { Okul } from 'gql/StaticTypes'
import ChangePasswordPage from 'pages/account-pages/change-password/ChangePasswordPage'
import MasterAccountPage from 'pages/account-pages/MasterAccountPage'
import MyAccountPage from 'pages/account-pages/my-account/MyAccountPage'
import OrdersPage from 'pages/account-pages/orders/OrdersPage'
import AuthPage from 'pages/auth/AuthPage'
import ClassProductsPage from 'pages/class-products/ClassProductsPage'
import HomePage from 'pages/home/HomePage'
import ResetPasswordPage from 'pages/reset-password/ResetPasswordPage'
import SchoolClosePage from 'pages/school-close-page/SchoolClosePage'
import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import { DEFAULT_API_TOKEN, DEFAULT_PRIMARY_COLOR } from 'utils/const'
import { doesLoginSessionExist, getLoginSession, saveLoginSession } from 'utils/helpers'
import { clearLoginSession } from 'utils/helpers'
import { AuthState } from 'utils/types'

import AddAddressPage from './pages/account-pages/addresses/AddAddressPage'
import AddressesPage from './pages/account-pages/addresses/AddressesPage'
import OrderDetailCheckPage from './pages/account-pages/order-details/OrderDetailCheckPage'
import OrderDetailPage from './pages/account-pages/order-details/OrderDetailPage'
import BasketPage from './pages/basket-pages/BasketPage'
import CrossProductDetailPage from './pages/cross-product-pages/CrossProductsDetailPage'
import CrossProductPage from './pages/cross-product-pages/CrossProductsPage'

export const AppRoutes = {
  auth: 'uyelik',
  account: {
    root: 'hesap',
    myAccountInformation: 'hesap-bilgilerim',
    addresses: 'adreslerim',
    addAddress: 'adres-ekle',
    updateAddress: 'adres-guncelle',
    orders: 'siparislerim',
    orderDetail: 'siparis-detay',
    orderDetailCheck: 'siparis-fatura',
    changePassword: 'sifre-degistir',
  },
  classProducts: 'sinif-urunleri',
  basket: 'sepet',
  crossproduct: 'kategori',
  crossproductdetail: 'urun',
  resetPassword: 'sifre-sifirlama',
  schoolClose: 'okul-kapali',
  classClose: 'sinif-kapali',
}

function App() {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null)
  const [apiToken, setApiToken] = useState<string>(DEFAULT_API_TOKEN) //Make this check expire date then decide to make it null or local storage token. perhaps with a function.
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOGGED_OUT)
  const [slug, setSlug] = useState<string | null>(null)
  const [okul, setOkul] = useState<Okul | null>(null)

  useEffect(() => {
    if (process.env.REACT_APP_SLUG) {
      setSlug(process.env.REACT_APP_SLUG)
    } else {
      const subdomain = window.location.hostname.split('.')[0]
      setSlug(subdomain)
    }

    //We can set api token here, public or
    //local storage item, at the same time
    //we can set initial auth state here too.
    if (doesLoginSessionExist()) {
      setApiToken(getLoginSession()!!.token!!)
    } else if (process.env.REACT_APP_AUTH_HEADER) {
      setApiToken(process.env.REACT_APP_AUTH_HEADER)
    }
  }, [])

  useEffect(() => {
    if (apiToken && slug) {
      const refreshLoginSession = async (client: ApolloClient<NormalizedCacheObject>) => {
        const { data } = (await client!!.mutate({ mutation: RefreshTokenDocument })) as RefreshTokenMutationResult
        if (data && data.refreshToken && data.refreshToken.token) {
          saveLoginSession(data.refreshToken)
          setApiToken(data.refreshToken.token)
          setAuthState(AuthState.LOGGED_IN)
        }
      }

      const httpLink = createHttpLink({
        uri: `${process.env.REACT_APP_API_URL}/graphql`,
        headers: {
          Authorization: apiToken,
        },
      })

      const errorLink = onError(({ graphQLErrors, networkError }) => {
        const includesUnauthorizedException = graphQLErrors?.some(
          (err: any) => err.errorType === 'UnauthorizedException',
        )
        if (includesUnauthorizedException) {
          clearLoginSession()
          setApiToken(DEFAULT_API_TOKEN)
          setAuthState(AuthState.LOGGED_OUT)
        }
      })

      const newApolloClient = new ApolloClient({
        cache: new InMemoryCache(introSpectionResult),
        connectToDevTools: true,
        link: from([errorLink, httpLink]),
      })

      let validToken = true
      if (doesLoginSessionExist()) {
        const expireAt = getLoginSession()!!.expire_at!! * 1000
        const now = Date.now()
        if (expireAt > now) {
          validToken = true
        } else {
          validToken = false
        }
      }

      if (slug && !okul && validToken) {
        const getOkul = async () => {
          const { data } = (await newApolloClient.query({
            query: OkulDocument,
            variables: { slug },
          })) as OkulQueryResult
          if (data && data.okul) {
            setOkul(data.okul)
          }
        }
        getOkul()
      }

      setApolloClient(newApolloClient)

      if (doesLoginSessionExist()) {
        const expireAt = getLoginSession()!!.expire_at!! * 1000
        const now = Date.now()
        if (expireAt <= now) {
          refreshLoginSession(newApolloClient)
        } else {
          setAuthState(AuthState.LOGGED_IN)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiToken, slug])

  if (!apolloClient || !okul) {
    return null
  }

  const getRoutes = () => {
    return (
      <>
        <Routes>
          <Route index element={<HomePage okul={okul} />} />
          <Route path={AppRoutes.auth} element={<AuthPage />} />
          <Route path={AppRoutes.account.root} element={<MasterAccountPage />}>
            <Route path={AppRoutes.account.myAccountInformation} element={<MyAccountPage />} />
            <Route path={`${AppRoutes.account.orderDetail}/:siparisId`} element={<OrderDetailPage />} />
            <Route path={`${AppRoutes.account.orderDetailCheck}/:siparisId`} element={<OrderDetailCheckPage />} />
            <Route path={AppRoutes.account.orders} element={<OrdersPage />} />
            <Route path={AppRoutes.account.changePassword} element={<ChangePasswordPage />} />
            <Route path={AppRoutes.account.addresses} element={<AddressesPage />} />
            <Route path={AppRoutes.account.addAddress} element={<AddAddressPage />} />
          </Route>
          <Route path={AppRoutes.resetPassword} element={<ResetPasswordPage />} />
          <Route path={AppRoutes.basket} element={<BasketPage />} />
          <Route
            path={`${AppRoutes.crossproduct}/:kategoriId`}
            element={
              <LoginRequired>
                <CrossProductPage />
              </LoginRequired>
            }
          />
          <Route
            path={`${AppRoutes.crossproductdetail}/:urunId`}
            element={
              <LoginRequired>
                <CrossProductDetailPage />
              </LoginRequired>
            }
          />
          <Route
            path={`${AppRoutes.classProducts}/:classId`}
            element={
              <LoginRequired>
                <ClassProductsPage />
              </LoginRequired>
            }
          />
          <Route path={`${AppRoutes.schoolClose}`} element={<SchoolClosePage okul={okul} />} />
          <Route path={`${AppRoutes.classClose}/:classId`} element={<SchoolClosePage okul={okul} />} />
        </Routes>
      </>
    )
  }

  const getThemeToken = (): Partial<AliasToken> => {
    const colorPrimary = okul.renk1 ?? DEFAULT_PRIMARY_COLOR

    return {
      colorPrimary,
      colorLink: colorPrimary,
      colorLinkHover: tinycolor(colorPrimary).darken(10).toString(),
      colorLinkActive: tinycolor(colorPrimary).lighten(20).toString(),
      colorIcon: colorPrimary,
      colorIconHover: tinycolor(colorPrimary).darken(10).toString(),
      controlItemBgActiveHover: tinycolor(colorPrimary).lighten(20).toString(),
    }
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider
        theme={{ token: getThemeToken() }}
        locale={trTR}
        renderEmpty={() => <Empty imageStyle={{ display: 'none' }} />}
      >
        <AppContext.Provider value={{ slug, authState, setAuthState, setToken: setApiToken, okul, token: apiToken }}>
          <BrowserRouter basename="/v1/">
            <div className="app">
              <Layout>
                <Header className="app-header">
                  <Toolbar logo={okul.logo} />
                </Header>
                <Content className="app-content">{getRoutes()}</Content>
                <Footer className="app-footer">
                  <FooterComponent />
                </Footer>
              </Layout>
            </div>
          </BrowserRouter>
        </AppContext.Provider>
      </ConfigProvider>
    </ApolloProvider>
  )
}

export default App

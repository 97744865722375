import './ClassProductsModal.scss'

import { Modal } from 'antd'
import { Button, Card, Col, Input, message, Row } from 'antd'
import { SecilenUrun } from 'pages/class-products/ClassProductsPage'
import { useEffect, useState } from 'react'
import { ETIKET_URUN_ID } from 'utils/const'

import defterImg from '../../images/defter1.png'
import useRemainingChars from '../class-products-table/useRemainingChars'

interface ClassProductsTableProps {
  modelVisible: any
  setModalVisible: any
  setSelectedProducts: any
  selectedProducts: SecilenUrun[]
}

const ClassProductsModal = ({
  modelVisible,
  setModalVisible,
  setSelectedProducts,
  selectedProducts,
}: ClassProductsTableProps) => {
  const [studentName, setStudentName, remainingCharsName] = useRemainingChars('')
  const [studentSurname, setStudentSurname, remainingCharsSurname] = useRemainingChars('')
  const [studentClass, setStudentClass] = useState('')
  const [studentNumber, setStudentNumber] = useState('')
  const [messageApi, contextHolder] = message.useMessage()
  const MAX_LENGTH = 14
  const etiketUrunId = ETIKET_URUN_ID

  useEffect(() => {
    if (selectedProducts.some((row: SecilenUrun) => row.urunId === 201156)) {
      setStudentName('')
      setStudentSurname('')
      setStudentClass('')
      setStudentNumber('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelVisible])

  const handleOk = () => {
    // Eğer öğrenci adı veya soyadı boş ise hata göster.
    if (studentName === '' || studentName === undefined || studentSurname === '' || studentSurname === undefined) {
      messageApi.warning('Lütfen öğrenci adı ve soyadını giriniz...', 4)
      return
    }

    // Seçili ürünleri güncelle
    const updatedProducts = selectedProducts.map((product: SecilenUrun) => {
      if (product.urunId === etiketUrunId) {
        return {
          ...product,
          etiket_bilgisi:
            studentName.toString() +
            '|' +
            studentSurname.toString() +
            '|' +
            studentNumber.toString() +
            '|' +
            studentClass.toString(),
        }
      }
      return product
    })

    // Güncellenmiş ürünleri setSelectedProducts ile set et
    setSelectedProducts(updatedProducts)
    setModalVisible(false)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleRefreshClick = () => {
    setStudentName('')
    setStudentSurname('')
    setStudentClass('')
    setStudentNumber('')
  }

  return (
    <Modal
      className="etiket-product-modal"
      width={1000}
      open={modelVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      {contextHolder}
      <div className="etiket-product">
        <Card bordered={false}>
          <Row gutter={[0, 24]}>
            <Col lg={13} md={24} sm={24} xs={24}>
              <Card title="Kitap-Defter Etiketi" bordered={false}>
                <Row gutter={24}>
                  <Col sm={24}>
                    <label htmlFor="ogrenci_ismi_input">Öğrenci Adı *</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input
                        required
                        maxLength={MAX_LENGTH}
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                        name="ogrenci_ismi"
                        id="ogrenci_ismi_input"
                      />
                      <Input
                        readOnly={true}
                        className="custom-input"
                        maxLength={3}
                        size={'small'}
                        value={remainingCharsName}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={20} className="mt-12">
                  <Col sm={24}>
                    <label htmlFor="ogrenci_soyismi_input">Öğrenci Soyadı *</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input
                        required
                        maxLength={MAX_LENGTH}
                        value={studentSurname}
                        onChange={(e) => setStudentSurname(e.target.value)}
                        name="ogrenci_soyismi"
                        id="ogrenci_soyismi_input"
                      />
                      <Input
                        readOnly={true}
                        className="form-control custom-input"
                        maxLength={MAX_LENGTH}
                        size={'small'}
                        name="ogrenci_soyismi"
                        id="ogrenci_soyismi_input"
                        value={remainingCharsSurname}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={20} className="mt-12">
                  <Col md={12}>
                    <label htmlFor="ogrenci_sinif_input">Sınıfı ve Şubesi (Belli ise)</label>
                    <Input
                      required
                      maxLength={5}
                      value={studentClass}
                      onChange={(e) => setStudentClass(e.target.value)}
                      name="ogrenci_sinif"
                      id="ogrenci_sinif_input"
                    />
                  </Col>
                  <Col md={12}>
                    <label htmlFor="ogrenci_numara_input">Okul Numarası (Belli ise)</label>
                    <Input
                      required
                      maxLength={6}
                      value={studentNumber}
                      onChange={(e) => setStudentNumber(e.target.value)}
                      name="ogrenci_numara"
                      id="ogrenci_numara_input"
                    />
                  </Col>
                </Row>
                <p>Etiket Boyutu: 3x4,5 cm</p>
                <Row gutter={20} className="mt-12">
                  <Col md={12}>
                    <Button type="default" onClick={() => handleRefreshClick()}>
                      Temizle
                    </Button>
                  </Col>
                </Row>
                <br />
                <p style={{ color: '#EA524D' }}>* Zorunlu alan</p>
              </Card>
            </Col>
            <Col lg={11} md={24} sm={24} xs={24}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <img alt="" style={{ width: '100%' }} src={defterImg} />
                  <div className="absolute student-info">
                    <div className="info-item">
                      <span className="bold" id="ogrenci_isim">
                        {studentName}
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="bold" id="ogrenci_soyisim">
                        {studentSurname}
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="bold" id="ogrenci_sube">
                        {studentClass}
                      </span>
                      {studentNumber !== '' && (
                        <span className="bold" id="ogrenci_numara">
                          {' / ' + studentNumber}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </Modal>
  )
}

export default ClassProductsModal

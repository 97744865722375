import './AddressesPage.scss'

import { Button, Col, Row, Space } from 'antd'
import { AppRoutes } from 'App'
import AddressCard from 'components/address-card/AddressCard'
import Spinner from 'components/spinner/Spinner'
import { useAdreslerimQuery } from 'gql/GeneratedGraphqlTypes'
import { useNavigate } from 'react-router-dom'

const AddressesPage = () => {
  const navigate = useNavigate()
  const { data, error, loading } = useAdreslerimQuery()

  if (loading) {
    return <Spinner showSpinner={loading} color={'red'}></Spinner>
  }

  if (error) {
    console.log(error)
  }

  if (!data) {
    return null
  }

  return (
    <div className="addresses-page">
      <Button
        className={'add-address-button'}
        type={'primary'}
        onClick={() => {
          navigate(`/${AppRoutes.account.root}/${AppRoutes.account.addAddress}`)
        }}
      >
        Yeni Adres Ekle
      </Button>
      <br />
      <Space direction={'horizontal'} wrap={true} align={'start'}>
        <Row gutter={[30, 10]}>
          {data.profil?.adresler?.map((adres) => (
            <Col key={adres?.id}>
              <AddressCard data={adres} />
            </Col>
          ))}
        </Row>
      </Space>
    </div>
  )
}

export default AddressesPage

import { AppRoutes } from 'App'
import AppContext from 'context/AppContext'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthState } from 'utils/types'

interface LoginRequiredProps {
  children: React.ReactNode
}

function LoginRequired({ children }: LoginRequiredProps) {
  const { authState } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (authState === AuthState.LOGGED_OUT) {
      navigate(`/${AppRoutes.auth}`, { state: { return_url: location.pathname } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return <>{children}</>
}

export default LoginRequired

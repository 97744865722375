import './AuthPage.scss'

import { Col, Radio, Row } from 'antd'
import Card from 'antd/es/card/Card'
import AuthForm from 'components/auth-form/AuthForm'
import { useState } from 'react'

import RegisterForm from '../../components/register-form/RegisterForm'

const AuthPage = () => {
  const [deger, setDeger] = useState('login')
  return (
    <div className="auth-page">
      <Row justify="center">
        <Col xs={{ span: 18 }} sm={{ span: 16 }} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Row className="auth-radio-button" justify={'center'}>
              <Col>
                <Radio.Group defaultValue={deger} buttonStyle="solid">
                  <Radio.Button onClick={() => setDeger('login')} className="radio-button-ayar" value="login">
                    Üye Girişi
                  </Radio.Button>
                  <Radio.Button onClick={() => setDeger('register')} className="radio-button-ayar" value="register">
                    Üye Ol
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            {deger === 'login' ? <AuthForm /> : <RegisterForm />}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AuthPage

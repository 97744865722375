import './HomePage.scss'

import { Col, Row } from 'antd'
import { AppRoutes } from 'App'
import Banner from 'components/banner/Banner'
import CenteredLayout from 'components/centered-layout/CenteredLayout'
import Slider from 'components/slider/Slider'
import { Okul } from 'gql/StaticTypes'
import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect } from 'react' // Import the Message component from antd
import { useNavigate } from 'react-router-dom'

interface HomePageProps {
  okul: Okul
}

const HomePage = ({ okul }: HomePageProps) => {
  const navigate = useNavigate()

  // Use useEffect to trigger Message.notice
  useEffect(() => {
    if (okul.active === false) {
      navigate(`/${AppRoutes.schoolClose}`)
    }
  })
  const emptyUrl = 'https://okulsepeti.s3.amazonaws.com/project_media/'
  const isMobile = useMediaQuery(768)
  return (
    <div className="homepage">
      {okul.bannerlari_goster && okul.bannerlar && okul.bannerlar?.length > 0 && (
        <Slider
          banner={okul.bannerlar}
          okulId={okul.id}
          onClassSelected={(selectedClassId) => {
            navigate(`/${AppRoutes.classProducts}/${selectedClassId}/`)
          }}
        />
      )}
      {okul.alan_resimlerini_goster && okul.alan_resimleri && (
        <CenteredLayout>
          <div className="banners">
            <Row gutter={[16, 16]}>
              {okul.alan_resimleri.some((item: any) => item.alan === 1) && okul.capraz_urun_satisi_varmi && (
                <Col xs={24}>
                  <Banner
                    kategoriId={2158}
                    value={1}
                    image={
                      isMobile && okul.alan_resimleri.find((item: any) => item.alan === 1)!.resim_mobil !== emptyUrl
                        ? okul.alan_resimleri.find((item: any) => item.alan === 1)!.resim_mobil!
                        : okul.alan_resimleri.find((item: any) => item.alan === 1)!.resim!
                    }
                  />
                </Col>
              )}
              {okul.alan_resimleri.some((item: any) => item.alan === 2) && (
                <Col xs={24} md={12}>
                  <Banner
                    kategoriId={1}
                    value={2}
                    image={
                      isMobile && okul.alan_resimleri.find((item: any) => item.alan === 2)!.resim_mobil !== emptyUrl
                        ? okul.alan_resimleri.find((item: any) => item.alan === 2)!.resim_mobil!
                        : okul.alan_resimleri.find((item: any) => item.alan === 2)!.resim!
                    }
                  />
                </Col>
              )}
              {okul.alan_resimleri.some((item: any) => item.alan === 3) && (
                <Col xs={24} md={12}>
                  <Banner
                    kategoriId={1}
                    value={2}
                    image={
                      isMobile && okul.alan_resimleri!.find((item: any) => item.alan === 3)!.resim_mobil !== emptyUrl
                        ? okul.alan_resimleri.find((item: any) => item.alan === 3)!.resim_mobil!
                        : okul.alan_resimleri.find((item: any) => item.alan === 3)!.resim!
                    }
                  />
                </Col>
              )}
              {okul.alan_resimleri.some((item: any) => item.alan === 4) && (
                <Col xs={24}>
                  <Banner
                    kategoriId={1}
                    value={2}
                    image={
                      isMobile && okul.alan_resimleri.find((item: any) => item.alan === 4)!.resim_mobil !== emptyUrl
                        ? okul.alan_resimleri.find((item: any) => item.alan === 4)!.resim_mobil!
                        : okul.alan_resimleri.find((item: any) => item.alan === 4)!.resim!
                    }
                  />
                </Col>
              )}
            </Row>
          </div>
        </CenteredLayout>
      )}
    </div>
  )
}

export default HomePage

import './ResetPasswordForm.scss'

import { UserSwitchOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, notification } from 'antd'
import AppContext from 'context/AppContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { DEV_BACKEND_DOMAIN } from 'utils/const'

type ResetPasswordFormData = {
  email: string
}

function ResetPasswordForm() {
  const { slug } = useContext(AppContext)
  const navigate = useNavigate()

  const onFinish = async (values: ResetPasswordFormData) => {
    fetch(`https://${slug}.${DEV_BACKEND_DOMAIN}/sifre-sifirlama/from-client/${values.email}`)
      .then((response) => {
        console.log(response)
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        console.log(data)
        notification.open({
          message: 'Başarılı',
          description: 'E-posta adresinize şifrenizi sıfırlamak için gerekli bilgiler gönderilmiştir.',
          type: 'success',
        })
        navigate('/')
      })
      .catch((error) => {
        console.log(error)
        notification.open({
          message: 'Hata',
          description: 'Mail gönderilemedi. Lütfen mailinizi kontrol ettikten sonra tekrar deneyiniz.',
          type: 'error',
        })
      })
  }

  return (
    <div className="reset-password-form">
      <Form layout="vertical" autoComplete="off" onFinish={onFinish}>
        <h1>
          <UserSwitchOutlined className="user-icon" /> Şifre Sıfırlama
        </h1>
        <Divider />
        <Form.Item
          label="E-posta:"
          name="email"
          rules={[{ type: 'email' }, { required: true, message: 'Lütfen Emailinizi Giriniz!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="forgot-link-btn" type="primary" htmlType="submit" block>
            Şifremi Sıfırla
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ResetPasswordForm

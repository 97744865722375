import './CenteredLayout.scss'

interface Props {
  children: React.ReactNode
  maxWidth?: number
  className?: string
}

export const DEFAULT_MAX_WIDTH = 1260

const CenteredLayout: React.FC<Props> = (props) => {
  const { children, maxWidth: width, className } = props

  const classNames = ['centered-layout', className].filter(Boolean).join(' ')

  return (
    <div className={classNames}>
      <div style={{ maxWidth: width ? width : DEFAULT_MAX_WIDTH }} className="centered-layout-content">
        {children}
      </div>
    </div>
  )
}

export default CenteredLayout

import { useEffect, useState } from 'react'
import { Triangle } from 'react-loader-spinner'
interface SpinnerProps {
  showSpinner: boolean
  color: string
}

const Spinner: React.FC<SpinnerProps> = ({ showSpinner, color }) => {
  const [visible, setVisible] = useState(showSpinner)

  useEffect(() => {
    if (showSpinner) {
      setVisible(true)
    } else {
      setTimeout(() => {
        setVisible(false)
      }, 100) // Todo: Time proptan alınabilir.
    }
  }, [showSpinner])

  return (
    <div>
      <Triangle
        height="120"
        width="120"
        color="#44B365"
        ariaLabel="circles-loading"
        wrapperStyle={{
          position: 'fixed',
          bottom: '0',
          right: '0',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '99',
        }}
        wrapperClass="spinner-wrapper"
        visible={visible}
      />
    </div>
  )
}

export default Spinner

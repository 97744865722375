import './OrderDetailPage.scss'

import { CaretRightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Collapse, message, Tabs, TabsProps } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CanceledProducts from '../../../components/cancelled-products/CancelledProducts'
import ExtraditionProducts from '../../../components/extradition-products/ExtraditionProducts'
import NotBoughtProducts from '../../../components/not-bought-products/NotBoughtProducts'
import PendingProducts from '../../../components/pending-products/PendingProducts'
import Products from '../../../components/products/Products'
import ReturnedProducts from '../../../components/returned-products/ReturnedProducts'
import SentProducts from '../../../components/sent-products/SentProducts'
import { useSiparisDetayQuery } from '../../../gql/GeneratedGraphqlTypes'
import useMediaQuery from '../../../hooks/useMediaQuery'

const { Panel } = Collapse
const OrderDetailPage = () => {
  const { siparisId } = useParams()
  const isMobile = useMediaQuery(768)
  const navigate = useNavigate()
  const veri = siparisId!.toString()
  const temp = parseInt(veri)
  const [messageApi] = message.useMessage()
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  useEffect(() => {
    if (error) {
      messageApi.error(`${error}`, 2)
    }
  }, [error, messageApi])
  console.log(data)
  if (!data) {
    return null
  }
  const degisken = data?.siparisDetay.satirlar?.filter((urun) => urun?.durum === 'İade Talebi').length
  const handleGeriDonClick = () => {
    navigate(-1) // Önceki sayfaya geri dönme
  }
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Ürünler`,
      children: <Products siparis={veri} />,
    },
    {
      key: '2',
      label: `Gönderilenler`,
      children: <SentProducts siparis={veri} />,
    },
    {
      key: '3',
      label: `Bekleyenler`,
      children: <PendingProducts siparis={veri} />,
    },
    {
      key: '4',
      label: `İade Talebi`,
      children: <ReturnedProducts siparis={veri} />,
    },
    {
      key: '6',
      label: `İptal Edilenler`,
      children: <CanceledProducts siparis={veri} />,
    },
    {
      key: '7',
      label: `Satın Alınmayanlar`,
      children: <NotBoughtProducts siparis={veri} />,
    },
  ]
  if (degisken > 0) {
    items.push({
      key: '5',
      label: `İade Edilenler`,
      children: <ExtraditionProducts siparis={veri} />,
    })
  }
  items.sort((a, b) => parseInt(a.key) - parseInt(b.key))
  return (
    <>
      <div className="orders-detail-page">
        <h3>
          <strong className="siparis-no-ayar">{siparisId}</strong>
          <span className="yazi-ayar">
            {' '}
            nolu siparişinize ait ürün ve gönderi bilgilerini aşağıdan kontrol edebilirsiniz.
          </span>
        </h3>
        <div>
          {isMobile ? (
            <Collapse
              accordion
              expandIconPosition="start"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined className={`custom-collapse-icon ${isActive ? 'active' : ''}`} />
              )}
            >
              {items.map((tab) => (
                <Panel header={tab.label} key={tab.key}>
                  {tab.children}
                </Panel>
              ))}
            </Collapse>
          ) : (
            <Tabs tabPosition="top" type="line" defaultActiveKey="1" items={items} />
          )}
        </div>
        <div>
          <Button type={'primary'} className="geri-don" icon={<LeftOutlined />} block onClick={handleGeriDonClick}>
            Geri Dön
          </Button>
        </div>
      </div>
    </>
  )
}

export default OrderDetailPage

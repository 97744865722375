import './Products.scss'

import { Alert, message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useEffect } from 'react'

import { useSiparisDetayQuery } from '../../gql/GeneratedGraphqlTypes'
import { ProductsItem } from './types'

interface Props {
  siparis: string
}

const rowClassName = (record: any, index: number) => {
  return record.durum === 'İptal' ? 'odd-row-product' : 'even-row-product'
}
const Products = ({ siparis }: Props) => {
  const temp = parseInt(siparis)
  console.log(temp)
  console.log('dds')
  const [messageApi, contextHolder] = message.useMessage()
  const { data, error } = useSiparisDetayQuery({
    variables: { siparis_id: temp },
  })
  useEffect(() => {
    if (error) {
      messageApi.error(`${error}`, 2)
    }
  }, [error, messageApi])
  console.log(data)
  if (!data) {
    return null
  }

  const ProductsItems: ProductsItem[] =
    data?.siparisDetay.satirlar?.map((urun) => {
      const adet = urun?.adet ?? 0 // Null veya undefined ise 0 kabul edelim.
      const kalan_adet = urun?.kalan_adet ?? 0 // Null veya undefined ise 0 kabul edelim.
      const birim_fiyat = parseFloat(urun?.birim_fiyat ?? '0') // Null veya undefined ise 0 kabul edelim ve string olarak gelen veriyi number'a çevirelim.
      const kdv_dahil_fiyat = parseFloat(urun?.birim_fiyat ?? '0') // Null veya undefined ise 0 kabul edelim ve string olarak gelen veriyi number'a çevirelim.

      return {
        key: urun?.id?.toString() || '', //Gerekli
        urun_isim: urun?.urun.isim || '',
        adet: adet,
        kalan_adet: kalan_adet,
        birim_fiyat: `${birim_fiyat} ₺`,
        kdv_dahil_fiyat: `${kdv_dahil_fiyat} ₺`,
        durum: urun?.durum || '',
      }
    }) || []

  const columns: ColumnsType<ProductsItem> = [
    {
      title: 'Ürün Adı',
      dataIndex: 'urun_isim',
      key: 'urun_isim',
      align: 'center',
    },
    {
      title: 'Adet',
      dataIndex: 'adet',
      key: 'adet',
      align: 'center',
    },
    {
      title: 'Kalan Adet',
      dataIndex: 'kalan_adet',
      key: 'kalan_adet',
      align: 'center',
    },
    {
      title: 'Fiyat',
      dataIndex: 'birim_fiyat',
      key: 'birim_fiyat',
      align: 'center',
    },
    {
      title: 'Toplam',
      dataIndex: 'kdv_dahil_fiyat',
      key: 'kdv_dahil_fiyat',
      align: 'center',
    },
    {
      title: 'Durum',
      dataIndex: 'durum',
      key: 'durum',
      align: 'center',
    },
  ]

  return (
    <>
      {contextHolder}
      <div className="products-res">
        <Alert
          className="custom-alert"
          description="Almış olduğunuz bütün ürünler listelenmektedir. "
          type="success"
          closable
        />
        <br />
        <div>
          <Table columns={columns} dataSource={ProductsItems} bordered={true} rowClassName={rowClassName} />
        </div>
      </div>
      <br />
    </>
  )
}

export default Products

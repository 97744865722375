import './BasketProductInfoTable.scss'

import {DeleteOutlined} from '@ant-design/icons'
import {Button, Table} from 'antd'
import {ColumnsType} from 'antd/es/table'
import useMediaQuery from 'hooks/useMediaQuery'
import React from 'react'

import {SepetSatiri} from '../../gql/GeneratedGraphqlTypes'
import {setPriceFormat} from '../../utils/helpers'
import QuantitySelector from '../quantity-selector/QuantitySelector'
import {BasketProductItem} from './types'

interface Props {
    data: SepetSatiri
    onChanges: (
        id: number,
        adet: number, // Change this to only accept a number type
    ) => void
    onDeleteChange: (id: number) => void
    isBasketTableVisible: boolean
}

const BasketProductInfoTable = ({data, onChanges, onDeleteChange, isBasketTableVisible}: Props) => {
    const isCustomTabletDimen = useMediaQuery(960)

    if (!data) {
        return null
    }

    const BasketProductItems: BasketProductItem[] = [
        {
            key: data.urun?.id?.toString() || '',
            urun_bilgisi: data.urun?.isim!,
            adet: data.adet,
            tutar: (parseInt(data.indirimli_birim_fiyat || '0') * data.adet).toString(), // Sayısal tipe dönüşüm yapılıyor
        },
    ]

    const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        onDeleteChange(data.id)
    }

    const handleIncrement = () => {
        const newValue = data.adet + 1 // Değeri bir artırın
        if (newValue <= 10) {
            // Değer 10'dan küçükse, onayla
            onChanges(data.id, newValue)
        }
    }

    // Eksi düğmesine tıklandığında çalışacak fonksiyon
    const handleDecrement = () => {
        const newValue = data.adet - 1 // Değeri bir azaltın
        if (newValue >= 1) {
            // Değer 1'den büyükse, onayla
            onChanges(data.id, newValue)
        }
    }

    var columns: ColumnsType<BasketProductItem> = [
        {
            dataIndex: 'urun_bilgisi',
            key: 'urun_bilgisi',
            width: '50%',
            render: (text: string) => {
                return <div className="text-height">{text}</div>
            },
        },
        {
            title: 'Adet',
            dataIndex: 'adet',
            key: 'adet',
            align: 'center',
            render: (urun: number) => {
                // ToDo: disabled özelliği ürünün sınıftaki zorunluluğuna göre düzenlenmeli
                return (
                    <QuantitySelector
                        data={data}
                        kaplama={data.urun?.id!}
                        handleDecrement={handleDecrement}
                        handleIncrement={handleIncrement}
                    />
                )
            },
        },
        {
            dataIndex: 'tutar',
            key: 'tutar',
            align: 'center',
            render: () => {
                // Todo: İndirimsiz fiyat daha küçük ise gösterilmeyecek
                const value = data?.adet
                return (
                    <div className="konum-ayar">
                        {parseInt(data.indirimli_birim_fiyat) < parseInt(data.indirimsiz_birim_fiyat) ?
                            <span className="text-line-through">
                              {setPriceFormat(String(data.indirimsiz_birim_fiyat || '0').toString(), value!)} &#8378;
                            </span>
                             : 
                                null
                            }
                        <span className="text-line-through-uncolor">
                          {setPriceFormat(String(data.indirimli_birim_fiyat || '0').toString(), value!)} &#8378;
                        </span>
                    </div>
                )
            },
        },
        {
            dataIndex: 'sil',
            key: 'sil',
            align: 'center',
            render: (_, record) => {
                return (
                    // ToDo: disabled özelliği ürünün sınıftaki zorunluluğuna göre düzenlenmeli
                    <>
                        {/*{data.urun?.grup.}*/}
                        <Button type="text" size="middle" onClick={handleDelete}>
                            <DeleteOutlined style={{color: 'red'}}/>
                        </Button>
                    </>

                )
            },
        },
    ]

    const tabletAndMobileColumns: ColumnsType<BasketProductItem> = [
        {
            dataIndex: 'urun_bilgisi',
            key: 'urun_bilgisi',
            width: '50%',
            onCell: () => {
                return {
                    style: {
                        padding: '16px 0px',
                    },
                }
            },
            render: (text: string) => {
                return <div className="text-height">{text}</div>
            },
        },
        {
            title: 'Adet',
            dataIndex: 'adet',
            key: 'adet',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        padding: '16px 0px',
                    },
                }
            },
            render: (urun: number) => {
                const value = data?.adet
                // ToDo: disabled özelliği ürünün sınıftaki zorunluluğuna göre düzenlenmeli
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div className="konum-ayar">

                            {data.indirimsiz_birim_fiyat < data.indirimli_birim_fiyat && (
                                <span className="text-line-through">
                            {setPriceFormat(String(data.indirimsiz_birim_fiyat || '0').toString(), value!)} &#8378;
                          </span>
                            )}
                            <span className="text-line-through-uncolor">
                        {setPriceFormat(String(data.indirimli_birim_fiyat || '0').toString(), value!)} &#8378;
                      </span>
                        </div>

                        <QuantitySelector
                            data={data}
                            kaplama={data.urun?.id!}
                            handleDecrement={handleDecrement}
                            handleIncrement={handleIncrement}
                        />
                    </div>
                )
            },
        },
        {
            dataIndex: 'sil',
            key: 'sil',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        padding: '16px 0px',
                    },
                }
            },
            render: (_, record) => {
                const value = data
                console.log(value)
                return (
                    // ToDo: disabled özelliği ürünün sınıftaki zorunluluğuna göre düzenlenmeli
                    <>
                        <Button type="text" size="middle" onClick={handleDelete}>
                            <DeleteOutlined style={{color: 'red'}}/>
                        </Button>
                    </>

                )
            },
        },
    ]

    if (isCustomTabletDimen) {
        columns = tabletAndMobileColumns
    }

    return (
        <>
            {isBasketTableVisible && ( // Conditionally render the table when isBasketTableVisible is true
                <Table
                    className="products-info-table"
                    columns={columns}
                    dataSource={BasketProductItems}
                    pagination={false}
                    showHeader={false}
                />
            )}
        </>
    )
}

export default BasketProductInfoTable

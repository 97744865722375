import './AddressCard.scss'

import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Card, Modal, notification } from 'antd'
import { useAdresSilMutation } from 'gql/GeneratedGraphqlTypes'
import { Adres } from 'gql/StaticTypes'

interface Props {
  data: Adres
}

const AddressCard: React.FC<Props> = (props) => {
  const { data } = props
  const [adresSilMutation] = useAdresSilMutation()

  if (!data) {
    return null
  }

  const { confirm } = Modal

  const showConfirm = (id: number) => {
    confirm({
      title: 'Adresi silmek istediğinize emin misiniz?',
      icon: <ExclamationCircleFilled />,
      content: 'Sildiğiniz adresi geri alamazsınız. Lütfen devam etmek için tamam tuşuna basınız.',
      onOk() {
        adresSilMutation({
          variables: {
            id: id,
          },
        })
        notification.open({
          message: 'Başarılı',
          description: 'Adres başarılı bir şekilde eklendi.',
          type: 'success',
          placement: 'topRight',
        })
      },
      okButtonProps: { style: { backgroundColor: '#44B365' } },
      cancelButtonProps: { style: { backgroundColor: '#EA524D', color: 'white' } },
    })
  }

  return (
    <div className="address-card">
      <Card
        className="card-size"
        title={<p className={'address-title'}>{data.baslik}</p>}
        type={'inner'}
        hoverable={true}
        actions={[
          <Button
            type={'primary'}
            className="sil-address"
            onClick={() => {
              showConfirm(data.id)
            }}
          >
            Sil
          </Button>,
        ]}
      >
        <div>
          <b>İsim:</b> {data.isim}
        </div>
        <div>
          <b>İl:</b> {data.il?.isim}
        </div>
        <div>
          <b>İlçe:</b> {data.ilce?.isim}
        </div>
        <div>
          <b>Mahalle:</b> {data.mahalle?.isim}
        </div>
        <div>
          <b>Açık Adres:</b> {data.adres}
        </div>
        <div>
          <b>Cep Telefonu:</b> {data.gsm}
        </div>
      </Card>
    </div>
  )
}

export default AddressCard

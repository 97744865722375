import './AddressesPage.scss'

import AddAddressForm from '../../../components/add-address-form/AddAddressForm'

const AddAddressesPage = () => {
  return (
    <div className="addresses-page">
      <AddAddressForm />
    </div>
  )
}

export default AddAddressesPage

import './CrossProductsPage.scss'

import { ShoppingCartOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, message, notification, Pagination, Row, Select } from 'antd'
import Spinner from 'components/spinner/Spinner'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CenteredLayout from '../../components/centered-layout/CenteredLayout'
import CrossProductFilterPage from '../../components/cross-products/crossProductFilter'
import AppContext from '../../context/AppContext'
import {
  CaprazUrunFiltre,
  useCarprazUrunlerQuery,
  useGetCategoriesQuery,
  useKategoriveMarkalarQuery,
  useSepetUrunEkleMutation,
} from '../../gql/GeneratedGraphqlTypes'

export type SelectedCategory = {
  id: number
  isim: string
}

const CrossProductPage = () => {
  const ustKategoriId = 2158
  const params = useParams()
  const { slug, okul } = useContext(AppContext)
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>({
    id: ustKategoriId,
    isim: '04. Liste Dışı İhtiyaçlar',
  })
  const [selectedMarkaId, setSelectedMarkaId] = useState<number>(0)
  const [sepetUrunEkleMutation, { loading }] = useSepetUrunEkleMutation()
  const [currentPage, setCurrentPage] = useState(1) // for pagination

  const [selectedOption, setSelectedOption] = useState(1)
  const [minPrice, setMinPrice] = useState(1)
  const [maxPrice, setMaxPrice] = useState(1)
  const [minTempPrice, setMinTempPrice] = useState(1)
  const [maxTempPrice, setMaxTempPrice] = useState(1)
  const [sortedData, setSortedData] = useState<any[]>([]) // Declare sortedData state variable

  let queryVariables = {
    slug: slug!,
    filtre: {} as CaprazUrunFiltre,
  }

  useEffect(() => {
    if (okul?.capraz_urun_satisi_varmi === false) {
      navigate(`/`)
    }

    setSelectedCategory({
      id: parseInt(params.kategoriId!),
      isim: '',
    })
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedMarkaId, selectedCategory])

  useEffect(() => {
    setSelectedMarkaId(0)
  }, [selectedCategory])

  queryVariables.filtre = {
    marka: selectedMarkaId !== 0 ? [selectedMarkaId] : [],
    min_fiyat: minPrice === 1 ? null : minPrice,
    max_fiyat: maxPrice === 1 ? null : maxPrice,
    kategori: [selectedCategory?.id ?? ustKategoriId],
    sayfa: currentPage - 1,
  }

  const { data, loading: caprazUrunLoading } = useCarprazUrunlerQuery({
    variables: queryVariables,
  })

  const { data: categories, loading: categoryLoading } = useGetCategoriesQuery({
    variables: {
      ust_kategori_id: ustKategoriId, // Ust kategori id
    },
  })

  const { data: kategoriMarkalar, loading: kategoriMarkalarLoading } = useKategoriveMarkalarQuery({
    variables: {
      kategoriId: selectedCategory.id ?? ustKategoriId,
    },
  })

  const handleClick = (index: number) => {
    const targetUrl = `/urun/${index}`
    navigate(targetUrl)
  }

  const handleAddToCart = async (productId: number) => {
    const quantity = 1
    try {
      await sepetUrunEkleMutation({
        variables: {
          okulSlug: slug!,
          urun: { urun_id: productId!, adet: quantity },
        },
      })
      notification.open({
        message: 'Başarılı',
        description: 'Ürün başarılı bir şekilde sepete eklendi.',
        type: 'success',
      })
    } catch (error) {
      messageApi.error(`${error}`, 6)
    }
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleFilterSelectChange = (value: number) => {
    setSelectedOption(value)
    if (value !== 1) {
      let updatedSortedData = [...sortedData]

      switch (value) {
        case 2:
          updatedSortedData = updatedSortedData.sort(
            (a, b) => parseFloat(a?.indirimli_birim_fiyat ?? '0') - parseFloat(b?.indirimli_birim_fiyat ?? '0'),
          )
          break
        case 3:
          updatedSortedData = updatedSortedData.sort(
            (a, b) => parseFloat(b?.indirimli_birim_fiyat ?? '0') - parseFloat(a?.indirimli_birim_fiyat ?? '0'),
          )
          break
        case 4:
          updatedSortedData = updatedSortedData.sort((a, b) => (a?.urun?.isim ?? '').localeCompare(b?.urun?.isim ?? ''))
          break
        case 5:
          updatedSortedData = updatedSortedData.sort((a, b) => (b?.urun?.isim ?? '').localeCompare(a?.urun?.isim ?? ''))
          break
        default:
          break
      }

      setSortedData(updatedSortedData)
      setCurrentPage(1)
    } else {
      setCurrentPage(1)
    }
  }

  if (loading || caprazUrunLoading || kategoriMarkalarLoading || categoryLoading) {
    return (
      <Spinner showSpinner={loading || caprazUrunLoading || kategoriMarkalarLoading || categoryLoading} color="red" />
    )
  }

  return (
    <div className="cross-product-container">
      <CenteredLayout>
        {contextHolder}
        <Row gutter={[24, 24]}>
          <Col span={24} order={2} lg={{ span: 18, push: 6, order: 1 }}>
            <Row>
              <Col span={16} md={{ span: 20 }}>
                <h1>{categories?.urunKategorileri?.find((cat) => cat?.id === selectedCategory.id)?.isim}</h1>
              </Col>
              <Col span={8} md={{ span: 4 }}>
                <Select
                  value={selectedOption}
                  style={{ width: '100%' }}
                  onSelect={(value) => handleFilterSelectChange(Number(value))}
                  options={[
                    { value: 1, label: 'Lütfen Seçiniz' },
                    { value: 2, label: 'Fiyata Göre Artan' },
                    { value: 3, label: 'Fiyata Göre Azalan' },
                    { value: 4, label: 'İsme Göre Artan' },
                    { value: 5, label: 'İsme Göre Azalan' },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <div className="card-base">
                <div className="card-container">
                  {data?.okul?.urunler?.capraz_urunler?.length! > 0 &&
                    data?.okul?.urunler?.capraz_urunler?.map((product, index) => {
                      const resim = product?.urun?.resimler?.[0]

                      return (
                        <div className="card-item" key={`cross_urun_${index}_${product?.urun?.id}`}>
                          <div className="img-container">
                            <Image className="card-image" alt="" src={resim ?? ''} />
                          </div>
                          <h3 className="card-prod-name" onClick={() => handleClick(product?.urun?.id ?? 0)}>
                            {product?.urun?.isim}
                          </h3>
                          <div className="card-description">
                            <div className="card-amount">
                              <span className="card-indirimli-fiyat">{product?.indirimli_birim_fiyat} TL</span>
                              {product?.indirimsiz_birim_fiyat! <= product?.indirimli_birim_fiyat! ? null : (
                                <span className="card-indirimsiz-fiyat">{product?.indirimsiz_birim_fiyat} TL</span>
                              )}
                            </div>
                          </div>
                          <div className="card-btn-container">
                            <Button
                              style={{ borderRadius: '0px' }}
                              className="card-sepete-ekle-btn"
                              onClick={() => handleAddToCart(product?.urun?.id ?? 0)}
                            >
                              <ShoppingCartOutlined />
                              <span className="ml-2">SEPETE EKLE</span>
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>

              {data?.okul?.urunler?.capraz_urunler?.length! <= 0 && (
                <Col span={24}>
                  <Card className="urun-card" style={{ textAlign: 'center' }}>
                    <p>Filtre sonucu ürün bulunamadı.</p>
                  </Card>
                </Col>
              )}
            </Row>
            <Pagination
              current={currentPage}
              pageSize={20}
              total={data?.okul?.urunler?.urun_sayisi}
              onChange={handlePageChange}
              style={{ marginTop: 16, textAlign: 'center' }}
              showSizeChanger={false}
            />
          </Col>
          <Col span={24} order={1} lg={{ span: 6, pull: 18, order: 2 }}>
            <CrossProductFilterPage
              categories={categories?.urunKategorileri}
              setSelectedCategory={setSelectedCategory}
              selectedCategoryId={selectedCategory?.id ?? 0}
              markalar={kategoriMarkalar?.urunKategorisi?.markalar}
              selectedMarkaId={selectedMarkaId}
              setSelectedMarkaId={setSelectedMarkaId}
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
              minTempPrice={minTempPrice}
              setMinTempPrice={setMinTempPrice}
              maxTempPrice={maxTempPrice}
              setMaxTempPrice={setMaxTempPrice}
            />
          </Col>
        </Row>
      </CenteredLayout>
    </div>
  )
}

export default CrossProductPage
